import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  CallMissedOutgoingOutlined,
  DonutLarge,
  EmojiEventsOutlined,
  Fingerprint,
} from "@material-ui/icons";
import HolosInput from "../../../../shared/components/holosInput";
import "./newPlaque.scss";
import IconText from "../../../../shared/components/iconText";
import HolosButton from "../../../../shared/components/holosButton";
import HolosAlert from "../../../../shared/components/holosAlert";
import IconSelect from "../../../../shared/components/iconSelect/iconSelect";
import PlaqueLocation from "./parts/location";
import RequestCircles from "../../../circles/services/request";
import RequestPlaque from "../../services/request";
import RequestMaps from "../../../maps/services/request";
import { parseForSelect } from "../../../../utils/helpers";
import DragAndDrop from "../../../../shared/components/dragAndDrop";
import qr from "../../../../assets/icons/qr.svg";
import { Loading } from "../../../../context";

const NewPlaque = (props) => {
  //states alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", message: "" });
  const [whoCalled, setWhoCalled] = useState("");

  const [name, setName] = useState("");
  const [reference, setReference] = useState(undefined);
  const [inMachine, setInMachine] = useState(false);
  const [latitude, setLatitud] = useState("");
  const [longitude, setLongitude] = useState("");
  const [circles, setCircles] = useState([]);
  const [circle, setCircle] = useState(undefined);
  const [filesImage, setFiles] = useState([]);
  const [filePDF, setFilesPDF] = useState([]);
  const [maps, setMaps] = useState([]);
  const [map, setMap] = useState(undefined);
  const [machineTag, setTagMachine] = useState("");
  const [idPlaque, setIdPlaque] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useContext(Loading);
  const { id } = props.match.params;
  const [challenge, setChallenge] = useState(undefined);
  const [recognition, setRecognition] = useState(undefined);
  const [canCreate, setCanCreate] = useState(true);
  const [currentPlaque, setCurrentPlaque] = useState(null);

  //get circles and get maps
  useEffect(() => {
    (async () => {
      setLoading(true);
      let res = RequestCircles.getCircles();
      let res1 = RequestMaps.getMaps();
      res = await res;
      res1 = await res1;
      if (res.status === 200 && res1.status === 200) {
        setCircles(
          parseForSelect(
            res.body.map((circle) => ({
              ...circle,
              challenges: circle?.challenges?.filter(
                (challenge) => challenge?.recognitions?.length > 0
              ),
            }))
          )
        );
        setMaps(parseForSelect(res1.body));
        setIsEdit(false);
        return;
      }
    })();
  }, [setLoading, id]);

  //methods edit plaque
  const parseFiles = async (file) => {
    const response = await fetch(file.awsPath, {
      method: "GET",
    });
    let image = await response.blob();
    let imageFile = new File([image], file.name, {
      type: image.type,
    });
    return imageFile;
  };

  //get plaque in case it get edit
  useEffect(() => {
    if (!circles?.length) {
      setLoading(false);
      return;
    }

    const setEditFiles = async (body) => {
      let imagesParsed = [];

      if (body.images?.length) {
        for (const file of body.images) {
          const parse = await parseFiles(file);
          imagesParsed.push(parse);
        }
      }

      setFiles(imagesParsed);

      let filePDfParsed = await parseFiles(body.file);
      setFilesPDF([filePDfParsed]);
      setLoading(false);
      setIsEdit(true);
    };

    (async () => {
      if (!id) {
        setLoading(false);
        return;
      }

      let res = await RequestPlaque.getPlaque(id);
      if (res?.status === 200) {
        let body = res.body;
        let circle = circles.find((circle) => circle.name === body.circleName);
        const challenge = circle?.challenges.find(
          (challenge) => challenge.name === body.challengeName
        );
        const recognition = challenge?.recognitions.find(
          (recognition) => recognition.name === body.recognitionName
        );

        setMaps((maps) => {
          let filtered = maps?.filter((map) => {
            return map._id === body.idLocation;
          });

          if (!filtered?.length) {
            filtered = maps;
          }
          return parseForSelect(filtered);
        });

        setCircle(circle);
        setChallenge({
          ...challenge,
          value: challenge?.name,
          label: challenge?.name,
        });
        setRecognition({
          ...recognition,
          value: recognition?.name,
          label: recognition?.name,
        });
        setName(body.name);
        setReference({ value: body.reference, label: body.reference });
        setInMachine(body.inMachine);
        setLatitud(parseFloat(body.latitude) || "");
        setLongitude(parseFloat(body.longitude) || "");
        setMap({
          ...body.location,
          label: body.location.name,
          value: body.location.name,
        });
        setTagMachine(body.machineTag);
        setEditFiles(body);
        setCurrentPlaque(body);
        return;
      }
    })();
  }, [circles, setLoading, id]);

  const haveRecognitions = useCallback(() => {
    if (!loading && circle && challenge && !challenge.recognitions?.length) {
      return setCanCreate(false);
    }

    setCanCreate(true);
  }, [challenge, loading, circle]);

  useEffect(() => {
    haveRecognitions();
  }, [haveRecognitions]);

  //methods save plaque
  const onSave = async (e) => {
    let body = handleFormData();
    let isValid = handleValidation(body);

    if (!isValid) {
      setAlertContent({
        type: "error",
        title: "Error",
        message: "Probablemente falte algún dato o archivo",
      });
      setShowAlert(true);
      return;
    }

    let res;

    setLoading(true);
    if (isEdit) res = await RequestPlaque.editPlaque(body, id);
    else res = await RequestPlaque.createPlaque(body);
    setLoading(false);

    if (res.status === 400) {
      setAlertContent({
        type: "error",
        title: "Error",
        message: "Probablemente falte algún dato o archivo",
      });
      setShowAlert(true);
      return;
    }
    setAlertContent({
      type: "success",
      title: "Se ha guardado correctamente la placa QR",
    });
    setShowAlert(true);
    setWhoCalled("saveSuccess");
    setIdPlaque((isEdit && id) || res.body);
  };

  const handleValidation = (body) => {
    const required = [
      "circleName",
      "challengeName",
      "recognitionName",
      "file",
      "name",
      // "latitude",
      // "longitude",
      // "idLocation",
    ];

    if (inMachine) required.push("machineTag");

    for (const req of required) {
      const element = body.get(req);

      if (!element) {
        setIsError(true);
        return false;
      }

      if (Array.isArray(element) && !element.length) {
        setIsError(true);
        return false;
      }

      if (typeof element === "object") {
        let attributes = [];
        for (const attr in element) {
          attributes.push(attr);
        }

        if (!attributes.length) {
          setIsError(true);
          return false;
        }
      }

      if (typeof element === "string" && !element.length) {
        setIsError(true);
        return false;
      }
    }

    setIsError(false);
    return true;
  };

  const handleFormData = () => {
    let formData = new FormData();

    name && formData.append("name", name);
    filePDF[0] && formData.append("file", filePDF[0]);
    parseImage(formData);
    reference?.value && formData.append("reference", reference.value);
    circle?.name && formData.append("circleName", circle.name);
    challenge?.name && formData.append("challengeName", challenge.name);
    recognition?.name && formData.append("recognitionName", recognition.name);
    map?._id && formData.append("idLocation", map._id);
    latitude && formData.append("latitude", latitude);
    longitude && formData.append("longitude", longitude);
    formData.append("user", "desarrollo@holos.cl");
    formData.append("inMachine", inMachine);
    machineTag && formData.append("machineTag", machineTag);
    return formData;
  };

  const parseImage = (formData) => {
    filesImage?.length &&
      filesImage.forEach((element) => {
        formData.append("images", element);
      });
    return formData;
  };

  //methods upload file
  const handleDrop = (newFiles, typeFile) => {
    const files = isImage(typeFile) ? filesImage : filePDF;
    for (var i = 0; i < newFiles.length; i++) {
      let file = newFiles[i];

      if (!file) {
        setAlertContent({
          type: "error",
          title: "Error",
          message: "Archivo corrupto",
        });
        setShowAlert(true);
        return;
      }

      if (files.length + newFiles.length > (isImage(typeFile) ? 3 : 1)) {
        setAlertContent({
          type: "error",
          title: "Error",
          message: "No es posible subir más archivos",
        });
        setShowAlert(true);
        return;
      }

      if (files.some((oldFile) => oldFile.name === file.name)) {
        setAlertContent({
          type: "error",
          title: "Error",
          message: "Archivo duplicado",
        });
        setShowAlert(true);
        return;
      }

      isImage(typeFile)
        ? setFiles((arr) => [...arr, file])
        : setFilesPDF((arr) => [...arr, file]);
    }
  };

  const deleteFile = (fileToDelete, typeFile) => {
    const files = isImage(typeFile) ? filesImage : filePDF;
    const newList = files.filter((file) => file.name !== fileToDelete.name);
    isImage(typeFile) ? setFiles(newList) : setFilesPDF(newList);
  };

  const isImage = (typeFile) => {
    if (typeFile === "image") return true;
    return false;
  };

  //methods alert
  const navigateTo = (typeShow) => {
    if (typeShow === "close") {
      return props.history.push("/list");
    }

    switch (whoCalled) {
      case "saveSuccess":
        props.history.push(`/preview/${idPlaque}`);
        return;
      default:
        return;
    }
  };

  return (
    <div className="NewPlaque">
      <React.Fragment>
        <div className="titleContainer">
          <IconText
            icon={qr}
            iconColor={"var(--black)"}
            iconOpacity={0.54}
            text={"Ingrese los datos de la nueva placa QR"}
            fontSize={"14px"}
            fontWeight={"600"}
            separatingMargin={"0.813em"}
          />
        </div>
        <div className="inputsContainer">
          <div className="first-select">
            <div className="circle-input">
              <IconSelect
                placeholder={"Círculo de calidad encargado"}
                icon={<DonutLarge />}
                iconColor={"var(--black)"}
                backgroundColor={"var(--softWhite)"}
                onChange={(e) => {
                  setCircle(e);
                  setChallenge(undefined);
                  setRecognition(undefined);
                }}
                value={circle}
                options={circles}
                isError={isError}
              />
            </div>
            {circle && (
              <div className="challenge-input">
                <IconSelect
                  placeholder={"Desafío"}
                  icon={<CallMissedOutgoingOutlined />}
                  iconColor={"var(--black)"}
                  backgroundColor={"var(--softWhite)"}
                  onChange={(e) => {
                    setChallenge(e);
                    setRecognition(undefined);
                  }}
                  value={challenge || null}
                  options={parseForSelect(circle.challenges)}
                  isError={isError}
                />
              </div>
            )}
          </div>

          {challenge?.recognitions?.length > 0 && (
            <div className="second-select">
              <div className="recognition-input">
                <IconSelect
                  placeholder={"Reconocimiento"}
                  icon={<EmojiEventsOutlined />}
                  iconColor={"var(--black)"}
                  backgroundColor={"var(--softWhite)"}
                  onChange={(e) => setRecognition(e)}
                  value={recognition || null}
                  options={
                    challenge.recognitions?.length > 0
                      ? parseForSelect(challenge.recognitions)
                      : []
                  }
                  // options={}
                  isError={isError}
                />
              </div>
              {recognition && (
                <HolosInput
                  className="white-background"
                  placeholder={"Nombre de la placa QR"}
                  icon={<Fingerprint />}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  isError={isError}
                />
              )}
            </div>
          )}
          {circle && challenge && !!!challenge.recognitions?.length && (
            <div className="without-recognitions">
              No es posible generar un código QR ya que el desafío seleccionado
              no posee reconocimientos
            </div>
          )}
        </div>
        {canCreate && (
          <React.Fragment>
            <PlaqueLocation
              currentPlaque={currentPlaque}
              reference={reference}
              setReference={setReference}
              latitude={latitude}
              setLatitud={setLatitud}
              longitude={longitude}
              setLongitude={setLongitude}
              maps={maps}
              setMaps={setMaps}
              map={map}
              setMap={setMap}
              inMachine={inMachine}
              setInMachine={setInMachine}
              machineTag={machineTag}
              setTagMachine={setTagMachine}
              files={filesImage}
              handleDrop={(files) => handleDrop(files, "image")}
              deleteFile={(file) => deleteFile(file, "image")}
              isError={isError}
            />
            <div className="pdfContainer">
              <DragAndDrop
                handleDrop={(files) => handleDrop(files, "PDF")}
                deleteFile={(file) => deleteFile(file, "PDF")}
                files={filePDF}
                text={"el archivo PDF"}
                title={"Archivo PDF"}
                types={["application/pdf", "application/vnd.ms-excel"]}
                isError={isError}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
      {canCreate && (
        <div className="saveContainer">
          <HolosButton
            className="purple-background"
            text={{
              name: `${isEdit ? "Editar placa QR" : "Generar placa QR"}`,
              className: "bold medium white-icon-text",
              icon: qr,
            }}
            onClick={(e) => onSave(e)}
          />
        </div>
      )}
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={(isOpen, typeShow) => {
          setShowAlert(isOpen);
          navigateTo(typeShow);
        }}
        title={alertContent.title}
        content={alertContent.message}
        type={alertContent.type}
      />
    </div>
  );
};

export default NewPlaque;
