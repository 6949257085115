import React from "react";

import "./styles.scss";
import {
  AlarmOffOutlined,
  BorderColorOutlined,
  CallMissedOutgoingOutlined,
  ChatBubbleOutline,
  DeleteForeverOutlined,
  DonutLargeOutlined,
  EmojiEventsOutlined,
  LocationOnOutlined,
  PeopleAltOutlined,
} from "@material-ui/icons";
import HolosChip from "../../../../../../shared/components/holosChip";
import GoBack from "../../../../../../shared/components/goBack";
import IconText from "../../../../../../shared/components/iconText";
import { Link } from "react-router-dom";
import HolosButton from "../../../../../../shared/components/holosButton";
import { Modal } from "../../../../../../shared/components/modal";

const CircleInformation = ({ showModal, setShowModal, cssClass }) => {
  // const [circlePhoto, setcirclePhoto] = useState({});

  return (
    <Modal
      key={1}
      showModal={showModal}
      cssClass={"Circle-profile"}
      title={
        <div>
          <div className="circle-profile-data">
            <div className="circle-profile-information">
              <div>
                <GoBack onClick={() => setShowModal(false)} />
              </div>
              <div className="circle-data">
                <div
                  className="circle-profile-picture"
                  // style={{ backgroundImage: `url(${circle})` }}
                ></div>
                <div className="circle-information">
                  <div className="circle-name">
                    <IconText
                      icon={<DonutLargeOutlined />}
                      text={
                        "Le Cangré / M172" /* circle?.name && circle?.code */
                      }
                      className={"small"}
                      separatingMargin={"0.625em"}
                    />
                  </div>
                  <div>
                    <div className="circle-location">
                      <IconText
                        icon={<LocationOnOutlined />}
                        text={
                          "Mantenimiento de Equipos Auxiliares de carga pesada" /* circle?.area */
                        }
                        className={"small"}
                        separatingMargin={"0.625em"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="circle-profile-actions">
                <div className="circle-profile-edition">
                  <Link to="/">
                    <BorderColorOutlined />
                  </Link>
                  <DeleteForeverOutlined />
                  <AlarmOffOutlined />
                </div>
                <HolosChip name={"Atrasado"} status={"late"} />
              </div>
            </div>
            <div className="circle-profile-filters">
              <HolosButton
                className="purple-rounded-corners"
                icon={{ icon: <CallMissedOutgoingOutlined /> }}
                text={{
                  name: "Desafios",
                  className: "bold medium white-icon-text",
                }}
              />
              <HolosButton
                className="transparent-rounded-corners"
                icon={{ icon: <PeopleAltOutlined /> }}
                text={{
                  name: "Equipo de trabajo",
                  className: "bold medium black-icon-text",
                }}
              />
              <HolosButton
                className="transparent-rounded-corners"
                icon={{ icon: <ChatBubbleOutline /> }}
                text={{
                  name: "Conversaciones",
                  className: "bold medium black-icon-text",
                }}
              />
              <div className="item-content circle-conversation">3</div>
              <HolosButton
                className="transparent-rounded-corners"
                icon={{ icon: <EmojiEventsOutlined /> }}
                text={{
                  name: "Reconocimientos",
                  className: "bold medium black-icon-text",
                }}
              />
            </div>
          </div>
        </div>
      }
      content={
        <div>
          <div id="modal-content" className="circle-profile-content">
            {/* <CircleChallengesList /> */}
          </div>
          <div className={"modal-footer"}></div>
        </div>
      }
      setShowModal={setShowModal}
    />
  );
};

export default CircleInformation;
