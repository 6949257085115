import React from "react";
import { Route } from "react-router";
import CirclesDashboard from "./pages/dashboard";
import CirclesList from "./pages/list";
import Actions from "./pages/actions";
import NewCircle from "./pages/new";
import EditChallenge from "./pages/challenges/parts/editChallenge";
import ChallengesList from "./pages/challenges";

const parentRoute = "/circles";

export const Routes = () => (
  <React.Fragment>
    <Route exact path={`${parentRoute}/list`} component={CirclesList} />
    <Route
      exact
      path={`${parentRoute}/challenges`}
      component={ChallengesList}
    />
    <Route
      exact
      path={`${parentRoute}/dashboard`}
      component={CirclesDashboard}
    />
    <Route exact path={`${parentRoute}/new`} component={NewCircle} />
    <Route exact path={`${parentRoute}/:id/edit`} component={NewCircle} />
    <Route
      exact
      path={`${parentRoute}/challenges/:id/edit`}
      component={EditChallenge}
    />
    <Route
      exact
      path={`${parentRoute}/challenges/actions`}
      component={Actions}
    />
  </React.Fragment>
);
