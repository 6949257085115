import React, { useState,useEffect} from "react";
import IconText from "../iconText";
import "./logo.scss";
import logo from "../../../assets/icons/escondida.svg";

const LogoOperation = (props) => {
  let { color, icon, name } = props;

  const [operation, setOperation] = useState("ESCONDIDA");

  useEffect(() => {
    // const getOperationLogo=()=>{};
    return () => {
      setOperation();
    };
  }, []);
  return (
    <div className="Logo-escondida" style={{ color: color }}>
      <img src={logo} alt={`${operation}| BHP |`}></img>
      <IconText
        className={"small bold purple-icon-text"}
        icon={icon}
        text={name}
        alignItems={"center"}
        separatingMargin={"6.4px"}
      />
    </div>
  );
};

export default LogoOperation;
