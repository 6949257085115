import React from "react";
import ButtonTab from "../buttonTab";
import "./styles.scss";

const FloatingModal = ({ header, tabs }) => {
  return (
    <div className="Floating-modal lessBold">
      {header && <div className="floating-modal-header">{header}</div>}
        <ButtonTab
          background={{
            color: "var(--purpleBHP)",
          }}
          tabs={tabs}
          orientation={"vertical"}
          separatingMargin={"1.782"}
        />
    </div>
  );
};

export default FloatingModal;
