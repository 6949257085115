import React from "react";
import "../header/styles.scss";
import ButtonTab from "../../../../shared/components/buttonTab";
import logoLandingPage from "../../../../assets/img/mock/logoLandingPage.svg"


/**
 * A component to display LandingHeader
 *
 * @param {object} props Props passed to the current component
 * @param {string} props.logoName Indicates the logo's name
 * @param {string} props.logoIcon Indicates the logo's icon
 *
 */

const LandingHeader = (props) => {
  const { tabs } = props;
  return (
    <div className="Landing-header grid">
      <div className="logos">
        <img src={logoLandingPage} alt=""></img>
       </div>
      <div className="header-tabs">
        <ButtonTab
          tabs={tabs}
          orientation={"horizontal"}
          separatingMargin={""}
        />
      </div>
    </div>
  );
};

export default LandingHeader;
