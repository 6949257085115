import { SvgIcon } from "@material-ui/core";
import React from "react";
import "./iconText.scss";

/**
 * A component to icon more label with styles
 *
 * @param {object} props Props passed to the current component
 * @param {string|<html>} props.icon Icon with format svg or Component Material-ui
 * @param {string} props.text Text
 * @param {string} props.fontColor Text Color in #hex
 * @param {string} props.fontSize Text size in px or rem or em
 * @param {string} props.fontWeight Text Weight in px
 * @param {string} props.fontOpacity Text opacity in percentage
 * @param {string} props.iconColor Icon Color in #hex
 * @param {string} props.iconSize Icon size in px or rem or em
 * @param {string} props.iconOpacity Icon opacity in percentage
 * @param {string} props.alignItems Horizontally align text
 * @param {string} props.separatingMargin Separation between text and icon in px
 *
 */
const IconText = (props) => {
  const {
    icon,
    text,
    fontColor,
    iconColor,
    fontSize,
    iconSize,
    fontWeight,
    alignItems,
    fontOpacity,
    iconOpacity,
    separatingMargin,
    className,
  } = props;

  const isComponentSVG = (icon, iconColor, className) => {
    if (icon && icon.type === "svg") {
      return icon;
    }

    if (typeof icon === "object" && icon) {
      return (
        <SvgIcon
          component={icon.type}
          style={{
            color: iconColor,
            opacity: !className && (iconOpacity || 1),
            fontSize: iconSize,
          }} 
        />
      );
    }

    return (
      <div
        className="icon"
        style={{
          WebkitMask: `url(${icon}) no-repeat center`,
          WebkitMaskSize: "100%",
          height: !className && (iconSize ? iconSize : "24px"),
          width: !className && (iconSize ? iconSize : "24px"),
          backgroundColor: iconColor,
          opacity: iconOpacity,
        }}
      ></div>
    );
  };

  const getClass = () => {
    let name = "Icon-text";
    if (className) name = `${name} ${className}`;
    return name;
  };

  return (
    <div className={getClass()} style={{ alignItems: alignItems || "center" }}>
      {icon && isComponentSVG(icon, iconColor, className)}
      <div
        className={icon ? "text" : ""}
        style={{
          color: fontColor,
          fontSize: fontSize,
          fontWeight: fontWeight,
          opacity: fontOpacity,
          marginLeft: separatingMargin || "0",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default IconText;
