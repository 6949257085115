import React from "react";
import { SvgIcon } from "@material-ui/core";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import "./iconSelect.scss";
import { Error } from "@material-ui/icons";
import IconText from "../iconText";

const IconSelect = ({
  options,
  name,
  id,
  onChange,
  placeholder,
  backgroundColor,
  icon,
  iconColor,
  iconOpacity,
  value,
  isError,
  msjError,
  loadOptions,
  className,
  onClickIcon,
  onInputChange,
  controlShouldRenderValue,
}) => {
  const isComponentSVG = (icon, iconColor) => {
    if (typeof icon === "object" && icon) {
      return (
        <SvgIcon
          component={icon.type}
          style={{
            color: iconColor,
            opacity: iconOpacity || 0.54,
          }}
        />
      );
    }
    return (
      <img
        src={icon}
        alt="icon"
        style={{ marginLeft: "1.6px", marginRight: "0" }}
      ></img>
    );
  };

  const getClassName = (className, isError) => {
    let currentClass = "IconSelect";
    if (className) currentClass = currentClass.concat(` ${className}`);
    if (isError && !value?.length)
      currentClass = currentClass.concat(" invalid");
    return currentClass;
  };

  return (
    <React.Fragment>
      <div
        className={getClassName(className, isError)}
        style={{ backgroundColor: backgroundColor }}
      >
        <div onClick={onClickIcon}>
          {icon && isComponentSVG(icon, iconColor)}
        </div>
        {!loadOptions ? (
          <Select
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange({ ...e, nameTarget: name })}
            options={options}
            id={id}
            name={name}
            styles={className === "purple-select" && purpleSelect}
            controlShouldRenderValue={controlShouldRenderValue}
          />
        ) : (
          <AsyncSelect
            defaultOptions
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange({ ...e, nameTarget: name })}
            loadOptions={loadOptions}
            id={id}
            name={name}
            onInputChange={onInputChange}
          />
        )}
      </div>
      {isError && msjError && (
        <div className="input-error">
          <IconText
            icon={<Error />}
            text={msjError}
            className="red-icon-text"
            separatingMargin="8px"
          />
        </div>
      )}
    </React.Fragment>
  );
};

//purple-select
const purpleSelect = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "var(--purpleBHP)",
    cursor: "pointer",
    color: "var(--softWhite)",
    "&:hover": {
      backgroundColor: "#661a48",
    },
    icon: {
      fill: "var(--softWhite)",
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    fill: "var(--softWhite)",
    color: "var(--softWhite)",
    icon: {
      fill: "var(--softWhite)",
    },
  }),

  placeholder: (styles) => ({
    ...styles,
    color: "var(--softWhite)",
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: "var(--softWhite)",
      cursor: "pointer",
      color: "var(--softBlack)",
      "&:hover": {
        color: "var(--softWhite)",
        backgroundColor: "var(--purpleBHP)",
      },
    };
  },
};

export default IconSelect;
