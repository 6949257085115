import React, { useCallback, useEffect } from "react";
import "./menu.scss";
import MenuNavigation from "@material-ui/icons/Menu";
import ButtonTab from "../../../buttonTab";

const MenuBrowserMobile = (props) => {
  const { setOpenMenu, openMenu, tabs } = props;
  const pathname = props?.history?.location?.pathname;

  useEffect(() => {
    if (!pathname) return;
    setOpenMenu(false);
  }, [pathname, setOpenMenu]);

  const isInsideMenu = useCallback((e) => {
    if (!document.getElementById("menu").contains(e.target))
      return setOpenMenu(false);
  },[setOpenMenu]);

  useEffect(() => {
    document.addEventListener("click", (e) => isInsideMenu(e));
    return () => {
      window.removeEventListener("click", (e) => isInsideMenu(e));
    };
  }, [openMenu,isInsideMenu]);

  return (
    <div className="Menu-browser-mobile">
      <MenuNavigation
        className="menu-navigation"
        onClick={() => setOpenMenu(!openMenu)}
      />
      {openMenu && (
        <div className="menu-items">
          <ButtonTab
            tabs={tabs}
            orientation={"vertical"}
            separatingMargin={"1.25em"}
            {...props}
          />
        </div>
      )}
    </div>
  );
};

export default MenuBrowserMobile;
