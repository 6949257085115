import { FileCopy, Share } from "@material-ui/icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import "./shareQR.scss";
import { saveAs } from "file-saver";
import qr from "../../../assets/icons/qr.svg";
import qrPurple from "../../../assets/icons/qrPurple.svg";
import { Clipboard } from "@capacitor/clipboard";
import IconText from "../iconText";
import HolosButton from "../holosButton";
import { Loading } from "./../../../context";
import HolosAlert from "../holosAlert";
import RequestManager from "./../../../services/requestManager";
import config from "./../../../services/config";

export const ShareQR = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({});
  const [, setLoading] = useContext(Loading);

  const {
    showModalShare,
    setShowModalShare,
    plaque,
    startDownload,
    setStartDownload,
  } = props;

  const downloadQR = useCallback(async () => {
    try {
      setLoading(true);
      const qr = plaque?.qr[0];
      let doc = await getImg(qr);
      let file = new File([doc], `qr${qr._id}.png`, {
        type: "image/png",
      });
      saveAs(file);
     
      setStartDownload && setStartDownload(false);
      setLoading(false);
    } catch (error) {
      setStartDownload && setStartDownload(false);
    }
  }, [plaque, setStartDownload, setLoading]);

  useEffect(() => {
    if (startDownload) downloadQR();
  }, [startDownload, downloadQR]);

  const copyImg = async (e) => {
    setLoading(true);
    const qr = plaque?.qr[0];
    let data = await getImg(qr);
    let reader = new FileReader();
    data = new Blob([data], { type: "image/png" });
    const callback = async (result) => {
      try {
        await Clipboard.write({
          image: result,
        });
        setShowAlert(true);
        setAlertContent({
          type: "success",
          title: "Se ha copiado el QR",
        });
      } catch (error) {
        return console.log(error);
      }
    };
    reader.readAsDataURL(data);
    reader.onloadend = (event) => {
      callback(event.target.result);
      setLoading(false);
    };
  };

  const getImg = async (qr) => {
    const url = `${config.REACT_APP_API_URL}getfiles/${encodeURIComponent(
      qr.awsKey
    )}`;
    const data = await RequestManager.getBlob(url);
    return data.body;
  };

  return (
    <div className="Share-qr">
      <HolosAlert
        cssClass="Share-qr"
        key={1}
        showAlert={showModalShare}
        setShowAlert={setShowModalShare}
        title={
          <React.Fragment>
            <IconText
              icon={qrPurple}
              text={plaque?.name}
              iconColor={"var(--purpleBHP)"}
              fontWeight={400}
              fontColor={"var(--softBlack)"}
              fontSize={"14px"}
            />
          </React.Fragment>
        }
        content={
          <div className="share-content">
            <IconText
              icon={<Share />}
              text={"Compartir Placa QR"}
              iconOpacity={0.54}
              fontSize={"14px"}
              fontWeight={500}
              fontColor={"var(--softBlack)"}
              separatingMargin={"1.375em"}
            />
          </div>
        }
        actions={[
          <HolosButton
            key={new Date()}
            background={{
              color: "var(--purpleBHP)",
            }}
            icon={{
              color: "#ffffff",
              icon: <FileCopy />,
              opacity: 1,
            }}
            text={{
              name: "Copiar QR",
              color: "#ffffff",
            }}
            onClick={(e) => {
              setShowModalShare(false);
              copyImg(e);
            }}
          />,
          <HolosButton
            key={1}
            background={{
              color: "transparent",
            }}
            icon={{
              color: "var(--purpleBHP)",
              icon: qr,
              opacity: 1,
            }}
            text={{
              name: "Descargar QR",
              color: "var(--purpleBHP)",
            }}
            onClick={() => {
              setShowModalShare(false);
              downloadQR();
            }}
          />,
        ]}
      />
      <HolosAlert
        cssClass={`toast`}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        title={alertContent.title}
        type={alertContent.type}
        timeToast={1500}
      />
    </div>
  );
};

export default ShareQR;
