import React from "react";

import {
  AvTimer,
  CallMissedOutgoingOutlined,
  Code,
  DashboardOutlined,
  DonutLargeOutlined,
  LocationOnOutlined,
} from "@material-ui/icons";
import actions from "./../../assets/icons/pendingActionsBlack24dp.svg";

export const darkGrey = "#444444";

export const moduleTabs = [
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Panel de control",
      icon: <DashboardOutlined />,
    },
    urlRedirect: "/circles/dashboard",
  },
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Círculos de calidad",
      icon: <DonutLargeOutlined />,
    },
    urlRedirect: "/circles/list",
  },
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Desafíos",
      icon: <CallMissedOutgoingOutlined />,
    },
    urlRedirect: "/circles/challenges",
  },
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Acciones",
      icon: actions,
    },
    urlRedirect: "/circles/challenges/actions",
  },
];

export const tabs = [
  {
    text: {
      name: "Nuevo círculo",
      color: "var(--softWhite)",
      size: "16px",
      weight: "600",
    },
    icon: {
      color: "#ffffff",
      icon: <DonutLargeOutlined />,
      opacity: 1,
    },
    background: {
      color: "var(--purpleBHP)",
    },
    urlRedirect: "/circles/new",
  },
];

export const generateHeaders = (onClick) => {
  const headers = [
    {
      text: "Acciones",
      class: "actions",
    },
    {
      icon: <Code />,
      class: "code",
      onClick: () => { onClick("code") }
    },
    {
      icon: <DonutLargeOutlined />,
      text: "Círculo",
      class: "circle",
      onClick: () => { onClick("name") }

    },
    {
      icon: <LocationOnOutlined />,
      text: "Area",
      class: "area",
      onClick: () => { onClick("area") }
    },
    {
      icon: <AvTimer />,
      text: "Estado",
      class: "status",
      onClick: () => { onClick("circleState") }
    },
  ];

  return headers
};

export const itemsPerPageRef = [
  { label: "Mostrar 20 filas", value: 20, id: 1 },
  { label: "Mostrar 10 filas", value: 10, id: 2 },
  { label: "Mostrar 5 filas", value: 5, id: 3 },
];