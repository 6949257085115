import {
  MyLocationOutlined,
  SettingsEthernetOutlined,
  Share,
} from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import IconText from "../../../../shared/components/iconText";
import { getCenterPolygon, removeAccents } from "../../../../utils/helpers";
import HolosSearch from "../../shared/holosSearch";
import InfoCard from "../../shared/infoCard";
import MapCard from "../../shared/mapCard";
import RequestMap from "../../../maps/services/request";
import qr from "./../../../../shared/components/googleMap/icons/QR.png";
import qricon from "../../../../assets/icons/qr.svg";
import qrPurple from "./../../../../shared/components/googleMap/icons/currentQR.png";
import { DonutLargeOutlined } from "@material-ui/icons";

import "./styles.scss";
import { Loading } from "../../../../context";
import ShareQR from "../../../../shared/components/shareQR";

const ListPlaque = (props) => {
  const [map, setMap] = useState(null);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [currentSearch, setCurrentSearch] = useState("");
  const [showModalShare, setShowModalShare] = useState(false);
  const [filter, setFilter] = useState([]);
  const [, setLoading] = useContext(Loading);

  const { id } = props.match.params;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const map = (await RequestMap.getMap(id)).body;
      setLoading(false);
      if (map) {
        setMap(map);
        const markers = map.plaques?.map((plaque) => {
          return {
            id: plaque._id,
            type: "plaque",
            images: plaque.images,
            circleName: plaque.circleName,
            name: plaque.name,
            coords: {
              lat: parseFloat(plaque.latitude),
              lng: parseFloat(plaque.longitude),
            },
            current: false,
          };
        });
        setMapMarkers(markers);
      } else setMap(null);
    })();
  }, [id, setLoading]);

  useEffect(() => {
    filterByLocation(map, currentSearch, setFilter);
  }, [map, currentSearch]);

  const onChange = (e, setCurrentSearch) => {
    setCurrentMarker(null);
    setCurrentSearch(e.currentTarget.value);
  };

  // filter regardless of capital letters and accents
  const filterByLocation = (map, text, setFilter) => {
    if (text === "") {
      setCurrentMarker(null);
      return setFilter(map);
    }
    const textWithoutAccent = removeAccents(text).toLowerCase();

    const filter = map.plaques.filter(
      (plaque) =>
        removeAccents(plaque.name.toLowerCase()).includes(textWithoutAccent) ||
        removeAccents(plaque.circleName.toLowerCase()).includes(
          textWithoutAccent
        )
    );

    return setFilter({ ...map, plaques: filter });
  };

  return (
    <div className="List-plaque">
      <HolosSearch
        icon={qricon}
        placeholder={"Buscar por placa QR o círculo"}
        value={currentSearch}
        onChangeSearch={(e) => onChange(e, setCurrentSearch)}
      />
      <MapCard
        header={
          <IconText
            icon={<MyLocationOutlined />}
            text={map && map.name}
            separatingMargin={"0.6875em"}
            fontColor={"var(--purpleBHP"}
            iconColor={"var(--purpleBHP"}
          />
        }
        footer={
          <IconText
            icon={qricon}
            text={`${
              map && map.plaques ? map.plaques.length : 0
            } placa/s QR activas en esta ubicación`}
            separatingMargin={"0.6875em"}
            fontColor={"#444444"}
            iconColor={"#444444"}
          />
        }
        areaCoords={map?.areaCoords}
        markers={mapMarkers}
        center={getCenterPolygon(map?.areaCoords)}
        markerIconURL={qr}
        currentMarkerIconURL={qrPurple}
        currentMarker={currentMarker}
        setCurrentMarker={setCurrentMarker}
      />
      {filter &&
        filter.plaques &&
        filter.plaques.map((plaque, index) => (
          <div
            key={index}
            className={`plaque-info-card ${
              plaque._id === currentMarker ? "active-marker" : ""
            }`}
            onMouseEnter={() => setCurrentMarker(plaque._id)}
            onMouseLeave={() => setCurrentMarker(null)}
            onTouchStart={() => setCurrentMarker(plaque._id)}
          >
            <InfoCard
              link={`/visor/preview/${plaque._id}`}
              title={
                <IconText icon={qricon} text={plaque.name} iconSize={"2em"} />
              }
              content={
                <React.Fragment>
                  <div className="content-row">
                    <IconText
                      icon={<DonutLargeOutlined />}
                      iconColor={"var(--softBlack)"}
                      text={`Círculo de Calidad`}
                      iconSize={"2em"}
                      alignItems={"center"}
                    />
                    <span>{plaque.circleName}</span>
                  </div>
                  {plaque.machineTag && (
                    <div className="content-row">
                      <IconText
                        icon={<SettingsEthernetOutlined />}
                        text={`Tag`}
                        fontWeigth={600}
                      />
                      <span>{plaque.machineTag}</span>
                    </div>
                  )}
                </React.Fragment>
              }
              footer={
                <div
                  onClick={() => setShowModalShare(true)}
                  style={{ cursor: "pointer" }}
                >
                  <IconText
                    icon={<Share />}
                    text={"Compartir placa QR"}
                    iconColor={"softBlack"}
                    fontSize={"14px"}
                    fontWeight={500}
                    fontColor={"var(--softBlack)"}
                  />
                </div>
              }
            />
            <ShareQR
              plaque={plaque}
              setShowModalShare={setShowModalShare}
              showModalShare={showModalShare}
            />
          </div>
        ))}
    </div>
  );
};

export default ListPlaque;
