import React from "react";

const CirclesDashboard = (props) => {
    return(
        <div className="Circles-dashboard">
            CirclesDashboard
        </div>
    )
}

export default CirclesDashboard;
