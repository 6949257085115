import React, { useState } from "react";
import Picture from "../../../../../../../../shared/components/picture";

const ProfilePictureDataForm = ({ setPersonData, setCanContinue }) => {
  const [objectURL, setObjectURL] = useState("");

  const onChange = (e) => {
    let file = e.target.files[0];

    let object = URL.createObjectURL(file);
    setObjectURL(object);

    //set the id of the select in the parent component
    setPersonData({ name: e.target.name, value: file });
  };

  return (
    <form className="form">
      <div className="medium bold form-title">Foto de perfil</div>
      <Picture
        isValidated={setCanContinue}
        onChange={onChange}
        url={objectURL}
        information
        types={["image/png","image/jpg","image/jpeg"]}
      />
    </form>
  );
};

export default ProfilePictureDataForm;
