import React from "react";
import HolosButton from "../../../../shared/components/holosButton";
import HolosLink from "../../../../shared/components/holosLink/holosLink";

import "./styles.scss";

const UsersButtons = ({
  disabled,
  onSubmit,
  buttonName,
  className,
  text,
  link,
  linkText,
  linkOnClick,
  type,
}) => {
  return (
    <React.Fragment>
      <HolosButton
        className="purple-background users-button"
        text={{ name: buttonName, className: "lessBold white-icon-text" }}
        type={type ||"button"}
        width="100%"
        disabled={disabled}
        onClick={onSubmit}
      />
      <HolosLink
        className={className}
        text={text}
        link={link}
        linkText={linkText}
        onClick={linkOnClick}
      />
    </React.Fragment>
  );
};

export default UsersButtons;
