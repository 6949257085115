import React, { useState, useEffect } from "react";
import "../pages/styles.scss";
import LandingHeader from "../shared/header";
import LandingBenefits from "../shared/benefits";
import EventCalendar from "../shared/eventCalendar";
import { AccountCircle, Fingerprint, PermPhoneMsg } from "@material-ui/icons";
import Support from "../../../shared/components/support";
import HolosButton from "../../../shared/components/holosButton";
import Request from "../../landingPage/services/request";

const LandingPage = (props) => {
  const [landing, setLanding] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [slogan, setSlogan] = useState("");
  const [background, setBackground] = useState("");
  const [benefit1, setBenefit1] = useState("");
  const [benefit2, setBenefit2] = useState("");
  const [benefit3, setBenefit3] = useState("");
  const [events, setEvents] = useState([]);

  const onClick = () => {
    return window.location.assign("/users/login/");
  }; 

  const tabs = [
    {
      className: "transparent-background grey-background-hover",
      text: {
        className: "small",
        name: "Soporte",
        icon: <PermPhoneMsg />,
      },
      urlRedirect: "#",
      component: {
        class: (
          <Support isOpen={isOpen} setIsOpen={(bool) => setIsOpen(bool)} />
        ),
        isOpen: isOpen,
        setIsOpen: setIsOpen,
      },
    },
    {
      className: "transparent-background grey-background-hover",
      text: {
        className: "small",
        name: "Crear Cuenta",
        icon: <AccountCircle />,
      },
      urlRedirect: "#",
      onClick: () => window.location.assign("/users/register/"),
    },

    {
      className: "purple-background white-background-hover",
      text: {
        className: "small white-icon-text",
        name: "Ingresar",
        icon: <Fingerprint />,
      },
      urlRedirect: "#",
      onClick: () => window.location.assign("/users/login/"),
    },
  ];
  
  useEffect(() => {
    const getLanding = async () => {
      const landing = (await Request.getLanding()).body;
      if (landing) setLanding(landing);
      setSlogan(landing?.slogan);
      setBackground(landing?.coverUrl);
      setBenefit1(landing?.benefits?.benefit1);
      setBenefit2(landing?.benefits?.benefit2);
      setBenefit3(landing?.benefits?.benefit3);
      setEvents(landing?.events);
    };

    if (!landing) getLanding();
  }, [landing]);

  return (
    <div
      className="Landing-page"
      style={{
        backgroundImage: `url(${background ? background : ""})`,
      }}
    >
      <LandingHeader tabs={tabs} />
      <div className="landing-body grid">
        <div className="main-container">
          <div className="main-title">{slogan}</div>
          <HolosButton
            separatingMargin={"1em"}
            background={{ color: "var(--purpleBHP)" }}
            text={{
              className: "small white-icon-text",
              name: "Ingresar",
              icon: <Fingerprint />,
            }}
            onClick={onClick}
          />
        </div>
        <LandingBenefits
          benefit1={benefit1}
          benefit2={benefit2}
          benefit3={benefit3}
        />
        <EventCalendar events={events} />
      </div>
    </div>
  );
};

export default LandingPage;
