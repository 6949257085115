import React from "react";
import { Route } from "react-router";
import ListMaps from "./pages/list";
import NewMap from "./pages/new";
import PreviewMap from "./pages/preview";

const parentRoute = "/maps";

export const Routes = () => (
  <React.Fragment>
    <Route path={`${parentRoute}/list`} component={ListMaps} />
    <Route path={`${parentRoute}/new`} component={NewMap} />
    <Route path={`${parentRoute}/preview/:id`} component={PreviewMap} />
    <Route path={`${parentRoute}/edit/:id`} component={NewMap} />
  </React.Fragment>
);
