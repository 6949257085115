import React from "react";

import "./styles.scss";

const ListRow = ({
  index,
  row,
  showRowsColor,
  gridTemplateColumns,
  setCurrent,
  gridGap,
}) => {
  return (
    <div
      className="list-row"
      onMouseEnter={() => setCurrent && setCurrent(row.id)}
      onMouseLeave={() => setCurrent && setCurrent(null)}
      style={{
        gridTemplateColumns: gridTemplateColumns,
        gridGap: gridGap,
        backgroundColor: `${
          showRowsColor && index % 2 === 0 ? "#F5F5F5" : "#FFFFFF"
        }`,
      }}>
      {row.row || row}
    </div>
  );
};

export default ListRow;
