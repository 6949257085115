import React, { useState } from "react";
import IconText from "../../../../../../shared/components/iconText";
import {
  AlarmOn,
  EventAvailable,
  Share,
  WifiTethering,
} from "@material-ui/icons";
import { Clipboard } from "@capacitor/clipboard";
import HolosAlert from "../../../../../../shared/components/holosAlert";
 
const Event = ({ event }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({});

  const onCopyEvent = () => {
    try {
      Clipboard.write({
        string: event?.link,
      });
      setShowAlert(true);
      setAlertContent({
        type: "success",
        title: "Se ha copiado el enlace",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Event">
      <div className="event-detail">
        <div className="title lessBold">{event?.name}</div>
        <div className="event-clock">
          <IconText
            className="small"
            icon={EventAvailable}
            text={event?.date}
            separatingMargin="13px"
          />
          <IconText
            className="small"
            icon={AlarmOn}
            text={event?.time}
            separatingMargin="13px"
          />
        </div>
        <IconText
          className="modality small"
          icon={WifiTethering}
          text={event?.modality}
          separatingMargin="13px"
        />
        <div className="event-share" onClick={() => onCopyEvent()}>
          <IconText
            className="share small purple-icon"
            icon={Share}
            text="Comparte este evento con tu equipo"
            separatingMargin="13px"
          />
        </div>
      </div>
      <div
        className={"img"}
        style={{ backgroundImage: `url(${event? event.imageUrl : ""})` }}
      ></div>
      <HolosAlert
        cssClass={`toast`}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        title={alertContent.title}
        type={alertContent.type}
        timeToast={1500}
      />
    </div>
  );
};

export default Event;
