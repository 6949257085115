import React, { useEffect, useState } from "react";
import HolosAlert from "../../../../../../../../../../shared/components/holosAlert";
import HolosInput from "../../../../../../../../../../shared/components/holosInput";
import HolosButton from "../../../../../../../../../../shared/components/holosButton";
import HolosTextArea from "../../../../../../../../../../shared/components/HolosTextArea";

import "./styles.scss";

const NewReasonModal = ({ setShowModal, showModal, updateWhy, reasonData }) => {
  const [data, setData] = useState({...reasonData});

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setData((data) => ({ ...data, [name]: value }));
  };

  useEffect(() => {
    setData({...reasonData})
  }, [reasonData])

  return (
    <HolosAlert
      showAlert={showModal}
      setShowAlert={setShowModal}
      title={"Porqué"}
      content={
        <form className="New-reason-modal">
          <div className="form-group">
            <div className="form-control">
              <label htmlFor="member" className="form-label small bold">
                Titulo *
              </label>
              <HolosInput
                className="bold title-input mr-small"
                placeholder="No se visualizan las filtraciones"
                id="title"
                name="title"
                value={data?.title ? data.title : ""}
                onChange={handleChange}
              />
            </div>
          </div>{" "}
          <div className="form-group">
            <div className="form-control">
              <label htmlFor="member" className="form-label small bold">
                Descripción *
              </label>
              <HolosTextArea
                className="mt-small"
                placeholder="Las instalaciones no permiten visualizar..."
                id="description"
                name="description"
                height="200px"
                value={data?.description ? data.description : ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
      }
      actions={[
        <HolosButton
          key={1}
          separatingMargin={"1em"}
          className="purple-background"
          text={{ name: "Guardar", className: "white-icon-text" }}
          onClick={() => {
            setShowModal(false);
            updateWhy(data);
            setData({})
          }}
        />,
      ]}
    ></HolosAlert>
  );
};

export default NewReasonModal;
