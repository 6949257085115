import React, { useContext, useEffect, useState } from "react";
import HolosLink from "../../../../shared/components/holosLink/holosLink";
import { useHistory, useParams } from "react-router-dom";
import SmallBaseBody from "../../../../shared/components/smallBaseBody";

import LoginForm from "./parts/loginForm";
import Request from "./../../services/request";
import UsersButtons from "../../shared/usersButtons";
import { Loading } from "../../../../context";

import "./styles.scss";
import HolosAlert from "../../../../shared/components/holosAlert";
import HolosCheck from "../../../../shared/components/holosCheck";
import { getErrorMessage } from "../../../../services/errors";
import ResendEmailAlert from "../../shared/resendEmailAlert";
import * as helpers from "../../../../../src/utils/helpers";
import emptyImage from "../../../../assets/img/emptyImage.svg";

const Login = () => {
  const history = useHistory();
  const { token } = useParams();
  const [, setLoading] = useContext(Loading);

  //states alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", message: "" });
  const [showInactiveModal, setShowInactiveModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [canLogin, setCanLogin] = useState(true);
  const [isLogged] = useState(localStorage.getItem("isLogged"));
  const [isError, setIsError] = useState("");
  const [msjError, setMsjError] = useState("");

  const timeToast = 2000;

  const [keepSession, setKeepSession] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleError = (res) => {
    if (res.internalCode === "USR1001") {
      setShowInactiveModal(true);
      return;
    }
    setMsjError(getErrorMessage(res.internalCode));
    setIsError(true);
    return;
  };

  const setDataToLocalStorage = (res) => {
    localStorage.setItem("isLogged", true);

    let person = res?.body;
    const hasPhoto = helpers.isEmptyElementInObject(person?.profile?.photo);
    if (!hasPhoto) {
      person.profile["photo"] = {
        name: "emptyImage",
        awsKey: "emptyImage.svg",
        mimetype: "image/svg+xml",
        awsPath: `${emptyImage}`,
      };
    }
    localStorage.setItem("person", JSON.stringify(person));
  };

  const onSubmit = async () => {
    const { email, password } = data;

    setLoading(true);
    const res = await Request.login(
      {
        email,
        password,
        idOperation: "mockup",
        keepSession,
      },
      token
    );

    setLoading(false);

    if (res.status !== 200) {
      handleError(res);
      return;
    }
    setDataToLocalStorage(res);

    if (token) {
      setAlertContent({
        type: "success",
        title: "Bienvenida/o a Yo Contribuyo",
        message: "¡Tu cuenta ha sido activada!",
      });
      return setShowAlert(true);
    }

    history.push("/dashboard");
  };

  const onChange = (e) => {
    const { name, value } = e.currentTarget;
    setData({ ...data, [name]: value });
    setIsError(false);
  };

  const onResend = async () => {
    setLoading(true);
    const body = {
      email: data.email,
      idOperation: "mockup",
    };
    const res = await Request.resendActivateAccount(body);
    if (res.status === 200) {
      setShowToast(true);
      setTimeout(() => {
        history.push("/landingpage");
      }, timeToast);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isLogged) history.push("/dashboard");
  }, [isLogged, history]);

  useEffect(() => {
    if (!data.email.length || !data.password.length) return setCanLogin(false);
    return setCanLogin(true);
  }, [data]);

  return (
    <div className="Login">
      <SmallBaseBody
        className={"login-container"}
        content={
          <React.Fragment>
            <p className="bold login-title great">Iniciar sesión</p>
            <LoginForm
              data={data}
              onChange={onChange}
              msjError={msjError}
              isError={isError}
            />
            <HolosCheck
              label="¿Mantener iniciada la sesión?"
              className="check-first"
              onChange={(checked) => {
                setKeepSession(checked);
              }}
            />
            <HolosLink
              text="¿Aún no tienes una cuenta?"
              link="/users/register"
              linkText={"Crea una nueva"}
            />
          </React.Fragment>
        }
        buttons={
          <UsersButtons
            disabled={!canLogin}
            onSubmit={onSubmit}
            buttonName="Ingresar"
            text="No recuerdas tu contraseña?"
            link="/users/restorePassword"
            linkText="Recuperala"
            type="submit"
          />
        }
      />
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={(isOpen) => {
          setShowAlert(isOpen);
          history.push(`/dashboard`);
        }}
        title={alertContent.title}
        content={alertContent.message}
        type={alertContent.type}
      />
      <ResendEmailAlert
        isOpen={showInactiveModal}
        title={"Tu cuenta no ha sido verificada"}
        text={`Por favor, revisa tu correo electrónico. Si no te ha llegado el correo de verificación puedes reenviarlo nuevamente`}
        onResend={onResend}
      />
      <HolosAlert
        cssClass={`toast`}
        showAlert={showToast}
        setShowAlert={setShowToast}
        title={"Email de reactivación de cuenta enviado correctamente"}
        type={"success"}
        timeToast={timeToast}
      />
    </div>
  );
};

export default Login;
