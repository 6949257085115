import React from "react";
import FormButtonStepper from "../../../../../../../../shared/components/formButtonStepper";
import DefinitionForm from "./parts/definitionForm";

const ChallengesDefinition = () => {
  return (
    <div className="Challenges-definition">
      <DefinitionForm />
      <FormButtonStepper />
    </div>
  );
};

export default ChallengesDefinition;
