import { Cached, DonutLarge, Settings, TrendingUp, WbIncandescentOutlined } from "@material-ui/icons";
import React from "react";
import QRCode from "../../assets/qr";

const tabs = [
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select ideas",
      name: "Ideas",
      icon: <WbIncandescentOutlined />,
    },
    urlRedirect: "/ideas",
  },
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Circulos de calidad",
      icon: <DonutLarge />,
    },
    urlRedirect: "/circles/list",
  },
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Placas",
      icon: <QRCode />,
    },
    urlRedirect: "/plaques/list",
  },
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Informes",
      icon: <TrendingUp />,
    },
    urlRedirect: "/reports",
  },
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Solicitudes de cambios",
      icon: <Cached />,
    },
    urlRedirect: "/requests",
  },
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Configuración",
      icon: <Settings />,
    },
    urlRedirect: "/settings",
  },
];

export default tabs