import React, { useEffect, useState } from "react";
import "./profile.scss";

const Profile = (props) => {
  let { color } = props;
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(JSON.parse(window.localStorage.getItem("person")));
  }, []);

  const generateProfileName = () => {
    let name = "";
    name = name.concat(` ${emptyString(user?.profile?.firstName)}`);
    name = name.concat(` ${emptyString(user?.profile?.secondName)}`);
    name = name.concat(` ${emptyString(user?.profile?.firstLastName)}`);
    name = name.concat(` ${emptyString(user?.profile?.secondLastName)}`);
    return name;
  };

  const emptyString = (string) => {
    return string || "";
  };

  return (
    <div className={"Profile"} style={{ color: color }}>
      <div
        className={"imgProfile"}
        style={{ backgroundImage: `url(${user?.profile?.photo?.awsPath})` }}
      ></div>
      <div className={"text"}>
        <span>{generateProfileName()} </span>
      </div>
    </div>
  );
};

export default Profile;
