import React, { useEffect } from "react";

import {
  CheckCircleOutline,
  Clear,
  ErrorOutline,
} from "@material-ui/icons";

import HolosButton from "../holosButton";
import IconText from "../iconText";
import { Modal } from "../modal";

import "./holosAlert.scss";

const getAlertIcon = (type) => {
  switch (type) {
    case "success":
      return <CheckCircleOutline />;
    case "error":
    case "warning":
      return <ErrorOutline />;
    default:
      break;
  }
};

/**
 * Alert component, can also be used as toast
 * @param {object} props
 * @param {boolean} props.showAlert - variable to show component.
 * @param {function} props.setShowAlert -  function to render component.
 * @param {string|<html>} props.title -  alert title, <html> can be injected.
 * @param {string|<html>} props.content -  alert content without buttons, <html> can be injected.
 * @param {<html>} props.actions -  alert buttons.
 * @param {string} props.type -  type alert, ex: "error","success",warning.
 * @param {number} props.timeToast -  time when the alert disappears, acctive mode Toast.
 * @param {string} props.cssClass -  modal class name.
 */

const HolosAlert = (props) => {
  const {
    showAlert,
    title,
    content,
    setShowAlert,
    type,
    actions,
    timeToast,
    cssClass,
    modalActions,
  } = props;
  console.log("modalActions :>> ", modalActions);

  useEffect(() => {
    if (!timeToast) return;
    if (!showAlert) return;
    setTimeout(() => {
      setShowAlert(false, "close");
    }, timeToast);
  }, [showAlert, timeToast, setShowAlert]);

  const getActions = () => {
    if (actions) return actions;
    if (timeToast) return undefined;
    return (
      <HolosButton
        separatingMargin={"1em"}
        background={{ color: "var(--purpleBHP)" }}
        text={{ name: "Aceptar", color: "white", colorHover: "white" }}
        onClick={() => {
          setShowAlert(false, "accept");
        }}
      />
    );
  };

  const showClose = () => {
    if (type === "error" || timeToast) return false;
    return true;
  };

  return (
    <div className="Holos-alert">
      <Modal
        key={1}
        showModal={showAlert}
        title={
          <div className={"holos-alert-title"}>
            <IconText
              icon={getAlertIcon(type)}
              text={title}
              iconColor={type === "error" ? "black" : "var(--purpleBHP)"}
              iconOpacity={type === "error" ? "0.54" : "1"}
            />
            <span className={"holos-alert-actions"}>
              {modalActions && [...modalActions]}
              {showClose() && (
                <Clear
                  onClick={() => {
                    setShowAlert(false, "close");
                  }}
                />
              )}
            </span>
          </div>
        }
        cssClass={`Holos-alert ${cssClass && cssClass}`}
        content={
          content ? <div className="holos-alert-content">{content}</div> : null
        }
        actions={getActions()}
        setShowModal={setShowAlert}
      />
    </div>
  );
};

export default HolosAlert;
