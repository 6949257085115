import React from "react";

const Actions = (props) => {
    return(
        <div className="Actions">
            Actions
        </div>
    )
}

export default Actions;
