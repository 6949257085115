import React from "react";
import ListRow from "../listRow";

const ListBody = ({
  rows,
  gridTemplateColumns,
  gridGap,
  setCurrent,
  showRowsColor,
}) => {
  return (
    <div className="list-body">
      {(rows?.length &&
        rows.map((row, index) => (
          <ListRow
            showRowsColor={showRowsColor}
            gridTemplateColumns={gridTemplateColumns}
            gridGap={gridGap}
            key={index}
            index={index}
            row={row}
            setCurrent={setCurrent}
          />
        ))) || (
        <div className="list-without-items">
          <p>No se han encontrado resultados</p>
        </div>
      )}
    </div>
  );
};

export default ListBody;
