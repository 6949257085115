import React from "react";

const InvalidQR = () => {
  return (
    <div style={{ padding: "0  0.625em 0.625em 0.625em" }}>
      Código QR inválido
    </div>
  );
};

export default InvalidQR;
