import React, { useState, useEffect, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { getErrorMessage } from "../../../../../../../../services/errors";
import HolosInput from "../../../../../../../../shared/components/holosInput";
import IconSelect from "../../../../../../../../shared/components/iconSelect/iconSelect";
import * as helpers from "../../../../../../../../utils/helpers";
import Request from "../../../../../../../users/services/request";
import Parameter from "../../../../../../../../services/globalApi/paremeters";
import HolosAlert from "../../../../../../../../shared/components/holosAlert";
import moment from "moment";
import HolosDate from "../../../../../../../../shared/components/holosDate";

const PersonalDataForm = ({
  setPersonData,
  person,
  setCanContinue,
  viewError,
}) => {
  const [rutMsjError, setRutMsjError] = useState("");

  const [currentGender, setCurrentGender] = useState(null);
  const [genders, setGenders] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", message: "" });
  const onSelect = (selectedItem, nameId) => {
    //set the id of the select in the parent component
    setPersonData({ name: nameId, value: selectedItem._id });

    //save selector data locally
    setCurrentGender(selectedItem);
  };

  const searchPerson = (e) => {
    setPersonData(e);
  };

  /** Validate fields */
  const IsInvalidRUT = async (value) => {
    let type = "person";
    if (helpers.isEmptyString(value)) return true;
    if (!helpers.validRUT(value, type)) {
      setRutMsjError(null);
      return true;
    }
    const res = await Request.validateRUT(person?.email, value);
    if (res.status !== 200) {
      setRutMsjError(getErrorMessage(res?.internalCode));
      return true;
    }
    setRutMsjError(null);
    return false;
  };

  const fieldValidation = {
    rut: async (rut) => await IsInvalidRUT(rut),
    firstName: (firstName) => !helpers.validateInputText(firstName),
    firstLastName: (firstLastName) => !helpers.validateInputText(firstLastName),
    contactPhone: (contactPhone) => helpers.isEmptyString(contactPhone),
    idGender: (idGender) => helpers.isEmptyString(idGender),
  };

  const errors = helpers.useValidation(person, fieldValidation);

  const existError = useCallback((objectError) => {
    //convert object to array
    const arrayErrors = Object.values(objectError);
    //search error=true
    let error = arrayErrors.find((e) => e);
    //object attributes repeats the process
    if (typeof error === "object") error = existError(error);
    return error;
  }, []);
  /** -- */

  useEffect(() => {
    if (!Object.values(errors).length) return;
    if (!existError(errors)) return setCanContinue(true);

    setCanContinue(false);
  }, [errors, setCanContinue, existError]);

  const getSelects = useCallback(async () => {
    // setLoading(true);
    let res = await Parameter.getGenders();
    // setLoading(false);
    if (res.status !== 200) handleError(res);

    (res.body || []).map((gender) => {
      if (gender.name === "female") gender.name = "Femenino";
      if (gender.name === "male") gender.name = "Masculino";
      return gender.name;
    });
    setGenders(helpers.parseForSelect(res?.body));
  }, [setGenders]);

  useEffect(() => {
    getSelects();
  }, [getSelects]);

  const handleError = (res) => {
    setAlertContent({
      type: "error",
      title: "Error",
      message: getErrorMessage(res.internalCode),
    });
    setShowAlert(true);
    return;
  };

  const handleArrayElement = (array, id) => {
    return array?.find((e) => e._id === id);
  };

  return (
    <form className="form">
      <div className=" medium bold form-title">Datos personales</div>
      <div className="form-group">
        <label htmlFor="rut" className="form-group small">
          RUT *
        </label>
        <HolosInput
          className="white-background"
          placeholder="16163530-8"
          type="text"
          id="rut"
          name="rut"
          onChange={(e) => {
            searchPerson(e);
          }}
          value={person?.rut}
          isError={viewError && errors["rut"]}
          msjError={rutMsjError || "Debe ingresar un RUT válido"}
        />
      </div>
      <div className="form-group">
        <label htmlFor="firstName" className="form-group small">
          Primer Nombre *
        </label>
        <HolosInput
          className="white-background"
          placeholder="Juana"
          id="firstName"
          name="firstName"
          onChange={setPersonData}
          value={person?.firstName}
          isError={viewError && errors["firstName"]}
          msjError={"Debe ingresar este campo"}
        />
      </div>
      <div className="form-group">
        <label htmlFor="secondName" className="form-group small">
          Segundo Nombre
        </label>
        <HolosInput
          className="white-background"
          placeholder="Luisa"
          id="secondName"
          name="secondName"
          onChange={setPersonData}
          value={person?.secondName}
        />
      </div>
      <div className="form-group">
        <label htmlFor="firstLastName" className="form-group small">
          Primer Apellido *
        </label>
        <HolosInput
          className="white-background"
          placeholder="Lozano"
          id="firstLastName"
          name="firstLastName"
          onChange={setPersonData}
          value={person?.firstLastName}
          isError={viewError && errors["firstLastName"]}
          msjError={"Debe ingresar este campo"}
        />
      </div>
      <div className="form-group">
        <label htmlFor="secondLastName" className="form-group small">
          Segundo Apellido
        </label>
        <HolosInput
          className="white-background"
          placeholder="Valdivia"
          id="secondLastName"
          name="secondLastName"
          onChange={setPersonData}
          value={person?.secondLastName}
        />
      </div>
      <div className="birth-date-picker">
        <label htmlFor="birthDate" className="form-group small">
          Fecha de nacimiento
        </label>
        <HolosDate
          id="birthDate"
          name="birthDate"
          autocomplete="off"
          dateFormat="dd/MM/yyyy"
          locale="es"
          className="date-picker small"
          placeholderText="07/11/1989"
          maxDate={moment().subtract(18, "year").toDate()}
          type="date"
          onChange={(date) =>
            setPersonData({ value: new Date(date), name: "birthDate" })
          }
          selected={person?.birthDate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </div>
      <div className="form-group">
        <label htmlFor="gender" className="form-group small">
          Genero *
        </label>
        <IconSelect
          placeholder={"Seleccione género"}
          options={helpers.parseForSelect(genders)}
          id="gender"
          name="gender"
          onChange={(e) => onSelect(e, "idGender")}
          value={currentGender || handleArrayElement(genders, person?.idGender)}
          isError={viewError && errors["idGender"]}
          msjError={"Debe ingresar este campo"}
        />
      </div>
      <div className="form-group">
        <label htmlFor="contactPhone" className="form-group small">
          Telefono *
        </label>
        <HolosInput
          className="white-background"
          placeholder="+56 2 2560 0180"
          id="contactPhone"
          name="contactPhone"
          onChange={setPersonData}
          value={person?.contactPhone}
          isError={viewError && errors["contactPhone"]}
          msjError={"Debe ingresar este campo"}
          type="number"
        />
      </div>
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={(isOpen, typeShow) => setShowAlert(isOpen)}
        title={alertContent.title}
        content={alertContent.message}
        type={alertContent.type}
      />
    </form>
  );
};
export default PersonalDataForm;
