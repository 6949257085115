import React, { useEffect, useState } from "react";
import FormButtonStepper from "../../../../../../shared/components/formButtonStepper";
import HolosAlert from "../../../../../../shared/components/holosAlert";
import HolosButton from "../../../../../../shared/components/holosButton";
import Support from "../../../../../../shared/components/support";
import PasswordForm from "../../../../shared/passwordForm";
import ResendEmailAlert from "../../../../shared/resendEmailAlert";

import "./styles.scss";

const PasswordData = ({
  setPersonData,
  person,
  setView,
  showEmailAlert,
  showErrorAlert,
  onSubmit,
  onResend,
}) => {
  const [samePassword, setSamePassword] = useState(true);
  const [validPassword, setValidPassword] = useState(false);
  const [showToast, setshowToast] = useState(false);
  const [, setShowErrorAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const timeToast = 2000;

  const { password, repeat } = person;

  useEffect(() => {
    if (validPassword && samePassword && person?.user?.password !== password) {
      let user = { ...person?.user };
      user.password = password;
      user.repeat = repeat;
      setPersonData({ name: "user", value: user });
    }
  }, [validPassword, password, setPersonData, person, samePassword, repeat]);

  useEffect(() => {
    if (password === repeat) setSamePassword(true);
    else setSamePassword(false);
  }, [password, repeat]);

  return (
    <div className="Password-data">
      <div className="medium bold password-form-title">Seguridad</div>
      <PasswordForm
        password={password}
        setPassword={(e) => setPersonData(e)}
        repeat={repeat}
        setRepeat={(e) => setPersonData(e)}
        setValidPassword={setValidPassword}
        samePassword={samePassword}
      />
      <FormButtonStepper
        nameButton="Crear"
        showGoBack={true}
        goBackView={"ProfilePictureData"}
        setView={setView}
        onSubmit={onSubmit}
        disabled={!(validPassword && samePassword)}
      />
      <ResendEmailAlert
        isOpen={showEmailAlert}
        title={"Verifica tu correo electrónico"}
        text={`Enviamos un email con un link a ${person?.email}`}
        onResend={onResend}
      />
      <HolosAlert
        cssClass={`toast`}
        showAlert={showToast}
        setShowAlert={setshowToast}
        title={"Email de reactivación de cuenta enviado correctamente"}
        type={"success"}
        timeToast={timeToast}
      ></HolosAlert>
      <HolosAlert
        showAlert={showErrorAlert}
        setShowAlert={setShowErrorAlert}
        title={"Se ha producido un error"}
        type="warning"
        content={"Vuelve a intentarlo o contacta a soporte"}
        actions={[
          <HolosButton
            key={1}
            separatingMargin={"1em"}
            className="purple-background"
            text={{ name: "Contactar a soporte", className: "white-icon-text" }}
            onClick={() => setIsOpen(true)}
          />,
        ]}
      ></HolosAlert>
      <Support isOpen={isOpen} setIsOpen={(bool) => setIsOpen(bool)} />
    </div>
  );
};
export default PasswordData;
