import React from "react";
import {
  CachedOutlined,
  HowToRegOutlined,
  Settings,
  TrendingUpOutlined,
  WbIncandescentOutlined,
} from "@material-ui/icons";
import QRCode from "../../../../assets/qr";

export const cards = [
  {
    title: "Ideas",
    text: "Escala tus ideas, mejóralas, optimízalas e impleméntalas",
    icon: <WbIncandescentOutlined className="dashboard-card-icon ideas-icon" />,
    path: "/ideas",
  },
  {
    title: "Círculos de calidad",
    text: "Supera desafíos en equipo y mejora los días de trabajo",
    icon: <HowToRegOutlined className="dashboard-card-icon circles-icon" />,
    path: "/circles/list",
  },
  {
    title: "Solicitudes de cambios",
    text: "Visualiza todos las solicitudes dentro del sistema",
    icon: <CachedOutlined className="dashboard-card-icon requests-icon" />,
    path: "/requests",
  },
  {
    title: "Placas",
    text: "Gestiona reconocimientos destacados con códigos QR",
    icon: <QRCode className={"dashboard-card-icon plaques-icon"} />,
    path: "/plaques/list",
  },
  {
    title: "Informes",
    text: "Mantén control permanente de los avances en proceso",
    icon: <TrendingUpOutlined className="dashboard-card-icon reports-icon" />,
    path: "/reports",
  },
  {
    title: "Configuración",
    text: "Configura las preferencias dentro del sistema",
    icon: <Settings className="dashboard-card-icon configuration-icon" />,
    path: "/settings",
  },
];
