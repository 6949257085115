import React from "react";
import "./styles.scss";
const HolosChip = ({ name, status }) => {
  return (
    <div className={"Holos-chip"}>
      <div className={status}>{name}</div>
    </div>
  );
};
export default HolosChip;
