import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  DeleteForever,
  BorderColorOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@material-ui/icons";

// components
import HolosList from "../../../../../../../../../../shared/components/holosList";
import ReasonsRow from "./parts/reasonsRow";
import IconSelect from "../../../../../../../../../../shared/components/iconSelect/iconSelect";
import HolosButton from "../../../../../../../../../../shared/components/holosButton";
import NewCauseModal from "../newCauseModal";
import NewReasonModal from "../newReasonModal";
import { handleSortHelper } from "../../../../../../../../../../utils/helpers";

// services
import { generateCausesHeaders } from "./constants";
import DeleteRequest from "../../../../../../../../../../shared/components/DeleteRequest";

// styles
import "./styles.scss";
import HolosAlert from "../../../../../../../../../../shared/components/holosAlert";
import ChallengeHeader from "../../../header";

const CausesForm = ({ challenge, setChallengeData, causeCategories }) => {
  const [showCauseModal, setShowCauseModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [query, setQuery] = useState({});
  const [causes, setCauses] = useState([]);
  
  const filterRef = useRef();
  
  const [currentCauseIndex, setCurrentCauseIndex] = useState(null);
  const [causeIndex, setCauseIndex] = useState(-1)
  const [currentReasonIndex, setCurrentReasonIndex] = useState(-1);

  const [showErrorToast, setShowErrorToast] = useState(false);

  const getCategoryName = (id) => {
    let category = causeCategories.filter((category) => category._id === id)[0];
    return category?.label;
  };

  const showHideWhy = (causeIndex) => {
    const causesLocal = causes.map((cause, index) => {
      if (index === causeIndex) cause.showWhy = !cause.showWhy;
      return cause;
    });
    setCauses(causesLocal);
  };

  const editCause = (causeIndex) => {
    setShowCauseModal(true);
    setCurrentCauseIndex(causeIndex);
  };

  const editWhy = (causeIndex, reasonIndex) => {
    setCauseIndex(causeIndex);
    setCurrentReasonIndex(reasonIndex);
    setShowReasonModal(true);
  };

  const removeWhy = (causeIndex, reasonIndex) => {
    setShowDeleteAlert(true);
    console.log("Eliminar Porque---->", causes[causeIndex].why[reasonIndex]);
  };

  const addWhy = (causeIndex) => {
    setCauseIndex(causeIndex);
    setCurrentReasonIndex(-1);
    setShowReasonModal(true);
  };

  const removeCause = (causeIndex) => {
    setShowDeleteAlert(true);
    console.log("Eliminar Causa---->", causes[causeIndex]);
  };

  const updateWhy = (data) => {

    const causes = [ ...challenge?.causes ]
    const reasons = causes[causeIndex].why || []
    
    if (currentReasonIndex < 0) reasons.push(data)
    else reasons[currentReasonIndex] = data
    
    causes[causeIndex].why = [ ...reasons ]

    setChallengeData({ ...challenge, causes })
  };

  useEffect(() => {
    console.log('challenge :>> ', challenge);
  }, [ challenge ])

  useEffect(() => {
    if (currentReasonIndex < 0) return;
    setShowReasonModal(true);
  }, [currentReasonIndex]);

  const getListRow = () => {
    let listRow = [];
    causes &&
      causes.forEach((cause, index) => {
        listRow.push(
          <React.Fragment>
            <div className="first-row">
              <div className="item-container">
                <div className="item-actions-with-modal">
                  <span>
                    <button
                      className="transparent-background"
                      onClick={() => showHideWhy(index)}
                    >
                      {cause.showWhy ? (
                        <KeyboardArrowUpOutlined />
                      ) : (
                        <KeyboardArrowDownOutlined />
                      )}
                    </button>
                  </span>
                  <button
                    className="transparent-background"
                    onClick={() => editCause(cause?.indexOnParent)}
                  >
                    <BorderColorOutlined />
                  </button>{" "}
                  <button
                    className="transparent-background"
                    onClick={() => {
                      removeCause(index);
                    }}
                  >
                    <DeleteForever />
                  </button>
                </div>
              </div>
              <div className="item-container ml-small">
                <div className="causes-counter mr-small">
                  {cause?.hierarchyOrder}
                </div>
                <div className="item-content">
                  {cause?.title || `Causa ${index + 1}`}
                </div>
              </div>
              <div className="item-container">
                <div className="item-content">
                  {getCategoryName(cause?.idCauseCategory)}
                </div>
              </div>
              <div className="item-container">
                <div className="item-content">
                  <div className="white-round-number">
                    {cause?.why?.length || 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="item-container second-list ">
              <ReasonsRow
                showReasonModal={showReasonModal}
                setShowReasonModal={setShowReasonModal}
                show={cause.showWhy}
                reasons={cause?.why}
                causeIndex={index}
                removeWhy={removeWhy}
                editWhy={editWhy}
                addWhy={addWhy}
                setCauseIndex={setCauseIndex}
                setCurrentReasonIndex={setCurrentReasonIndex}
                setCurrentCauseIndex={setCurrentCauseIndex}
              />
            </div>
          </React.Fragment>
        );
      });
    return listRow;
  };

  const filter = (e, filterName) => {
    if (e !== 0 || e.length > 0) return setQuery({ ...query, [filterName]: e });
    delete query[filterName];
    return setQuery({ ...query });
  };

  const handleSort = (param) => {
    handleSortHelper(param, query, setQuery, "");
  };

  const searchCauses = useCallback(() => {
    if (Object.keys(query).length === 0) return;
    let causesFiltered = challenge?.causes;
    for (let key in query) {
      causesFiltered = causesFiltered.filter((cause) => {
        return cause[key].includes(query[key]);
      });
    }
    setCauses(causesFiltered);
  }, [query, challenge.causes]);

  useEffect(() => {
    const causesMapped = challenge?.causes?.map((cause, i) => {
      cause.indexOnParent = i;
      return cause;
    });
    setCauses(causesMapped);
  }, [challenge]);

  useEffect(() => {
    searchCauses();
  }, [query, searchCauses]);

  return (
    <div className="form-wide">
      <ChallengeHeader key={0} />
      <div className="button-width">
        <div className="causes-actions-container">
          <HolosButton
            key={2}
            separatingMargin={"1em"}
            className="purple-background"
            text={{
              name: "Agregar causa",
              className: "white-icon-text",
            }}
            onClick={() => {
              setShowCauseModal(true);
            }}
          />
        </div>
      </div>
      <div className="causes-list">
        <HolosList
          filterRef={filterRef}
          headers={generateCausesHeaders(handleSort)}
          showFilterBar={true}
          searchFilter={(e) => {
            filter(e.currentTarget.value, "title");
          }}
          currentSearch={query.cause}
          gridTemplateColumns={"15% 45% 25% 5% 5% 5%"}
          gridGap={"5px"}
          rows={getListRow(causes)}
          showRowsColor={true}
          placeholder={"Buscar por nombre de causa"}
          moreFilters={
            <React.Fragment>
              <div className="causes-filters-end">
                <IconSelect
                  options={causeCategories}
                  onChange={(e) => filter(e._id, "idCauseCategory")}
                  placeholder={"Categorías"}
                />
              </div>
            </React.Fragment>
          }
        />
      </div>

      <NewCauseModal
        currentCauseIndex={currentCauseIndex}
        setCurrentCauseIndex={setCurrentCauseIndex}
        showCauseModal={showCauseModal}
        setShowCauseModal={setShowCauseModal}
        categories={causeCategories.slice(1)}
        challenge={challenge}
        setChallengeData={setChallengeData}
      />

      <DeleteRequest
        showAlert={showDeleteAlert}
        setShowAlert={setShowDeleteAlert}
        onClick={() => {}}
      />

      <NewReasonModal
        reasonData={
          causeIndex >= 0 && currentReasonIndex >= 0
            ? causes[causeIndex].why[currentReasonIndex]
            : null
        }
        showModal={showReasonModal}
        updateWhy={updateWhy}
        setShowModal={setShowReasonModal}
      />

      <HolosAlert
        cssClass="toast"
        type={"error"}
        timeToast={2000}
        content={"No puede agregar mas de cinco causas"}
        showAlert={showErrorToast}
        setShowAlert={setShowErrorToast}
      />
    </div>
  );
};

export default CausesForm;
