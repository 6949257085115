import React from "react";

import {
  LocationOnOutlined,
  Map,
  NotInterested,

  //  Place
} from "@material-ui/icons";
import QRCode from "../../assets/qr";

export const darkGrey = "#444444";

export const moduleTabs = [
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Placas QR",
      icon: <QRCode className="opacity-54" />,
    },
    urlRedirect: "/plaques/list",
  },
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Mapas",
      icon: <Map />,
    },
    urlRedirect: "/maps/list",
  },
  {
    className: "transparent-background purple-hover purple-background-select",
    text: {
      className: "small bold purple-background-select",
      name: "Qrs Desactivados",
      icon: <NotInterested />,
    },
    urlRedirect: "/plaques/trash",
  },
];

export const tabs = [
  {
    text: {
      name: "Nuevo mapa",
      color: "var(--softWhite)",
      size: "16px",
      weight: "600",
    },
    icon: {
      color: "#ffffff",
      icon: <LocationOnOutlined />,
      opacity: 1,
    },
    background: {
      color: "var(--purpleBHP)",
    },
    urlRedirect: "/maps/new",
  },
  {
    text: {
      name: "Nueva placa QR",
      colorHover: "var(--softWhite)",
      color: "var(--softBlack)",
      size: "16px",
      weight: "600",
    },
    icon: {
      color: "var(--black)",
      opacity: "0.54",
      icon: <QRCode className="opacity-54"/>,
    },
    background: {
      color: "transparent",
    },
    urlRedirect: "/plaques/new",
  },
];
