import React from "react";
import {
  DeleteForever,
  BorderColorOutlined,
  Close,
  CheckCircleOutlineOutlined,
} from "@material-ui/icons";

// components
import HolosList from "../../../../../../../../../../shared/components/holosList";

// services
import { generateSolutionsHeaders } from "./constants";

// styles
import "./styles.scss";
import { IonToggle } from "@ionic/react";
import HolosTextArea from "../../../../../../../../../../shared/components/HolosTextArea";

const solutions = [
  {
    name: (
      <HolosTextArea
        height="50px"
        className="bold title-input mr-small"
        disabled
        placeholder="Implementar una piscina para hacer la toma de muestra"
        id="name"
        name="name"
      />
    ),
    risk: (
      <input
        className="number-selector"
        type="number"
        disabled
        placeholder="1"
        id="risk"
        name="risk"
        min="1"
        max="3"
      />
    ),
    cost: (
      <input
        className="number-selector"
        type="number"
        disabled
        placeholder="1"
        id="cost"
        name="cost"
        min="1"
        max="3"
      />
    ),
    impact: (
      <input
        className="number-selector"
        placeholder="1"
        type="number"
        disabled
        id="impact"
        name="impact"
        min="1"
        max="3"
      />
    ),
    total: "9",
    usage: <IonToggle />,
  },
];

const SolutionsList = () => {
  const getListRow = () => {
    let listRow = [];
    solutions &&
      solutions.forEach((solution, index) => {
        listRow.push(
          <React.Fragment>
            <div className="item-container">
              <div className="item-content">
                <div>{solution.name}</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item-content">
                <div className="white-round-number">{solution.risk}</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item-content">
                <div className="white-round-number">{solution.cost}</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item-content">
                <div className="white-round-number">{solution.impact}</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item-content">
                <div className="purple-round-number">{solution.total}</div>
              </div>
            </div>
            <div className="item-container">
              <div className="item-content">
                <div>{solution.usage}</div>
              </div>
            </div>{" "}
            <div className="item-container">
              <div className="item-actions">
                <button className="transparent-background">
                  <BorderColorOutlined />
                </button>{" "}
                <button
                  className="transparent-background"
                  style={{ display: "none" }}
                >
                  <CheckCircleOutlineOutlined />
                </button>{" "}
                <button className="transparent-background">
                  <DeleteForever />
                </button>{" "}
                <button
                  className="transparent-background"
                  style={{ display: "none" }}
                >
                  <Close />
                </button>{" "}
              </div>
            </div>{" "}
          </React.Fragment>
        );
      });
    return listRow;
  };

  return (
    <div className="Solutions-list">
      <HolosList
        headers={generateSolutionsHeaders()}
        gridTemplateColumns={"35% 10% 10% 10% 10% 10% 15%"}
        gridGap={"5px"}
        rows={getListRow(solutions)}
        showRowsColor={true}
      />
    </div>
  );
};

export default SolutionsList;
