import config from "../../../services/config";
import RequestManager from "../../../services/requestManager";
import {landingURL} from "../services/urls.json"




class Request {
    constructor() {
        this.baseUrl = config.REACT_APP_API_URL;
      }

  getLanding() {
    let url = this.baseUrl + landingURL;
    return RequestManager.get(url);
  }

}

const instance = new Request();
Object.freeze(instance);
export default instance;
