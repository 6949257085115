import React from "react";
import HolosInput from "../../../../../../../../../../shared/components/holosInput";
import DragAndDrop from "../../../../../../../../../../shared/components/dragAndDrop";
import "./styles.scss";
import HolosTextArea from "../../../../../../../../../../shared/components/HolosTextArea";
import ChallengeHeader from "../../../header/index";

const DefinitionForm = (index) => {
  return (
    <div className="Definition-form form">
      <ChallengeHeader key={index} />
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="yammerLink" className="small bold required">
            Problema
          </label>
          <HolosInput
            placeholder="Existen filtraciones en la planta..."
            id="yammerLink"
            name="yammerLink"
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="yammerLink" className="small bold required">
            Objetivo SMART
          </label>
          <HolosTextArea
            placeholder="Existen filtraciones en la planta..."
            id="yammerLink"
            name="yammerLink"
            height="200px"
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="yammerLink" className="small bold required">
            Evidencia
          </label>
          <DragAndDrop
            title={"Imagenes"}
            text={"el archivo PNG, JPG o PDF"}
            types={["application/pdf", "image/png", "image/jpg", "image/jpeg"]}
          />
        </div>
      </div>
    </div>
  );
};

export default DefinitionForm;
