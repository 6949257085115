import React, { useState } from "react";
import { useHistory } from "react-router";

import { Phone } from "@material-ui/icons";
import MenuNavigation from "@material-ui/icons/Menu";
import Notifications from "@material-ui/icons/NotificationsOutlined";

import GoBack from "../goBack";
import HolosButton from "../holosButton";
import MenuDropdown from "./parts/dropdown";

import "./appBar.scss";

const AppBar = () => {
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="App-bar" >
      <GoBack onClick={() => history.goBack()} />
      <div className="action-buttons">
        <HolosButton
          icon={{ icon: <Phone /> }}
          className="transparent-background continue-button"
          text={{ name: "Soporte", className: "black-icon-text" }}
          type="submit"
          width="100%"
        />
        <div className="action-button">
          <Notifications className="notifications" />
        </div>
        <div
          className="action-button"
          onClick={() => setShowDropdown((showDropdown) => !showDropdown)}
        >
          <MenuNavigation className="menu-navigation" />
        </div>
        <MenuDropdown showDropdown={showDropdown} setShowDropdown={setShowDropdown}/>
      </div>
    </div>
  );
};
export default AppBar;
