import React from "react";
import FilterBar from "./filterBar";
// import FilterBar from "./filterBar";
import ListBody from "./listBody";
import ListHeader from "./listHeader";

import "./styles.scss";

const HolosList = ({
  headers,
  rows,
  gridTemplateColumns,
  gridGap,
  showFilterBar,
  icon,
  searchFilter,
  currentSearch,
  moreFilters,
  showRowsColor,
  placeholder,
  setCurrent,
  filterRef
}) => {
  return (
    <div className="list">
      {showFilterBar && (
        <FilterBar
          filterRef={filterRef}
          gridGap={""}
          gridTemplateColumns={gridTemplateColumns}
          icon={icon}
          searchFilter={searchFilter}
          currentSearch={currentSearch}
          moreFilters={moreFilters}
          placeholder={placeholder}
        />
      )}
      <ListHeader
        headers={headers}
        gridTemplateColumns={gridTemplateColumns}
        gridGap={gridGap}
      />
      <ListBody
        setCurrent={setCurrent}
        showRowsColor={showRowsColor}
        rows={rows}
        gridTemplateColumns={gridTemplateColumns}
        gridGap={gridGap}
      />
    </div>
  );
};

export default HolosList;
