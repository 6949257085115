import React, { useState } from "react";
import "./buttonTab.scss";
import HolosButton from "../holosButton";
import { Link } from "react-router-dom";

/**
 * A component to icon more label with styles
 *
 * @param {object} props Props passed to the current component.
 * @param {string} props.orientation  Button orientation vertical or horizontal
 * @param {string} props.separatingMargin Separation between buttons vertical or horizontal
 * @param {Array<object>} props.tabs List of buttons with their properties.
 * @param {object} props.tabs.text Objeto text containing the button
 * @param {string} props.tabs.text.name Text or name button
 * @param {string} props.tabs.text.colorHover Text Color in #hex when hover
 * @param {string} props.tabs.text.color Text Color in #hex
 * @param {string} props.tabs.text.colorSelect Text Color in #hex when Selected button
 * @param {string} props.tabs.text.size Text size in px or rem or em
 * @param {string} props.tabs.text.weight Text Weight in px
 * @param {object} props.tabs.icon Objeto icon containing the button
 * @param {string} props.tabs.icon.color Icon Color in #hex
 * @param {string} props.tabs.icon.opacity Icon opacity in percentage
 * @param {string} props.tabs.icon.icon Icon with format svg or Component Material-ui
 * @param {object} props.tabs.background Objeto background button containing the button
 * @param {string} props.tabs.background.color Background button Color in #hex
 * @param {string} props.tabs.background.colorSelect Text Color in #hex when Selected button
 * @param {string} props.tabs.urlRedirect path when click buttong
 * @param {boolean} props.tabs.active which button is activated
 * @param {class} props.tabs.component open specific component when click buttong
 * @param {function} props.tabs.component.setIsOpen onclick componenet
 *
 */
const ButtonTab = (props) => {
  const [idSelect, setSelect] = useState(undefined);

  const { tabs, orientation, separatingMargin } = props;

  const onSelect = (index, tab) => {
    //the father is setting the state of the button, disable onSelect
    if (tab?.active === false) return false;
    if (index === idSelect) {
      //in case that it was selected, it is deleted for select the route
      setSelect(undefined);
      return true;
    }
    return false;
  };

  //when it is a module tab, the button is selected depending on the current path.
  const autoSelect = (tab) => {
    if (!props?.history?.location?.pathname) return false;
    if (tab?.urlRedirect === props?.history?.location?.pathname) {
      //in case that it was selected, it is deleted for select the route
      idSelect && setSelect(undefined);
      return true;
    }
    return false;
  };

  const whereMargin = (orientation, index) => {
    if (index === tabs.length - 1) return;
    if (orientation === "vertical") return { marginBottom: separatingMargin };
    return { marginBottom: separatingMargin };
  };

  const isVertical = (orientation) => {
    if (orientation === "vertical") return "column";
    return "row";
  };

  return (
    <div
      className="Button-tab"
      style={{
        flexDirection: isVertical(orientation),
      }}
    >
      {tabs?.map((tab, index) => (
        <div
          className={`conteiner ${isVertical(orientation)} button${index}`}
          key={index}
          style={whereMargin(orientation, index)}
        >
          <Link
            onClick={() => tab?.component?.setIsOpen(true)}
            to={
              tab?.urlRedirect
                ? tab.urlRedirect
                : props?.history?.location?.pathname
            }
          >
            <HolosButton
              className={tab?.className}
              text={tab?.text}
              icon={tab?.icon}
              background={tab?.background}
              isSelect={onSelect(index, tab) || tab?.active || autoSelect(tab)}
              onClick={(e) => {
                setSelect(index);
                tab.onClick && tab.onClick(e);
              }}
            />
          </Link>
          {tab?.component?.class}
        </div>
      ))}
    </div>
  );
};

export default ButtonTab;
