import { CreateOutlined, MoreVertOutlined } from "@material-ui/icons";
import React from "react";
import FormButtonStepper from "../../../../../../../../shared/components/formButtonStepper";
import HolosInput from "../../../../../../../../shared/components/holosInput";
import IshikawaDiagram from "./parts/ishikawaDiagram";
import SolutionsList from "./parts/solutionsList";
import "./styles.scss";

const ChallengesSolutions = () => {
  return (
    <div className="Challenges-solutions">
      {" "}
      <div className="form-wide">
        <div className="form-title">
          <div className="title-internal-container">
            <HolosInput
              className="bold title-input mr-small"
              placeholder="Nombre del desafío"
              id="name"
              name="name"
            />
            <CreateOutlined htmlFor="name" />
          </div>
          <MoreVertOutlined />
        </div>
      </div>
      <IshikawaDiagram />
      <SolutionsList />
      <FormButtonStepper className="buttons-flex-end ishikawa-hd-padding" />
    </div>
  );
};

export default ChallengesSolutions;
