import React, { useCallback, useEffect, useState } from "react";
import HolosAlert from "../../../../../../../shared/components/holosAlert";
import HolosButton from "../../../../../../../shared/components/holosButton";
import HolosLink from "../../../../../../../shared/components/holosLink/holosLink";
import Support from "../../../../../../../shared/components/support";
import * as helpers from "../../../../../../../utils/helpers";

const CreateCircleAlert = ({
  showAlert,
  setShowAlert,
  type,
  code,
  msg,
  circle,
  setIsOpenSupport,
  isOpenSupport,
}) => {
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [actions, setActions] = useState(null);

  const isSucces = useCallback(() => {
    setTitle("Circulo creado exitosamente");
    setContent(
      <form>
        <div className="mb-medium">
          <div htmlFor="email" className="mb-small small bold">
            Circulos de calidad
          </div>
          <div className="white-background">{circle?.name}</div>
        </div>{" "}
        <div className="mb-medium">
          <div htmlFor="email" className="mb-small small bold">
            Codigo
          </div>
          <div className="white-background">{code}</div>
        </div>
        <div>
          <div htmlFor="email" className="mb-small small bold">
            Desafío
          </div>
          <div className="white-background">{circle?.challenges[0].name}</div>
        </div>
      </form>
    );
    setActions(
      <HolosButton
        key={1}
        separatingMargin={"1em"}
        className="purple-background"
        text={{ name: "Completar desafío", className: "white-icon-text" }}
        onClick={() => helpers.redirect("/circles/challenges")}
      />
    );
  }, [circle, code, setContent, setTitle, setActions]);

  const isError = useCallback(() => {
    setTitle("No se pudo crear el circulo de calidad");
    setContent(
      <div>
        <div htmlFor="message" className="mb-medium small lessBold">
          {msg ||
            "Hubo un problema al crear tu circulo de calidad. Por favor, intentalo nuevamente."}
        </div>
        <div htmlFor="info" className="small lessBold">
          Si el problema persiste, contacta a Soporte.
        </div>
      </div>
    );
    setActions(
      <div className="double-cta">
        {" "}
        <HolosButton
          key={2}
          separatingMargin={"1em"}
          className="purple-background"
          text={{ name: "Entendido", className: "white-icon-text" }}
          onClick={() => setShowAlert(false)}
        />
        <HolosLink
          className="Holos-link-column"
          text="¿Necesitas ayuda?"
          link={
            <Support
              isOpen={isOpenSupport}
              setIsOpen={(bool) => setIsOpenSupport(bool)}
            />
          }
          linkText={"Contacta a soporte"}
          onClick={() => setIsOpenSupport(true)}
        />
      </div>
    );
  }, [
    isOpenSupport,
    setIsOpenSupport,
    setShowAlert,
    setContent,
    setTitle,
    setActions,
    msg,
  ]);

  useEffect(() => {
    if (!showAlert) return;
    if (type === "error") return isError();
    if (type === "success") return isSucces();
  }, [type, showAlert, isSucces, isError]);

  return (
    <div>
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        title={title || msg}
        type={type}
        content={content}
        actions={[actions]}
      ></HolosAlert>
    </div>
  );
};
export default CreateCircleAlert;
