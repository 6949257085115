import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { usePrevious } from "../../../utils/helpers";
import HolosButton from "../holosButton";
import HolosInput from "../holosInput";
import "./styles.scss";
/**
 * Pagination component. Dvidide elements-items into pages, providing a button of the pages.
 * @param {object} props
 * @param {object[]} props.items -  list of items. If filtering is used, send filter variable.
 * @param {function} props.setItemsList -  method that returns the items to be show on the current page.
 * @param {number} props.pageNumber -  current number page.
 * @param {number} props.itemsPerPage -  number of items to display per page..
 * @param {function} props.setPageNumber -  method to set number of elements to display per page.
 * @param {boolean} props.showGoToPage -  show input to enter page number.
 */
const HolosPagination = ({
  items,
  // setItems,
  pageNumber,
  setPageNumber,
  pageCount,
  itemsPerPage,
  showGoToPage,
}) => {
  let currentPaginate = { items, pageNumber, itemsPerPage, pageCount };
  const [paginate, setPaginate] = useState(null);
  const prevItems = usePrevious(currentPaginate);
  const totalItemsCount = paginate?.total_pages * paginate?.per_page;
  const [currentPage, setCurrentPage] = useState(pageNumber);



  useEffect(() => {
    if (JSON.stringify(prevItems) === JSON.stringify(currentPaginate)) return;
    const paginate = getPaginate({ ...currentPaginate });

    setPaginate(paginate);
  }, [prevItems, currentPaginate]);

  useEffect(() => {
    setCurrentPage(pageNumber)
  }, [pageNumber])
  return (
    <div className="holos-pagination">
      <Pagination
        activePage={paginate?.page}
        itemsCountPerPage={paginate?.per_page}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={5}
        prevPageText={<KeyboardArrowLeftOutlined />}
        nextPageText={<KeyboardArrowRightOutlined />}
        hideFirstLastPages={true}
        onChange={setPageNumber}
      />
      {showGoToPage && (
        <div className={"go-to"}>
          <span>Página: </span>
          <HolosInput
            className="white-background"
            width={"100px"}
            placeholder={pageNumber}
            value={currentPage}
            onChange={(e) => setCurrentPage(e.currentTarget.value)}
          />
          <HolosButton
          className={"go-button"}
            key={1}
            background={{ color: "var(--purpleBHP)" }}
            text={{
              name: "Ir",
              color: "white",
              colorHover: "white",
            }}
            onClick={() => setPageNumber(parseInt(currentPage))}
          />
        </div>  
      )}
    </div>
  );
};
export default HolosPagination;

const getPaginate = ({ items, pageNumber, itemsPerPage, pageCount }) => {
  const page = pageNumber || 1,
    per_page = itemsPerPage || 10,
    paginatedItems = items,
    total_pages = pageCount,
    totalItemsCount = per_page * total_pages;

  return {
    page: page,
    per_page: per_page,
    pre_page: page - 1 ? page - 1 : null,
    next_page: total_pages > page ? page + 1 : null,
    total: items ? items.length : 0,
    total_pages: total_pages,
    totalItemsCount: totalItemsCount,
    data: paginatedItems,
  };
};
