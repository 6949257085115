import React from "react";
import IndividualCard from "./individualCard";
import { cards } from "./constants";

function ModuleCards() {
  const flex = `1 0 ${cards.length % 3 > 0 ? "40%" : "30%"}`;

  return (
    <div className="dashboard-module-cards">
      {cards.map((card,index) => (
        <IndividualCard {...card} flex={flex} key={index} />
      ))}
    </div>
  );
}

export default ModuleCards;
