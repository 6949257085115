import React from "react";
import "../benefits/styles.scss";
import benefits from "../../../../assets/img/benefits.svg";
import { DoneAll } from "@material-ui/icons";

/**
 * A component to display LandingBenefits
 *
 * @param {object} props Props passed to the current component
 * @param {string} props.benefit1 Indicates the first benefit text
 * @param {string} props.benefit2 Indicates the second benefit text
 * @param {string} props.benefit3 Indicates the third benefit text
 *
 */

const LandingBenefits = ({benefit1, benefit2, benefit3}) => {



  return (
    <div className="Benefits-landing">
      <div className="benefits-header">
        <img src={benefits} alt="" />
      </div>
      <div className="benefits-list">
        <div className="benefits-items">
          <div className="benefit-icon">
            <DoneAll />
          </div>
          <div className="benefits-text">
           {benefit1}
          </div>
        </div>
        <div className="benefits-items">
          <div className="benefit-icon">
            <DoneAll />
          </div>
          <div className="benefits-text">
          {benefit2}
          </div>
        </div>
        <div className="benefits-items">
          <div className="benefit-icon">
            <DoneAll />
          </div>
          <div className="benefits-text">
            {benefit3}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBenefits;
