import React from "react";
import HolosInput from "../../../../../../../../../../../../shared/components/holosInput";
import IconSelect from "../../../../../../../../../../../../shared/components/iconSelect/iconSelect";

const FirstStep = ({ genders, member, setMemberData, errors, showErrors }) => {
  return (
    <form>
      <div className="mb-medium">
        <div htmlFor="idGender" className="small bold">
          Género *
        </div>
        <IconSelect
          placeholder={"Seleccione género"}
          options={genders}
          id="idGender"
          name="idGender"
          onChange={(value) =>
            setMemberData({ name: "idGender", value: value._id })
          }
          isError={showErrors && errors["idGender"]}
          value={genders.find(g => {
            return g._id === member?.idGender
          })}
        />
      </div>
      <div className="mb-medium">
        <div htmlFor="rut" className="mb-small small bold">
          RUT *
        </div>
        <HolosInput
          placeholder={"16163530-8"}
          iconColor={"var(--black)"}
          backgroundColor={"var(--softWhite)"}
          options={""}
          name="rut"
          onChange={setMemberData}
          value={member?.rut}
          isError={showErrors && errors["rut"]}
          msjError={"Debe ingresar un RUT válido"}
        />
      </div>{" "}
      <div className="mb-medium">
        <div htmlFor="name" className="mb-small small bold">
          Primer Nombre *
        </div>
        <HolosInput
          placeholder={"Maximiano Ignacio Veloso Besio"}
          iconColor={"var(--black)"}
          backgroundColor={"var(--softWhite)"}
          options={""}
          onChange={setMemberData}
          name="firstName"
          isError={showErrors && errors["firstName"]}
          msjError={"Debe completar este campo"}
          value={member?.firstName}
        />
      </div>
      <div className="mb-medium">
        <div htmlFor="name" className="mb-small small bold">
          Segundo Nombre
        </div>
        <HolosInput
          placeholder={"Maximiano Ignacio Veloso Besio"}
          iconColor={"var(--black)"}
          backgroundColor={"var(--softWhite)"}
          options={""}
          onChange={setMemberData}
          name="secondName"
          isError={showErrors && errors["secondName"]}
          msjError={"Debe completar este campo"}
          value={member?.secondName}
        />
      </div>
      <div className="mb-medium">
        <div htmlFor="name" className="mb-small small bold">
          Primer Apellido *
        </div>
        <HolosInput
          placeholder={"Maximiano Ignacio Veloso Besio"}
          iconColor={"var(--black)"}
          backgroundColor={"var(--softWhite)"}
          options={""}
          onChange={setMemberData}
          name="firstLastName"
          isError={showErrors && errors["firstLastName"]}
          msjError={"Debe completar este campo"}
          value={member?.firstLastName}
        />
      </div>
      <div className="mb-medium">
        <div htmlFor="name" className="mb-small small bold">
          Segundo Apellido
        </div>
        <HolosInput
          placeholder={"Maximiano Ignacio Veloso Besio"}
          iconColor={"var(--black)"}
          backgroundColor={"var(--softWhite)"}
          options={""}
          onChange={setMemberData}
          name="secondLastName"
          isError={showErrors && errors["secondLastName"]}
          msjError={"Debe completar este campo"}
          value={member?.secondLastName}
        />
      </div>
     
    </form>
  );
};

export default FirstStep;
