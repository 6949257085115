import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import React from "react";
import HolosButton from "../../../../../../../../../../../../shared/components/holosButton";
import "./../../styles.scss";

const BottomSpines = () => {
  return (
    <div className="bottom-spines">
      <div className="category-causes">
        <div className="horizontal-lines-container">
          <div className="bottom-causes">Por diseño de la planta</div>
          <div className="single-bottom-cause-container">
            <div className="reasons-counter">2</div>
            <div className="bottom-horizontal-line"></div>
            <div className="causes-counter-white">1</div>
          </div>

          <div className="bottom-causes">Por diseño de la planta</div>
          <div className="single-bottom-cause-container">
            <div className="reasons-counter">3</div>
            <div className="bottom-horizontal-line"></div>
            <div className="causes-counter-white">2</div>
          </div>

          <div className="bottom-causes">Por diseño de la planta</div>
          <div className="single-bottom-cause-container">
            <div className="reasons-counter">5</div>
            <div className="bottom-horizontal-line"></div>
            <div className="causes-counter-white">3</div>
          </div>
        </div>
        <HolosButton
          className="transparent-rounded-corners bottom-causes-button"
          icon={{ icon: <KeyboardArrowDownOutlined /> }}
          text={{
            name: "Ambiente",
            className: "bold medium black-icon-text",
          }}
        />
      </div>

      <div className="category-causes">
        <div className="horizontal-lines-container">
          <div className="bottom-causes">Por diseño de la planta</div>
          <div className="single-bottom-cause-container">
            <div className="reasons-counter">2</div>
            <div className="bottom-horizontal-line"></div>
            <div className="causes-counter-white">1</div>
          </div>

          <div className="bottom-causes">Por diseño de la planta</div>
          <div className="single-bottom-cause-container">
            <div className="reasons-counter">3</div>
            <div className="bottom-horizontal-line"></div>
            <div className="causes-counter-white">2</div>
          </div>

          <div className="bottom-causes">Por diseño de la planta</div>
          <div className="single-bottom-cause-container">
            <div className="reasons-counter">5</div>
            <div className="bottom-horizontal-line"></div>
            <div className="causes-counter-white">3</div>
          </div>
        </div>
        <HolosButton
          className="transparent-rounded-corners bottom-causes-button translate50"
          icon={{ icon: <KeyboardArrowDownOutlined /> }}
          text={{
            name: "Metodo/Proceso",
            className: "bold medium black-icon-text",
          }}
        />
      </div>
      <div className="category-causes">
        <div className="horizontal-lines-container">
          <div className="bottom-causes">Por diseño de la planta</div>
          <div className="single-bottom-cause-container">
            <div className="reasons-counter">2</div>
            <div className="bottom-horizontal-line"></div>
            <div className="causes-counter-white">1</div>
          </div>
          <div className="bottom-causes">Por diseño de la planta</div>
          <div className="single-bottom-cause-container">
            <div className="reasons-counter">1</div>
            <div className="bottom-horizontal-line"></div>
            <div className="causes-counter-white">2</div>
          </div>
          <div className="bottom-causes">Por diseño de la planta</div>{" "}
          <div className="single-bottom-cause-container">
            <div className="reasons-counter">5</div>
            <div className="bottom-horizontal-line"></div>
            <div className="causes-counter-white">3</div>
          </div>
        </div>
        <div className="icon-select-container"></div>
        <HolosButton
          className="transparent-rounded-corners bottom-causes-button"
          icon={{ icon: <KeyboardArrowDownOutlined /> }}
          text={{
            name: "Personas",
            className: "bold medium black-icon-text",
          }}
        />
      </div>
    </div>
  );
};

export default BottomSpines;
