import React from "react";

import { SyncOutlined } from "@material-ui/icons";

import HolosAlert from "../../../../../../../../../../shared/components/holosAlert";
import HolosInput from "../../../../../../../../../../shared/components/holosInput";
import HolosButton from "../../../../../../../../../../shared/components/holosButton";
import HolosTextArea from "../../../../../../../../../../shared/components/HolosTextArea";
import IconText from "../../../../../../../../../../shared/components/iconText";

import "./styles.scss";

const EffectModal = ({ setShowModal, showModal }) => {
  return (
    <HolosAlert
      showAlert={showModal}
      setShowAlert={setShowModal}
      title={<IconText icon={<SyncOutlined />} text={"Efecto"} iconColor={"var(--softBlack)"} />}
      cssClass={"effect-modal-container"}
      content={
        <form className="effect-modal">
          <div className="form-group">
            <div className="form-control">
              <label htmlFor="title" className="form-label small bold">
                Titulo *
              </label>
              <HolosInput
                className="bold title-input mr-small"
                placeholder="No se visualizan las filtraciones"
                id="title"
                name="title"
              />
            </div>
          </div>{" "}
          <div className="form-group">
            <div className="form-control">
              <label htmlFor="description" className="form-label small bold">
                Descripción *
              </label>
              <HolosTextArea
                placeholder="Las instalaciones no permiten visualizar..."
                id="description"
                name="description"
                height="200px"
              />
            </div>
          </div>
        </form>
      }
      actions={[
        <HolosButton
          key={1}
          separatingMargin={"1em"}
          className="purple-background"
          text={{ name: "Guardar", className: "white-icon-text" }}
          onClick={() => {
            setShowModal(false);
          }}
        />,
      ]}
    ></HolosAlert>
  );
};

export default EffectModal;
