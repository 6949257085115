import React, { useState, useContext, useEffect, useCallback } from "react";
import { Loading } from "../../../../../../../../context";
import HolosInput from "../../../../../../../../shared/components/holosInput";
import * as helpers from "../../../../../../../../utils/helpers";
import IconSelect from "../../../../../../../../shared/components/iconSelect/iconSelect";
import Request from "../../../../../../services/request";
import Parameter from "../../../../../../../../services/globalApi/paremeters";
import HolosAlert from "../../../../../../../../shared/components/holosAlert";
import { getErrorMessage } from "../../../../../../../../services/errors";

const JobDataForm = ({ setPersonData, person, setCanContinue, viewError }) => {
  //states alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", message: "" });

  const [, setLoading] = useContext(Loading);

  const [rutMsjError, setRutMsjError] = useState("");
  const [errors, setErrors] = useState({});
  const [groups, setGroups] = useState([]);
  const [workSchedules, setWorkSchedule] = useState();
  const [areas, setAreas] = useState();
  const [leaders, setLeaders] = useState([]);
  const [currentSelects, setCurrentSelects] = useState({
    group: null,
    workSchedule: null,
    area: null,
    leader: null,
  });
  const [existContractor, setExistContractor] = useState(false);

  const prevItems = helpers.usePrevious({ person, currentSelects });
  const previousPerson = helpers.usePrevious(person);

  const onSelect = (selectedItem, nameId) => {
    //set the id of the select in the parent component
    setPersonData({ name: nameId, value: selectedItem._id });

    //save selector data locally
    setCurrentSelects({
      ...currentSelects,
      [selectedItem.nameTarget]: selectedItem,
    });
  };

  /** Validate fields */
  const existsRut = useCallback(
    async (previous, value) => {
      let type = "contractor";
      if (helpers.isEmptyString(value)) return true;
      if (!helpers.validRUT(value, type)) {
        setRutMsjError(null);
        return true;
      }

      if (previous === value) {
        return false;
      }
      setLoading(true);
      const contractor = await Request.validateContractorRut(value);
      setLoading(false);

      if (contractor?.status !== 200) {
        setRutMsjError(getErrorMessage(contractor?.internalCode));
        return true;
      }
      if (Object.keys(contractor?.body)?.length > 0) {
        setPersonData({ name: "contractorRUT", value: contractor?.body?.rut });
        setPersonData({
          name: "contractorName",
          value: contractor?.body?.name,
        });
        setExistContractor(true);
        return false;
      }
      setExistContractor(false);
      setRutMsjError(null);
      return false;
    },

    [setPersonData, setExistContractor, setLoading]
  );

  const fieldValidation = useCallback(
    {
      contractorRUT: async (contractorRUT) =>
        person.origin === "external" &&
        (await existsRut(previousPerson?.contractorRUT, contractorRUT)),
      contractorName: (contractorName) =>
        person.origin === "external" && helpers.isEmptyString(contractorName),
      idGroup: (idGroup) => helpers.isEmptyString(idGroup),
      idWorkSchedule: (idWorkSchedule) => helpers.isEmptyString(idWorkSchedule),
      idArea: (idArea) => helpers.isEmptyString(idArea),
      idLeader: (idLeader) => helpers.isEmptyString(idLeader),
    },
    [previousPerson, person, existsRut]
  );

  const validate = useCallback(async () => {
    const errors = {};
    for (const key in person) {
      if (Object.hasOwnProperty.call(fieldValidation, key)) {
        const isValid = await fieldValidation[key](person[key]);
        errors[key] = isValid;
      }
    }
    return errors;
  }, [person, fieldValidation]);

  useEffect(() => {
    (async () => {
      const currentErrors = await validate();
      setErrors((errors) => ({ ...errors, ...currentErrors }));
    })();
  }, [person, validate]);

  const existError = useCallback((objectError) => {
    //convert object to array
    const arrayErrors = Object.values(objectError);
    //search error=true
    let error = arrayErrors.find((e) => e);
    //object attributes repeats the process
    if (typeof error === "object") error = existError(error);
    return error;
  }, []);

  useEffect(() => {
    if (!Object.values(errors).length) return;
    if (!existError(errors)) return setCanContinue(true);

    setCanContinue(false);
  }, [errors, setCanContinue, existError]);
  /** -- */

  const getParameters = async () => {
    let resultGroups = Parameter.getGroups();
    let resultWorkS = Parameter.getWorkSchedules();
    let resultAreas = Parameter.getAreas();
    resultGroups = await resultGroups;
    resultWorkS = await resultWorkS;
    resultAreas = await resultAreas;
    return { resultGroups, resultWorkS, resultAreas };
  };

  const getSelectorsOptions = useCallback(async () => {
    setLoading(true);
    const { resultGroups, resultWorkS, resultAreas } = await getParameters();
    setLoading(false);

    if (resultGroups?.status !== 200 || resultWorkS?.status !== 200)
      return handleError(resultGroups || resultWorkS);

    setGroups(helpers.parseForSelect(resultGroups.body));
    setWorkSchedule(helpers.parseForSelect(resultWorkS.body));
    setAreas(helpers.parseForSelect(resultAreas.body));
  }, [setLoading, setGroups, setAreas]);

  useEffect(() => {
    getSelectorsOptions();
  }, [getSelectorsOptions]);

  const handleError = (res) => {
    setAlertContent({
      type: "error",
      title: "Error",
      message: getErrorMessage(res.internalCode),
    });
    setShowAlert(true);
    return;
  };

  const handleArrayElement = (array, id) => {
    if (!array || !id) return null;
    return array?.find((e) => e._id === id);
  };

  const handleLeaders = useCallback(async () => {
    const idArea = currentSelects?.area?._id || person?.idArea;
    if (!idArea || prevItems?.person?.idArea === person?.idArea) return;
    setLoading(true);
    const res = await Request.getLeader(idArea);
    setLoading(false);
    if (res?.status !== 200) return handleError(res);
    const leaders = res?.body;
    setLeaders(
      helpers.parseForSelect(
        leaders.map((leader) => ({
          ...leader,
          name: getNameLeader(leader.profile),
        }))
      )
    );
    //set leader in case of being unique
    if (isOnlyLeader(leaders)) {
      let leader = leaders[0];
      if (prevItems?.person?.idLeader !== leader?._id) {
        setPersonData({ name: "idLeader", value: leader?._id });
      }
      if (
        currentSelects?.leader?._id !== leader?._id ||
        currentSelects?.leader === null
      ) {
        setCurrentSelects({
          ...currentSelects,
          leader: { ...leader, name: getNameLeader(leader.profile) },
        });
      }
    }
  }, [
    currentSelects,
    setCurrentSelects,
    person,
    setPersonData,
    setLeaders,
    prevItems,
    setLoading,
  ]);

  useEffect(() => {
    (async () => {
      await handleLeaders();
    })();
  }, [handleLeaders]);

  const getNameLeader = (leader) => {
    if (!leader) return "";
    return `${leader?.firstName || ""} ${leader?.secondName || ""} ${
      leader?.firstLastName || ""
    } ${leader?.secondLastName || ""}`;
  };

  const isOnlyLeader = (leaders) => {
    if (leaders?.length === 1) return true;
    return false;
  };

  return (
    <form className="form">
      <div className=" medium bold form-title">Datos laborales</div>
      {person.origin === "external" && (
        <React.Fragment>
          <div className="form-group">
            <label htmlFor="rut" className="form-group small">
              RUT *
            </label>
            <HolosInput
              className="white-background"
              placeholder="16163530-8"
              type="text"
              id="contractorRUT"
              name="contractorRUT"
              onChange={(e) => {
                setExistContractor(false);
                setPersonData({ name: "contractorName", value: "" });
                setPersonData(e);
              }}
              value={person?.contractorRUT}
              isError={viewError && errors?.contractorRUT}
              msjError={rutMsjError || "Debe ingresar un RUT válido"}
            />
          </div>
          <div className="form-group">
            <label htmlFor="contractorName" className="form-group small">
              Empresa *
            </label>
            <HolosInput
              className="white-background"
              placeholder="Holos Technology SA"
              type="text"
              id="contractorName"
              name="contractorName"
              disabled={existContractor}
              onChange={(e) => {
                setPersonData(e);
              }}
              value={person?.contractorName}
              isError={viewError && errors?.contractorName}
              msjError={"Debe ingresar este campo"}
            />
          </div>
        </React.Fragment>
      )}
      <div className="form-group">
        <label htmlFor="group" className="form-group small">
          Grupo *
        </label>
        <IconSelect
          placeholder={"Seleccione un grupo"}
          options={groups}
          id="group"
          name="group"
          onChange={(e) => onSelect(e, "idGroup")}
          value={
            currentSelects?.group || handleArrayElement(groups, person?.idGroup)
          }
          isError={viewError && errors["idGroup"]}
          msjError={"Debe ingresar este campo"}
        />
      </div>
      <div className="form-group">
        <label htmlFor="workSchedule" className="form-group small">
          Periodo de trabajo *
        </label>
        <IconSelect
          placeholder={"Seleccione un periodo"}
          options={workSchedules}
          id="workSchedule"
          name="workSchedule"
          onChange={(e) => onSelect(e, "idWorkSchedule")}
          value={
            currentSelects?.workSchedule ||
            handleArrayElement(workSchedules, person?.idWorkSchedule)
          }
          isError={viewError && errors["idWorkSchedule"]}
          msjError={"Debe seleccionar este campo"}
        />
      </div>
      <div className="form-group">
        <label htmlFor="area" className="form-group small">
          Area de trabajo *
        </label>
        <IconSelect
          placeholder={"Mejoramiento continuo"}
          options={areas}
          id="area"
          name="area"
          onChange={(e) => {
            onSelect(e, "idArea");
            // clear local and global leader
            setCurrentSelects((current) => ({
              ...current,
              leader: null,
            }));
            // clear father var leader
            setPersonData({ name: "idLeader", value: "" });
          }}
          value={
            currentSelects?.area || handleArrayElement(areas, person?.idArea)
          }
          isError={viewError && errors["idArea"]}
          msjError={"Debe seleccionar este campo"}
        />
      </div>
      {leaders.length > 0 && (
        <div className="form-group">
          <label htmlFor="idLeader" className="form-group small">
            Jefe de area *
          </label>
          {isOnlyLeader(leaders) ? (
            <p className="small">{currentSelects?.leader?.name}</p>
          ) : (
            <IconSelect
              placeholder={"Seleccione un jefe de area"}
              options={leaders}
              id="leader"
              name="leader"
              onChange={(e) => onSelect(e, "idLeader")}
              value={
                currentSelects?.leader ||
                handleArrayElement(leaders, person?.idLeader)
              }
              isError={viewError && errors["idLeader"]}
              msjError={"Debe seleccionar este campo"}
            />
          )}
        </div>
      )}
      {currentSelects?.leader?.email && (
        <div className="form-group">
          <label htmlFor="areaLeaderEmail" className="form-group small">
            Email
          </label>
          <p className="small">{currentSelects?.leader?.email[0]}</p>
        </div>
      )}

      {currentSelects?.leader?.profile?.contactPhone && (
        <div className="form-group">
          <label htmlFor="areaLeaderPhone" className="form-group small">
            Teléfono
          </label>
          <p className="small">
            {currentSelects?.leader?.profile?.contactPhone}
          </p>
        </div>
      )}
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={(isOpen) => setShowAlert(isOpen)}
        title={alertContent.title}
        content={alertContent.message}
        type={alertContent.type}
      />
    </form>
  );
};
export default JobDataForm;
