import React, { useContext, useState } from "react";

import SmallBaseBody from "../../../../../../shared/components/smallBaseBody";

import EmailForm from "./parts/EmailForm";

import { validEmail } from "../../../../../../utils/helpers";

import "./styles.scss";
import UsersButtons from "../../../../shared/usersButtons";
import Request from "./../../../../services/request";
import { Loading } from "../../../../../../context";
import { getErrorMessage } from "../../../../../../services/errors";

const Email = ({ setPerson, setPersonData, person, setView }) => {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [ emailError, setEmailError ] = useState(null);

  const [, setLoading] = useContext(Loading)

  const onDisabled = () => {
    if (!person?.email) return true;
    if (isEmailValid) return true;
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault()
    getPersonByEmail()
  }

  const getPersonByEmail = async () => {
    setLoading(true);
    const res = await Request.validateEmail(person?.email, "idOperation");
    setLoading(false);
    if (res?.status !== 200) {
      setIsEmailValid(true)
      setEmailError(getErrorMessage(res.internalCode))
      return;
    }
    //TODO delete after DTO
    const body = res.body
    delete res.email;
    const p = {
      ...person,
      ...body,
      ...body?.profile,
      birthDate: body?.profile?.birthDate ? new Date(body?.profile?.birthDate) : null,
    };  

    setPerson(p);
    setView("PersonalData")
  };

  return (
    <div className="BhpMail">
      <SmallBaseBody
        className={"bhpmail-container"}
        content={
          <React.Fragment>
            <p className="bold company-title great">Ingresa tu Email</p>
            <EmailForm
              isError={isEmailValid}
              email={person?.email}
              setIsEmailValid={(value) => {
                setIsEmailValid(!validEmail(value));
              }}
              emailError={emailError}
              setEmailError={setEmailError}
              setPersonData={setPersonData}
              onSubmit={onSubmit}
            />
            {person?.origin === "internal" && (
              <div className="form-group small">
                Si tienes una cuenta "@bhp.com" recuerda que debes usarla
              </div>
            )}
          </React.Fragment>
        }
        buttons={
          <UsersButtons
            disabled={onDisabled()}
            buttonName="Continuar"
            text="Ya tienes una cuenta?"
            link="/users/login"
            linkText="Inicia sesión"
            onSubmit={onSubmit}
          />
        }
      />
    </div>
  );
};

export default Email;
