import React from "react";
import "./styles.scss";
import LogoYoContribuyo from "../logoYoContribuyo";

/**
 * A component to display a link with some text before
 *
 * @param {object} props Props pased to the component
 * @param {string} props.className Custom classname
 * @param {JSX.Element} props.content Content to be showed
 * @param {JSX.Element} props.buttons Buttons to be showed
 */
const SmallBaseBody = ({ content, buttons, className }) => {
  return (
    <div className="Small-base-body-page">
      <div className={`Small-base-body ${className ? className : ""}`}>
        <div className="container">
          <div className="title">
            <LogoYoContribuyo />
          </div>
          <div className="content ">{content}</div>
          <div className="buttons">{buttons}</div>
        </div>
      </div>
    </div>
  );
};

export default SmallBaseBody;
