import RequestManager from "../../../services/requestManager";
import config from "../../../services/config";
import {
  loginURL,
  changePassURL,
  registerURL,
  restorePassword,
  validateRutURL,
  validateEmail,
  resendActivateAccount,
  contractorRutURL,
  areasURL,
  createAccountUrl,
  leaderURL,
} from "./urls.json";
import { encodedEmail, encodedRUT } from "../../../utils/helpers";

class Request {
  constructor() {
    this.baseUrl = `${config.REACT_APP_API_URL}`;
  }

  login(body, params) {
    const validate = params ? `?validate=${params}` : "";
    let url = `${this.baseUrl}${loginURL}${validate}`;
    return RequestManager.post(url, body);
  }

  changeEmail(body) {
    let url = `${this.baseUrl}${changePassURL}`;
    return RequestManager.post(url, body);
  }

  getPersonByEmail(params) {
    const emailEncoded = encodedEmail(params);
    const email = emailEncoded ? `${emailEncoded}` : "";
    let url = `${this.baseUrl}${registerURL}${email}`;
    return RequestManager.get(url);
  }

  restoreEmail(body) {
    let url = `${this.baseUrl}${restorePassword}`;
    return RequestManager.post(url, body);
  }

  validateRUT(email, rut, type) {
    const emailEncoded = encodedEmail(email);
    const emailURL = emailEncoded ? `${emailEncoded}` : "";
    const rutEncoded = encodedRUT(rut);
    const rutURL = rutEncoded ? `${rutEncoded}` : "";
    let url = `${this.baseUrl}${validateRutURL}${emailURL}/${rutURL}`;
    return RequestManager.get(url);
  }

  validateEmail(email, idOperation) {
    const emailEncoded = encodedEmail(email);
    const emailURL = emailEncoded ? `${emailEncoded}` : "";
    let url = `${this.baseUrl}${validateEmail}${emailURL}/${idOperation}`;
    return RequestManager.get(url);
  }

  createAccount(body) {
    let url = `${this.baseUrl}${createAccountUrl}`;
    return RequestManager.post(url, body);
  }

  resendActivateAccount(body) {
    let url = `${this.baseUrl}${resendActivateAccount}`;
    return RequestManager.post(url, body);
  }
  validateContractorRut(rut) {
    const contractorRutEncoded = encodedRUT(rut);

    const contractorRut = contractorRutEncoded ? `${contractorRutEncoded}` : "";
    let url = `${this.baseUrl}${contractorRutURL}${contractorRut}`;
    return RequestManager.get(url);
  }

  getLeader(id) {
    let url = `${this.baseUrl}${areasURL}${id}/${leaderURL}`;
    return RequestManager.get(url);
  }
}

const instance = new Request();
Object.freeze(instance);
export default instance;
