import React from "react";
import FormButtonStepper from "../../../../../../../../shared/components/formButtonStepper";
import CausesForm from "./parts/causesForm";
import "./styles.scss";

const ChallengesCauses = (props) => {
  return (
    <div className="Challenges-causes">
      {" "}
      <CausesForm {...props} />
      <FormButtonStepper className="buttons-flex-end" />
    </div>
  );
};

export default ChallengesCauses;
