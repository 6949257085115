import React from "react";
import "./styles.scss";
import { Error } from "@material-ui/icons";
import IconText from "../iconText";

const HolosTextArea = ({
  //var
  placeholder,
  width,
  height,
  value,
  name,
  disabled,
  isError,
  msjError,
  id,
  className,
  filterRef,
  //method
  onChange,
  onBlur,
}) => {
  const getClassName = (disabled, className, isError) => {
    let currentClass = "Holos-textarea";
    if (disabled) currentClass = currentClass.concat(" disabled");
    if (className) currentClass = currentClass.concat(` ${className}`);
    if (!msjError && isError) currentClass = currentClass.concat(" invalid");
    return currentClass;
  };
  return (
    <React.Fragment>
      <div
        className={getClassName(disabled, className, isError)}
        style={{
          width: width || "100%",
          height: height || "",
        }}
      >
        <textarea
          className="holos-text-area-input"
          ref={filterRef}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
      {isError && msjError && (
        <div className="input-error">
          <IconText
            icon={<Error />}
            text={msjError}
            className="red-icon-text"
            separatingMargin="8px"
          />
        </div>
      )}
    </React.Fragment>
  );
};
export default HolosTextArea;
