import React, { useState } from "react";
import FormButtonStepper from "../../../../../../shared/components/formButtonStepper";
import JobDataForm from "./parts/jobDataForm";
import "./styles.scss";

const JobData = ({ setPersonData, person, setView }) => {
  const [viewError, setViewError] = useState(false)
  const [canContinue, setCanContinue] = useState(false);

  const onSubmit = () => {
    setViewError(true)
    if (canContinue) {
      setView("ProfilePictureData")
      return
    }
  }

  return (
    <div className="Job-data">
      <JobDataForm
        setPersonData={setPersonData}
        person={person}
        viewError={viewError}
        setCanContinue={setCanContinue}
      />
      <FormButtonStepper
        showGoBack={true}
        goBackView={"PersonalData"}
        onSubmit={onSubmit}
        setView={setView}
      />
    </div>
  );
};

export default JobData;
