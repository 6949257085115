import RequestManager from "../../../services/requestManager";
import config from "../../../services/config";
import { mapURL } from "./urls.json";

class Request {
  constructor() {
    this.baseUrl = config.REACT_APP_API_URL;
  }

  getMaps() {
    let url = this.baseUrl + mapURL;
    return RequestManager.get(url);
  }

  getMap(id) {
    let url = this.baseUrl + mapURL + id;
    return RequestManager.get(url);
  }

  createMap(body) {
    let url = this.baseUrl + mapURL;
    return RequestManager.post(url, body);
  }

  editMap(id, body) {
    let url = this.baseUrl + mapURL + id;
    return RequestManager.put(url, body);
  }

  deleteMap(id) {
    let url = this.baseUrl + mapURL + "delete/" + id;
    return RequestManager.put(url);
  }
}

const instance = new Request();
Object.freeze(instance);
export default instance;
