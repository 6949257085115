import React, { useState } from "react";
import "./styles.scss";
import IconText from "../iconText";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Settings, DonutLarge, TrendingUp } from "@material-ui/icons";
import qr from "../../../assets/icons/qr.svg";
import ideas from "../../../assets/icons/wbIncandescentBlack24dp.svg";
import FloatingModal from "../floatingModal";

const DropdownButton = ({ text }) => {
  const [showFloating, setShowFloating] = useState(false);
  //TODO: Delelete combine repeating constants 
  const dropdownTabs = [
    {
      className: "transparent-background purple-hover purple-background-select",
      text: {
        name: "Ideas",
        className: "small bold purple-background-select",
        icon: ideas,
      },
      urlRedirect: "/ideas",
      onClick: () => setShowFloating(false),
    },
    {
      className: "transparent-background purple-hover purple-background-select",
      text: {
        name: "Círculos de calidad",
        className: "small bold purple-background-select",
        icon: <DonutLarge />,
      },
      urlRedirect: "/circles/list",
      onClick: () => setShowFloating(false),
    },
    {
      className: "transparent-background purple-hover purple-background-select",
      text: {
        className: "small bold purple-background-select",
        name: "Placas QR",
        icon: qr,
      },
      urlRedirect: "/plaques/list",
      onClick: () => setShowFloating(false),
    },
    {
      className: "transparent-background purple-hover purple-background-select",
      text: {
        className: "small bold purple-background-select",
        name: "Informes",
        icon: <TrendingUp />,
      },
      urlRedirect: "/reports",
      onClick: () => setShowFloating(false),
    },
    {
      className: "transparent-background purple-hover purple-background-select",
      text: {
        className: "small bold purple-background-select",
        name: "Solicitudes de cambios",
        icon: <TrendingUp />,
      },
      urlRedirect: "/requests",
      onClick: () => setShowFloating(false),
    },
    {
      className: "transparent-background purple-hover purple-background-select",
      text: {
        className: "small bold purple-background-select",
        name: "Configuración",
        icon: <Settings />,
      },
      urlRedirect: "/settings",
      onClick: () => setShowFloating(false),
    },
  ];

  const getTabs = (dropdownTabs) => {
    dropdownTabs = dropdownTabs.filter((tab) => tab?.text?.name !== text?.name);
    return dropdownTabs;
  };

  return (
    <div className={"Dropdown-button"}>
      <button onClick={() => setShowFloating(!showFloating)}>
        <IconText
          text={text?.name}
          className={"purple-icon-text bold small"}
          icon={text?.icon}
          separatingMargin={"13px"}
        />
        <KeyboardArrowDown className="dropdow-arrow " />
        {/* <KeyboardArrowUpOutlined/> */}
      </button>
      {showFloating && <FloatingModal tabs={getTabs(dropdownTabs)} />}
    </div>
  );
};

export default DropdownButton;
