import { IonPage } from "@ionic/react";
import React from "react";
import AppBar from "../../../shared/components/appBar";
import SideBar from "../../../shared/components/sideBar";
import logoIcon from "../../../assets/icons/qrPurple.svg";
import moduleIcon from "../../../assets/icons/qrPurplex24.svg";
import "./plaque.scss";
import { Routes } from "../routes";
import { moduleTabs } from "../constants";
import { isDesktop } from "../../../utils/helpers";

const HomePlaque = (props) => {
  const canAccess = isDesktop();
  return (
    <IonPage id="home-plaque">
      {(canAccess && (
        <div className="plaqueHome">
          <SideBar
            moduleIcon={moduleIcon}
            moduleTabs={moduleTabs}
            moduleName={"Placas QR"}
            logoIcon={logoIcon}
            logoName={"QR"}
            isDesktop={canAccess}
            {...props}
          />
          <div className="mainContent">
            <AppBar {...props} />
            <Routes />
          </div>
        </div>
      )) || <p>No disponible en la version mobile</p>}
    </IonPage>
  );
};

export default HomePlaque;
