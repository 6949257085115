import { NotInterested, WarningOutlined } from "@material-ui/icons";
import React from "react";
import HolosAlert from "../holosAlert";
import HolosButton from "../holosButton";
import IconText from "../iconText";
import QRCode from "../../../assets/qr";

import "./styles.scss";

const DeleteAlert = ({
  showAlert,
  setShowAlert,
  item,
}) => {
  return (
    <HolosAlert
      key={1}
      showAlert={showAlert}
      setShowAlert={setShowAlert}
      title={
        <React.Fragment>
          <IconText
            icon={<QRCode />}
            text={item.name}
            iconColor={"var(--purpleBHP)"}
          />
        </React.Fragment>
      }
      content={
        <div className="delete-plaque">
          <div className="alert-icon">
            <WarningOutlined className="Warning-outlined"/>
          </div>
          <div className="alert-content">
            <p>
              ¿Estás seguro que deseas desactivar esta
              placa QR?
            </p>
            <p>
              Si quieres acceder nuevamente, podrás hacerlo desde la pestaña “Qrs Desactivados”
            </p>
          </div>
        </div>
      }
      actions={[
        <HolosButton
        key={1}
          separatingMargin={"1em"}
          icon={{ icon: <NotInterested />, color: "white",opacity:"1" }}
          background={{ color: "var(--purpleBHP)" }}
          text={{ name: "Desactivar", color: "white", colorHover: "white" }}
          onClick={() => {
            setShowAlert(false, "accept")
          }}
        />,
      ]}
    />
  );
};


export default DeleteAlert;
