import { LocationOnOutlined, MyLocationOutlined } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import IconText from "../../../../shared/components/iconText";
import { getCenterPolygon, removeAccents } from "../../../../utils/helpers";
import HolosSearch from "../../shared/holosSearch";
import InfoCard from "../../shared/infoCard";
import MapCard from "../../shared/mapCard";
import Request from "./../../../maps/services/request";
import qr from "./../../../../assets/icons/qr.svg";

import location from "./../../../../shared/components/googleMap/icons/location.png";
import locationPurple from "./../../../../shared/components/googleMap/icons/currentLocation.png";

import "./styles.scss";
import { Loading } from "../../../../context";
const getCenter = (markers) => {
  if (markers) {
    const currentCoords = markers.map((marker) => marker.coords);
    currentCoords.push(markers[0].coords);
    const center = getCenterPolygon(currentCoords);
    return center;
  }
};

const ListMap = () => {
  const [maps, setMaps] = useState(null);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [centerMap, setCenterMap] = useState(null);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [currentSearch, setCurrentSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [, setLoading] = useContext(Loading);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const maps = (await Request.getMaps()).body;
      setLoading(false);

      if (maps) {
        setMaps(maps);
        const markers = maps.map((map) => {
          return {
            id: map._id,
            type: "location",
            plaques: map.plaques,
            name: map.name,
            coords: getCenterPolygon(map.areaCoords),
            current: false,
          };
        });
        setMapMarkers(markers);
        setCenterMap(getCenter(markers));
      } else setMaps(null);
    })();
  }, [setLoading]);

  useEffect(() => {
    filterByLocation(maps, currentSearch, setFilter);
  }, [maps, currentSearch]);
  const onChange = (e, setCurrentSearch) => {
    setCurrentMarker(null);
    setCurrentSearch(e.currentTarget.value);
  };

  // filter regardless of capital letters and accent
  const filterByLocation = (maps, name, setFilter) => {
    if (name === "") return setFilter(maps);
    const nameWithoutAccent = removeAccents(name).toLowerCase();

    const filter = maps.filter((map) =>
      removeAccents(map.name.toLowerCase()).includes(nameWithoutAccent)
    );

    return setFilter(filter);
  };

  return (
    <div className="List-map">
      <HolosSearch
        icon={<LocationOnOutlined />}
        placeholder={"Buscar ubicación"}
        value={currentSearch}
        onChangeSearch={(e) => onChange(e, setCurrentSearch)}
      />
      <MapCard
        header={
          <IconText
            icon={<MyLocationOutlined />}
            text={"Mina Escondida, Antofagasta, Chile"}
            separatingMargin={"0.6875em"}
            fontColor={"var(--purpleBHP"}
            iconColor={"var(--purpleBHP"}
          />
        }
        footer={
          <IconText
            icon={<LocationOnOutlined />}
            text={`${maps?.length} ubicaciones activas`}
            separatingMargin={"0.6875em"}
            fontColor={"#444444"}
            iconColor={"#444444"}
          />
        }
        markers={mapMarkers}
        center={centerMap}
        markerIconURL={location}
        currentMarkerIconURL={locationPurple}
        currentMarker={currentMarker}
        setCurrentMarker={setCurrentMarker}
      />
      {filter &&
        filter.map((map, index) => (
          <div
            key={index}
            className={`map-info-card ${
              map._id === currentMarker ? "active-marker" : ""
            }`}
            onMouseEnter={() => setCurrentMarker(map._id)}
            onMouseLeave={() => setCurrentMarker(null)}
            onTouchStart={() => setCurrentMarker(map._id)}
          >
            <InfoCard
              link={`/visor/list/plaques/${map._id}`}
              title={
                <IconText
                  icon={<LocationOnOutlined />}
                  text={map.name}
                  iconSize={"1em"}
                />
              }
              content={
                <IconText
                  icon={qr}
                  text={`${map.plaques?.length} placas QR`}
                  iconSize={"2em"}
                />
              }
            />
          </div>
        ))}
    </div>
  );
};

export default ListMap;
