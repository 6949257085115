import React, { useState, useCallback, useEffect } from "react";
import HolosAlert from "../../../../../../../../../../shared/components/holosAlert";
import HolosInput from "../../../../../../../../../../shared/components/holosInput";
import IconSelect from "../../../../../../../../../../shared/components/iconSelect/iconSelect";
import HolosButton from "../../../../../../../../../../shared/components/holosButton";
import * as helpers from "../../../../../../../../../../utils/helpers"

import "./styles.scss";
import HolosCheck from "../../../../../../../../../../shared/components/holosCheck";

const NewCauseModal = ({
  setShowCauseModal, 
  showCauseModal, 
  categories, 
  challenge, 
  setChallengeData,
  currentCauseIndex,
  setCurrentCauseIndex
}) => {
  const [title, setTitle] = useState("")
  const [idCauseCategory, setIdCauseCategory] = useState("")
  const [hierarchyOrder, setHierarchyOrder] = useState("")
  const [showA3, setShowA3] = useState(false)
  const [viewError, setViewError] = useState(false);
  const [valid, setValid] = useState(false);
  const cause = { title, idCauseCategory, hierarchyOrder, showA3 }
  const editMode = currentCauseIndex != null

  const hierarchy = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" }
  ]

  const controlShowModal = (close)=>{
    setEmptyCause()
    setShowCauseModal(close)
    setViewError(false);
  }

  const fieldValidation = {
    title: (title) => title?.length < 1,
    idCauseCategory: (idCauseCategory) => idCauseCategory?.length < 1,
    hierarchyOrder: (hierarchyOrder) =>hierarchyOrder?.length < 1
  };

  const errors = helpers.useValidation(cause, fieldValidation);

  const existError = useCallback(helpers.existError, []);

  const loadValues = useCallback(()=>{
    if (currentCauseIndex === null) return
    const causes =  challenge?.causes
    setTitle(causes[currentCauseIndex].title)
    setIdCauseCategory(causes[currentCauseIndex].idCauseCategory)
    setHierarchyOrder(causes[currentCauseIndex].hierarchyOrder)
    setShowA3(causes[currentCauseIndex].showA3)
  },[setTitle,setIdCauseCategory,setHierarchyOrder,setShowA3,currentCauseIndex,challenge.causes])

  const onSubmit = () => {
    setViewError(true);
    if (valid) {
      saveCause()
      controlShowModal(false)
      return;
    }
  };
  
  const saveCause = ()=>{
    let causes =  challenge?.causes
    if (editMode)
      causes[currentCauseIndex] = {...causes[currentCauseIndex],...cause}
    else
      causes.push(cause)
    setChallengeData({...challenge, 'causes': causes})
  }

  const setEmptyCause = ()=>{ 
    setCurrentCauseIndex(null)
    setTitle("")
    setIdCauseCategory("")
    setHierarchyOrder("")
    setShowA3(false)
  }

  useEffect(()=>{
    if(editMode)
      loadValues()
  },[editMode, loadValues])

  useEffect(() => {
    if (!existError(errors)) return setValid(true);
    setValid(false);
  }, [errors, existError, setValid]);

  return (
    <HolosAlert
      showAlert={showCauseModal}
      setShowAlert={controlShowModal}
      title={"Causa"}
      content={
        <form className="New-cause-modal">
          <div className="form-group">
            <div className="form-control">
              <label htmlFor="member" className="form-label small bold required">
                Nombre
              </label>
              <HolosInput
                className="bold title-input mr-small"
                placeholder="Nombre del desafío"
                id="title"
                name="title"
                onChange = {(e)=>{setTitle(e.target.value)}}
                isError={viewError && errors?.title}
                msjError="Campo Obligatorio"
                value = {title}
              />
            </div>
          </div>{" "}
          <div className="form-group">
            <div className="form-control">
              <label htmlFor="member" className="form-label small bold required">
                Categoria
              </label>
              <IconSelect
                className="white-background"
                placeholder="Categoría de la causa"
                options={categories}
                onChange={(e) =>
                    setIdCauseCategory(e._id)
                }
                value={categories.find(
                  (item) => item._id === idCauseCategory)}
                isError={viewError && errors?.idCauseCategory}
                msjError="Campo Obligatorio"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-control">
              <label htmlFor="member" className="form-label small bold required">
                Jerarquía
              </label>
              <IconSelect 
                className="white-background" 
                placeholder="1" 
                options={hierarchy}
                onChange={(e) =>
                  setHierarchyOrder(e.value)
                }
                value={hierarchy?.find(
                  (item) => item.value === hierarchyOrder)}
                isError={viewError && errors?.hierarchyOrder}
                msjError="Campo Obligatorio"
                />
            </div>
          </div>{" "}
          <div className="form-group">
            <div className="form-control">
              <HolosCheck
                label="Mostrar en A3 (Maximo 2)"
                className="check-first"
                onChange={(e)=>{setShowA3(e)}}
                isChecked = {showA3}
              />
            </div>
          </div>
        </form>
      }
      actions={[
        <HolosButton
          key={1}
          separatingMargin={"1em"}
          className="purple-background"
          text={editMode?{ name: "Guardar", className: "white-icon-text" }:{ name: "Agregar", className: "white-icon-text" }}
          onClick={onSubmit}
        />,
      ]}
    ></HolosAlert>
  );
};

export default NewCauseModal;
