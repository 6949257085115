import React, { useState } from "react";
import FloatingModal from "../../../../../../../../shared/components/floatingModal";
import HolosInput from "../../../../../../../../shared/components/holosInput";
import {
  CreateOutlined,
  MoreVertOutlined,
  DeleteForever,
  EmojiEventsOutlined,
} from "@material-ui/icons";
import CausesIcon from "../../../../../../../../assets/icons/causesIcon";
import ActionsIcon from "../../../../../../../../assets/icons/actionsIcon";


const ChallengeHeader = (index) => {
    const [showFloating, setShowFloating] = useState(null);

  const showFloatingModal = (index) => {
    if (showFloating === null) return false;
    if (showFloating === index) return true;
    return false;
  };
  const editChallengeTabs = [
    {
      className: "transparent-background purple-hover purple-background-select",
      text: {
        name: "Agregar causa",
        className: "small bold purple-background-select",
        icon: <CausesIcon />,
      },
      urlRedirect: "/causes",
      onClick: () => setShowFloating(false),
    },
    {
      className: "transparent-background purple-hover purple-background-select",
      text: {
        name: "Agregar acción",
        className: "small bold purple-background-select",
        icon: <ActionsIcon />,
      },
      urlRedirect: "/actions",
      onClick: () => setShowFloating(false),
    },
    {
      className: "transparent-background purple-hover purple-background-select",
      text: {
        name: "Agregar reconocimiento",
        className: "small bold purple-background-select",
        icon: <EmojiEventsOutlined />,
      },
      urlRedirect: "/recognitions",
      onClick: () => setShowFloating(false),
    },
    {
      className: "transparent-background purple-hover purple-background-select",
      text: {
        name: "Eliminar desafío",
        className: "small bold purple-background-select",
        icon: <DeleteForever />,
      },
      urlRedirect: "/deleteChallenge",
      onClick: () => setShowFloating(false),
    },
  ];
  return (
    <div className="ChallengesHeader-form">
      <div className="form-title">
        <div className="title-internal-container">
          <HolosInput
            className="bold title-input mr-small"
            placeholder="Nombre del desafío"
            id="name"
            name="name"
          />
          <CreateOutlined htmlFor="name" />
        </div>
        <div className="floating-modal-container">
          <MoreVertOutlined
            onClick={() =>
              showFloatingModal(index)
                ? setShowFloating(null)
                : setShowFloating(index)
            }
          />{" "}
          {showFloatingModal(index) && (
            <FloatingModal tabs={editChallengeTabs} key={index} />
          )}
        </div>
      </div>
    </div>
  );
};
export default ChallengeHeader;
