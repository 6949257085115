import {
  AttachFileOutlined,
  Collections,
  DescriptionOutlined,
  HighlightOffOutlined,
  InsertDriveFileOutlined,
  PhotoOutlined,
} from "@material-ui/icons";
import React, { Component } from "react";
import HolosAlert from "../holosAlert";
import IconText from "../iconText";
import "./dragAndDrop.scss";
import iconCloud from "../../../assets/icons/cloudUpload.svg";

class DragAndDrop extends Component {
  state = {
    drag: false,
    showAlert: false,
  };

  dropRef = React.createRef();

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: true });
    //no delete, because it can be used in the future 
    // this.dragCounter++;
    // if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
    // }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    //no delete, because it can be used in the future 
    // this.dragCounter--;
    // if (this.dragCounter === 0) {
    // }
  };

  handleDrop = (e) => {
    const files = e.dataTransfer.files;
    e.preventDefault();
    e.stopPropagation();

    if (this.validType(files)) return;

    this.setState({ drag: false });
    if (files && files.length > 0) {
      this.props.handleDrop(files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  validType = (files) => {
    const { types } = this.props;
    let index = -1;

    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      do {
        index++;

        if (index === types.length) {
          this.setState({ showAlert: true, drag: false });
          return true;
        }
      } while (!(element.type === types[index]));
    }
    return false;
  };

  handleUpload(e) {
    const files = e.currentTarget.files;
    e.preventDefault();
    e.stopPropagation();
    if (files && files.length > 0) {
      this.props.handleDrop(files);
      this.dragCounter = 0;
    }
  }

  isPhoto = (types) => {
    if (types[0] === "image/png" || types[0] === "image/jpeg") return true;
    return false;
  };

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
    this.setState({ drag: false });
  }

  render() {
    const { drag, showAlert } = this.state;
    const { files, deleteFile, types, text, title, isError } = this.props;

    return (
      <div
        className="DragAndDrop"
        ref={this.dropRef}
        style={{
          backgroundImage: `url(${drag ? iconCloud : ""})`,
        }}
      >
        <div className={`columnContainer ${drag ? "drag" : ""}`}>
          {!drag && (
            <IconText
              icon={
                this.isPhoto(types) ? <PhotoOutlined /> : <AttachFileOutlined />
              }
              iconColor={"var(--black)"}
              iconOpacity={0.54}
              text={title}
              fontSize={"14px"}
              fontWeight={"500"}
              fontColor={"var(--softBlack)"}
              separatingMargin={"0.813em"}
            />
          )}
          {!drag && (
            <div className="uploadedContainer">
              {files &&
                files.map((file, index) => (
                  <div className="fileUploaded" key={index}>
                    <InsertDriveFileOutlined className="icon InsertDriveFile" />
                    <span>{file.name}</span>
                    <HighlightOffOutlined
                      onClick={() => deleteFile(file, index)}
                      className="icon HighlightOffOutlined"
                    />
                  </div>
                ))}
            </div>
          )}
          {!drag && files?.length === 0 && (
            <div>
              <AttachFileOutlined className="icon AttachFileOutlined" />
              {this.isPhoto(types) ? (
                <Collections className="icon Collections" />
              ) : (
                <DescriptionOutlined className="icon Collections" />
              )}
            </div>
          )}
          {!drag && (
            <div className="fileContainer">
              <span className="text">Soltar {text}</span>
              <div className="upload">
                <span className="text">o</span>
                <input
                  multiple
                  accept={types.map((type) => `${type},`)}
                  onChange={(e) => {
                    this.handleUpload(e);
                  }}
                  //fuction onClick to detect input type=file “change” for the same file
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  type="file"
                  className="inputFile"
                />
              </div>
            </div>
          )}
          {isError && text.includes("PDF") && !files.length && (
            <span className={"invalid-file"}>Falta un documento pdf </span>
          )}
        </div>
        <HolosAlert
          showAlert={showAlert}
          setShowAlert={(isOpen) => this.setState({ showAlert: isOpen })}
          type={"error"}
          title={"Error"}
          content={"Formato incorrecto"}
        />
      </div>
    );
  }
}
export default DragAndDrop;
