import React, { useCallback, useEffect, useState } from "react";

//Components
import { IonToggle } from "@ionic/react";
import HolosInput from "../../../../../../../../shared/components/holosInput";
import HolosDate from "../../../../../../../../shared/components/holosDate";
import HolosAlert from "../../../../../../../../shared/components/holosAlert";
import HolosButton from "../../../../../../../../shared/components/holosButton";
import IconText from "../../../../../../../../shared/components/iconText";
import { Error } from "@material-ui/icons";

//Services
import Request from "../../../../../../services/request";
import * as helpers from "../../../../../../../../utils/helpers";

export default function ChallengeModal({
  show,
  setShow,
  addChallenge,
  circle,
  challengeIndex,
  setIsEditing,
  isEditing,
  editChallenge,
}) {
  const [validChallenge, setValidChallenge] = useState(false);
  const [viewErrorModal, setViewErrorModal] = useState(false);

  //Challenge Info
  const [name, setName] = useState("");
  const [justDoIt, setJustDoIt] = useState(false);
  const [secondWorkShop, setSecondWorkShop] = useState(null);

  const [leverages, setLeverages] = useState([]);

  const challenge = {
    name: name,
    justDoIt: justDoIt,
    secondWorkShop: secondWorkShop,
    leverages: leverages?.filter((item) => item.selected),
  };

  const getLeverageList = async () => {
    let res = (await Request.getValueList({ type: "leverage" }))?.body;
    res = res?.map((e) => {
      return { ...e, selected: false };
    });
    setLeverages(res);
  };

  const getLeverage = () =>
    leverages &&
    leverages?.map(
      (leverage, index) =>
        leverage.isActive && (
          <React.Fragment key={index}>
            <div className="Ion-toggle">
              <IonToggle
                checked={leverage.selected}
                onIonChange={(e) => {
                  selectLeverage(e.detail.checked, index);
                }}
              />
              <div className="toggle-name">{leverage.name}</div>
            </div>
          </React.Fragment>
        )
    );

  const selectLeverage = (selection, index) => {
    let array = leverages;
    array[index].selected = selection;
    setLeverages([]); //If leverages is not setted to a empty array first the values doesn't detect changes of status
    setLeverages(array);
  };

  const resetChallengeInfo = () => {
    setName("");
    setJustDoIt(false);
    setSecondWorkShop(null);
    let leverageArray = leverages?.map((e) => {
      return { ...e, selected: false };
    });
    setLeverages(leverageArray);
  };

  const onSubmit = () => {
    setViewErrorModal(true);
    if (validChallenge) {
      addChallenge(challenge);
      setShow(false);
      setViewErrorModal(false);
      resetChallengeInfo();
      return;
    }
  };
  const onEdit = () => {
    if (validChallenge) {
      editChallenge(challenge, circle?.challenges[challengeIndex]);
      setIsEditing(false);
      setShow(false);
      setViewErrorModal(false);
      resetChallengeInfo();
      return;
    }
  };

  /** Validate fields */
  const fieldValidation = {
    name: async (name) => await validateEmptyString(name),
    leverages: (leverages) =>
      validateLeverages(
        leverages?.filter((leverage) => leverage.selected === true)
      ),
    secondWorkShop: (secondWorkShop) => validateDate(secondWorkShop),
  };

  const validateEmptyString =async (string) => {
    if (helpers.isEmptyString(string))
      return { status: true, message: "Debe ingresar este campo" };

    const res = await Request.getValidateChallenge({
      challengeName: name,
      challengeId: circle?.challenges[challengeIndex]?._id,
    });
    if (res?.status === 400) {
      return { status: true, message: "Desafío existente" };
    }

    return { status: false };
  };

  const validateLeverages = (leverages) => {
    if (leverages?.length === 0)
      return { status: true, message: "Debe seleccionar un apalancamiento" };
    return { status: false };
  };

  const validateDate = (secondWorkShop) => {
    if (helpers.isEmptyDate(secondWorkShop))
      return { status: true, message: "Debe seleccionar una fecha" };
    if (helpers.compareDate(secondWorkShop, new Date(), "isafter"))
      return {
        status: true,
        message: "La fecha no puede ser posterior a la fecha actual",
      };
    if (helpers.compareDate(secondWorkShop, circle.date, "isbefore"))
      return {
        status: true,
        message:
          "La fecha no puede ser anterior a la fecha de creación del circulo",
      };

    return { status: false };
  };

  const errorsModal = helpers.useValidation(challenge, fieldValidation);

  const existError = useCallback(helpers.existError, []);

  useEffect(() => {
    getLeverageList();
  }, []);

  const setEditChallenge = useCallback(() => {
    const leverageInChallenge = circle?.challenges[challengeIndex]?.leverages;
    setName(circle?.challenges[challengeIndex]?.name);
    setJustDoIt(circle?.challenges[challengeIndex]?.justDoIt);
    setSecondWorkShop(circle?.challenges[challengeIndex]?.secondWorkShop);
    let leverageArray = leverages.map((leverage) => {
      const found = leverageInChallenge.some((e) => {
        return e._id === leverage._id;
      });
      if (found) {
        leverage.selected = true;
      }
      return leverage;
    });

    setLeverages(leverageArray);
  }, [challengeIndex, circle, leverages]);

  useEffect(() => {
    if (isEditing) {
      setEditChallenge();
    }
    // TODO FIX the missing dependency and avoid the loop
    // eslint-disable-next-line
  }, [isEditing]);

  useEffect(() => {
    if (!existError(errorsModal)) return setValidChallenge(true);
    setValidChallenge(false);
  }, [errorsModal, existError, validChallenge]);

  return (
    <HolosAlert
      showAlert={show}
      setShowAlert={setShow}
      title={
        <React.Fragment>
          <IconText icon={""} text={"Crear desafío"} className="small bold" />
        </React.Fragment>
      }
      content={
        <form>
          <div className="mb-medium">
            <label
              htmlFor="challenges-name"
              className="mb-small small bold required"
            >
              Desafío
            </label>
            <HolosInput
              placeholder={"Nombre del desafío"}
              className="mb-small"
              iconColor={"var(--black)"}
              backgroundColor={"var(--softWhite)"}
              options={""}
              onChange={(e) => setName(e.target.value)}
              value={name}
              isError={viewErrorModal && errorsModal?.name?.status}
              msjError={errorsModal?.name?.message}
              id="challenges-name"
              name="challenges-name"
            />
            <div className="Ion-toggle">
              <IonToggle
                checked={justDoIt}
                onIonChange={(e) => setJustDoIt(e.detail.checked)}
              />
              <div className="toggle-name">Just do it</div>
            </div>
          </div>
          <div className="mb-medium">
            <label className="mb-small small bold required">
              Apalancamiento
            </label>
            <div className="challenges-toggles">{getLeverage()}</div>
            {viewErrorModal &&
              errorsModal?.leverages?.status &&
              errorsModal?.leverages?.message && (
                <div className="input-error">
                  <IconText
                    icon={<Error />}
                    text={errorsModal?.leverages?.message}
                    className="red-icon-text"
                    separatingMargin="8px"
                  />
                </div>
              )}
          </div>
          <div className="second-workshop">
            <label
              htmlFor="second-workshop"
              className="mb-small small bold required"
            >
              Segundo taller
            </label>
            <HolosDate
              id="second-workshop"
              name="second-workshop"
              autocomplete="off"
              dateFormat="dd/MM/yyyy"
              className="date-picker small"
              placeholderText="07/11/1989"
              dropdownMode="select"
              onChange={(e) => setSecondWorkShop(e)}
              selected={secondWorkShop}
              isError={viewErrorModal && errorsModal?.secondWorkShop?.status}
              msjError={errorsModal?.secondWorkShop?.message}
            />
          </div>
        </form>
      }
      actions={[
        <HolosButton
          key={1}
          separatingMargin={"1em"}
          className="purple-background"
          text={
            !isEditing
              ? { name: "Crear desafío", className: "white-icon-text" }
              : { name: "Guardar desafío", className: "white-icon-text" }
          }
          onClick={!isEditing ? () => onSubmit() : () => onEdit()}
        />,
      ]}
    />
  );
}
