const env =
  process.env.NODE_ENV === "development"
    ? process.env.NODE_ENV
    : window._env_.REACT_APP_API_ENVIRONMENT;
    
const development = {
  REACT_APP_API_URL: "api/",
  METEOR_APP: "http://localhost:3000/",
};

const test = {
  REACT_APP_API_URL: "api/",
  METEOR_APP: "http://test.yocontribuyo.cl/",
};

const staging = {
  REACT_APP_API_URL: "api/",
  METEOR_APP: "http://test.yocontribuyo.cl/",
};

const prod = {
  REACT_APP_API_URL: "api/",
  METEOR_APP: "http://yocontribuyo.cl/",
};

const config = {
  development,
  staging,
  test,
  prod,
};

console.log('env :>> ', env);

module.exports = config[env];
