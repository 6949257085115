import React, { useState } from "react";
import SmallBaseBody from "../../../../../../shared/components/smallBaseBody";
import UsersButtons from "../../../../shared/usersButtons";
import CompanyContent from "./parts/companyContent";

import "./styles.scss";

const CompanySelect = ({setPersonData, person, setView}) => {
  const [canContinue, setCanContinue] = useState(false);
  const [company, setCompany] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault()
    const origin = company?.value?.includes("BHP") ? "internal" : "external";
    setPersonData({ name: "origin", value: origin})
    setView("Email");
  };

  return (
    <SmallBaseBody
      content={
        <CompanyContent
          company={company}
          setCompany={setCompany}
          setCanContinue={setCanContinue}
        />
      }
      buttons={
        <UsersButtons
          disabled={!canContinue || !company}
          buttonName="Continuar"
          text="Ya tienes una cuenta?"
          link="/users/login/"
          linkText="Inicia sesión"
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default CompanySelect;
