import { SearchOutlined } from "@material-ui/icons";
import React from "react";
import "./styles.scss";

import HolosInput from "../../holosInput";

const FilterBar = ({
  icon,
  gridTemplateColumns,
  gridGap,
  searchFilter,
  currentSearch,
  moreFilters,
  placeholder,
  filterRef,
}) => {
  return (
    <div className="filter-bar">
      <div
        className="filter-bar-search"
        style={{ gridTemplateColumns: gridTemplateColumns, gridGap: gridGap }}
      >
        {icon}
        <HolosInput
        filterRef={filterRef}
          className="white-background"
          onChange={(e) => searchFilter(e)}
          value={currentSearch}
          placeholder={placeholder}
          icon={<SearchOutlined />}
        />
        <div className="more-filters">{moreFilters}</div>
      </div>
    </div>
  );
};

export default FilterBar;
