import React, { useCallback, useEffect, useState } from "react";
import IconText from "../../../../../../../../shared/components/iconText";
import {
  Camera,
  DonutLargeOutlined,
  LocationOnOutlined,
} from "@material-ui/icons";
import "./styles.scss";
import HolosInput from "../../../../../../../../shared/components/holosInput";
import * as helpers from "../../../../../../../../utils/helpers";
import Request from "../../../../../../services/request";

const KickOffForm = ({
  circle,
  setCircleData,
  setCanContinue,
  download,
  setDownload,
}) => {
  const [superIntendence, setSuperIntendence] = useState("");
  const [management, setManagment] = useState("");

  const [selectedFile] = useState(circle?.photo);
  const [preview, setPreview] = useState();

  /** Validate fields */
  const fieldValidation = {
    challenges: (challenges) => (challenges?.length > 0 ? false : true),
  };

  const errors = helpers.useValidation(circle, fieldValidation);

  const existError = useCallback((objectError) => {
    //convert object to array
    const arrayErrors = Object.values(objectError);
    let error = arrayErrors.some((e) => {
      return e;
    });
    //object attributes repeats the process
    if (typeof error === "object") error = existError(error);
    return error;
  }, []);

  const getParentsAreaToShow = useCallback(
    async (id) => {
      const parents = (await Request.getAreaParents(id)).body;
      if (circle?.area?._id) {
        parents &&
          parents.forEach((parent) => {
            if (circle?.area?.idAreaType[0]?.name === "superintendencia")
              setSuperIntendence(circle?.area?.name);

            if (circle?.area?.idAreaType[0]?.name === "gerencia")
              setManagment(circle?.area?.name);

            if (parent?.idAreaType[0]?.name === "superintendencia") {
              setSuperIntendence(parent?.name);
            }
            if (parent?.idAreaType[0]?.name === "gerencia") {
              setManagment(parent?.name);
            }
          });
      }
    },
    [circle]
  );

  const printKickOff = useCallback(async () => {
    window.print();
    setDownload(false);
  }, [setDownload]);

  useEffect(() => {
    if (download) {
      printKickOff();
      setDownload(!download);
    }
  }, [printKickOff, setDownload, download]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl =
      typeof selectedFile === "string"
        ? selectedFile
        : URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    let idArea = circle?.area?._id;
    getParentsAreaToShow(idArea);
  }, [circle, getParentsAreaToShow]);

  useEffect(() => {
    if (!Object.values(errors).length) return;
    if (!existError(errors)) return setCanContinue(true);
    setCanContinue(false);
  }, [errors, setCanContinue, existError]);
  /** -- */

  return (
    <div className="form-wide">
      <div className="form-title">
        <div>
          <HolosInput
            className="bold title-input without-border"
            placeholder="Círculo de calidad"
            value={circle?.name.toUpperCase()}
            id="name"
            name="name"
            onChange={setCircleData}
            disabled
          />
        </div>
      </div>
      <div className="Kick-off">
        <div className="kick-off-header">
          <div className="large mr-medium bold">ESCONDIDA | BHP</div>
          <div className="large lessBold">Circulos de calidad</div>
        </div>
        <div className="kick-off-circle">
          <div className="kick-off-orange-container">
            <IconText
              icon={<DonutLargeOutlined />}
              iconColor={"var(--softWhite)"}
              text={"Nombre del circulo de calidad"}
              className={"small bold"}
              separatingMargin={"0.625em"}
            />
          </div>
          <div className="medium">{circle?.name}</div>
        </div>
        <div className="kick-off-locations">
          <div className="kick-off-location">
            <div className="kick-off-orange-container">
              <IconText
                icon={<LocationOnOutlined />}
                iconColor={"var(--softWhite)"}
                text={"Area"}
                className={"small bold"}
                separatingMargin={"0.625em"}
              />
            </div>
            <div className="small">{circle?.area?.name}</div>
          </div>
          <div className="kick-off-location">
            <div className="kick-off-orange-container">
              <IconText
                icon={<LocationOnOutlined />}
                iconColor={"var(--softWhite)"}
                text={"Superintendencia"}
                className={"small bold"}
                separatingMargin={"0.625em"}
              />
            </div>
            <div className="small">{superIntendence}</div>
          </div>
          <div className="kick-off-location">
            <div className="kick-off-orange-container">
              <IconText
                icon={<LocationOnOutlined />}
                iconColor={"var(--softWhite)"}
                text={"Gerencia"}
                className={"small bold"}
                separatingMargin={"0.625em"}
              />
            </div>
            <div className="small">{management}</div>
          </div>
        </div>
        <div className="kick-off-data">
          <div className="kick-off-team">
            <div className="kick-off-members">
              <div className="kick-off-data-title small mb-medium bold">
                Equipo de circulistas
              </div>
              <div className="kick-off-data-content">
                <div className="kick-off-facilitator small mr-small bold">
                  Lider:
                </div>
                <div className="kick-off-member small mr-small">
                  {circle?.leader?.name}
                </div>
              </div>
              <div className="kick-off-data-content">
                <div className="kick-off-facilitator small mr-small bold">
                  Supervisor:
                </div>
                <div className="kick-off-member small">
                  {circle?.supervisor?.name}
                </div>
              </div>
              <div className="kick-off-data-content">
                <div className="kick-off-facilitator small mr-small bold">
                  Integrante:
                </div>
                {circle?.members.map((member, i) => (
                  <div key={i} className="kick-off-member small">
                    {member?.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="kick-off-coordinators">
              <div className="kick-off-data-title small mb-medium bold">
                Equipo de coordinacion
              </div>
              <div className="kick-off-data-content">
                <div className="kick-off-facilitator small mr-small bold">
                  S.I:
                </div>
                <div className="kick-off-member small">
                  {circle?.validator?.name}
                </div>
              </div>
              <div className="kick-off-data-content">
                <div className="kick-off-facilitator small mr-small bold">
                  Facilitador:
                </div>
                <div className="kick-off-member small">
                  {circle?.bettermentLead?.name}
                </div>
              </div>
            </div>

            {circle?.challenges.map((challenge, i) => (
              <div key={i} className="kick-off-challenges">
                <div className="kick-off-data-title small mb-medium bold">
                  Desafío
                </div>
                <div className="kick-off-data-content">
                  <div className="kick-off-facilitator small mr-small">
                    <div className="item-content">{challenge?.name}</div>
                  </div>
                </div>
              </div>
            ))}
            {circle?.challenges.map((challenge, i) => (
              <div key={i} className="kick-off-leverages">
                <div className="kick-off-data-title small mb-medium bold">
                  Apalancamientos
                </div>
                <div className="kick-off-data-content">
                  {challenge?.leverages?.map((leverage, i) => (
                    <div key={i} className="kick-off-leverage small mr-small">
                      {`${leverage?.name} `}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="kick-off-picture">
            <div className="kick-off-data-title small bold">
              Fotografia del equipo
            </div>
            {!circle?.photo && (
              <div className="kick-off-picture-icon-container">
                <Camera />
              </div>
            )}
            {circle?.photo && (
              <div className="kick-off-picture-icon-container">
                {selectedFile && (
                  <img
                    className="kick-off-picture"
                    src={preview}
                    alt={"circle-team"}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default KickOffForm;
