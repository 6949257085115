import React from "react";
import { Route } from "react-router";
import InvalidQR from "./components/invalidQR";
import ListMap from "./pages/listMap";
import ListPlaque from "./pages/listPlaque";

import PreviewViewFinder from "./pages/preview";

const parentRoute = "/visor";

export const Routes = () => {
  return (
    <React.Fragment>
      <Route
        path={`${parentRoute}/preview/:id`}
        component={PreviewViewFinder}
      />
      
      <Route path={`${parentRoute}/list/maps`} component={ListMap} />
      <Route path={`${parentRoute}/list/plaques/:id`} component={ListPlaque} />

      <Route path={`${parentRoute}/invalidQR`} component={InvalidQR} />
    </React.Fragment>
  );
};
