import React from "react";
import { DeleteForever, BorderColorOutlined } from "@material-ui/icons";

// components
import HolosList from "../../../../../../../../../../../../shared/components/holosList";

// services
import { generateReasonsHeaders } from "../../constants";

const ReasonsRow = ({
  show,
  causeIndex,
  reasons,
  removeWhy,
  editWhy,
  addWhy,
  setShowReasonModal,
}) => {
  const getReasonsRow = () => {
    let reasonsRow = [];
    reasons &&
      reasons.forEach((reason, reasonIndex) => {
        reasonsRow.push(
          <React.Fragment>
            <div className="second-row">
              <div className="item-container">
                <div className="item-content"></div>
              </div>
              <div className="item-container reasons-names">
                <div className="reasons-counter mr-small">
                  {reason?.whyOrder ? reason.whyOrder : reasonIndex + 1}
                </div>
                <div className="item-content">{reason?.title}</div>
              </div>
              <div className="item-actions-with-modal">
                <button
                  className="transparent-background"
                  onClick={() => editWhy(causeIndex, reasonIndex)}
                >
                  <BorderColorOutlined />
                </button>{" "}
                <button
                  className="transparent-background"
                  onClick={() => removeWhy(causeIndex, reasonIndex)}
                >
                  <DeleteForever />
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      });
    return reasonsRow;
  };


  return show ? (
    <HolosList
      headers={generateReasonsHeaders(() => {
        addWhy(causeIndex)
        setShowReasonModal(true);
      })}
      gridTemplateColumns={"15% 65% 15%"}
      gridGap={"5px"}
      rows={getReasonsRow(reasons)}
      showRowsColor={true}
      placeholder={"Buscar por nombre de causa"}
    />
  ) : (
    ""
  );
};

export default ReasonsRow;
