import React, { useState } from "react";
import "./holosButton.scss";
import IconText from "../iconText";

const HolosButton = (props) => {
  const [isHover, setHover] = useState(false);

  const {
    //var
    text,
    icon,
    background,
    isSelect,
    className,
    //method
    onClick,
    disabled,
    type,
  } = props;

  const onSelect = (color, colorSelect) => {
    //if the user does not specify colorSelect, the same color is set
    if (isSelect) return colorSelect || color;
    return color;
  };

  const onMouseHover = (color, colorHover) => {
    //if the user does not specify colorHover, the same color is set
    if (isHover) return colorHover || color;
    return color;
  };

  const getClass = () => {
    let name = "Holos-button";
    if (disabled) (name = `${name} disabled`);
    if (className) name = `${name} ${className}`;
    if (isSelect) name = `${name} select`;
    return name;
  };

  return (
    <div className={getClass()}>
      <button
        disabled={disabled}
        type={type}
        style={{
          backgroundColor: onSelect(background?.color, background?.colorSelect),
        }}
        onClick={(e) => onClick(e)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <IconText
          text={text?.name}
          className={!disabled && text?.className}
          fontColor={
            disabled
              ? "#666666"
              : onSelect(
                  onMouseHover(text?.color, text?.colorHover),
                  text?.colorSelect
                )
          }
          fontSize={text?.size}
          fontWeight={text?.weight}
          icon={icon?.icon || text?.icon}
          iconColor={
            disabled
              ? "#666666"
              : onSelect(
                  icon && onMouseHover(icon?.color, text?.colorHover),
                  text?.colorSelect
                )
          }
          iconOpacity={
            !text?.className &&
            onSelect(onMouseHover(icon?.opacity || 0.54, 1), 1)
          }
          separatingMargin={icon?.icon || text?.icon ? "13px" : "0"}
        />
      </button>
    </div>
  );
};

export default HolosButton;
