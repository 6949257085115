import React from "react";
import SmallBaseBody from "../../../../../../shared/components/smallBaseBody";
import { useHistory, useParams } from "react-router-dom";
import UsersButtons from "../../../../shared/usersButtons";
import { decodedEmail } from "../../../../../../utils/helpers";

const SendEmailChangePassword = () => {
  const history = useHistory();
  const { email } = useParams();
  const onSubmit = (e) => {
    e.preventDefault();
    history.push("/landingpage");
  };
  return (
    <div className="Change-password">
      <SmallBaseBody
        className={"change-container"}
        content={
          <React.Fragment>
            <p className="bold change-title great">
              Revisa tu correo electrónico
            </p>
            <p className="change-title small">
              Enviamos un email con un link a {decodedEmail(email)}{" "}
            </p>
          </React.Fragment>
        }
        buttons={
          <UsersButtons
            className="Holos-link-column"
            buttonName="Aceptar"
            text="¿No encuentras el email? Recuerda buscar en la carpeta Spam ó"
            link="/users/restorePassword"
            linkText="ingrésalo nuevamente"
            onSubmit={onSubmit}
          />
        }
      />
    </div>
  );
};

export default SendEmailChangePassword;
