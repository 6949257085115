import React from "react";
import HolosInput from "../../../../../../shared/components/holosInput";

const LoginForm = ({ data, onChange, msjError, isError }) => (
  <form className="form">
    <div className="form-group">
      <label htmlFor="email" className="form-group small">
        Mail
      </label>
      <HolosInput
        value={data.email}
        className="white-background"
        name="email"
        id="email"
        width="100%"
        height="34px"
        msjError={msjError}
        isError={isError}
        onChange={onChange}
      />
    </div>
    <div className="form-group">
      <label htmlFor="password" className="form-group small">
        Contraseña
      </label>
      <HolosInput
        value={data.password}
        className="white-background"
        name="password"
        type="password"
        id="password"
        width="100%"
        height="34px"
        onChange={onChange}
      />
    </div>
  </form>
);

export default LoginForm;
