import RequestManager from "../requestManager";
import config from "../config";
import {
  gendersURL,
  workSchedulesURL,
  areasURL,
  groupsURL,
  circleTypesURL,
} from "./urls.json";

class Request {
  constructor() {
    this.baseUrl = config.REACT_APP_API_URL;
  }

  getGroups() {
    let url = `${this.baseUrl}${groupsURL}`;
    return RequestManager.get(url);
  }

  getGenders() {
    let url = `${this.baseUrl}${gendersURL}`;
    return RequestManager.get(url);
  }

  getWorkSchedules() {
    let url = `${this.baseUrl}${workSchedulesURL}`;
    return RequestManager.get(url);
  }

  getAreas() {
    let url = `${this.baseUrl}${areasURL}`;
    return RequestManager.get(url);
  }

  getCircleTypes() {
    let url = `${this.baseUrl}${circleTypesURL}`;
    return RequestManager.get(url);
  }
}

const instance = new Request();
Object.freeze(instance);
export default instance;
