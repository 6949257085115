import React from "react";
import HolosInput from "../../../../shared/components/holosInput";
import ValidationPassword from "../../../../shared/components/validationPassword";

const PasswordForm = ({
  password,
  setPassword,
  repeat,
  setRepeat,
  setValidPassword,
  samePassword,
  onSubmit,
}) => (
  <form
    className="form Password-form"
    onSubmit={(e) => {
      e.preventDefault();
      onSubmit(e);
    }}
  >
    <div className="form-group">
      <label htmlFor="password" className="form-group small lessBold">
        Contraseña
      </label>
      <HolosInput
        className="white-background"
        name="password"
        type="password"
        id="password"
        width="100%"
        height="34px"
        onChange={(e) => setPassword(e)}
        value={password}
      />
    </div>
    <div className="form-group">
      <label htmlFor="repeat" className="form-group small lessBold">
        Confirmacion
      </label>
      <HolosInput
        className="white-backgrzund"
        name="repeat"
        type="password"
        id="repeat"
        width="100%"
        height="34px"
        onChange={(e) => setRepeat(e)}
        value={repeat}
        isError={!samePassword}
        msjError="Las contraseña deben coincidir"
      />
    </div>
    <ValidationPassword text={password} onVerifyPassword={setValidPassword} />
  </form>
);

export default PasswordForm;
