import { LocationOnOutlined } from "@material-ui/icons";
import React from "react";
import qr from "../../../assets/icons/qr.svg";

export const darkGrey = "#444444";

export const tabs = [
  {
    text: {
      name: "Nuevo mapa",
      colorHover: "var(--softWhite)",
      color: "var(--softBlack)",
      size: "16px",
      weight: "600",
    },
    icon: {
      color: darkGrey,
      icon: <LocationOnOutlined />,
      opacity: 1,
    },
    background: {
      color: "transparent",
    },
    urlRedirect: "/maps/new"
  },
  {
    text: {
      name: "Nueva placa QR",
      color: "var(--softWhite)",
      size: "16px",
      weight: "600",
    },
    icon: {
      color: "var(--softWhite)",
      icon: qr,
      opacity: "1"
    },
    background: {
      color: "var(--purpleBHP)",
    },
    urlRedirect: "/plaques/new"
  },
];
