import React, { useState, useEffect, useCallback, useContext } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import IconSelect from "../../../../../../../../shared/components/iconSelect/iconSelect";
import HolosInput from "../../../../../../../../shared/components/holosInput";
import "./styles.scss";
import Request from "../../../../../../services/request";
import Parameter from "../../../../../../../../services/globalApi/paremeters";
import { parseForSelect } from "../../../../../../../../utils/helpers";
import DeleteButton from "../../../data/parts/deleteButton";
import AlertToAddExternal from "../alertToAddExternal";
import * as helpers from "../../../../../../../../utils/helpers";
import { getTranslate } from "../../../../../../../../i18n/translate";
import AlertToAddInternal from "../alertToAddInternal";
import AlertDeletePerson from "../alertDeletePerson";
import { Loading } from "../../../../../../../../context";

const TeamForm = ({ setCircleData, circle, setCanContinue, viewError }) => {
  const [, setLoading] = useContext(Loading);
  const [showValidator, setShowValidator] = useState(false);
  const [showBettermentLead, setShowBettermentLead] = useState(false);
  const [showLeader, setShowLeader] = useState(false);
  const [showSupervisor, setShowSupervisor] = useState(false);
  const [currentSelects, setCurrentSelects] = useState({
    validator: "",
    bettermentLead: "",
    supervisor: "",
    leader: "",
    member: null,
    members: null,
  });
  const [currentMemberAlert, setCurrentMemberAlert] = useState("");
  const [memberAlerts, setMemberAlerts] = useState({
    internal: false,
    external: false,
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDeleteInfo, setShowDeleteInfo] = useState(false);
  const [genders, setGenders] = useState([]);

  /** Validate fields */
  const canAddOrRemoveMembers = circle?.members?.length > 1;

  const fieldValidation = {
    members: async (members) => await validateMembers(members),
    validator: (validator) => validateTeam(validator?._id),
    bettermentLead: (bettermentLead) => validateTeam(bettermentLead?._id),
    supervisor: async (supervisor) => await validateTeam(supervisor?._id),
    leader: (leader) => validateTeam(leader?._id),
  };

  const errors = helpers.useValidation(circle, fieldValidation);

  const existError = useCallback(helpers.existError, []);

  const validateTeam = async (idMember) => {
    if (helpers.isEmptyString(idMember))
      return { status: true, message: "Debe ingresar este campo" };
    if (isDuplicated(idMember))
      return { status: true, message: "Persona ya asignada" };

    if (circle?.supervisor?._id === idMember) {
      if (await supervisorIsIn3ActiveCircles(idMember)) {
        return {
          status: true,
          message: "El supervisor ya asignado en 3 circulos activos",
        };
      }
    }
    return { status: false };
  };

  const validateMembers = async (members) => {
    const isBetween = isBetweenTwoAndSix(members);
    if (isBetween?.status) return isBetween;

    const errors = await Promise.all(
      members?.map(async (member) => {
        const dupl = isDuplicated(member._id);
        if (dupl) {
          return { status: true, message: "Persona ya asignada" };
        }

        const isActive = await isInActiveCircle(member._id);
        if (isActive) {
          return {
            status: true,
            message: "Persona ya asignada a un círculo activo",
          };
        }
        return { status: false };
      })
    );
    return errors;
  };

  const isBetweenTwoAndSix = (members) => {
    if (members?.length <= 6 && members?.length >= 2) {
      return { status: false };
    }
    return { status: true, message: "Debe ser entre 2 y 6" };
  };

  const isDuplicated = useCallback(
    (id) => {
      const keysAccepted = [
        "members",
        "leader",
        "supervisor",
        "validator",
        "bettermentLead",
      ];
      //captures the specified attributes of the circle and puts them in an array
      let circleParseArray = [];
      for (const key in circle) {
        if (!keysAccepted.some((accept) => key === accept)) continue;
        if (Array.isArray(circle[key])) {
          circleParseArray = circleParseArray.concat(circle[key]);
          continue;
        }
        circleParseArray.push(circle[key]);
      }
      const existing = circleParseArray.filter((value) => value?._id === id);

      if (existing.length > 1) {
        return true;
      }
      return false;
    },
    [circle]
  );

  const isInActiveCircle = async (id) => {
    if (!id) return false;
    const res = await Request.getValidateCircle({
      member: id,
      circleId: circle._id,
    });
    return res?.status === 400;
  };

  const supervisorIsIn3ActiveCircles = async (id) => {
    if (!id) return false;
    const res = await Request.getValidateCircle({
      supervisor: id,
      circleId: circle._id,
    });
    return res?.status === 400;
  };

  useEffect(() => {
    if (!existError(errors)) return setCanContinue(true);
    setCanContinue(false);
  }, [errors, setCanContinue, existError]);

  const addMember = (member) => {
    let array = [...circle?.members];
    if (circle?.members.length < 6) array.push(member);
    setCircleData({ name: "members", value: array });
  };

  const removeMember = (member) => {
    let array = circle?.members;
    if (circle?.members?.length > 1) {
      const indexMember = array.findIndex((e) => e === member);
      errors.members.splice(indexMember, 1);
      array.splice(indexMember, 1);
    }
    setCircleData({ name: "members", value: array });
  };

  const editMember = (data, oldData) => {
    let array = circle?.members?.map((el) =>
      el === oldData ? { ...oldData, ...data } : el
    );
    setCircleData({ name: "members", value: array });
  };

  const ShowOrHideMember = (member) => {
    let array = circle?.members?.map((el) =>
      el === member ? (el.show = !el.show) : el
    );
    setCircleData("members", array);
  };

  const changeMemberAlert = (type) => {
    setMemberAlerts((memberAlerts) => ({
      ...memberAlerts,
      [type]: !memberAlerts[type],
    }));
  };
  useEffect(() => {
    changeMemberAlert(currentMemberAlert);
  }, [currentMemberAlert]);

  useEffect(() => {
    if (genders?.length) return;
    (async () => {
      setLoading(true);
      const genders = await Parameter.getGenders();
      setLoading(false);
      setGenders(
        parseForSelect(
          genders?.body?.map((gender) => ({
            ...gender,
            name: getTranslate(gender?.name),
          }))
        )
      );
    })();
  }, [genders, setLoading]);

  const tabs = [
    { label: "Interno", value: "internal", id: 1 },
    { label: "Externo", value: "external", id: 2 },
  ];

  const promiseOptions = async (query) => {
    //TODO delete when all inputs send an object with the parameters to filter
    if (typeof query !== "object") {
      query = { name: query };
    }

    if (query.name?.length < 3) return;
    const res = (await Request.getPerson(query))?.body;
    return parseForSelect(res);
  };

  const onSelect = (selectedItem) => {
    //set the id of the select in the parent component
    setCircleData({ name: selectedItem?.nameTarget, value: selectedItem });
    //save selector data locally
    setCurrentSelects({
      ...currentSelects,
      [selectedItem.nameTarget]: selectedItem,
    });
  };

  const internal_members_array = circle?.members?.filter(
    (member) => member.origin === "internal"
  );
  const internal_members = internal_members_array?.map((member, index) => (
    <React.Fragment key={index}>
      <div className="form-toggle-mapped">
        <div className="form-toggle">
          <div className="member-content">
            <IconSelect
              className="white-background reverse"
              id={"member-" + index}
              name={"member-" + index}
              value={member}
              options={circle?.members}
              icon={member?.show ? <Visibility /> : <VisibilityOff />}
              loadOptions={promiseOptions}
              onInputChange={(value) => promiseOptions(value)}
              onClickIcon={() => ShowOrHideMember(member)}
              onChange={(e) => {
                editMember(e, member);
              }}
              isError={
                viewError &&
                errors?.members?.length > 0 &&
                errors?.members[index]?.status
              }
              msjError={
                errors?.members?.length > 0 && errors?.members[index]?.message
              }
            />
          </div>
          <DeleteButton
            enabled={canAddOrRemoveMembers}
            className="form-toggle-button"
            onClick={() => {
              setShowDeleteConfirm(true);
              onSelect(member);
            }}
          />
        </div>
        {member.show && (
          <div className="show-data">
            <div className="form-person-data small">{member?.email[0]}</div>
            <div className="form-person-data small">{member?.contactPhone}</div>
          </div>
        )}
      </div>
    </React.Fragment>
  ));
  const external_members_array = circle?.members?.filter(
    (member) => member?.origin === "external"
  );
  const external_members = external_members_array?.map((member, index) => (
    <React.Fragment key={index}>
      <div className="form-toggle-mapped">
        <div className="form-toggle">
          <div className="member-content">
            <IconSelect
              className="white-background reverse"
              id={"member-" + index}
              name={"member-" + index}
              value={member}
              options={circle?.members}
              icon={member?.show ? <Visibility /> : <VisibilityOff />}
              loadOptions={promiseOptions}
              onInputChange={(value) => promiseOptions(value)}
              onClickIcon={() => ShowOrHideMember(member)}
              onChange={(e) => editMember(e, member)}
              isError={
                viewError &&
                errors?.members?.length > 0 &&
                errors?.members[index]?.status
              }
              msjError={
                errors?.members?.length > 0 && errors?.members[index]?.message
              }
            />
          </div>
          <DeleteButton
            enabled={canAddOrRemoveMembers}
            className="form-toggle-button"
            onClick={() => {
              setShowDeleteConfirm(true);
              onSelect(member);
            }}
          />
        </div>
        {member?.show && (
          <div className="show-data">
            <div className="form-person-data small">{member?.email[0]}</div>
            <div className="form-person-data small">{member?.contactPhone}</div>
          </div>
        )}
      </div>
    </React.Fragment>
  ));

  return (
    <form className="form">
      <div className="form-title">
        <div>
          <HolosInput
            className="bold title-input"
            placeholder="Círculo de calidad"
            value={circle?.name.toUpperCase()}
            id="name"
            name="name"
            onChange={setCircleData}
            disabled
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="validator" className="form-label small bold">
            Responsable de Area *
          </label>
          <IconSelect
            className="white-background reverse"
            placeholder="Busque Responsable de Área"
            id="validator"
            name="validator"
            value={circle?.validator}
            icon={showValidator ? <Visibility /> : <VisibilityOff />}
            loadOptions={promiseOptions}
            onInputChange={(value) => promiseOptions(value)}
            onClickIcon={() => setShowValidator(!showValidator)}
            onChange={(e) => onSelect(e)}
            isError={viewError && errors?.validator?.status}
            msjError={errors?.validator?.message}
          />
          {showValidator && (
            <div className="show-data">
              <div className="form-person-data mb-small small">
                {currentSelects?.validator?.email &&
                  currentSelects?.validator?.email[0]}
              </div>
              <div className="form-person-data mb-small small">
                {currentSelects?.validator?.contactPhone}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="bettermentLead" className="form-label small bold">
            Facilitador *
          </label>
          <IconSelect
            className="white-background reverse"
            placeholder="Busque Facilitador"
            id="bettermentLead"
            name="bettermentLead"
            value={circle?.bettermentLead}
            icon={showBettermentLead ? <Visibility /> : <VisibilityOff />}
            loadOptions={promiseOptions}
            onInputChange={(value) => promiseOptions(value)}
            onChange={(e) => onSelect(e)}
            onClickIcon={() => setShowBettermentLead(!showBettermentLead)}
            isError={viewError && errors?.bettermentLead?.status}
            msjError={errors?.bettermentLead?.message}
          />
          {showBettermentLead && (
            <div className="show-data">
              <div className="form-person-data mb-small small">
                {currentSelects?.bettermentLead?.email &&
                  currentSelects?.bettermentLead?.email[0]}
              </div>
              <div className="form-person-data mb-small small">
                {currentSelects?.bettermentLead?.contactPhone}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="leader" className="form-label small bold">
            Lider del Circulo *
          </label>
          <IconSelect
            className="white-background reverse"
            placeholder="Busque Lider del Circulo"
            id="leader"
            name="leader"
            value={circle?.leader}
            icon={showLeader ? <Visibility /> : <VisibilityOff />}
            loadOptions={promiseOptions}
            onInputChange={(value) => promiseOptions(value)}
            onClickIcon={() => setShowLeader(!showLeader)}
            onChange={(e) => onSelect(e)}
            isError={viewError && errors?.leader?.status}
            msjError={errors?.leader?.message}
          />
          {showLeader && (
            <div className="show-data">
              <div className="form-person-data mb-small small">
                {currentSelects?.leader?.email &&
                  currentSelects?.leader?.email[0]}
              </div>
              <div className="form-person-data mb-small small">
                {currentSelects?.leader?.contactPhone}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="supervisor" className="form-label small bold">
            Supervisor *
          </label>
          <IconSelect
            className="white-background reverse"
            placeholder="Busque Supervisor"
            id="supervisor"
            name="supervisor"
            value={circle?.supervisor}
            icon={showSupervisor ? <Visibility /> : <VisibilityOff />}
            loadOptions={promiseOptions}
            onInputChange={(value) => promiseOptions(value)}
            onClickIcon={() => setShowSupervisor(!showSupervisor)}
            onChange={(e) => onSelect(e)}
            isError={viewError && errors?.supervisor?.status}
            msjError={errors?.supervisor?.message}
          />

          {showSupervisor && (
            <div className="show-data">
              <div className="form-person-data mb-small small">
                {currentSelects?.supervisor?.email &&
                  currentSelects?.supervisor?.email[0]}
              </div>
              <div className="form-person-data mb-small small">
                {currentSelects?.supervisor?.contactPhone}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <div className="form-control form-double-label">
          <label className="form-label medium bold">Equipo de trabajo *</label>
          <label className="form-label small lessBold">
            1 a 6 integrantes (minimo 2)
          </label>
        </div>
      </div>
      {internal_members?.length > 0 && (
        <div className="form-group">
          <div className="form-control">
            <label htmlFor="member" className="small bold">
              Internos
            </label>
            {internal_members}
          </div>
        </div>
      )}
      {external_members?.length > 0 && (
        <div className="form-group">
          <div className="form-control">
            <label htmlFor="member2" className="small bold">
              Externos
            </label>
            {external_members}
          </div>
        </div>
      )}{" "}
      <div className="form-group">
        <IconSelect
          placeholder="Agregar integrante"
          options={tabs}
          className="purple-select"
          onChange={(e) => {
            setCurrentMemberAlert(e.value);
          }}
          controlShouldRenderValue={false}
          msjError={errors?.members?.message}
          isError={viewError && errors?.members?.status}
        />
      </div>
      <AlertToAddInternal
        promiseOptions={promiseOptions}
        onSelect={onSelect}
        setShowAddInternalAlert={() => {
          changeMemberAlert("internal");
          setCurrentMemberAlert("");
        }}
        showAddInternalAlert={memberAlerts["internal"]}
        addMember={addMember}
      />
      <AlertToAddExternal
        promiseOptions={promiseOptions}
        onSelect={onSelect}
        genders={genders}
        setShowAddExternalAlert={() => {
          changeMemberAlert("external");
          setCurrentMemberAlert("");
        }}
        showAddExternalAlert={memberAlerts["external"]}
        addMember={addMember}
      />
      <AlertDeletePerson
        showDeleteConfirm={showDeleteConfirm}
        setShowDeleteConfirm={setShowDeleteConfirm}
        currentSelects={currentSelects}
        showDeleteInfo={showDeleteInfo}
        setShowDeleteInfo={setShowDeleteInfo}
        removeMember={removeMember}
      />
    </form>
  );
};

export default TeamForm;
