import superagent from "superagent";
import interceptor from "superagent-intercept";

let authIntercept = interceptor((_, res) => {
  switch (res.status) {
    case 401:
      localStorage.removeItem("isLogged");
      window.location.replace("/users/login");
      break;
    case 500:
      return (res.body = { internalCode: "GNRL1001" });
    default:
      break;
  }
});

const handleResponse = (response) => {
  return { status: response.status, text: response.text, body: response.body };
};
class RequestManager {
  constructor() {
    this._agent = superagent.agent();
  }

  async get(url) {
    try {
      let res = await this._agent.get(url).use(authIntercept);
      return handleResponse(res);
    } catch (error) {
      return {
        status: error.status,
        msg: error.response && error.response.text,
        ...error.response.body,
      };
    }
  }

  async getBlob(url) {
    try {
      let res = await this._agent
        .get(url)
        .use(authIntercept)
        .responseType("blob");
      return handleResponse(res);
    } catch (error) {
      throw new Error(error);
    }
  }

  async post(url, body) {
    try {
      const res = await this._agent.post(url).send(body).use(authIntercept);
      return handleResponse(res);
    } catch (error) {
      return error.response?.body;
    }
  }

  async put(url, body) {
    try {
      const res = await this._agent.put(url).send(body).use(authIntercept);
      return handleResponse(res);
    } catch (error) {
      return {
        status: error.status,
        ...error.response.body,
      };
    }
  }
}

const instance = new RequestManager();
Object.freeze(instance);

export default instance;
