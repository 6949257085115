import React from "react";
import HolosInput from "../../../../../../../../shared/components/holosInput";

const EmailForm = ({
  isError,
  email,
  emailError,
  setEmailError,
  setPersonData,
  setIsEmailValid,
  onSubmit,
}) => {
  return (
    <form
      className="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e);
      }}
    >
      <div className="form-group">
        <label htmlFor="email" className="form-group small">
          Email
        </label>
        <HolosInput
          className="white-backgrzund"
          name="email"
          id="email"
          email={email}
          width="100%"
          height="34px"
          onChange={(e) => {
            setEmailError(null);
            setPersonData(e);
            setIsEmailValid(e.target.value);
          }}
          value={email}
          isError={isError}
          msjError={emailError || "Formato de email incorrecto"}
        />
      </div>
    </form>
  );
};

export default EmailForm;
