import { getPlatforms } from "@ionic/react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { getTranslate } from "../i18n/translate";

export const parseForSelect = (array) => {
  let parse =
    Array.isArray(array) &&
    array.map((arr) => ({
      ...arr,
      label: getTranslate(arr?.name) || arr?.name,
      value: arr?._id || getTranslate(arr?.name) || arr?.name,
    }));
  if (!parse) return [];
  return parse;
};

export const getCenterPolygon = (areaCoords) => {
  const bounds = new window.google.maps.LatLngBounds();

  let polygonCoords = [];

  areaCoords &&
    areaCoords.forEach((coords) => {
      polygonCoords.push(new window.google.maps.LatLng(coords.lat, coords.lng));
    });

  for (let index = 0; index < polygonCoords.length; index++) {
    const coords = polygonCoords[index];
    bounds.extend(coords);
  }

  const center = bounds.getCenter();
  const centerLatLng = { lat: center.lat(), lng: center.lng() };
  return centerLatLng;
};

/**
 * Get the previous value
 * @param {object} value hook variable
 * @return { {state:object}} return the previous state of the hook variableasyn
 * @example const prevHooks = usePrevious({ items, count ,pageNumber });
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Handle form validation
 * @param {object} data current form data
 * @param {object} valdations form field validations
 * @return { {object} } errors result
 */
export const useValidation = (data, validations) => {
  const [errors, setErrors] = useState({});
  const previousData = usePrevious(data);

  const validate = useCallback(async () => {
    const currentErrors = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(validations, key)) {
        const namesPromise = ["AsyncFunction", "PromiseFunction"];
        const validation = validations[key];
        if (
          namesPromise.includes(validation.constructor?.name) &&
          previousData &&
          previousData[key] === data[key]
        ) {
          currentErrors[key] = errors[key];
          continue;
        }
        const isValid = await validation(data[key]);
        currentErrors[key] = isValid;
      }
    }
    return currentErrors;
  }, [data, validations, errors, previousData]);

  useEffect(() => {
    if (JSON.stringify(previousData) === JSON.stringify(data)) return;
    (async () => {
      const currentErrors = await validate();
      setErrors((errors) => ({ ...errors, ...currentErrors }));
    })();
  }, [data, previousData, validate]);

  return errors;
};

export const existError = (objectError) => {
  //convert object to array
  const arrayErrors = Object.values(objectError);
  //search error=true
  const detectError = (array) => {
    return array.some((e) => {
      if (Array.isArray(e)) return detectError(e);
      if (typeof e === "object") return e?.status;
      return e;
    });
  };
  //object attributes repeats the process
  let error = detectError(arrayErrors);
  if (typeof error === "object") error = existError(error);
  return error;
};

/**
 * Remove accents from a phrase
 * @param {string} text text with accents.
 * @return {{ text:string }} without accents.
 */
export const removeAccents = (text) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

/**
 * Determines if a device can access the component
 * @return { boolean }.
 */
export const isDesktop = () => {
  const platforms = getPlatforms();

  if (platforms.includes("android") && !platforms.includes("tablet"))
    return false;
  if (platforms.includes("iphone")) return false;

  return true;
};

/**
 * Determines if a plaque is inside a area
 * @param { Array } area the area where the coordinates of the point are verified
 * @param { Array } plaques the plaques that have to be within the area
 * @return { boolean } area to verif.
 */
export const isInsideArea = (area, plaques) => {
  let valid = true;
  plaques.forEach((plaque) => {
    let inside = false;
    const x = plaque.latitude;
    const y = plaque.longitude;
    for (let i = 0, j = area.length - 1; i < area.length; j = i++) {
      const xi = area[i]["lat"];
      const yi = area[i]["lng"];
      const xj = area[j]["lat"];
      const yj = area[j]["lng"];

      const intersect =
        // eslint-disable-next-line
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    if (!inside) return (valid = false);
  });
  return valid;
};

/**
 * Hook of setInterval or setTimeout, but with variable delay.
 * @param { function } callback its inside is going to run
 * @param { number } delay delay time
 */
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

/**
 * Validation email
 * @param { string } email email that you want to validate
 * @return { boolean } email to verif.
 */
export const validEmail = (email) => {
  if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[A-Za-z]/.test(email)) return true;

  return false;
};

export const encodedEmail = (email) => {
  const encodingEmail = btoa(email);
  return encodingEmail;
};

export const encodedRUT = (rut) => {
  const encodingRut = btoa(rut);
  return encodingRut;
};

export const decodedEmail = (email) => {
  const decodingEmail = atob(email);
  return decodingEmail;
};

export const validRUT = (rut, type) => {
  if (!rut?.length) return false;
  rut = rut.toString();
  rut = rut.replace(".", "");
  rut = rut.replace(",", "");
  rut = rut.trim();

  if (rut.length <= 8 || rut.length >= 11) return false;
  if (!/^[0-9]+(-[kK0-9])$/.test(rut)) return false;

  rut = rut.split("-");
  let number = rut[0].split("").reverse();

  if (type === "contractor" && rut[0] < 30000000) return false;
  if (type === "person" && rut[0] > 30000000) return false;
  const checkDigit = rut[1];
  const multiplier = generateMultiplier(number).split("");
  let sum = 0;
  for (let index = 0; index < number.length; index++) {
    sum += number[index] * multiplier[index];
  }
  const mod = parseInt(sum / 11) * 11;
  let sub = 11 - Math.abs(sum - mod);
  if (sub === 11) sub = 0;
  if (sub === 10) sub = "k";
  if (String(sub) === checkDigit.toLowerCase()) return true;
  else return false;
};

const generateMultiplier = (number) => {
  const serie = "234567".split("");
  let multiplier = "";
  let serieIndex = 0;
  for (let index = 0; index < number.length; index++) {
    if (index >= serie.length && serieIndex >= serie.length) serieIndex = 0;
    multiplier += serie[serieIndex];
    serieIndex++;
  }
  return multiplier;
};

export const isEmptyString = (string) => {
  return !string?.length;
};

export const isEmptyDate = (string) => {
  return isEmptyString(string?.toISOString());
};

export const validateInputText = (string) => {
  const regex = new RegExp("^[a-zA-ZÑñ]+$", "ig");
  return regex.test(string);
};

export const isEmptyElementInObject = (object) => {
  for (const key in object) {
    if (
      object[key] !== null ||
      object[key] !== "" ||
      object.hasOwnProperty(`${key}`)
    )
      return false;
  }
};

export const objectContainsFields = (object, fields) => {
  let hasField = true;
  fields.forEach((field) => {
    if (
      !object ||
      !object[field] ||
      object[field] === "" ||
      !object.hasOwnProperty(field)
    ) {
      hasField = false;
    }
  });
  return hasField;
};

export const redirect = (path) => window.location.assign(path);

//This function parse object to url format
export const parseObjectToUrl = (object) => {
  if (!object) return;
  const url = Object.entries(object)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  return url;
};

export const parseSpaceByDash = (text) => {
  return text?.replace(/ /g, "-");
};

/**
 * Comparison of dates
 * @param { object } firstDate
 * @param { object } secondDate
 * @return { boolean } firstDate compared to secondDate .
 */
export const compareDate = (
  firstDate,
  secondDate,
  condition,
  comparision = "day"
) => {
  if (condition === "isbefore")
    return moment(firstDate).isBefore(secondDate, comparision);
  if (condition === "issameorbefore")
    return moment(firstDate).isSameOrBefore(secondDate, comparision);
  if (condition === "isafter")
    return moment(firstDate).isAfter(secondDate, comparision);
  if (condition === "issameorafter")
    return moment(firstDate).isSameOrAfter(secondDate, comparision);
  if (condition === "issame")
    return moment(firstDate).isSame(secondDate, comparision);
};

export const generateFormData = (data) => {
  const formdata = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      if (Array.isArray(element)) {
        element.forEach((e) => {
          formdata.append(key, e);
        });
        continue;
      }
      formdata.set(key, element);
    }
  }
  return formdata;
};

export const useQuery = ()=> {
  return new URLSearchParams(useLocation().search);
};

export const capitalizeFirstLetter = (str)=>{
  return str[0].toUpperCase() + str.substring(1)
}

export const handleSortHelper = (param, query, setQuery, orderField) => {
  let p = "order"

  if (param !== orderField)
    p = ("asc" in query)? "dsc": "asc";

  setQuery((query) => {
    if (query[p] === param) {
      delete query[p];
      delete query["asc"]
      delete query["dsc"]
      return { ...query };
    }
    
    if (p === "asc")
      delete query['dsc'];

    if (p === "dsc")
      delete query['asc'];

    return { ...query, [p]: param };
  });
};
