import React, { useState } from "react";
import FormButtonStepper from "../../../../../../shared/components/formButtonStepper";
import HolosButton from "../../../../../../shared/components/holosButton";
import CircleChallengeForm from "./parts/challengeForm";

const CircleChallenges = (props) => {
  const [canContinue, setCanContinue] = useState(false);
  const [viewError, setViewError] = useState(false);
  const { setView, mode, discardEdit } = props;

  const onSubmit = () => {
    setViewError(true);
    if (canContinue) {
      if (isContinueButton()) return setView("KickOff");
      return props?.createCircle();
    }
  };

  const isContinueButton = () => {
    if (mode === "edit") return false;
    return true;
  };

  return (
    <div className="new-challenges">
      <CircleChallengeForm
        viewError={viewError}
        setCanContinue={setCanContinue}
        {...props}
      />
      <FormButtonStepper
        onSubmit={onSubmit}
        nameButton={isContinueButton() ? "Continuar" : "Guardar"}
        showGoBack={isContinueButton()}
        goBackView={"CircleTeam"}
        setView={setView}
        className="buttons-flex-end"
        otherButton={
          mode === "edit" && (
            <HolosButton
              text={{ name: "Descartar", className: "small" }}
              //This is only to test error alert and then clean it
              onClick={discardEdit}
              className={"transparent-background beside-next"}
            />
          )
        }
      />
    </div>
  );
};
export default CircleChallenges;
