import {
  Clear,
  ExpandLess,
  ExpandMore,
  NotificationsNone,
  PlaceOutlined,
  TurnedInNot,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import "../newPlaque.scss";
import Select from "react-select";
import IconText from "../../../../../shared/components/iconText";
import HolosButton from "../../../../../shared/components/holosButton";
import HolosInput from "../../../../../shared/components/holosInput";
import { IonToggle } from "@ionic/react";
import mapExample from "../../../../../assets/img/mapExample.png";
import { getCenterPolygon, isInsideArea } from "../../../../../utils/helpers";
import { MapModal } from "../../../../maps/shared/mapModal";
import GoogleMap from "../../../../../shared/components/googleMap";
import DragAndDrop from "../../../../../shared/components/dragAndDrop";
import { Link } from "react-router-dom";
import qr from "./../../../../../shared/components/googleMap/icons/QR.png";
import qrPurple from "./../../../../../shared/components/googleMap/icons/currentQR.png";
import moment from "moment";
import HolosAlert from "../../../../../shared/components/holosAlert";

const PlaqueLocation = (props) => {
  //states alert
  const [open, setLocation] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    //var
    latitude,
    longitude,
    inMachine,
    reference,
    files,
    machineTag,
    maps,
    map,
    isError,
    currentPlaque,
    //method
    setReference,
    setInMachine,
    setLongitude,
    setLatitud,
    handleDrop,
    deleteFile,
    setTagMachine,
    setMap,
  } = props;

  const [currentLatitude, setCurrentLatitude] = useState(latitude);
  const [currentLongitude, setCurrentLongitude] = useState(longitude);
  const [ showAlert, setShowAlert ] = useState(false);

  const collectionRef = [
    { label: "Planta baja", value: "Planta baja", id: 1 },
    { label: "1er Piso", value: "1er Piso", id: 2 },
    { label: "2o Piso", value: "2o Piso", id: 3 },
    { label: "3er Piso", value: "3er Piso", id: 4 },
    { label: "4o Piso", value: "4o Piso", id: 5 },
    { label: "5o Piso", value: "5o Piso", id: 6 },
  ];

  const isMapSelected = () => {
    if (map && map.areaCoords) return true;
    return false;
  };

  useEffect(() => {
    setCurrentLatitude(latitude);
  }, [latitude]);

  useEffect(() => {
    setCurrentLongitude(longitude);
  }, [longitude]);

  const generateMarkers = () => {
    const markers = map?.plaques?.map((plaque) => {
      return {
        editable: plaque._id === currentPlaque?._id,
        type: "plaque",
        id: plaque._id,
        name: plaque.name,
        images: plaque.images,
        location: map.name,
        createdAt: moment.utc(plaque.createdAt).format("DD-MM-YYYY"),
        circleName: plaque.circleName,
        coords: {
          lat:
            plaque._id === currentPlaque?._id
              ? parseFloat(latitude)
              : parseFloat(plaque.latitude),
          lng:
            plaque._id === currentPlaque?._id
              ? parseFloat(longitude)
              : parseFloat(plaque.longitude),
        },
      };
    });

    if (
      currentPlaque &&
      !markers.some((marker) => marker.id === currentPlaque._id)
    ) {
      markers.push({
        editable: true,
        type: "plaque",
        id: currentPlaque._id,
        name: currentPlaque.name,
        images: currentPlaque.images,
        location: map.name,
        createdAt: moment.utc(currentPlaque.createdAt).format("DD-MM-YYYY"),
        circleName: currentPlaque.circleName,
        coords: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        },
      });
    }

    if (!currentPlaque) {
      markers.push({
        editable: true,
        type: "plaque",
        name: `Nueva placa QR`,
        location: map.name,
        coords: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        },
        id: `new-${new Date().toLocaleString()}`,
      });
    }

    return markers;
  };

  const callback = (param) => {
    setLatitud(param?.lat || "");
    setLongitude(param?.lng || "");
  };

  const validateCoords = () => {
    if (
      !currentLongitude?.toString().length ||
      !currentLatitude?.toString().length ||
      !map
    )
      return;
    let coords = {
      longitude: parseFloat(currentLongitude),
      latitude: parseFloat(currentLatitude),
    };

    if (isInsideArea(map.areaCoords, [coords])) {
      setLatitud(currentLatitude);
      setLongitude(currentLongitude);
    } else {
      setShowAlert(true)
      setCurrentLatitude(latitude);
      setCurrentLongitude(longitude);
    }
  };

  return (
    <div className="PlaqueLocation">
      <div className="title" onClick={() => setLocation(!open)}>
        <IconText
          icon={<PlaceOutlined />}
          iconColor={"var(--black)"}
          iconOpacity={0.54}
          text={"Ubicación de la placa QR"}
          fontColor={"var(--softBlack)"}
          fontSize={"14px"}
          fontWeight={"500"}
          separatingMargin={"0.813em"}
        />
        {(!open && (
          <ExpandMore style={{ color: "#444444", opacity: 0.54 }} />
        )) || <ExpandLess style={{ color: "#444444", opacity: 0.54 }} />}
      </div>
      {open && (
        <div className="body">
          <div className="row containerMap">
            <div
              className={`img ${!map && "empty"}`}
              onClick={() => {
                isMapSelected() && setShowModal(true);
              }}
              style={{
                backgroundImage: `url(${
                  (map && encodeURI(map.staticImageUrl)) || mapExample
                })`,
              }}
            ></div>
            {isMapSelected() && (
              <MapModal
                showModal={showModal}
                setShowModal={setShowModal}
                mode={currentPlaque ? "editMarkerPosition" : "addMarker"} // location can not be modified
                callback={callback}
                area={map?.areaCoords}
                markers={generateMarkers()}
                currentMarker={currentPlaque?._id || `new-${new Date().toLocaleString()}`}
                markerIconURL={qr}
                currentMarkerIconURL={qrPurple}
                center={getCenterPolygon(map.areaCoords)}
                title={
                  <div className="mapModalTitle">
                    <IconText
                      icon={<GoogleMap />}
                      text={"Elegir ubicación"}
                      iconOpacity={0.54}
                    />
                    <Clear style={{ opacity: 0.54 }} />
                  </div>
                }
              />
            )}
            <div
              className={`mapSelector ${
                isError && !map?.length ? "invalid" : ""
              }`}
            >
              <Select
                value={map}
                placeholder={"Seleccionar un mapa"}
                onChange={(e) => setMap(e)}
                options={maps}
              />
              <p>
                ¿No existe el mapa? Puedes crearlo haciendo click en el
                siguiente botón
              </p>
              <Link to="/maps/new">
                <HolosButton
                  width={"148px"}
                  background={{
                    color: "var(--purpleBHP)",
                  }}
                  text={{
                    name: "Nuevo mapa",
                    color: "var(--softWhite)",
                    weight: "500",
                    size: "18px",
                  }}
                  onClick={() => setShowModal(false)}
                />
              </Link>
            </div>
          </div>
          <div className="row containerCoord">
            <IconText
              icon={<NotificationsNone />}
              iconColor={"var(--black)"}
              iconOpacity={0.54}
              text={
                "¿No encuentras la ubicación? Si conoces la latitud y longitud exacta cárgarlas a continuación."
              }
              fontColor={"var(--softBlack)"}
              fontSize={"14px"}
              fontWeight={"500"}
              separatingMargin={"0.813em"}
            />
            <div className="coord">
              <p>Coordenadas:</p>
              <HolosInput
                placeholder="latitude"
                name={"latitude"}
                onBlur={() => validateCoords()}
                onChange={(e) => setCurrentLatitude(e.target.value)}
                width={"4.6em"}
                value={currentLatitude}
                // isError={isError}
                disabled={map ? false : true}
              />
              <span>,</span>
              <HolosInput
                placeholder="longitude"
                name={"longitude"}
                onBlur={() => validateCoords()}
                onChange={(e) => setCurrentLongitude(e.target.value)}
                width={"4.6em"}
                value={currentLongitude}
                // isError={isError}
                disabled={map ? false : true}
              />
            </div>
          </div>
          <div className="row containerReference">
            <IconText
              icon={<TurnedInNot />}
              iconColor={"var(--black)"}
              iconOpacity={0.54}
              text={"Referencias"}
              fontColor={"var(--softBlack)"}
              fontSize={"14px"}
              fontWeight={"500"}
              separatingMargin={"0.813em"}
            />
            <Select
              options={collectionRef}
              onChange={(e) => setReference(e)}
              value={reference}
              placeholder={"Seleccionar una planta"}
            />
            <div className="toogle">
              <IonToggle
                checked={inMachine}
                onIonChange={(e) => {
                  setInMachine(e.detail.checked);
                  setTagMachine("");
                }}
              />
              <p>¿Estará pegado en una máquina?</p>
            </div>

            <div className="inMachine">
              {inMachine && (
                <React.Fragment>
                  <div className="title">Tag de la máquina:</div>
                  <div className="tag">
                    <HolosInput
                      placeholder="#5F98XV3"
                      onChange={(e) => setTagMachine(e.target.value)}
                      value={machineTag}
                      isError={isError}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>

          <div className="row containerPhotos">
            {/* mock upload photo jpg */}
            <img alt=""></img>
            <DragAndDrop
              handleDrop={handleDrop}
              deleteFile={deleteFile}
              files={files}
              text={"imágenes aquí"}
              title={"Imágenes"}
              types={["image/png", "image/jpeg"]}
            />
            <IconText
              icon={<NotificationsNone />}
              iconColor={"var(--black)"}
              iconOpacity={0.54}
              text={
                "Máximo 3 imágenes.  Seleccionar imágenes relevantes, de alta definición, incluyendo el entorno inmediato del desafío y puntos de referencia si los hubiera."
              }
              fontColor={"var(--softBlack)"}
              fontSize={"14px"}
              fontWeight={"500"}
              separatingMargin={"0.813em"}
            />
          </div>
        </div>
      )}
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={(isOpen, typeShow) => {
          setShowAlert(isOpen);
        }}
        title={"Coordenadas Inválidas"}
        content={"Las coordenadas ingresadas son inválidas. Por favor intente nuevamente"}
        type={"error"}
      />
    </div>
  );
};

export default PlaqueLocation;
