import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// const googleMapScript = document.createElement("script");
// googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAzb36tJNp7S7gBy37C0V6_2rbpBEH0lkE&libraries=places`;
// googleMapScript.async = true;
// window.document.body.appendChild(googleMapScript);
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
