import React from "react";
import IconText from "../iconText";
import "./infoCard.scss";

const InfoCard = (props) => {
  const { arrayFields, fontSize, fontWeight } = props;
  return (
    <div className="Info-card">
      {arrayFields.map(
        (field, i) =>
          field.value && (
            <div key={i} className="row">
              <IconText
                icon={field.icon}
                text={field.label}
                iconColor={field.iconColor}
                iconOpacity={0.54}
                fontSize={fontSize}
                fontWeight={fontWeight}
                fontColor={"var(--softBlack)"}
                separatingMargin={"1.375em"}
              />
              {field.isRefer ? (
                <a
                  className="aws-path"
                  href={field.value}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span>{field.value}</span>
                </a>
              ) : (
                <span>{field.value}</span>
              )}
            </div>
          )
      )}
    </div>
  );
};

export default InfoCard;
