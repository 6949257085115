import React, { useEffect, useState } from "react";
import ButtonTab from "../../../../shared/components/buttonTab";
import Event from "./parts/event";
import { EventAvailable } from "@material-ui/icons";
import { useInterval } from "../../../../utils/helpers";
import "./styles.scss";
// import { events } from "./parts/mock";

const EventCalendar = ({ events }) => {
  const [event, setEvent] = useState({});
  const [indexEvent, setIndexEvent] = useState(0);
  const [timeLoop, setTimeLoop] = useState(5000);
  const [resume, setResume] = useState(true);

  useEffect(() => {
    setEvent(events && events[0]);
  }, [events]);

  useEffect(() => {
    setResume(false);
    if (events?.length === indexEvent) setIndexEvent(0);
    setResume(true);
  }, [indexEvent,events]);

  useInterval(
    () => {
      if (timeLoop === 10000) {
        setTimeLoop(5000);
        setIndexEvent(indexEvent);
      } else setIndexEvent(indexEvent + 1);
      setEvent(events && events[indexEvent]);
    },
    resume ? timeLoop : null
  );

  const onChangeEvent = (index) => {
    setResume(false);
    setTimeLoop(10000);
    setResume(true);
    setEvent(events[index]);
  };

  const tabs = [
    {
      className: "transparent-background purple-select",
      text: {
        name: events && events[0]?.name,
        className: "medium lessBold",
        icon: <EventAvailable />,
      },
      onClick: () => onChangeEvent(0),
      active: events && events[0]?.name === event?.name ? true : false,
      urlRedirect: "#"
    },
    {
      className: "transparent-background purple-select",
      text: {
        name: events && events[1]?.name,
        className: "medium lessBold",
        icon: <EventAvailable />,
      },
      onClick: () => onChangeEvent(1),
      active: events && events[1]?.name === event?.name ? true : false,
      urlRedirect: "#"
    },
    {
      className: "transparent-background purple-select",
      text: {
        name: events && events[2]?.name,
        className: "medium lessBold",
        icon: <EventAvailable />,
      },
      onClick: () => onChangeEvent(2),
      active: events && events[2]?.name === event?.name ? true : false,
      urlRedirect: "#"
    },
  ]; 

  return (
    <div className="Event-calendar">
      {events && (
        <React.Fragment>
          <div className="event-tab">
            <ButtonTab tabs={tabs} />
          </div>
          <Event event={event} />
        </React.Fragment>
      )}
    </div>
  );
};

export default EventCalendar;
