import React, { useContext, useState } from "react";

import SmallBaseBody from "../../../../shared/components/smallBaseBody";
import { useHistory } from "react-router-dom";
import RestorePasswordForm from "./parts/restorePasswordForm";

import { encodedEmail, validEmail } from "../../../../utils/helpers";
import "./styles.scss";
import UsersButtons from "../../shared/usersButtons";
import Support from "../../../../shared/components/support";
import Request from "../../services/request";
import { Loading } from "../../../../context";
import HolosAlert from "../../../../shared/components/holosAlert";
import { getErrorMessage } from "../../../../services/errors";

const RestorePassword = () => {
  //states alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", message: "" });
  const [isOpen, setIsOpen] = useState(false);

  const [, setLoading] = useContext(Loading);

  const [disabledButton, setDisabledButtonn] = useState(false);
  const history = useHistory();
  const [email, setEmail] = useState(undefined);

  const onChange = (value) => {
    setEmail(value);
    setDisabledButtonn(!validEmail(value));
  };

  const onDisabled = () => {
    if (!email) return true;
    if (disabledButton) return true;
    return false;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(false);
    const res = await Request.restoreEmail({
      email,
      idOperation: "mock",
    });
    setLoading(false);
    if (res.status !== 200) {
      setAlertContent({
        type: "error",
        title: "Error",
        message: getErrorMessage("GNRL1001"),
      });
      setShowAlert(true);
      return;
    }
    history.push(`/users/restorePassEmail/${encodedEmail(email)}`);
  };
  return (
    <div className="Restore-password">
      <SmallBaseBody
        className={"restore-password-container"}
        content={
          <React.Fragment>
            <p className="bold restore-password-title great">
              Recuperar contraseña
            </p>
            <RestorePasswordForm
              isError={disabledButton}
              email={email}
              onChange={(value) => onChange(value)}
              onSubmit={onSubmit}
            />
          </React.Fragment>
        }
        buttons={
          <UsersButtons
            disabled={onDisabled()}
            buttonName="Recuperar contraseña"
            onSubmit={onSubmit}
            text="Necesitas ayuda?"
            linkText="Contacta a soporte"
            link={
              <Support isOpen={isOpen} setIsOpen={(bool) => setIsOpen(false)} />
            }
            linkOnClick={() => setIsOpen(true)}
            type="submit"
          />
        }
      />
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        title={alertContent.title}
        content={alertContent.message}
        type={alertContent.type}
      />
    </div>
  );
};

export default RestorePassword;
