import React, { useState } from "react";
import Picture from "../../../../../../../../shared/components/picture";
import "./styles.scss";

const PictureInput = ({ setCircleData, setCanContinue, types,urlPhoto }) => {
  const [objectURL, setObjectURL] = useState("");
  
  const onChange = (e) => {
    let file = e.target.files[0];
    let object = URL.createObjectURL(file);
    setObjectURL(object);

    //set the id of the select in the parent component
    setCircleData({ name: e.target.name, value: file });
  };

  return (
    <>
      <Picture
        isValidated={setCanContinue}
        onChange={onChange}
        url={objectURL || urlPhoto}
        types={types}
      />
    </>
  );
};

export default PictureInput;
