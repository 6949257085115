import React, { useState } from "react";
import "./sideBar.scss";
import ButtonTab from "../buttonTab";
import MenuBrowserMobile from "./parts/menu/menuBrowserMobile";

//mock
import Profile from "./parts/profile";
import LogoOperation from "../logo";
import DropdownButton from "../dropdownModule";

// /**
//  * A component to sidebar or menu for mobile
//  *
//  * @param {object} props - Props passed to the current component
//  * @param {string} props.moduleIcon - Current icon module
//  * @param {string} props.moduleName - Current name module
//  * @param {object[]} props.moduleTabs - Module sections or tabs
//  * @param {string} props.logoName - Name of the application owner (App)
//  * @param {string} props.logoIcon - App logo
//  * @param {string} props.isDesktop - If mobile mode or desktop mode (isDesktop = true) is displayed
//  * @param {boolean} props.hide - If true hide sidebar, If hide = null the show and hide effects are disabled.
//  *
//  */

const SideBar = (props) => {
  const [openMenu, setOpenMenu] = useState(false);

  let {
    moduleIcon,
    moduleName,
    moduleTabs,
    logoName,
    logoIcon,
    isDesktop,
    hide,
  } = props;

  const getClassName = () => {
    let className = "SideBar";
    if (!isDesktop) className = `${className} mobile-browser `;
    if (openMenu) className = `${className} open-menu `;
    if (hide) className = `${className} hide`;
    if (hide === false) className = `${className} background`;

    return className;
  };

  return (
    <div className={getClassName()} id="menu">
      <LogoOperation
        icon={logoIcon}
        name={logoName}
        color={"var(--purpleBHP)"}
      />
      {(isDesktop && (
        <React.Fragment>
          <Profile />
          <div className="module-bar">
            <DropdownButton
              text={{
                name: moduleName,
                icon: moduleIcon,
              }}
            />
          </div>
          <ButtonTab
            tabs={moduleTabs}
            orientation={"vertical"}
            separatingMargin={"0.625em"}
            {...props}
          />{" "}
        </React.Fragment>
      )) || (
        <MenuBrowserMobile
          setOpenMenu={setOpenMenu}
          openMenu={openMenu}
          tabs={moduleTabs}
          {...props}
        />
      )}
    </div>
  );
};

export default SideBar;
