import React, { useState } from "react";
import FormButtonStepper from "../../../../../../shared/components/formButtonStepper";
import HolosButton from "../../../../../../shared/components/holosButton";
import { redirect } from "../../../../../../utils/helpers";
import KickOffForm from "./parts/kickOffForm";

const KickOff = (props) => {
  const { setView, createCircle,mode } = props;
  const [canContinue, setCanContinue] = useState(false);
  const [viewError, setViewError] = useState(false);
  const [download, setDownload] = useState(false);

  const onSubmit = () => {
    setViewError(true);
    if (canContinue) {
      createCircle();
      return;
    }
  };

  const isSaveButton = () => {
    if (mode === "view") return false;
    return true;
  };

  return (
    <div className="Kick-off">
      <KickOffForm
        viewError={viewError}
        setCanContinue={setCanContinue}
        download={download}
        setDownload={setDownload}
        {...props}
      />
      <FormButtonStepper
        onSubmit={() => {
          isSaveButton() ? onSubmit() : redirect("/circles/list");
        }}
        showGoBack
        goBackView={"CircleChallenges"}
        setView={setView}
        className="buttons-flex-end"
        nameButton={isSaveButton() ? "Crear" : "Finalizar"}
        otherButton={
          <HolosButton
            text={{ name: "Descargar", className: "small" }}
            //This is only to test error alert and then clean it
            onClick={() => setDownload(!download)}
            className={"transparent-background beside-next"}
          />
        }
      />
    </div>
  );
};
export default KickOff;
