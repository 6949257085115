import { LooksOne, LooksTwo } from "@material-ui/icons";
import React from "react";
import HolosInput from "../../../../../../shared/components/holosInput";
import IconText from "../../../../../../shared/components/iconText";

const RestorePasswordForm = ({ onChange, isError, email, onSubmit }) => {
  return (
    <form
      className="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e);
      }}
    >
      <div className="form-group">
        <label htmlFor="email" className="form-group small">
          Mail
        </label>
        <HolosInput
          className="white-backgrzund"
          name="email"
          id="email"
          email={email}
          width="100%"
          height="34px"
          onChange={(e) => onChange(e.target.value)}
          isError={isError}
          msjError={"Formato de email incorrecto"}
        />
      </div>
      <div className="guide-parent">
        <IconText
          icon={<LooksOne fontSize={16} />}
          fontSize={"14px"}
          iconColor={"var(--softBlack)"}
          text={"Recibiras un mail con un link"}
          className="guide-children"
          separatingMargin="0.5em"
        />
        <IconText
          icon={<LooksTwo fontSize={16} />}
          fontSize={"14px"}
          iconColor={"var(--softBlack)"}
          text={"Deberas hacer click en el link para confirmar tu identidad."}
          className="guide-children"
          separatingMargin="0.5em"
        />
      </div>
    </form>
  );
};

export default RestorePasswordForm;
