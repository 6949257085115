import { Input } from "@material-ui/icons";
import React, { useState } from "react";
import HolosAlert from "../../../holosAlert";
import HolosButton from "../../../holosButton";
import DropdownItem from "./DropdownItem";
import items from "./items";

import Users from "../../../../../services/globalApi/users";

const generateItems = (setShowAlert) => {
  const currentItems = [...items];

  const logout = {
    name: "Cerrar sesión",
    icon: <Input />,
    path: "/landingpage",
    onClick: () => setShowAlert(true),
  };

  currentItems.push(logout);

  return currentItems;
};

const MenuDropdown = ({ showDropdown, setShowDropdown }) => {
  const [showAlert, setShowAlert] = useState(false);
  const currentItems = generateItems(setShowAlert);

  const logout = async () => { 
    await Users.logout();
    localStorage.removeItem("isLogged");
    localStorage.removeItem("person")
  };

  const handleLogout = async () => {
    await logout();
    setShowAlert(false);
    window.location.replace("/")
  };

  return (
    <React.Fragment>
      <div className={`menu-navigation-dropdown ${showDropdown ? "" : "hide"}`}>
        {currentItems.map((item, i) => (
          <DropdownItem
            {...item}
            key={i}
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
          />
        ))}
      </div>
      <HolosAlert
        showAlert={showAlert}
        onClose={(e) => setShowAlert(e)}
        setShowAlert={setShowAlert}
        title={"Salir de Yo Contribuyo"}
        type={"warning"}
        content={
          'Si deseas salir haz click en Cerrar Sesión o en la "x" para continuar trabajando'
        }
        actions={
          <HolosButton
            className="purple-background"
            text={{ name: "Cerrar sesión", className: "white-icon-text" }}
            onClick={handleLogout}
          />
        }
      ></HolosAlert>
    </React.Fragment>
  );
};

export default MenuDropdown;
