import React from "react";
import { SearchOutlined } from "@material-ui/icons";

import "./styles.scss";

const getIcon = icon => {
  if (typeof icon === "string")
    return (
      <div
        className="icon"
        style={{
          WebkitMask: `url(${icon}) no-repeat center`,
          height: "1.5em",
          width: "1.5em",
        }}></div>
    );

  return icon;
};

const HolosSearch = ({ value, icon, onChangeSearch, placeholder }) => {
  return (
    <div className="holos-search">
      <SearchOutlined />
      <input
        type="text"
        name="holos-search"
        value={value}
        onChange={onChangeSearch}
        placeholder={placeholder}
      />
      {getIcon(icon)}
    </div>
  );
};

export default HolosSearch;
