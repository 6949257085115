import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import SmallBaseBody from "../../../../shared/components/smallBaseBody";
import UsersButtons from "../../shared/usersButtons";
import PasswordForm from "../../shared/passwordForm";
import Request from "../../services/request";

import "./styles.scss";
import { Loading } from "../../../../context";
import HolosAlert from "../../../../shared/components/holosAlert";
import Support from "../../../../shared/components/support";
import { getErrorMessage } from "../../../../services/errors";

const ChangePassword = () => {
  //states alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", message: "" });
  const [isOpen, setIsOpen] = useState(false);

  const { token, email } = useParams();
  const history = useHistory();
  const [, setLoading] = useContext(Loading);

  const [password, setPassword] = useState("");
  const [repeat, setRepeat] = useState("");
  const [samePassword, setSamePassword] = useState(true);
  const [validPassword, setValidPassword] = useState(false);

  useEffect(() => {
    if (password === repeat) return setSamePassword(true);
    return setSamePassword(false);
  }, [password, repeat]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const body = {
      email: email,
      idOperation: "mockup",
      password: password,
      repeat: repeat,
      token: token,
    };
    setLoading(true);
    let res = await Request.changeEmail(body);
    setLoading(false);
    if (res.status !== 200) {
      setAlertContent({
        type: "error",
        title: "Error",
        message: getErrorMessage(res.internalCode),
      });
      setShowAlert(true);
      return;
    }
    history.push(`/users/successChangePass`);
  };

  return (
    <div className="Change-password">
      <SmallBaseBody
        className={"change-container"}
        content={
          <React.Fragment>
            <p className="bold change-title great">Cambiar contraseña</p>
            <PasswordForm
              password={password}
              setPassword={(e) => setPassword(e.target.value)}
              repeat={repeat}
              setRepeat={(e) => setRepeat(e.target.value)}
              setValidPassword={setValidPassword}
              samePassword={samePassword}
              onSubmit={onSubmit}
           />
          </React.Fragment>
        }
        buttons={
          <UsersButtons
            disabled={!(samePassword && validPassword)}
            buttonName="Cambiar contraseña"
            onSubmit={onSubmit}
            text="Necesitas ayuda?"
            linkText=" Contactar a soporte"
            link={
              <Support isOpen={isOpen} setIsOpen={(bool) => setIsOpen(bool)} />
            }
            linkOnClick={() => setIsOpen(true)}
            type="submit"
          />
        }
      />
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={(isOpen) => {
          setShowAlert(isOpen);
          history.push(`/users/restorePassword`);
        }}
        title={alertContent.title}
        content={alertContent.message}
        type={alertContent.type}
      />
    </div>
  );
};

export default ChangePassword;
