import React, { useState } from "react";
import FormButtonStepper from "../../../../../../shared/components/formButtonStepper";
import PersonalDataForm from "./parts/personalDataForm";

import "./styles.scss";

const PersonalData = ({ setPersonData, person, setView }) => {
  const [viewError, setViewError] = useState(false)
  const [ canContinue, setCanContinue ] = useState(false)

  const onSubmit = () => {
    setViewError(true)
    if (canContinue) {
      setView("JobData")
      return
    }
  }

  return (
    <div>
      <PersonalDataForm
        setPersonData={setPersonData}
        person={person}
        setCanContinue={setCanContinue}
        viewError={viewError}
      />
      <FormButtonStepper onSubmit={onSubmit} />
    </div>
  );
};
export default PersonalData;
