import {
  ControlCameraOutlined,
  DeleteForever,
  DoneAllOutlined,
  GestureOutlined,
  ZoomIn,
} from "@material-ui/icons";

import React, { useCallback, useEffect, useRef, useState } from "react";

import ReactDOMServer from "react-dom/server";
import HolosButton from "../holosButton";
import HolosInfoWindow from "./parts/HolosInfoWindow";
import locationSearchIcon from "./icons/currentLocation.png";
import "./styles.scss";
import IconText from "../iconText";

let allMarkers = [];
let allInfoWindow = [];
let currentIconURL = "";
let iconURL = "";
let currentPoint = null;

function getBoundsZoomLevel(bounds, mapDim) {
  var WORLD_DIM = { height: 256, width: 256 };
  var ZOOM_MAX = 21;

  function latRad(lat) {
    var sin = Math.sin((lat * Math.PI) / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  var ne = bounds.getNorthEast();
  var sw = bounds.getSouthWest();

  var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  var lngDiff = ne.lng() - sw.lng();
  var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  const newZoom = Math.min(latZoom, lngZoom, ZOOM_MAX);
  return newZoom;
}

const addMarker = (map, location, setCurrentMarker) => {
  const infowindow = new window.google.maps.InfoWindow({
    content: ReactDOMServer.renderToStaticMarkup(
      <HolosInfoWindow marker={{ name: "Nueva placa QR", type: "plaque" }} />
    ),
  });

  const marker = new window.google.maps.Marker({
    position: location,
    draggable: true,
    title: `Nueva placa QR`,
    icon: { url: iconURL },
    map: map,
    id: `new-${new Date().toLocaleString()}`,
  });

  for (let index = 0; index < allMarkers?.length; index++) {
    const m = allMarkers[index];
    if (m.get("id") === currentPoint?.get("id")) {
      m.setMap(null);
      allMarkers.splice(index, 1);
    }
  }

  marker.addListener("click", () => {
    closeAllInfoWindow();
    infowindow.open(map, marker);
    setCurrentMarker && setCurrentMarker(marker.get("id"));
  });

  allInfoWindow.push(infowindow);
  allMarkers.push(marker);
  currentPoint = marker;
  addMarkersToMap(map);
};

const addMarkersToMap = (map) => {
  for (let i = 0; i < allMarkers?.length; i++) {
    const marker = allMarkers[i];
    marker.setMap(map);
  }
};

const closeAllInfoWindow = () => {
  for (let i = 0; i < allInfoWindow?.length; i++) {
    allInfoWindow[i].close();
  }
};

window.google.maps.Polygon.prototype.getBounds = function () {
  var bounds = new window.google.maps.LatLngBounds();
  this.getPath().forEach(function (element, index) {
    bounds.extend(element);
  });

  return bounds;
};

const handleCallback = (callback, mode) => {
  switch (mode) {
    case "addMarker":
    case "editMarkerPosition":
      callback({
        lat: currentPoint?.getPosition().lat(),
        lng: currentPoint?.getPosition().lng(),
      });
      break;
    default:
      break;
  }
};

const getClassName = (startEdit, mode) => {
  if (mode !== "draw") return "";

  if (mode === "draw") {
    switch (startEdit) {
      case true:
        return "";
      case false:
        return "cant-edit";
      default:
        return "cant-edit";
    }
  }
};

const cleanMarkers = () => {
  allMarkers.forEach((marker) => {
    marker.setMap(null);
  });

  allMarkers = [];
};

/**
 * A component to display Google Maps. Depends on parent height
 *
 * @param {object} props Props passed to the current component
 * @param {object} props.options Options used when created map
 * @param {number} props.options.zoom Initial zoomm of the map
 * @param {object} props.options.center Object used to initially center the map
 * @param {number} props.options.center.lat Latituted of the initial center
 * @param {number} props.options.center.lng Longitude of the initial center
 * @param {boolean} props.options.center.disableDefaultUI Show default controls of the map
 * @param {Array<object>} props.markers Markers to show in the map
 * @param {Array<object>} props.area Coordinates to draw a polygon on the map
 * @param {Function} props.callback Function execute when map is unmount
 * @param {string} props.markerIconURL Icon URL of the markers
 * @param {string} props.currentMarkerIconURL Icon URL of the marker clicked on the map
 * @param {Function} props.setCurrentMarker Function to set current marker on the parent's state
 * @param {Object} props.center Coordinate to center the map
 * @param {number} props.center.lat Latituted of the new center
 * @param {number} props.center.lng Longitude of the new center
 * @param {string} props.mode Mode of the map (view, draw, addMarker)
 * @param {Function} props.setIsEditing Function to set isEditing on parent component
 */
const GoogleMap = ({
  options,
  markers,
  area,
  callback,
  markerIconURL,
  currentMarkerIconURL,
  currentMarker,
  setCurrentMarker,
  center,
  mode,
  setIsEditing,
  isModalShowed,
}) => {
  const ref = useRef();
  const refButtons = useRef();
  const [map, setMap] = useState(null);
  const [didLoad, setDidLoad] = useState(false);
  const [startEdit, setStartEdit] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");
  const [currentArea, setCurrentArea] = useState(area);
  const [currentPolygon, setCurrentPolygon] = useState(null);

  const [mapDim, setMapDim] = useState({ height: 0, width: 0 });
  const { height, width } = mapDim;

  currentIconURL = currentMarkerIconURL;
  iconURL = markerIconURL;

  const setDimensions = useCallback(() => {
    const buttonsHeight = refButtons.current?.clientHeight
      ? refButtons.current.clientHeight
      : 0;

    const parentHeight = ref.current?.parentElement.clientHeight || 0;
    const parentWidth = ref.current?.parentElement.clientWidth || 0;

    if (parentHeight === height) return;
    setMapDim({
      height: parentHeight - buttonsHeight,
      width: parentWidth,
    });
    setDidLoad(true);
  }, [height]);

  // useEffect used when options change
  useEffect(() => {
    if (map) return;
    allMarkers = [];
    allInfoWindow = [];
    currentPoint = null;
    setCurrentArea([]);
    options.zoom = 8;
    const newMap = new window.google.maps.Map(ref.current, {
      ...options,
      mapTypeId: "hybrid",
      streetViewControl: false,
    });

    window.addEventListener("resize", () => {
      setDimensions();
    });

    window.onload = function () {
      setDimensions();
    };

    window.google.maps.event.addListener(newMap, "idle", () => {
      setTimeout(() => {
        setDimensions();
      }, 200);
    });

    setMap(newMap);
  }, [map, options, setDimensions]);

  // useEffect used when mode change
  useEffect(() => {
    if (!map || !didLoad) return;
    window.google.maps.event.clearListeners(map, "click");
    switch (mode) {
      case "draw":
        map.addListener("click", (event) => {
          const point = { lat: event.latLng.lat(), lng: event.latLng.lng() };
          setCurrentArea((currentArea) => [...currentArea, point]);
          closeAllInfoWindow();
        });
        break;
      case "view":
        currentPolygon &&
          window.google.maps.event.clearListeners(currentPolygon, "mouseover");
        map.addListener("click", () => {
          closeAllInfoWindow();
        });
        break;
      case "editMarkerPosition":
        map.addListener("click", () => {
          closeAllInfoWindow();
        });
        break;
      default:
        break;
    }
  }, [map, mode, didLoad, currentPolygon]);

  // useEffect used when area change
  useEffect(() => {
    setCurrentArea(area);
  }, [area]);

  // useEffect used when currentArea change
  useEffect(() => {
    if (!currentArea || !currentArea?.length) return;
    const polygon = new window.google.maps.Polygon({
      paths: currentArea,
      strokeColor: "#742068",
      strokeWeight: 1.5,
      fillColor: "white",
      fillOpacity: 0.4,
      editable: mode === "draw",
    });

    polygon.addListener("click", (event) => {
      closeAllInfoWindow();
      if (mode === "addMarker") {
        addMarker(map, event.latLng, setCurrentMarker);
        window.google.maps.event.addListener(
          currentPoint,
          "dragend",
          function (event) {
            if (
              window.google.maps.geometry.poly.containsLocation(
                event.latLng,
                polygon
              )
            ) {
              currentPoint.setPosition(event.latLng);
            } else {
              currentPoint.setPosition(null);
            }
          }
        );
      }
      if (mode === "editMarkerPosition") {
        const current = allMarkers.find((marker) => marker.draggable);
        if (current) current.setPosition(event.latLng);
      }
    });

    polygon.setMap(map);

    setCurrentPolygon((currentPolygon) => {
      currentPolygon && currentPolygon.setMap(null);
      return polygon;
    });
  }, [map, currentArea, mode, setCurrentMarker]);

  // useEffect used when currentPolygon change
  useEffect(() => {
    const bounds = currentPolygon && currentPolygon?.getBounds();
    if (
      !bounds ||
      currentPolygon.getPath().getArray()?.length < 3 ||
      width === 0 ||
      height === 0
    )
      return;
    map && map.fitBounds(bounds);
    map && map.setZoom(getBoundsZoomLevel(bounds, { width, height }));
  }, [map, currentPolygon, width, height]);

  // useEffect used to start search places
  useEffect(() => {
    const input = document.getElementById("location-search");
    if (!input || !map) return;

    const searchBox = new window.google.maps.places.SearchBox(input);

    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    searchBox.addListener("places_changed", () => {
      cleanMarkers();

      const places = searchBox.getPlaces();

      if (places?.length === 0) {
        return;
      }

      // For each place, get the icon, name and location.
      const bounds = new window.google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }

        // Create a marker for each place.
        allMarkers.push(
          new window.google.maps.Marker({
            icon: { url: locationSearchIcon },
            title: place.name,
            position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
      addMarkersToMap(map);
    });
  }, [map]);

  // useEffect used to center map when only markers was passed
  useEffect(() => {
    if (!map) return;
    if (!currentPolygon && markers?.length) {
      const newBounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < markers?.length; i++) {
        newBounds.extend(markers[i].coords);
      }
      const newZoom = getBoundsZoomLevel(newBounds, { height, width });
      map.fitBounds(newBounds);
      map.setZoom(newZoom);
    }
  }, [map, currentPolygon, markers, height, width]);

  // useEffect used to add markers to map
  useEffect(() => {
    if (!map || !markers) return;
    // Clear out the old markers.
    cleanMarkers();

    markers.forEach((m) => {
      const infowindow = new window.google.maps.InfoWindow({
        content: ReactDOMServer.renderToStaticMarkup(
          <HolosInfoWindow marker={m} />
        ),
      });

      const marker = new window.google.maps.Marker({
        position: m.coords,
        map: map,
        title: m.name,
        icon: { url: iconURL },
        id: m.id,
        draggable: m.editable,
        current: m.current,
      });

      const isValid = !allMarkers.some((ma) => {
        return ma.get("id") === marker.get("id");
      });

      if (!isValid) {
        marker.setMap(null);
        return;
      }

      allMarkers.push(marker);
      allInfoWindow.push(infowindow);

      if (m.editable) {
        window.google.maps.event.addListener(
          marker,
          "dragend",
          function (event) {
            if (
              window.google.maps.geometry.poly.containsLocation(
                event.latLng,
                currentPolygon
              )
            ) {
              marker.setPosition(event.latLng);
            } else {
              marker.setPosition(m.coords);
            }
          }
        );

        currentPoint = marker;
      }

      marker.addListener("click", () => {
        closeAllInfoWindow();
        setCurrentMarker && setCurrentMarker(marker.get("id"));
        infowindow.open(map, marker);
      });
    });

    addMarkersToMap(map);
  }, [map, setCurrentMarker, markers, currentPolygon]);

  // useEffect used when currentMarker change
  useEffect(() => {
    if (center) return
    const current = allMarkers.find(
      (marker) => marker.get("id") === currentMarker
    );
    current && current.set("icon", { url: currentIconURL });
    allMarkers.forEach((marker) => {
      if (marker.get("id") !== currentMarker)
        marker.set("icon", { url: iconURL });

      if (currentMarker === null) {
        closeAllInfoWindow();
      }
    });
  });

  useEffect(() => {
    return () => {
      closeAllInfoWindow();
      callback && isModalShowed && handleCallback(callback, mode);
    };
  }, [callback, mode, isModalShowed]);

  // useEffect used when center is given
  useEffect(() => {
    if (!map || !center) return;

    const marker = new window.google.maps.Marker({
      title: "coordinates point",
      position: center,
      id: Date.now()
    });

    marker.set("icon", { url: locationSearchIcon })
    allMarkers.push(marker);
    map.setCenter(center);
    addMarkersToMap(map)
  }, [map, center]);

  const deleteArea = () => {
    setCurrentPolygon((currentPolygon) => {
      currentPolygon && currentPolygon.setMap(null);
    });
  };

  return (
    <React.Fragment>
      {mode === "draw" && (
        <div className="map-controllers">
          <div ref={refButtons} className="map-buttons">
            <HolosButton
              icon={{ icon: <GestureOutlined />, color: "#444444" }}
              text={{
                name: "Dibujar área",
                color: "#444444",
                colorSelect: "white",
              }}
              background={{
                color: "transparent",
                colorSelect: "var(--purpleBHP)",
              }}
              isSelect={selectedButton === "startEdit"}
              onClick={() => {
                setIsEditing(true);
                setSelectedButton("startEdit");
                setStartEdit(true);
              }}
            />
            <HolosButton
              icon={{ icon: <DeleteForever /> }}
              text={{
                name: "Borrar selección",
                color: "#444444",
                colorSelect: "white",
              }}
              background={{
                color: "transparent",
                colorSelect: "var(--purpleBHP)",
              }}
              isSelect={selectedButton === "deleteArea"}
              onClick={() => {
                deleteArea();
                setSelectedButton("startEdit");
                setStartEdit(true);
                setIsEditing(true);
                return callback([]);
              }}
            />
            <HolosButton
              icon={{ icon: <DoneAllOutlined /> }}
              text={{
                name: "Terminar selección",
                color: "#444444",
                colorSelect: "white",
              }}
              background={{
                color: "transparent",
                colorSelect: "var(--purpleBHP)",
              }}
              isSelect={selectedButton === "endEdit"}
              onClick={() => {
                setSelectedButton("endEdit");
                setStartEdit(false);
                setIsEditing(false);
                return callback(
                  currentPolygon
                    ? currentPolygon
                        .getPath()
                        .getArray()
                        .map((coords) => ({
                          lat: coords.lat(),
                          lng: coords.lng(),
                        }))
                    : []
                );
              }}
            />
          </div>
          <div className="map-helpers">
            <IconText
              separatingMargin={"16px"}
              icon={<ZoomIn />}
              text={"Acercate o alejate haciendo scroll"}
              className="small"
            />
            <IconText
              separatingMargin={"16px"}
              icon={<ControlCameraOutlined />}
              text={"Desplazate haciendo click"}
              className="small"
            />
          </div>
        </div>
      )}
      <div
        id="google-map"
        className={getClassName(startEdit, mode)}
        ref={ref}
        style={{
          height: height,
          width: "100%",
        }}
      />
    </React.Fragment>
  );
};

export default GoogleMap;

GoogleMap.defaultProps = {
  options: {
    zoom: 5,
    center: {
      lat: -35.675147,
      lng: -71.542969,
    },
  },
};
