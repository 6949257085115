import React from "react";
import { Route } from "react-router";
import { isDesktop } from "../../utils/helpers";
import ChangePassword from "./pages/changePassword";
import Login from "./pages/login";
import Register from "./pages/register";
import RestorePassword from "./pages/restorePassword";
import SendEmailChangePassword from "./pages/restorePassword/parts/sendEmailChangePassword";
import SuccessForm from "./pages/changePassword/parts/successForm";

const parentRoute = "/users";

export const Routes = () => (
  <React.Fragment>
    {isDesktop() && (
      <React.Fragment>
        <Route exact path={`${parentRoute}/login/:token?`} component={Login} />
        <Route
          exact
          path={`${parentRoute}/restorePassword`}
          component={RestorePassword}
        />
        <Route
          exact
          path={`${parentRoute}/changePassword/:token/:email`}
          component={ChangePassword}
        />
        <Route
          exact
          path={`${parentRoute}/successChangePass`}
          component={SuccessForm}
        />
        <Route
          exact
          path={`${parentRoute}/restorePassEmail/:email`}
          component={SendEmailChangePassword}
        />
        <Route exact path={`${parentRoute}/register`} component={Register} />
      </React.Fragment>
    )}
  </React.Fragment>
);
