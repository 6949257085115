import {
  DonutLargeOutlined,
  EmojiEventsOutlined,
  LocationOnOutlined,
  Maximize,
  PictureAsPdf,
  SettingsEthernet,
} from "@material-ui/icons";
import React, { useState } from "react";
import Draggable from "react-draggable";
import QRCode from "../../../../../../assets/qr";
import IconText from "../../../../../../shared/components/iconText";
import InfoCard from "../../../../../../shared/components/infoCard";
import "./data.scss";

const PreviewData = (props) => {
  const { plaque, setOpenData } = props;
  const [cancelDrag, setCancelDrag] = useState(false);
  const [positionY, setPositionY] = useState(0);
  const arrayFields = [
    {
      icon: <EmojiEventsOutlined />,
      iconColor: "var(--softBlack)",
      label: "Reconocimiento",
      value: plaque?.recognitionName,
    },
    {
      icon: <DonutLargeOutlined />,
      iconColor: "var(--softBlack)",
      label: "CC",
      value: plaque?.circleName,
    },
    {
      icon: <LocationOnOutlined />,
      iconColor: "var(--softBlack)",
      label: "Ubicación",
      value: plaque?.location?.name,
    },
    {
      icon: <SettingsEthernet />,
      iconColor: "var(--softBlack)",
      label: "Tag",
      value: plaque?.machineTag,
    },
  ];

  const handleDrag = (position) => {
    if (position.y < 0) return false;
    return true;
  };

  const resizeDrag = (position) => {
    if (position.y < 350) return setPositionY(0);
    setPositionY(800);
    setTimeout(() => {
      setOpenData(false);
    }, 1000);
  };

  return (
    <Draggable
      axis="y"
      onDrag={(e, position) => handleDrag(position)}
      onStop={(e, p) => resizeDrag(p)}
      disabled={cancelDrag}
      position={{ x: 0, y: positionY }}
    >
      <div className="Preview-data">
        <div
          className="preview-data-draggable"
          onTouchStart={() => setCancelDrag(false)}
        >
          <div className="preview-data-border">
            <Maximize className="Maximize" />
          </div>
          <div className="preview-data-title">
            <IconText
              icon={<PictureAsPdf />}
              text={plaque?.file?.name}
              iconColor={"var(--purpleBHP)"}
              fontColor={"var(--softBlack)"}
              fontSize={"14px"}
              fontWeight={"Normal"}
              separatingMargin={"1.375em"}
            />
          </div>
          <InfoCard
            arrayFields={arrayFields}
            fontSize={"14px"}
            backgroundColor={"var(--softBlack)"}
            fontWeight={500}
          />
        </div>
        <div className="preview-data-qr">
          <IconText
            icon={<QRCode />}
            text={"QR"}
            iconColor={"Black"}
            fontColor={"var(--softBlack)"}
            fontSize={"14px"}
            fontWeight={"500"}
            separatingMargin={"1.375em"}
          />

          <div className="img-qr">
            <a href={plaque && plaque.qr && plaque.qr[0].qrLink}>
              <img src={plaque && plaque.qr && plaque.qr[0].qrLink} alt="qr" />
            </a>
          </div>
        </div>
        <div
          className="preview-data-img"
          onTouchStart={() => setCancelDrag(true)}
        >
          {plaque.images?.length &&
            plaque.images.map((image, i) => (
              <img key={i} src={image.awsPath} alt="img1" />
            ))}
        </div>
      </div>
    </Draggable>
  );
};

export default PreviewData;
