import React from "react";

import ModuleCards from "./parts/Cards";
import ProfileStatus from "./parts/profileStatus";
import SideBar from "../../shared/components/sideBar";
import { IonPage } from "@ionic/react";
import AppBar from "../../shared/components/appBar";
import { isDesktop } from "../../utils/helpers";
import YCLogo from "./../../assets/icons/YCLogo.svg";
import "./styles.scss";

import tabs from "./tabs"

const Dashboard = () => {
  return (
    <React.Fragment>
      <IonPage>
        <div className="Dashboard">
          <SideBar
            isDesktop={isDesktop()}
            hide={false}
            moduleTabs={tabs}
            logoIcon={YCLogo}
            logoName={"YC"}
          />
          <div className="dashboard-body">
            <div className="dashboard-appbar">
              <AppBar />
            </div>
            <div className="dashboard-content">
              <div className="dashboard-title bold">Escritorio</div>
              <ModuleCards />
              <ProfileStatus />
            </div>
          </div>
        </div>
      </IonPage>
    </React.Fragment>
  );
};

export default Dashboard;
