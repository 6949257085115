import React from "react";
import { DeleteForever } from "@material-ui/icons";
import "./styles.scss";

export default function index({ className, onClick, enabled = true }) {
  return (
    <button
      type="button"
      className={`bg-none ${className}`}
      onClick={onClick}
      disabled={!enabled}
    >
      <DeleteForever
        className={`Delete-forever ${!enabled ? "disabled" : ""}`}
      />
    </button>
  );
}
