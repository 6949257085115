import React, { useState } from "react";
import { Route } from "react-router-dom";
import { IonApp, IonLoading, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
// import Home from "./pages/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import HomeViewFinder from "./modules/viewfinder/pages/homeViewFinder";
import { Loading } from "./context.js";
import HomeCircles from "./modules/circles/pages";
import HomePlaques from "./modules/plaques/pages/homePlaques";
import Users from "./modules/users/pages/home";
import LandingPage from "./modules/landingPage/pages/home";
import Dashboard from "./modules/dashboard";

console.log("funciona el cd/ci!")
const App = () => {
  const [loading, setLoading] = useState(false);

  return (
    <IonApp>
      <Loading.Provider value={[loading, setLoading]}>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path={`/`} component={LandingPage} />
            <Route path={`/dashboard`} component={Dashboard} />
            <Route path={`/circles`} component={HomeCircles} />
            <Route path={`/plaques`} component={HomePlaques} />
            <Route path={"/visor"} component={HomeViewFinder} />
            <Route path={"/users"} component={Users} />
            <Route path={"/landingpage"} component={LandingPage} />
          </IonRouterOutlet>
        </IonReactRouter>
        <IonLoading cssClass="loading" isOpen={loading} />
      </Loading.Provider>
    </IonApp>
  );
};

export default App;
