import React from "react";
import QRCode from "../../../../../assets/qr";
import {
  Cached,
  DonutLarge,
  Settings,
  TrendingUp,
  WbIncandescentOutlined,
} from "@material-ui/icons";

const items = [
  {
    name: "Ideas",
    icon: <WbIncandescentOutlined />,
    path: "/ideas",
    className: "ideas"
  },
  {
    name: "Circulos de calidad",
    icon: <DonutLarge />,
    path: "/circles/list",
  },
  {
    name: "Placas",
    icon: <QRCode />,
    path: "/plaques/list",
  },
  {
    name: "Informes",
    icon: <TrendingUp />,
    path: "/reports",
  },
  {
    name: "Solicitudes de cambios",
    icon: <Cached />,
    path: "/requests",
  },
  {
    name: "Configuración",
    icon: <Settings />,
    path: "/settings",
  },
];


export default items;
