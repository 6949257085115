import React from "react";

import {
  AdjustOutlined,
  AvTimer,
  CallMissedOutgoingOutlined,
  DonutLargeOutlined,
} from "@material-ui/icons";

export const darkGrey = "#444444";

export const generateHeaders = (onClick) => {
  const headers = [
    {
      text: "Acciones",
      class: "actions",
    },
    {
      icon: <CallMissedOutgoingOutlined />,
      text: "Desafío",
      class: "challenge",
      onClick: () => {
        onClick("name");
      },
    },
    {
      icon: <DonutLargeOutlined />,
      text: "Círculo",
      class: "circle",
      onClick: () => {
        onClick("circle");
      },
    },
    {
      icon: <AdjustOutlined />,
      text: "Tipo",
      class: "type",
      onClick: () => {
        onClick("type");
      },
    },
    {
      icon: <AvTimer />,
      text: "Estado",
      class: "status",
      onClick: () => {
        onClick("challengeState");
      },
    },
  ];

  return headers;
};

export const itemsPerPageRef = [
  { label: "Mostrar 20 filas", value: 20, id: 1 },
  { label: "Mostrar 10 filas", value: 10, id: 2 },
  { label: "Mostrar 5 filas", value: 5, id: 3 },
];
