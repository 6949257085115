import React from "react";
import "./base.scss";
import ButtonTab from "../buttonTab";
import IconText from "../iconText";

const BaseBody = (props) => {
  let { tabsBody, bodyIcon, bodyTitle, children } = props;

  return (
    <div className="BaseBody">
      <div className="bodyHead">
        <IconText
          icon={bodyIcon}
          text={bodyTitle}
          fontColor={"var(--softBlack)"}
          iconColor={"#444444"}
          iconSize={"32px"}
          separatingMargin={"16px"}
          fontSize={"18px"}
          fontWeight={"600"}
        />
        <ButtonTab
          tabs={tabsBody}
          orientation={"horizontal"}
          separatingMargin={"1.782"}
        />
      </div>
      <div className="body">{children}</div>
    </div>
  );
};

export default BaseBody;
