import { IonCardHeader, IonContent, IonPopover } from "@ionic/react";
import { Close } from "@material-ui/icons";
import React from "react";
import idea from "../../../assets/icons/idea.svg";

import "./support.scss";

const Support = (props) => {
  const { isOpen, setIsOpen } = props;
  return (
    <IonPopover
      isOpen={isOpen}
      onDidDismiss={(e) => {
        setIsOpen(false);
      }}
      cssClass={"Support"}
    >
      <IonCardHeader className={"support-title"}>
        <div className="support-row">
          <div
            className="img"
            style={{ backgroundImage: `url(${idea})` }}
          ></div>
          <label>Yo Contribuyo | Soporte</label>
        </div>
        <div onClick={() => setIsOpen(false)}>
          <Close className="Close" />
        </div>
      </IonCardHeader>
      <IonContent
        id="support-content"
        className="ion-text-center support-content"
      >
        <span>
          Bienvenido a Mesa de Ayuda. Comunícate con nosotros a través de los siguientes canales de atención:
        </span>
        <div className="support-row">
          <span className="span-title" style={{ marginRight: "2.563em" }}>
            Email
          </span>
          <span>yocontribuyo@holos.cl</span>
        </div>
        <div className="support-row">
          <span className="span-title" style={{ marginRight: "1.25em" }}>
            Teléfono
          </span>
          <span>+56 2 29797636</span>
        </div>
      </IonContent>
    </IonPopover>
  );
};

export default Support;
