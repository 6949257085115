import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Autorenew, NotInterested, Visibility } from "@material-ui/icons";

import BaseBody from "../../../../shared/components/baseBody";
import HolosList from "../../../../shared/components/holosList";
import HolosPagination from "../../../../shared/components/holosPagination";
import Request from "../../services/request";
import { tabs } from "../constants";
import "./styles.scss";
import { headers, itemsPerPageRef } from "./constants";
import { Link } from "react-router-dom";
import { Loading } from "../../../../context";
import HolosAlert from "../../../../shared/components/holosAlert";
import RenewAlert from "../../../../shared/components/renewAlert";
import { removeAccents } from "../../../../utils/helpers";

const getListRow = (items, setCurrentPlaque, setShowRenewAlert) => {
  let listRow = [];
  items &&
    items.forEach((item, index) => {
      listRow.push(
        <React.Fragment>
          <div className="item-actions" style={{ justifyContent: "center" }}>
            <Link
              to={`/preview/${item._id}`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <Visibility className="visibility" />
            </Link>
            <span
              onClick={() => {
                setCurrentPlaque(item);
                setShowRenewAlert(true);
              }}
            >
              <Autorenew className="autorenew" />
            </span>
          </div>
          <Link to={`/preview/${item._id}`}>
            <div className="item-description">
              <div className="description-row">
                <div className="description-content">
                  <div className="data-body-content">{item.name}</div>
                </div>
              </div>
            </div>
          </Link>
          <div className="item-location">
            <div className="data-body-content">{item.location?.name}</div>
          </div>
          <div className="item-circle">
            <div className="data-body-content">{item.circleName}</div>
          </div>
          <div className="item-recognition">
            <div className="data-body-content">{item.recognitionName}</div>
          </div>
        </React.Fragment>
      );
    });

  return listRow;
};

const ListTrash = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [plaques, setPlaques] = useState(null);
  const [filter, setFilter] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showRenewAlert, setShowRenewAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [currentPlaque, setCurrentPlaque] = useState("");
  const [updatePlaques, setUpdatePlaques] = useState(false);
  const [currentSearch, setCurrentSearch] = useState("");
  const [, setLoading] = useContext(Loading);
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    const getDisabledPlaques = async () => {
      setLoading(true);
      const listPlaques = (await Request.getDisabledPlaques()).body;
      setLoading(false);
      if (listPlaques) {
        setPlaques(listPlaques);
        setFilter(listPlaques);
      } else setPlaques([]);
    };
    plaqueFilters(plaques, currentSearch, setFilter);

    if (!plaques || updatePlaques) {
      getDisabledPlaques();
      setUpdatePlaques(false);
    }
  }, [plaques, currentSearch, setPlaques, updatePlaques, setLoading]);

  // filter regardless of capital letters and accents
  const plaqueFilters = (items, text, setFilter) => {
    setPageNumber(1);
    if (text === "") return setFilter(items);
    const textWithoutAccent = removeAccents(text).toLowerCase();

    const filter = items.filter(
      (item) =>
        removeAccents(item.name.toLowerCase()).includes(textWithoutAccent) ||
        removeAccents(item.location.name.toLowerCase()).includes(
          textWithoutAccent
        ) ||
        removeAccents(item.circleName.toLowerCase()).includes(
          textWithoutAccent
        ) ||
        removeAccents(item.recognitionName.toLowerCase()).includes(
          textWithoutAccent
        )
    );

    return setFilter(filter);
  };

  const onChange = (e, setCurrentSearch) => {
    setCurrentSearch(e.currentTarget.value);
  };

  const navigateTo = (typeShow, id) => {
    if (typeShow === "accept") enablePlaque(id);
  };

  const enablePlaque = async (id) => {
    const form = new FormData();
    form.append("user", "desarrollo@holos.cl");
    await Request.setEnabledPlaque(id);
    setUpdatePlaques(true);
    props.history.push(`/list`);
    return;
  };

  return (
    <BaseBody
      tabsBody={tabs}
      bodyIcon={<NotInterested />}
      bodyTitle={"Qrs Desactivados"}
      children={
        <div className="disabled-list">
          <HolosList
            icon={<NotInterested />}
            headers={headers}
            showFilterBar={true}
            searchFilter={(e) => onChange(e, setCurrentSearch)}
            currentSearch={currentSearch}
            gridTemplateColumns={"13% 20% 20% 20% 20%"}
            gridGap={"1.5em"}
            rows={getListRow(itemsList, setCurrentPlaque, setShowRenewAlert)}
            showRowsColor={true}
            placeholder={
              "Buscar por placa QR, ubicación, círculo o reconocimiento"
            }
            moreFilters={
              <React.Fragment>
                <Select
                  options={itemsPerPageRef}
                  onChange={(e) => setItemsPerPage(parseInt(e.value))}
                  value={itemsPerPageRef.find(
                    (item) => item.value === itemsPerPage
                  )}
                  placeholder={"Elementos por página"}
                />
              </React.Fragment>
            }
          />
          <HolosPagination
            items={filter}
            setItems={setItemsList}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            itemsPerPage={itemsPerPage}
            showGoToPage={true}
          />
          <RenewAlert
            setUpdatePlaques={setUpdatePlaques}
            item={currentPlaque}
            showAlert={showRenewAlert}
            setShowAlert={(bool, typeShow) => {
              setShowRenewAlert(bool);
              navigateTo(typeShow, currentPlaque._id);
            }}
          />
          <HolosAlert
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            title={"Atencion!"}
            content={"Seleccione al menos una placa QR"}
            type={"error"}
          />
        </div>
      }
    />
  );
};

export default ListTrash;
