import React from "react";
import HolosCheck from "../../../../../../../../shared/components/holosCheck";
import IconSelect from "../../../../../../../../shared/components/iconSelect/iconSelect";

const CompanyContent = ({ company, setCompany, setCanContinue }) => (
  <div className="Company-select">
    <p className="bold great">¿A qué empresa perteneces?</p>
    <div className="company-group">
      <p>Empresa: </p>
      <IconSelect
        name={"companySelect"}
        placeholder={"Seleccione una empresa"}
        value={company}
        onChange={(e) => {
          setCompany(e);
        }}
        options={[
          { value: "BHP Escondida", label: "BHP Escondida" },
          { value: "Contratista externo", label: "Contratista externo" },
        ]}
      />
    </div>
    <HolosCheck
      className={"check-first"}
      label={"Entiendo que esta será mi unica cuenta dentro de Yo Contribuyo"}
      onChange={(checked) => {
        setCanContinue(checked);
      }}
    />
  </div>
);

export default CompanyContent;
