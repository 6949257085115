import { CodeOutlined, LocationOnOutlined, TagFaces } from "@material-ui/icons";
import React from "react";
import IconText from "../iconText";

import "./styles.scss";

const getAreaName = (area) => {
  if (!area) return "";
  if (Object.keys(area).length === 0) return "";
  if (area.name) return area.name;
  return area;
};

const PersonCard = ({ person }) => {
  return (
    <div className="Person-card">
      <div className="person-card-image-container">
        {person?.photo !== "" && (
          <img className="person-card-image" src={person?.photo} alt={""} />
        )}
        {person?.photo === "" && <div className="person-card-image"></div>}
      </div>
      <div className="person-card-info">
        <div className="person-card-name">
          <IconText
            icon={<TagFaces />}
            text={person?.name}
            className={"small bold"}
            separatingMargin={"0.625em"}
          />
        </div>
        <div className="person-card-extra-info">
          <div className="person-card-data">
            <IconText
              icon={<LocationOnOutlined />}
              text={"Área:"}
              className={"small bold person-card-data-title"}
              separatingMargin={"0.625em"}
            />
            <div>{getAreaName(person?.area)}</div>
          </div>
          <div className="person-card-data">
            <IconText
              icon={<CodeOutlined />}
              text={"Rut:"}
              className={"small bold person-card-data-title"}
              separatingMargin={"0.625em"}
            />
            <div>{person?.rut}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonCard;
