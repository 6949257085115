import React from "react";
import HolosInput from "../../../../../../../../../../../../shared/components/holosInput";

const SecondStep = ({
  member,
  setMemberData,
  errors,
  showErrors,
  contractorExist,
  setContractorExist
}) => {
  return (
    <form>
      <div className="mb-medium">
        <div htmlFor="email" className="mb-small small bold">
          Mail *
        </div>
        <HolosInput
          placeholder={"ejemplo@tuempresa.com"}
          iconColor={"var(--black)"}
          backgroundColor={"var(--softWhite)"}
          options={""}
          name="email"
          value={member?.email}
          onChange={setMemberData}
          isError={showErrors && errors["email"]}
          msjError={"Debe ingresar un email válido"}
        />
      </div>
      <div className="mb-medium">
        <div htmlFor="phone" className="mb-small small bold">
          Telefono *
        </div>
        <HolosInput
          placeholder={"+56 2 2560 0180"}
          iconColor={"var(--black)"}
          backgroundColor={"var(--softWhite)"}
          options={""}
          name="contactPhone"
          value={member?.contactPhone}
          onChange={setMemberData}
          isError={showErrors && errors["contactPhone"]}
          msjError={"Debe completar este campo"}
        />
      </div>{" "}
      <div className="mb-medium">
        <div htmlFor="rut" className="mb-small small bold">
          RUT Empresa *
        </div>
        <HolosInput
          placeholder={"16163530-8"}
          iconColor={"var(--black)"}
          backgroundColor={"var(--softWhite)"}
          options={""}
          name="contractorRUT"
          value={member?.contractorRUT}
          onChange={(e) => {
            console.log("changing rut");
            setMemberData(e, "rut");
            setMemberData({ name: "contractorName", value: "" });
            setContractorExist(false);
          }}
          isError={showErrors && errors["contractorRUT"]}
          msjError={"Debe ingresar un RUT válido para empresa"}
        />
      </div>{" "}
      <div className="mb-medium">
        <div htmlFor="business" className="mb-small small bold">
          Empresa
        </div>
        <HolosInput
          disabled={contractorExist}
          placeholder={"16163530-8"}
          iconColor={"var(--black)"}
          backgroundColor={"var(--softWhite)"}
          options={""}
          name="contractorName"
          value={member?.contractorName}
          onChange={setMemberData}
          isError={showErrors && errors["contractorName"]}
          msjError={"Debe completar este campo"}
        />
      </div>
    </form>
  );
};

export default SecondStep;
