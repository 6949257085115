import React, { useCallback, useEffect, useState } from "react";

import {
  BorderColorOutlined,
  DeleteForever,
  DonutLargeOutlined,
  MoreVertOutlined,
} from "@material-ui/icons";

// services
import { headers } from "../../constants";
import HolosChip from "../../../../../../../../shared/components/holosChip";
import BaseBody from "../../../../../../../../shared/components/baseBody";
import HolosList from "../../../../../../../../shared/components/holosList";

// components
import HolosInput from "../../../../../../../../shared/components/holosInput";
import IconSelect from "../../../../../../../../shared/components/iconSelect/iconSelect";
import * as helpers from "../../../../../../../../utils/helpers";
import ChallengeModal from "../challengeModal";
import { getTranslate } from "../../../../../../../../i18n/translate";
import DeleteRequest from "../../../../../../../../shared/components/DeleteRequest";

const CircleChallengeForm = ({
  setCanContinue,
  circle,
  viewError,
  setCircleData,
}) => {
  const [showChallengeModal, setShowChallengeModal] = useState(false);
  const [showChallengeModalEditing, setShowChallengeModalEditing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [challengeIndex, setChallengeIndex] = useState(null);
  //TODO Only can add one challenge at tim
  const tabs = [
    { label: "Nuevo", value: 1, id: 1 },
    { label: "Desde ideas", value: 2, id: 2 },
  ];

  const fieldValidation = {
    challenges: (challenges) => challenges?.length < 1,
  };

  const errors = helpers.useValidation(circle, fieldValidation);

  const existError = useCallback(helpers.existError, []);

  const getListRow = () => {
    let listRow = [];
    const challenges = circle?.challenges;
    challenges &&
    challenges.forEach((challenge, index) => {
      const leverageList = challenge?.leverages;
      listRow.push(
        <React.Fragment key={index}>
          <div>
            <div className="item-actions-with-modal">
              <BorderColorOutlined
                onClick={() => {
                  setIsEditing(!isEditing);
                  setShowChallengeModalEditing(!showChallengeModalEditing);
                  setChallengeIndex(index);
                }}
              />
              
              <div onClick={() =>checkRemoveAction(challenge)}>
                  <DeleteForever />
                </div>
              </div>
            </div>
            <div className="item-container">
              <div className="item-content">{challenge?.name}</div>
            </div>
            <div className="item-container leverages-list">
              {leverageList?.map((leverage, i) => (
                <div className="item-content mr-small" key={i}>
                  {leverage?.name}
                </div>
              ))}
            </div>
            <div className="item-container">
              <div className="item-content">
                {" "}
                <HolosChip
                  name={getTranslate(challenge?.state || "created")}
                  status={helpers.parseSpaceByDash(
                    challenge?.state || "created"
                  )}
                />
              </div>
            </div>
            <div className="item-container">
              <div className="item-content"></div>
            </div>
          </React.Fragment>
        );
      });

    return listRow;
  };

  const checkListSelection = ({ value }) => {
    if ( value === 1 ) setShowChallengeModal(true);
  };

  const addChallenge = (challenge) => {
    let array = [...circle?.challenges, challenge];
    setCircleData({ name: "challenges", value: array });
  };

  const editChallenge = (data, oldData) => {
    let challenges = circle?.challenges?.map((chall) =>
      chall === oldData ? { ...oldData, ...data } : chall
    );
    setCircleData({ name: "challenges", value: challenges });
  };

  const checkRemoveAction = (challenge) => {
    challenge._id ? setShowDeleteAlert(true) : removeChallenge(challenge)
  }

  const removeChallenge = (challenge) => {
    let array = circle?.challenges;
    array.pop(challenge);
    setCircleData({ name: "challenges", value: array });
  };

  const requestRemoveChallenge = () => {
    setShowDeleteAlert(false)
  }

  useEffect(() => {
    if (!existError(errors)) return setCanContinue(true);
    setCanContinue(false);
  }, [errors, existError, setCanContinue]);

  return (
    <div className="form-challenges">
      {" "}
      <form className="form">
        <div className="form-title">
          <div className="form-circle-name">
            <div>
              <HolosInput
                className="bold title-input"
                placeholder="Círculo de calidad"
                value={circle?.name?.toUpperCase()}
                id="name"
                name="name"
                onChange={setCircleData}
                disabled
              />
            </div>
          </div>
          <MoreVertOutlined className="More-vert-outlined" />
        </div>
        <div>
          <IconSelect
            placeholder="Crear desafío"
            options={tabs}
            name="challenges"
            id="challenges"
            className="purple-select"
            isError={viewError && errors?.challenges}
            msjError="Debe ingresar al menos un desafio"
            onChange={(e) => checkListSelection(e)}
          />
        </div>
      </form>
      <div className="form-wide">
        <BaseBody
          children={
            <div className="challenges-list">
              <HolosList
                icon={<DonutLargeOutlined />}
                headers={headers}
                showFilterBar={false}
                gridTemplateColumns={"10% 35% 35% 10%"}
                gridGap={"1.5em"}
                rows={getListRow()}
                showRowsColor={true}
              />
            </div>
          }
        />
      </div>
      <ChallengeModal
        show={isEditing ? showChallengeModalEditing : showChallengeModal}
        setShow={
          isEditing ? setShowChallengeModalEditing : setShowChallengeModal
        }
        addChallenge={addChallenge}
        editChallenge={editChallenge}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        challengeIndex={challengeIndex}
        circle={circle}
      />
      <DeleteRequest
        showAlert={showDeleteAlert}
        setShowAlert={setShowDeleteAlert}
        onClick={()=>requestRemoveChallenge()}
      />
    </div>
  );
};
export default CircleChallengeForm;
