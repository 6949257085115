import React from "react";
import { Route } from "react-router";
import NewPlaque from "./pages/new";
import HomeMaps from "../maps/pages/home";
import ListPlaques from "./pages/list";
import ListTrash from "./pages/trash";
import PreviewPlaque from "./pages/preview";
import { isDesktop } from "../../utils/helpers";


const parentRoute = "/plaques";

export const Routes = () => (
  <React.Fragment>
    {isDesktop() && (
      <React.Fragment>
        <Route exact path={`${parentRoute}/`} component={ListPlaques} />
        <Route exact path={`${parentRoute}/new`} component={NewPlaque} />
        <Route exact path={`${parentRoute}/edit/:id`} component={NewPlaque} />
        <Route exact path={`${parentRoute}/list`} component={ListPlaques} />
        <Route exact path={`${parentRoute}/trash`} component={ListTrash} />
        <Route exact path={`${parentRoute}/preview/:id`} component={PreviewPlaque} />
        <Route path={"/maps"} component={HomeMaps} />
      </React.Fragment>
    )}
  </React.Fragment>
);
