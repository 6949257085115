// libraries
import React, { useContext, useEffect, useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Select from "react-select";
import { Link } from "react-router-dom";

import {
  BorderColorOutlined,
  GetAppOutlined,
  NotInterested,
} from "@material-ui/icons";

// context
import { Loading } from "../../../../context";

// components
import HolosList from "../../../../shared/components/holosList";
import HolosPagination from "../../../../shared/components/holosPagination";
import DeleteAlert from "../../../../shared/components/deleteAlert";
import BaseBody from "../../../../shared/components/baseBody";
import HolosAlert from "../../../../shared/components/holosAlert";

// services
import Request from "../../services/request";

// assets
import QRCode from "../../../../assets/qr";
import { tabs } from "../constants";
import { headers, itemsPerPageRef } from "./constants";

// utils
import { removeAccents } from "../../../../utils/helpers";

// styles
import "./styles.scss";

const ListPlaques = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [plaques, setPlaques] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [currentPlaque, setCurrentPlaque] = useState("");
  const [updatePlaques, setUpdatePlaques] = useState(false);
  const [currentSearch, setCurrentSearch] = useState("");
  const [, setLoading] = useContext(Loading);
  const [alertContent, setAlertContent] = useState("");
  const [selectedQRs, setSelectedQrs] = useState([]);

  useEffect(() => {
    const getPlaques = async () => {
      setLoading(true);
      const data = (await Request.getPlaques()).body;
      setLoading(false);
      if (data) {
        setPlaques(data);
        setFilter(data);
      } else setPlaques(null);
    };
    plaqueFilters(plaques, currentSearch, setFilter);

    if (plaques?.length === 0 || updatePlaques) {
      getPlaques();
      setUpdatePlaques(false);
    }
  }, [plaques, currentSearch, setPlaques, updatePlaques, setLoading]);

  const handleCheckPlaque = (plaque) => {
    const index = selectedQRs.findIndex((qr) => qr._id === plaque._id);
    if (index !== -1) {
      setSelectedQrs(
        selectedQRs.filter((selected) => selected._id !== plaque._id)
      );
      return;
    }
    setSelectedQrs([...selectedQRs, plaque]);
  };

  const getListRow = () => {
    let listRow = [];
    itemsList &&
      itemsList.forEach((item, index) => {
        listRow.push(
          <React.Fragment>
            <div className="item-actions">
              <Link
                to={`plaques/edit/${item._id}`}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <BorderColorOutlined />
              </Link>
              <span
                onClick={() => {
                  setCurrentPlaque(item);
                  setShowDeleteAlert(true);
                }}
              >
                <NotInterested />
              </span>
              <input
                type="checkbox"
                checked={selectedQRs.some((qr) => qr._id === item._id)}
                name={`plaques`}
                id={`${item._id}`}
                onChange={() => handleCheckPlaque(item)}
              />
            </div>
            <Link to={`plaques/preview/${item._id}`}>
              <div className="item-description">
                <div className="description-row">
                  <div className="description-content">
                    <div className="data-body-content">{item.name}</div>
                  </div>
                </div>
              </div>
            </Link>
            <div className="item-location">
              <div className="data-body-content">{item.location?.name}</div>
            </div>
            <div className="item-circle">
              <div className="data-body-content">{item.circleName}</div>
            </div>
            <div className="item-recognition">
              <div className="data-body-content">{item.recognitionName}</div>
            </div>
          </React.Fragment>
        );
      });

    return listRow;
  };

  // filter regardless of capital letters and accents
  const plaqueFilters = (items, text, setFilter) => {
    setPageNumber(1);
    if (text === "") return setFilter(items);
    const textWithoutAccent = removeAccents(text).toLowerCase();

    const newFilter = items?.filter(
      (item) =>
        removeAccents(item.name.toLowerCase()).includes(textWithoutAccent) ||
        removeAccents(item.location.name.toLowerCase()).includes(
          textWithoutAccent
        ) ||
        removeAccents(item.circleName.toLowerCase()).includes(
          textWithoutAccent
        ) ||
        removeAccents(item.recognitionName.toLowerCase()).includes(
          textWithoutAccent
        )
    );

    return setFilter(newFilter);
  };

  const onChange = (e, setCurrentSearch) => {
    setCurrentSearch(e.currentTarget.value);
  };

  const downloadQRs = async () => {
    setLoading(true);
    try {
      if (selectedQRs?.length === 0) {
        setShowAlert(true);
        setAlertContent("Seleccione al menos una placa");
        setLoading(false);
        return;
      }
      const QRImages = await getImagesQR(selectedQRs);
      generateZipWithImages(QRImages);
    } catch (error) {
      setShowAlert(true);
      setAlertContent("No se pudieron descargar las imágenes");
    }

    setLoading(false);
  };

  const getImagesQR = async (plaques) => {
    try {
      let images = [];

      for (const plaque of plaques) {
        const awsKey = plaque?.qr[0].awsKey;
        const fileName = `${plaque.name}-${plaque.circleName}.png`;
        const image = (await Request.getImageQR(awsKey)).body;
        images.push({ name: fileName, image });
      }

      return images;
    } catch (error) {
      throw new Error("No se pudieron obtener las imágenes de los códigos QRs");
    }
  };

  const generateZipWithImages = (images) => {
    let zip = new JSZip();

    images.forEach((image) => {
      zip.file(image.name, image.image, { binary: true });
    });

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "PlacasQR.zip");
    });
  };

  const navigateTo = (typeShow, id) => {
    if (typeShow === "accept") deletePlaque(id);
  };

  const deletePlaque = async (id) => {
    const form = new FormData();
    form.append("user", "desarrollo@holos.cl");
    await Request.deletePlaque(id, form);
    setUpdatePlaques(true);
    return;
  };

  return (
    <BaseBody
      tabsBody={tabs}
      bodyIcon={<QRCode />}
      bodyTitle={"Placas QR activas"}
      children={
        <div className="plaques-list">
          <HolosList
            icon={<QRCode className={"opacity-54"}/>}
            headers={headers}
            showFilterBar={true}
            searchFilter={(e) => onChange(e, setCurrentSearch)}
            currentSearch={currentSearch}
            gridTemplateColumns={"13% 20% 20% 20% 20%"}
            gridGap={"1.5em"}
            rows={getListRow(itemsList, setCurrentPlaque, setShowDeleteAlert)}
            showRowsColor={true}
            placeholder={
              "Buscar por placa QR, ubicación, círculo o reconocimiento"
            }
            moreFilters={
              <React.Fragment>
                <Select
                  options={itemsPerPageRef}
                  onChange={(e) => setItemsPerPage(parseInt(e.value))}
                  value={itemsPerPageRef.find(
                    (item) => item.value === itemsPerPage
                  )}
                  placeholder={"Elementos por página"}
                />
                <div
                  className="download-qr"
                  onClick={() => downloadQRs(plaques)}
                >
                  <GetAppOutlined />
                  Descargar QRs
                </div>
              </React.Fragment>
            }
          />
          <HolosPagination
            items={filter}
            setItems={setItemsList}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            itemsPerPage={itemsPerPage}
            showGoToPage={true}
          />
          <DeleteAlert
            setUpdatePlaques={setUpdatePlaques}
            item={currentPlaque}
            showAlert={showDeleteAlert}
            setShowAlert={(bool, typeShow) => {
              setShowDeleteAlert(bool);
              navigateTo(typeShow, currentPlaque._id);
            }}
          />
          <HolosAlert
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            title={"Atencion!"}
            content={alertContent}
            type={"error"}
          />
        </div>
      }
    />
  );
};

export default ListPlaques;
