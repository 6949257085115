import React from "react";
import IconSelect from "../../../../../../../../../../shared/components/iconSelect/iconSelect";

const MemberData = ({ promiseOptions, setMember, member, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="name" className="form-label small bold">
          Nombre *
        </label>
        <IconSelect
          className="white-background"
          placeholder="Busque un integrante"
          id="member"
          name="member"
          loadOptions={(value) =>
            promiseOptions({ name: value, type: "external" })
          }
          onChange={(e) => {
            setMember(e);
          }}
        />
      </div>{" "}
      <div className="alert-add-internal">
        <div className="mb-medium ">
          <div htmlFor="email" className="mb-small small bold">
            Email
          </div>
          <div className="white-background">
            {member?.email && member?.email[0]}
          </div>
        </div>{" "}
        <div>
          <div htmlFor="phone" className="mb-small small bold">
            Telefono
          </div>
          <div className="white-background">{member?.contactPhone}</div>
        </div>
      </div>
    </form>
  );
};

export default MemberData;
