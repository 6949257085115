import React from "react";

import { Add } from "@material-ui/icons";

export const generateSolutionsHeaders = () => {
  const headers = [
    {
      icon: <Add />,
      text: "Soluciones",
      class: "white-round-number plus-button",
    },
    {
      text: "Riesgo",
      class: "risk",
    },
    {
      text: "Costo",
      class: "cost",
    },
    {
      text: "Impacto",
      class: "impact",
    },
    {
      text: "Total",
      class: "total",
    },
    {
      text: "Usar",
      class: "usage",
    },
    {
      text: "Acciones",
      class: "actions",
    },
  ];

  return headers;
};
