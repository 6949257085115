import React, { useState } from "react";
import "./viewFinder.scss";
import SideBar from "../../../shared/components/sideBar";
import { Routes } from "../routes";
import { LocationOnOutlined, Phone } from "@material-ui/icons";
import Support from "../../../shared/components/support";
import { isDesktop } from "../../../utils/helpers";
import { Redirect } from "react-router";

const HomeViewFinder = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [hideSideBar, setHideSideBar] = useState(null);

  const tabs = [
    {
      text: {
        name: "Ubicaciones",
        colorHover: "var(--purpleBHP)",
        color: "var(--softBlack)",
        size: "14px",
        weight: "400",
      },
      icon: {
        color: "black",
        icon: <LocationOnOutlined />,
        opacity: 0.54,
      },
      background: {
        color: "transparent",
      },
      urlRedirect: "/visor/list/maps",
    },
    {
      text: {
        name: "Contactar soporte",
        colorHover: "var(--purpleBHP)",
        color: "var(--softBlack)",
        size: "14px",
        weight: "400",
      },
      icon: {
        color: "black",
        icon: <Phone />,
        opacity: 0.54,
      },
      background: {
        color: "transparent",
      },
      component: {
        class: (
          <Support isOpen={isOpen} setIsOpen={(bool) => setIsOpen(bool)} />
        ),
        isOpen: isOpen,
        setIsOpen: setIsOpen,
      },
    },
  ];

  //methods for hiding the menu depending on whether it goes up or down.
  const isScrollUp = (e) => {
    //if the scroll top, disabled hide effects.
    if (e.target.scrollTop === 0) return null;
    
    //if the scroll is not abrupt, it remains with the previous state 
    if (
      e.target.scrollTop >= scrollTop - 5 &&
      e.target.scrollTop <= scrollTop + 5
    )
      return hideSideBar;

    if (e.target.scrollTop > scrollTop) return true;
    return false;
  };

  const onScroll = (e) => {
    let boolScrollUp = isScrollUp(e);
    setScrollTop(e.target.scrollTop);
    setHideSideBar(boolScrollUp);
  };

  if (isDesktop()) return <Redirect to="/" />;

  return (
    <div id="Home-view-finder" onScroll={onScroll}>
      <SideBar
        isDesktop={isDesktop()}
        moduleTabs={tabs}
        hide={hideSideBar}
        {...props}
      />
      <Routes />
    </div>
  );
};

export default HomeViewFinder;
