import RequestManager from "../../../services/requestManager";
import config from "../../../services/config";
import { plaquesURL } from "./urls.json";

class Request {
  constructor() {
    this.baseUrl = config.REACT_APP_API_URL;
  }

  createPlaque(body) {
    let url = `${this.baseUrl}${plaquesURL}`;
    return RequestManager.post(url, body);
  }

  getPlaques() {
    let url = `${this.baseUrl}${plaquesURL}`;
    return RequestManager.get(url);
  }

  getPlaque(id) {
    let url = `${this.baseUrl}${plaquesURL}${id}`;
    return RequestManager.get(url);
  }

  getImageQR(key) {
    const url = `${config.REACT_APP_API_URL}getfiles/${encodeURIComponent(
      key
    )}`;
    return RequestManager.getBlob(url);
  }

  checkQR(id) {
    let url = `${this.baseUrl}${plaquesURL}qr/${id}`;
    return RequestManager.get(url);
  }

  getDisabledPlaques() {
    let url = `${this.baseUrl}${plaquesURL}disabledList`;
    return RequestManager.get(url);
  }

  editPlaque(body, id) {
    let url = `${this.baseUrl}${plaquesURL}${id}`;
    return RequestManager.put(url, body);
  }

  setEnabledPlaque(id, body) {
    let url = `${this.baseUrl}${plaquesURL}enable/${id}`;
    return RequestManager.put(url, body);
  }

  deletePlaque(id, body) {
    let url = `${this.baseUrl}${plaquesURL}delete/${id}`;
    return RequestManager.put(url, body);
  }
}

const instance = new Request();
Object.freeze(instance);
export default instance;
