import RequestManager from "../../../services/requestManager";
import config from "../../../services/config";
import {
  circlesURL,
  circlesListURL,
  personURL,
  valuesURL,
  parentsAreaURL,
  validateURL,
  challengesURL,
  causeCategoriesURL,
  validateChallengeURL
} from "./urls.json";
import { parseObjectToUrl } from "../../../utils/helpers";

class Request {
  constructor() {
    this.baseUrl = config.REACT_APP_API_URL;
  }

  getCircles() {
    let url = this.baseUrl + circlesURL;
    return RequestManager.get(url);
  }

  getCircle(id) {
    let url = `${this.baseUrl}${circlesURL}${id}`;
    return RequestManager.get(url);
  }

  postCircle(circle) {
    let url = this.baseUrl + circlesURL;
    return RequestManager.post(url, circle);
  }

  putCircle(circle,id) {
    let url = `${this.baseUrl}${circlesURL}${id}`;
    return RequestManager.put(url, circle);
  }

  getCirclesList(query) {
    let url = this.baseUrl + circlesListURL + "?" + parseObjectToUrl(query);
    return RequestManager.get(url);
  }

  getPerson(query) {
    let url = `${this.baseUrl}${personURL}?${parseObjectToUrl(query)}`;
    return RequestManager.get(url);
  }

  getValueList(query) {
    let url = `${this.baseUrl}${valuesURL}?${parseObjectToUrl(query)}`;
    return RequestManager.get(url);
  }

  getValidateCircle(query) {
    let url = `${this.baseUrl}${validateURL}?${parseObjectToUrl(query)}`;
    return RequestManager.get(url);
  }

  getValidateChallenge(query) {
    let url = `${this.baseUrl}${validateChallengeURL}?${parseObjectToUrl(query)}`;
    return RequestManager.get(url);
  }

  getAreaParents(id) {
    let url = `${this.baseUrl}${parentsAreaURL}/${id}/fathers/`;
    return RequestManager.get(url);
  }

  getChallengesList(query) {
    let url = this.baseUrl + challengesURL + "/list?" + parseObjectToUrl(query);
    return RequestManager.get(url);
  }

  getChallenge(id,query={}) {
    let url = this.baseUrl + challengesURL + "/" + id + "?" + parseObjectToUrl(query);
    return RequestManager.get(url);
  }

  getCauseCategoriesList() {
    let url = this.baseUrl + causeCategoriesURL;
    return RequestManager.get(url);
  }
}

const instance = new Request();
Object.freeze(instance);
export default instance;
