import React from "react";
import { useHistory } from "react-router";

import { Modal } from "../../../../shared/components/modal";
import HolosButton from "../../../../shared/components/holosButton";
import LogoYoContribuyo from "../../../../shared/components/logoYoContribuyo";

import "./styles.scss";

const ResendEmailAlert = ({ isOpen, title, text, onResend }) => {
  const history = useHistory();

  return (
    <Modal
      showModal={isOpen}
      cssClass={"resend-email-modal ion-text-left"}
      title={<LogoYoContribuyo />}
      content={
        <div className="resend-content">
          <h2 className="resend-title great">{title}</h2>
          <p className="resend-text small">{text}</p>
        </div>
      }
      actions={
        <div className="resend-buttons">
          <HolosButton
            className="purple-background"
            text={{ name: "Aceptar", className: "white-icon-text" }}
            onClick={() => history.push("/landingpage")}
          />
          <HolosButton
            className="transparent-background resend-button"
            text={{ name: "Reenviar email", className: "black-icon-text" }}
            onClick={onResend}
          />
        </div>
      }
    />
  );
};

export default ResendEmailAlert;
