import { CheckCircleOutline, HighlightOff, ThumbUp } from "@material-ui/icons";
import React, { useState, useEffect, useCallback } from "react";
import HolosButton from "../../../../shared/components/holosButton";
import IconText from "../../../../shared/components/iconText";
import {  objectContainsFields } from "../../../../utils/helpers";

function ProfileStatus() {
  const [isCompletePersonal, setCompletePersonal] = useState(false);
  const [isCompleteJobData, setCompleteJobData] = useState(true);
  const [hasPhoto, setHasPhoto] = useState(true);
  const [completeProfile, setCompleteProfile] = useState(false);

  const user = JSON.parse(window.localStorage.getItem("person"));

  const defaultPhoto = {
    awsKey: "emptyImage.svg",
    awsPath: "/static/media/emptyImage.9eafc28e.svg",
    mimetype: "image/svg+xml",
    name: "emptyImage",
  };

  const completePersonal = useCallback(() => {
    const fields = [
      "firstName",
      "firstLastName",
      "birthDate",
      "contactPhone",
      "idGender",
      "rut",
    ];
    let complete = objectContainsFields(user?.profile, fields);
    return setCompletePersonal(complete);
  }, [user, setCompletePersonal]);

  const completeJobData = useCallback(() => {
    const fields = [
      "idGroup",
      "idWorkSchedule",
      "idArea",
      "idLeader",
    ]
    let complete =  objectContainsFields(user, fields);
    return setCompleteJobData(complete);
  }, [user, setCompleteJobData]); 

  const completePhoto = useCallback(() => {
    const complete = JSON.stringify(user?.profile?.photo) === JSON.stringify(defaultPhoto)
    return setHasPhoto(complete);
  }, [user, defaultPhoto, setHasPhoto]);

  const validAllinOne = useCallback(() => {
    completePersonal();
    completeJobData();
    completePhoto();
  }, [completePersonal, completeJobData, completePhoto]);

  useEffect(() => {
    validAllinOne();
  }, [user, validAllinOne]);

  useEffect(() => {
    if (isCompletePersonal && isCompleteJobData && hasPhoto)
      return setCompleteProfile(true);
    return setCompleteProfile(false);
  }, [isCompletePersonal, isCompleteJobData, hasPhoto, setCompleteProfile]);

  return (
    !completeProfile && (
      <div className="profile-status">
        <IconText
          className={"medium bold"}
          text={"¡Genial! Tu perfil casi esta completo"}
          icon={<ThumbUp />}
          separatingMargin={"16px"}
        />
        <div className="profile-status-buttons">
          <HolosButton
            icon={{
              icon: isCompletePersonal ? (
                <CheckCircleOutline />
                ) : (
                  <HighlightOff />
              ),
              color: isCompletePersonal ? "white-icon-text" : "icon-text" ,
            }}
            className={
              isCompletePersonal ? "purple-background" : "gray-background"
            }
            text={{
              name: "Datos Personales",
              className: isCompletePersonal ? "white-icon-text" : "icon-text",
            }}
            onClick={() => {}}
          />
           <HolosButton
            icon={{
              icon: isCompleteJobData ? (
                <CheckCircleOutline />
                ) : (
                  <HighlightOff />
              ),
              color: isCompleteJobData ? "white-icon-text" : "icon-text" ,
            }}
            className={
              isCompleteJobData ? "purple-background" : "gray-background"
            }
            text={{
              name: "Datos Laborales",
              className: isCompleteJobData ? "white-icon-text" : "icon-text",
            }}
            onClick={() => {}}
          />
          <HolosButton
            icon={{
              icon: hasPhoto ?  <CheckCircleOutline /> : <HighlightOff />,
              color: hasPhoto ?  "icon-text" : "white-icon-text"
            }}
            className={hasPhoto ? "icon-text" : "gray-background"}
            text={{
              name: "Foto de perfil",
              className: hasPhoto ?  "white-icon-text" : "icon-text",
            }}
            onClick={() => {}}
          />
          <HolosButton
            icon={{
              icon: !user ? <HighlightOff /> : <CheckCircleOutline />,
              color: !user ?  "white-icon-text" : "icon-text",
            }}
            className={!user ? "gray-background" : "purple-background"}
            text={{
              name: "Seguridad",
              className: !user ? "icon-text" : "white-icon-text",
            }}
            onClick={() => {}}
          />
        </div>
      </div>
    )
  );
}

export default ProfileStatus;
