import React from "react";
import IconText from "../../iconText";

import "./styles.scss";

const ListHeader = ({ headers, gridTemplateColumns, gridGap }) => {
  return (
    <div
      className="headers"
      style={{ gridTemplateColumns: gridTemplateColumns, gridGap: gridGap }}>
      {headers &&
        headers.map((header, index) => (
          <div className={header.class} key={`${header.text}-${index}`} onClick={header.onClick}>
            <IconText
              icon={header.icon}
              text={header.text}
              iconOpacity={0.54}
              fontWeight={600}
              fontColor={"var(--softBlack)"}
              iconColor={"var(--softBlack)"}
              separatingMargin={"0.75em"}
            />
          </div>
        ))}
    </div>
  );
};

export default ListHeader;
