import {
  BorderColorOutlined,
  ErrorOutline,
  LocationOnOutlined,
  DeleteForever,
  WarningOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";

import "./styles.scss";
import BaseBody from "../../../../shared/components/baseBody";
import { tabs } from "../constants";
import IconText from "../../../../shared/components/iconText";
import GoogleMap from "../../../../shared/components/googleMap";
import RequestMap from "./../../../maps/services/request";
import { Link, Redirect } from "react-router-dom";
import HolosAlert from "../../../../shared/components/holosAlert";
import HolosButton from "../../../../shared/components/holosButton";
import spa from "./../../../../i18n/spa.json";

const confirmContent = (
  <React.Fragment>
    <p> La eliminación del mapa es permanente. ¿Estás seguro que deseas continuar?</p>

    <p>No será posible volver acceder a él</p>
  </React.Fragment>
);

const PreviewMap = props => {
  const [map, setMap] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [requestStatus, setRequestStatus] = useState("confirm");

  const { id } = props.match.params;

  const deleteMap = async id => {
    const res = await RequestMap.deleteMap(id);
    if (res.status === 200) {
      setIsDeleted(true);
    } else {
      setShowAlert(true);
      setRequestStatus("fail");
      setAlertContent(<p>{spa[res.code]}</p>);
    }
  };

  const handleClick = () => {
    if (requestStatus !== "confirm") {
      setShowAlert(false);
      setRequestStatus("confirm");
      setAlertContent(confirmContent);
      return;
    }

    deleteMap(id);
  };

  useEffect(() => {
    id &&
      (async () => {
        const map = (await RequestMap.getMap(id)).body;
        if (map) {
          setMap(map);
        }
      })();
    setAlertContent(confirmContent);
  }, [props, id]);

  if (isDeleted) return <Redirect to="/maps/list" />;

  return (
    <React.Fragment>
      <BaseBody
        tabsBody={tabs}
        bodyIcon={<LocationOnOutlined />}
        bodyTitle={"Mapa activo"}
        children={
          <div className="maps-preview">
            <div className="preview-title">
              <IconText
                icon={<LocationOnOutlined />}
                iconSize={"3em"}
                iconOpacity={0.54}
                text={map?.name}
                separatingMargin={"1.25em"}
              />
              <div className="preview-actions">
                <Link to={`/maps/edit/${id}`}>
                  <BorderColorOutlined
                    style={{ cursor: "pointer", marginRight: "0.75em" }}
                  />
                </Link>
                <span onClick={() => setShowAlert(true)}>
                  <DeleteForever style={{ cursor: "pointer" }} />
                </span>
              </div>
            </div>
            <div className="gmaps-card">
              <GoogleMap area={map?.areaCoords} mode="preview" />
            </div>
          </div>
        }
      />
      <HolosAlert
        showAlert={showAlert}
        onClose={e => setShowAlert(e)}
        setShowAlert={setShowAlert}
        title={
          <React.Fragment>
            <IconText
              icon={<LocationOnOutlined />}
              text={map?.name}
              iconColor={"var(--purpleBHP)"}
            />
          </React.Fragment>
        }
        content={
          <div className="delete-map">
            <div className="alert-icon">
              {requestStatus === "confirm" ? (
                <WarningOutlined />
              ) : (
                <ErrorOutline />
              )}
            </div>
            <div className="alert-content">{alertContent}</div>
          </div>
        }
        actions={[
          <HolosButton
            key={1}
            separatingMargin={"1em"}
            icon={{
              icon: requestStatus === "confirm" && <DeleteForever />,
              color: "white",
            }}
            background={{ color: "var(--purpleBHP)" }}
            text={{
              name: `${requestStatus === "confirm" ? "Eliminar" : "Aceptar"}`,
              color: "white",
              colorHover: "white",
            }}
            onClick={() => {
              handleClick();
            }}
          />,
        ]}
      />
    </React.Fragment>
  );
};

export default PreviewMap;
