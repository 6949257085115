import { DonutLargeOutlined } from "@material-ui/icons";
import React from "react";
import IconText from "../../../iconText";
import qr from "./../../../../../assets/icons/qr.svg";
import "./styles.scss";

const getContent = item => {
  switch (item.type) {
    case "plaque":
      return (
        <React.Fragment>
          {item.circleName && (
            <IconText
              icon={<DonutLargeOutlined fontSize={16} />}
              fontSize={"10px"}
              iconColor={"var(--softBlack)"}
              text={item.circleName}
              separatingMargin="0.5em"
            />
          )}
        </React.Fragment>
      );
    case "location": {
      return (
        <React.Fragment>
          <IconText
            icon={qr}
            fontSize={"10px"}
            iconColor={"var(--softBlack)"}
            text={`${item.plaques?.length} placas QR activas`}
            separatingMargin="0.5em"
          />
        </React.Fragment>
      );
    }
    default:
      break;
  }
};

const HolosInfoWindow = ({ marker }) => {
  return (
    <div className="info-window">
      {marker?.images?.length && (
        <div className="marker-images">
          {marker.images.map((image, index) => (
            <div
              key={`image-${index}`}
              style={{
                backgroundImage: `url(${image.awsPath})`,
              }}></div>
          ))}
        </div>
      )}
      {marker?.type && (
        <div className="info-window-content">
          <div className="info-window-title">{marker.name}</div>
          {getContent(marker)}
        </div>
      )}
    </div>
  );
};

export default HolosInfoWindow;
