import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";

import {
  BorderColorOutlined,
  CallMissedOutgoingOutlined,
  MoreVertOutlined,
  NotInterestedOutlined,
  DeleteForeverOutlined,
  PictureAsPdf,
} from "@material-ui/icons";

// components
import HolosList from "../../../../shared/components/holosList";
import BaseBody from "../../../../shared/components/baseBody";
import HolosChip from "../../../../shared/components/holosChip";
import HolosButton from "../../../../shared/components/holosButton";
import FloatingModal from "../../../../shared/components/floatingModal";
import HolosPagination from "../../../../shared/components/holosPagination";
import {
  usePrevious,
  handleSortHelper,
  parseSpaceByDash,
} from "../../../../utils/helpers";
import Request from "../../services/request";


// styles
import "./styles.scss";

// services
import { generateHeaders, itemsPerPageRef } from "./constants";

//utils
import DeleteRequest from "../../../../shared/components/DeleteRequest";
import DeactivateHolosAlert from "../../../../shared/components/DeactivateHolosAlert";
import { getTranslate } from "../../../../i18n/translate";

const ChallengesList = () => {
  const [challenges, setChallenges] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeactiveAlert, setShowDeactiveAlert] = useState(false);
  const [showUserChallenges, setShowUserChallenges] = useState(false);
  const [showFloating, setShowFloating] = useState(null);
  const [query, setQuery] = useState({
    page_size: 10,
    page_number: 1,
    page_count: 1,
  });

  const prevItems = usePrevious({ ...query });

  const filterRef = useRef();

  const showFloatingModal = (index) => {
    if (showFloating === null) return false;
    if (showFloating === index) return true;
    return false;
  };

  const floatingTabs = [
    {
      className: "transparent-background purple-hover",
      text: {
        name: "Ver A3",
        className: "white-background small lessBold",
        icon: <PictureAsPdf />,
      },
      onClick: () => {
        console.log("Comming Soon -----> Ver A3")
        setShowFloating(null)},
    },
    {
      className: "transparent-background purple-hover",
      text: {
        name: "Eliminar",
        className: "white-background small lessBold",
        icon: <DeleteForeverOutlined />,
      },
      onClick: () => {
        setShowFloating(null)
        setShowDeleteAlert(true)
      },
    },
    {
      className: "transparent-background purple-hover",
      text: {
        name: "Desactivar",
        className: "white-background small lessBold",
        icon: <NotInterestedOutlined />,
      },
      onClick: () => {
        setShowFloating(null)
        setShowDeactiveAlert(true)
      },
    },
  ];

  useEffect(() => {
    (async () => {
      if (JSON.stringify(prevItems) === JSON.stringify(query)) return;
      const res = (await Request.getChallengesList(query))?.body;
      if (!res?.data) setChallenges([]);
      setChallenges(res?.data);
      setQuery((query) => ({
        ...query,
        page_size: res?.page_size,
        page_number: res?.page_number,
        page_count: res?.page_count,
      }));
      filterRef.current.focus();
    })();
  }, [prevItems, query]);

  const getListRow = () => {
    let listRow = [];
    challenges &&
      challenges.forEach((challenge, index) => {
        listRow.push(
          <React.Fragment>
            <div>
              <div className="item-actions-with-modal">
                <Link
                  to={`/circles/challenges/${challenge._id}/edit`}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <BorderColorOutlined />
                </Link>
                <span>
                  <MoreVertOutlined
                    onClick={() =>
                      showFloatingModal(index)
                        ? setShowFloating(null)
                        : setShowFloating(index)
                    }
                  />
                </span>
              </div>
                <div className="floating-modal-container">
                  {showFloatingModal(index) && (
                    <div>
                      <FloatingModal tabs={floatingTabs} key={index} />
                    </div>
                  )}
                </div>
            </div>
            <div className="item-container">
              <div className="item-content">{challenge?.name}</div>
            </div>
            <div className="item-container">
              <div className="item-content">{challenge?.circle}</div>
            </div>
            <div className="item-container">
              <div className="item-content">
                {challenge?.justDoIt ? "Just do it" : "Completo"}
              </div>
            </div>
            <div className="item-container">
              <div className="item-content">
                <HolosChip
                  name={getTranslate(challenge?.state)}
                  status={parseSpaceByDash(challenge?.state)}
                />
              </div>
            </div>
          </React.Fragment>
        );
      });

    return listRow;
  };

  const filter = (e) => {
    setQuery({
      ...query,
      challenge: e,
      page_number: 1,
    });
  };
  const handleSort = (param) => {
    handleSortHelper(param, query, setQuery, "challengeState");
  };

  return (
    <BaseBody
      bodyIcon={<CallMissedOutgoingOutlined />}
      bodyTitle={"Desafíos"}
      children={
        <div className="Challenges-list">
          <HolosList
            filterRef={filterRef}
            icon={<CallMissedOutgoingOutlined />}
            headers={generateHeaders(handleSort)}
            showFilterBar={true}
            searchFilter={(e) => {
              filter(e.currentTarget.value);
            }}
            currentSearch={query.filter}
            gridTemplateColumns={"10% 45% 20% 10% 10% 5%"}
            gridGap={"0.625em"}
            rows={getListRow(challenges)}
            showRowsColor={true}
            placeholder={"Buscar por nombre"}
            moreFilters={
              <React.Fragment>
                <div className="challenges-filters-start">
                  <Select
                    options={itemsPerPageRef}
                    onChange={(e) =>
                      setQuery((query) => ({ ...query, page_size: e.value }))
                    }
                    value={itemsPerPageRef.find(
                      (item) => item.value === query.page_size
                    )}
                    placeholder={"Elementos por página"}
                  />
                </div>
                <div className="challenges-filters-end">
                  <HolosButton
                    className={
                      !showUserChallenges
                        ? "purple-rounded-corners"
                        : "transparent-rounded-corners"
                    }
                    text={{
                      name: "Todos los desafíos",
                      className: !showUserChallenges
                        ? "bold medium white-icon-text"
                        : "bold medium black-icon-text",
                    }}
                    onClick={() => {
                      setQuery({ ...query, owner: null, page_number: 1 });
                      setShowUserChallenges(false);
                    }}
                  />
                  <HolosButton
                    className={
                      showUserChallenges
                        ? "purple-rounded-corners"
                        : "transparent-rounded-corners"
                    }
                    text={{
                      name: "Mis desafíos",
                      className: showUserChallenges
                        ? "bold medium white-icon-text"
                        : "bold medium black-icon-text",
                    }}
                    onClick={() => {
                      setQuery({ ...query, owner: "user", page_number: 1 });
                      setShowUserChallenges(true);
                    }}
                  />
                </div>
              </React.Fragment>
            }
          />
          {
            <DeleteRequest
              showAlert={showDeleteAlert}
              setShowAlert={setShowDeleteAlert}
              onClick={() => setShowDeleteAlert(false)}
            />
          }
          {
            <DeactivateHolosAlert
              showAlert={showDeactiveAlert}
              setShowAlert={setShowDeactiveAlert}
              onClick={() => setShowDeactiveAlert(false)}
            />
          }
          <HolosPagination
            items={challenges}
            pageCount={query.page_count}
            pageNumber={query.page_number}
            setPageNumber={(e) => {
              setQuery({ ...query, page_number: e });
            }}
            itemsPerPage={query.page_size}
            showGoToPage={true}
          />
        </div>
      }
    />
  );
};

export default ChallengesList;