import { Visibility } from "@material-ui/icons";
import React, { useState } from "react";
import HolosButton from "../../../../../../../../../../../../shared/components/holosButton";
import EffectModal from "../../../effectModal";
import "./../../styles.scss";

const MainSpine = () => {
  const [showEffectModal, setShowEffectModal] = useState(false);

  return (
    <div className="main-spine">
      <div className="spine"></div>
      <div className="effect-container">
        <HolosButton
          className="transparent-rounded-corners"
          text={{
            name: "Efecto",
            className: "bold medium black-icon-text",
          }}
          onClick={() => {}}
        />
        <EffectModal
          setShowModal={setShowEffectModal}
          showModal={showEffectModal}
        />
        <div
          className="effect"
          onClick={() => {
            setShowEffectModal(true);
          }}
        >
          <Visibility />
        </div>
      </div>
    </div>
  );
};

export default MainSpine;
