import React, { useState } from "react";
import HolosAlert from "../../../../../../../../shared/components/holosAlert";
import HolosButton from "../../../../../../../../shared/components/holosButton";
import IconSelect from "../../../../../../../../shared/components/iconSelect/iconSelect";
import IconText from "../../../../../../../../shared/components/iconText";

const AlertToAddInternal = ({
  promiseOptions,
  showAddInternalAlert,
  setShowAddInternalAlert,
  addMember,
}) => {
  const addNewMember = () => {
    setMember({ ...member, show: false });
    addMember(member);
    setShowAddInternalAlert(false);
    setMember(null);
  };
  const [member, setMember] = useState(null);

  return (
    <div>
      <HolosAlert
        showAlert={showAddInternalAlert}
        setShowAlert={setShowAddInternalAlert}
        title={
          <React.Fragment>
            <IconText
              icon={""}
              text={"Nuevo integrante interno"}
              className="small bold"
            />
          </React.Fragment>
        }
        content={
          <form>
            <div className="form-group">
              <label htmlFor="member" className="form-label small bold">
                Nombre *
              </label>
              <IconSelect
                className="white-background"
                placeholder="Busque un integrante"
                id="member"
                name="member"
                loadOptions={(value) =>
                  promiseOptions({ name: value, type: "internal" })
                }
                onChange={(e) => {
                  setMember(e);
                }}
              />
            </div>{" "}
            <div className="alert-add-internal">
              <div className="mb-medium ">
                <div htmlFor="email" className="mb-small small bold">
                  Mail
                </div>
                <div className="white-background">
                  {member?.email && member?.email[0]}
                </div>
              </div>{" "}
              <div>
                <div htmlFor="phone" className="mb-small small bold">
                  Telefono
                </div>
                <div className="white-background">{member?.contactPhone}</div>
              </div>
            </div>
          </form>
        }
        actions={[
          <HolosButton
            key={1}
            disabled={member ? false : true}
            separatingMargin={"1em"}
            className="purple-background"
            text={{ name: "Agregar", className: "white-icon-text" }}
            onClick={() => addNewMember()}
          />,
        ]}
      ></HolosAlert>
    </div>
  );
};
export default AlertToAddInternal;

