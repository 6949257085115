import React, { useEffect, useState } from "react";

import {
  BorderColorOutlined,
  LocationOnOutlined,
  Map,
} from "@material-ui/icons";
import BaseBody from "../../../../shared/components/baseBody";

import "./styles.scss";

import Request from "../../services/request";
import { tabs } from "../constants";
import QRCode from "./../../../../assets/qr";
import HolosList from "../../../../shared/components/holosList";
import { Link } from "react-router-dom";
import { removeAccents } from "../../../../utils/helpers";

const headers = [
  {
    icon: <Map />,
    text: "Datos",
    class: "data",
  },
  {
    icon: <LocationOnOutlined />,
    text: "Mapa",
    class: "image",
  },
];

const getListItems = (items) => {
  let listItems = [];
  items &&
    items.forEach((item) => {
      listItems.push(
        <React.Fragment>
          <div className="item-description">
            <div className="description-row">
              <div className="description-icon">
                <LocationOnOutlined />
              </div>
              <div className="description-content">
                <div className="description-title">Ubicación</div>
                <Link to={`/maps/preview/${item._id}`}>
                  <div className="data-body-content">{item.name} </div>
                </Link>
              </div>
            </div>
            <div className="description-row">
              <div className="description-icon"><QRCode className={"opacity-54"} /></div>

              <div className="description-content">
                <div className="data-body-content">
                  {item.plaques ? item.plaques.length : 0} placas QR
                </div>
              </div>
            </div>
          </div>
          <div
            className="map"
            style={{
              backgroundImage: `url(${encodeURI(item.staticImageUrl)})`,
              height: "150px",
            }}
          ></div>
          <div className="actions">
            <Link to={`/maps/edit/${item._id}`}>
              <BorderColorOutlined fillOpacity={1} style={{ opacity: 0.54 }} />
            </Link>
          </div>
        </React.Fragment>
      );
    });

  return listItems;
};

const ListMaps = (props) => {
  const [maps, setMaps] = useState(null);
  const [filter, setFilter] = useState([]);
  const [currentSearch, setCurrentSearch] = useState("");

  useEffect(() => {
    const getMaps = async () => {
      const maps = (await Request.getMaps()).body;
      if (maps) setMaps(maps);
      else setMaps(null);
    };

    filterByName(maps, currentSearch, setFilter);

    if (!maps) getMaps();
  }, [maps, currentSearch]);

  return (
    <React.Fragment>
      <BaseBody
        tabsBody={tabs}
        bodyIcon={<LocationOnOutlined />}
        bodyTitle={"Mapas activos"}
        children={
          <div className="maps-new">
            <HolosList
              gridTemplateColumns={"10% 25% 57.5% 12.5%"}
              rows={getListItems(filter)}
              headers={headers}
              showFilterBar={true}
              icon={<LocationOnOutlined />}
              searchFilter={(e) => onChange(e, setCurrentSearch)}
              currentSearch={currentSearch}
            />
          </div>
        }
      />
    </React.Fragment>
  );
};

// filter regardless of capital letters and accents
const filterByName = (items, text, setFilter) => {
  if (text === "") return setFilter(items);
  const textWithoutAccent = removeAccents(text).toLowerCase();

  const filter = items.filter((item) =>
    removeAccents(item.name.toLowerCase()).includes(textWithoutAccent)
  );

  return setFilter(filter);
};

const onChange = (e, setCurrentSearch) => {
  setCurrentSearch(e.currentTarget.value);
};

export default ListMaps;
