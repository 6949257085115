import React, { useContext, useEffect, useState } from "react";
import HolosAlert from "../../../../../../../../../../shared/components/holosAlert";
import HolosButton from "../../../../../../../../../../shared/components/holosButton";
import IconText from "../../../../../../../../../../shared/components/iconText";
import * as helpers from "../../../../../../../../../../utils/helpers";
import Request from "../../../../../../../../../users/services/request";
import Users from "../../../../../../../../../../services/globalApi/users";
import FirstStep from "./parts/firstStep";
import { Loading } from "../../../../../../../../../../context";

import "./styles.scss";
import { getTranslate } from "../../../../../../../../../../i18n/translate";
import SecondStep from "./parts/secondStep";

// import { getTranslate } from "../../../../../../../../../../../../i18n/translate";

function Step({ component, required, label, onClick }) {
  return {
    component: component,
    required,
    button: {
      label,
      onClick,
    },
  };
}

const emptyMember = {
  firstName: "",
  secondName: "",
  firstLastName: "",
  secondLastName: "",
  rut: "",
  idGender: "",
  email: "",
  contactPhone: "",
  contractorRUT: "",
  contractorName: "",
  origin: "external",
  circleCreation: true,
};

const AlertSecondStepExternal = ({
  genders,
  isOpen,
  setIsOpen,
  addMember,
  setShowAddExternalAlert,
}) => {
  const [currentStep, setCurrentStep] = useState("firstStep");
  const [, setLoading] = useContext(Loading);
  const [contractorExist, setContractorExist] = useState(false);
  const [member, setMember] = useState(emptyMember);
  const previousMember = helpers.usePrevious(member);
  const [errorAlert, setErrorAlert] = useState({ show: false, message: "" });

  const fieldValidation = {
    firstName: (name) => helpers.isEmptyString(name),
    firstLastName: (name) => helpers.isEmptyString(name),
    rut: (rut) => !helpers.validRUT(rut),
    idGender: (idGender) => helpers.isEmptyString(idGender),
    email: (email) => !helpers.validEmail(email),
    contactPhone: (contactPhone) => helpers.isEmptyString(contactPhone),
    contractorRUT: (contractorRUT) =>
      !helpers.validRUT(contractorRUT, "contractor"),
    contractorName: (contractorName) => helpers.isEmptyString(contractorName),
  };

  const [showErrors, setShowErrors] = useState(false);
  const errors = helpers.useValidation(member, fieldValidation);

  const setMemberData = (e) => {
    const { name, value } = e.currentTarget || e;
    setMember((member) => ({ ...member, [name]: value }));
  };

  useEffect(() => {
    if (
      helpers.validRUT(member.contractorRUT, "contractor") &&
      previousMember?.contractorRUT !== member.contractorRUT
    ) {
      (async () => {
        setLoading(true);
        const contractor = await Request.validateContractorRut(
          member.contractorRUT
        );
        setLoading(false);

        if (contractor?.status !== 200) return;

        if (Object.keys(contractor?.body)?.length > 0) {
          setMemberData({
            name: "contractorName",
            value: contractor?.body?.name,
          });
          setContractorExist(true);
          return;
        }
        setMemberData({ name: "contractorName", value: "" });
        setContractorExist(false);
      })();
    }
  }, [errors, setLoading, member, previousMember]);

  const steps = {
    firstStep: new Step({
      component: FirstStep({
        genders,
        member,
        setMemberData,
        errors,
        showErrors,
      }),
      required: ["name", "rut", "idGender", "firstName", "firstLastName"],
      label: "Continuar",
      onClick: () => {
        setCurrentStep("secondStep");
      },
    }),
    secondStep: new Step({
      component: SecondStep({
        member,
        setMemberData,
        errors,
        showErrors,
        contractorExist,
        setContractorExist,
      }),
      required: ["email", "contactPhone", "contractorRUT", "contractorName"],
      label: "Agregar",
      onClick: () => {
        createExternalMember();
      },
    }),
  };

  const generateFormData = (data) => {
    const formdata = new FormData();

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        formdata.set(key, element);
      }
    }

    return formdata;
  };

  const closeAlerts = () => {
    setIsOpen(false);
    setShowAddExternalAlert();
  };

  const createExternalMember = async () => {
    const data = generateFormData(member);
    const res = await Users.createPerson(data);

    if (res?.status === 200) {
      const person = res.body;
      const parsed = helpers.parseForSelect([{ ...person }]);
      addMember({ ...parsed[0] });
      setMember(emptyMember);
      closeAlerts();
      return
    }

    closeAlerts();
    setErrorAlert({ show: true, message: getTranslate(res?.internalCode) });
  };

  const handleClick = () => {
    setShowErrors(true);
    const { required } = steps[currentStep];

    const stepErrors = Object.keys(errors).filter(function (key) {
      if (this.indexOf(key) > -1 && errors[key]) return true;
      return false;
    }, required);

    if (!stepErrors.length) {
      setShowErrors(false);
      return steps[currentStep].button.onClick();
    }
  };

  return (
    <div>
      <HolosAlert
        showAlert={isOpen}
        setShowAlert={(state, action) => {
          setMember(emptyMember);
          setIsOpen(state, action);
        }}
        title={
          <React.Fragment>
            <IconText icon={""} text={"Crear persona"} className="small bold" />
          </React.Fragment>
        }
        content={steps[currentStep].component}
        actions={[
          <HolosButton
            key={1}
            separatingMargin={"1em"}
            className="purple-background"
            text={{
              name: steps[currentStep].button.label,
              className: "white-icon-text",
            }}
            onClick={handleClick}
          />,
        ]}
      ></HolosAlert>{" "}
      <HolosAlert
        showAlert={errorAlert.show}
        setShowAlert={() => {
          setErrorAlert((alert) => ({ ...alert, show: !alert.show }));
        }}
        title={"Error al crear el miembro"}
        content={errorAlert.message}
      ></HolosAlert>{" "}
    </div>
  );
};
export default AlertSecondStepExternal;
