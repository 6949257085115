import React from "react";

const QRCode = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
      className={`QRCode-icon ${className}`}
    >
      <path
        d="M13.9001 10.0452V3.50012H19.5001C20.0001 3.50012 20.4001 3.90012 20.4001 4.40012V10.0001L13.9001 10.0452Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M10.1001 10.1001V3.6001H4.5001C4.0001 3.6001 3.6001 4.0001 3.6001 4.5001V10.1001H10.1001Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M10.1001 13.8999V20.3999H4.5001C4.0001 20.3999 3.6001 19.9999 3.6001 19.4999V13.8999H10.1001Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M15.7001 13.8999H13.9001V16.0999"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.3999 15.9999V13.8999H16.0999"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.0999 20.4H19.4999C19.9999 20.4 20.3999 20 20.3999 19.5V18.2"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.9001 18.2V20.4H15.7001"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.1999 16.1H16.0999V18.2H18.1999V16.1Z"
        fill="black"
      />
    </svg>
  );
};

export default QRCode;
