import {
  Clear,
  DateRangeSharp,
  DonutLargeOutlined,
  Map,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import HolosButton from "../../../../shared/components/holosButton";
import IconText from "../../../../shared/components/iconText";
import { Modal } from "../../../../shared/components/modal";
import GoogleMap from "../../../../shared/components/googleMap";

import "./mapModal.scss";
import HolosList from "../../../../shared/components/holosList";
import qr from "./../../../../assets/icons/qr.svg";

/**
 * A component to display Google Maps on modal
 *
 * @param {object} props Props passed to the current component
 * @param {boolean} props.showModal Indicates if the modal is open or not
 * @param {Function} props.setShowModal Function to change showModal var
 * @param {Array<object>} props.area Array of points { lat, lng } corresponding to the polygon
 * @param {Array<object>} props.markers Array of markers to shown in map
 * @param {String} props.currentMarker ID of the highlighted marker
 * @param {String} props.currentMarkerIconURL Icon of the highlighted marker
 * @param {object} props.center Object { lat, lng } that indicates the initial center of the map
 * @param {String} props.mode The mode in which the map will be displayed
 *
 */
export const MapModal = ({
  showModal,
  setShowModal,
  callback,
  area,
  markers,
  markerIconURL,
  currentMarker,
  currentMarkerIconURL,
  center,
  mode,
}) => {
  const [current, setCurrent] = useState(null);

  // useEffect used to unset current marker on modal dismiss
  useEffect(() => {
    if (!showModal) setCurrent(null);
    else
      setCurrent((current) => {
        if (current !== currentMarker) return currentMarker;
      });
  }, [showModal, currentMarker]);

  const getListRows = () => {
    let rows = [];
    markers && markers.forEach((marker, index) => {
      if (marker.name === "Nueva placa QR") return;
      rows.push({
        row: [
          <React.Fragment key={index}>
            <div className="map-modal-item">{marker.name}</div>
            <div className="map-modal-item">{marker.circleName}</div>
            <div className="map-modal-item">{marker.createdAt}</div>
          </React.Fragment>,
        ],
        id: marker.id,
      });
    });
    return rows;
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      cssClass={"MapModal"}
      className={"MapModal"}
      title={
        <div className="map-modal-title">
          <IconText
            icon={<Map />}
            text={"Elegir ubicación"}
            iconOpacity={0.54}
            fontColor={"#444444"}
          />
          <Clear
            onClick={() => setShowModal(false)}
            style={{ opacity: 0.54, fontSize: "18px", cursor: "pointer" }}
          />
        </div>
      }
      content={
        <React.Fragment>
          <div className="map-modal-gmaps">
            <GoogleMap
              mode={mode}
              isModalShowed={showModal}
              currentMarker={current}
              setCurrentMarker={setCurrent}
              markerIconURL={markerIconURL}
              currentMarkerIconURL={currentMarkerIconURL}
              area={area}
              markers={markers}
              callback={callback}
              options={{ center, zoom: 4 }}
            />
          </div>
          <div className="map-modal-list">
            <HolosList
              setCurrent={setCurrent}
              headers={[
                { icon: qr, text: "Placa QR", class: "map-modal-header" },
                {
                  icon: <DonutLargeOutlined />,
                  text: "Círculo",
                  class: "map-modal-header",
                },
                {
                  icon: <DateRangeSharp />,
                  text: "Fecha de creación",
                  class: "map-modal-header",
                },
              ]}
              showFilterBar={false}
              gridTemplateColumns={"33% 33% 33%"}
              onMouseEnter={(id) => setCurrent(id)}
              onMouseLeave={(id) => setCurrent(id)}
              rows={getListRows()}
              showRowsColor={true}
            />
          </div>
        </React.Fragment>
      }
      actions={[
        <HolosButton
          key={new Date()}
          width={"fit-content"}
          background={{
            color: "#742054",
          }}
          text={{
            name: "Elegir ubicación",
            color: "#ffffff",
          }}
          onClick={() => setShowModal(false)}
        />,
      ]}
    ></Modal>
  );
};
