import React, { useContext, useEffect, useState } from "react";
import {
  Fingerprint,
  LocationOnOutlined,
  LooksOne,
  LooksTwo,
  SearchOutlined,
} from "@material-ui/icons";
import moment from "moment";

import { Loading } from "../../../../context";
import RequestMap from "./../../services/request";
import { isInsideArea } from "../../../../utils/helpers";

import GoogleMap from "../../../../shared/components/googleMap";
import HolosAlert from "../../../../shared/components/holosAlert";
import HolosButton from "../../../../shared/components/holosButton";
import HolosInput from "../../../../shared/components/holosInput";
import IconText from "../../../../shared/components/iconText";

import qr from "../../../../shared/components/googleMap/icons/QR.png";
import "./styles.scss";

const getCenter = (e, setCenter) => {
  const coords = e.currentTarget.value.split(",");
  const lat = parseFloat(coords[0]);
  const lng = parseFloat(coords[1]);

  if (!lat || !lng) return setCenter(null);

  return setCenter({ lat, lng });
};

const NewMap = (props) => {
  const [name, setName] = useState("");
  const [center, setCenter] = useState(null);
  const [areaCoords, setAreaCoords] = useState([]);
  const [oldAreaCoords, setOldAreaCoords] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({});
  const [isError, setIsError] = useState(false);
  const [plaques, setPlaques] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [centerSearch, setCenterSearch] = useState("");

  const [, setLoading] = useContext(Loading);
  const { id } = props.match.params;

  const handleMap = async () => {
    if (!areaCoords[0]) {
      setIsError(true);
      setLoading(false);
      return;
    }

    if (!name.length || areaCoords.length < 3) {
      setIsError(true);
      setLoading(false);

      return;
    }

    setIsError(false);

    let res;
    if (!id) {
      res = await RequestMap.createMap({ name, areaCoords });
    } else {
      res = await RequestMap.editMap(id, { name, areaCoords });
    }
    setShowAlert(true);
    setLoading(false);

    if (res.status === 200) {
      return setAlertContent({
        title: `Mapa ${id ? "editado" : "creado"} exitosamente`,
        type: "success",
        actions: [
          <HolosButton
            separatingMargin={"1em"}
            background={{ color: "var(--purpleBHP)" }}
            text={{ name: "Ir al mapa", color: "white", colorHover: "white" }}
            onClick={() => {
              setShowAlert(false);
              props.history.push(`/maps/preview/${res.body._id}`);
            }}
          />,
        ],
      });
    }

    return setAlertContent({
      title: `No se ha podido ${id ? "editar" : "crear"} el mapa`,
      message: "Probablemente falte agregar algún dato",
      type: "error",
      actions: [
        <HolosButton
          separatingMargin={"1em"}
          background={{ color: "var(--purpleBHP)" }}
          text={{ name: "Volver", color: "white", colorHover: "white" }}
          onClick={() => {
            setShowAlert(false);
          }}
        />,
      ],
    });
  };

  useEffect(() => {
    id &&
      (async () => {
        const map = (await RequestMap.getMap(id)).body;
        if (map) {
          setPlaques(map.plaques);
          setName(map.name);
          //set only at the beginning, in case you want to use it in the future.
          setOldAreaCoords(JSON.parse(JSON.stringify(map.areaCoords)));

          setAreaCoords(map.areaCoords);
        }
      })();
  }, [id]);

  const validAreaCord = (area) => {
    if (area?.length && plaques?.length && !isInsideArea(area, plaques)) {
      setShowAlert(true);
      setAlertContent({
        title: "No es posible dejar placas QR fuera del área",
        message: "Por favor, dibujar el área nuevamente",
        type: "error",
      });
      setAreaCoords(oldAreaCoords);
      return;
    }
    setAreaCoords(area);
  };

  useEffect(() => {
    if (!isEditing) return setIsError(false);
  }, [isEditing, areaCoords]);

  
  return (
    <div className="New-map">
      <div className="title-container">
        <IconText
          icon={<LocationOnOutlined />}
          iconColor={"var(--black)"}
          iconOpacity={0.54}
          text={"Ingresa los datos del nuevo mapa"}
          fontSize={"16px"}
          fontWeight={"600"}
          separatingMargin={"0.813em"}
        />
      </div>
      <div className="inputs-container">
        <HolosInput
          backgroundColor={"var(--softWhite)"}
          className="white-background"
          placeholder={"Nombre del nuevo mapa"}
          icon={<Fingerprint />}
          onChange={(e) => setName(e.target.value)}
          value={name}
          isError={isError}
        />
      </div>
      <div className="new-map-area">
        <div className="area-container-title">
          <IconText
            icon={<LocationOnOutlined />}
            text={"Ubicación del nuevo mapa"}
            separatingMargin={"1em"}
            iconOpacity={"0.54"}
          />
        </div>
        <div className="step-one">
          <div className="description">
            <LooksOne />
            <span>
              Posicionate en una ubicación conocida. Solo puedes utilizar
              <b> un tipo</b> de posicionamiento.
            </span>
          </div>
        </div>
        <div className="location-search">
          <HolosInput
            placeholder={"Buscar ubicación"}
            className="white-background"
            icon={<SearchOutlined />}
            onChange={(e) => {
              setAreaCoords([]);
              setCenter("");
              setCenterSearch("")
            }}
            id={"location-search"}
          />
          <div className="coordinates">
            <IconText
              text={"Coordenadas: "}
              iconOpacity={0.54}
              separatingMargin={"1em"}
            />
            <HolosInput
              width={"10em"}
              placeholder={"37.755, -122.443"}
              value={centerSearch}
              onChange={(e) => {
                const input = document.getElementById("location-search");
                input.value = ""
                setAreaCoords([]);
                setCenterSearch(e.currentTarget.value);
                getCenter(e, setCenter);
              }}
            />
          </div>
        </div>
        <div className="step-two">
          <div className="description">
            <LooksTwo />
            <span>
              <b>Dibuja un area </b>
              con los limites del nuevo mapa.
            </span>
          </div>
        </div>
        <div className="gmaps-card" style={{ height: "28em" }}>
          <GoogleMap
            mode="draw"
            center={center}
            markers={plaques?.map((plaque) => ({
              type: "plaque",
              id: plaque._id,
              name: plaque.name,
              images: plaque.images,
              createdAt: moment.utc(plaque.createdAt).format("DD-MM-YYYY"),
              circleName: plaque.circleName,
              coords: {
                lat: parseFloat(plaque.latitude),
                lng: parseFloat(plaque.longitude),
              },
            }))}
            markerIconURL={qr}
            callback={(area) => {
              validAreaCord(area);
            }}
            area={areaCoords}
            setIsEditing={setIsEditing}
          />
          {isError && (
            <span className={"invalid-area"}>
              El área debe estar formada por al menos tres puntos
            </span>
          )}
          {isEditing && (
            <span className={"invalid-area"}>
              Debe finalizar el dibujo del área del mapa para poder continuar
            </span>
          )}
        </div>
        <div className="new-button">
          <HolosButton
            disabled={isEditing}
            text={{
              name: `${!id ? "Crear" : "Editar"} mapa `,
              className: "white-icon-text bold",
              icon: <LocationOnOutlined />,
            }}
            className={"purple-background"}
            onClick={() => {
              setLoading(true);
              handleMap();
            }}
          />
        </div>
      </div>
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={(isOpen, typeShow) => {
          setShowAlert(isOpen);
          if (typeShow === "close") {
            props.history.push("/maps/list");
          }
        }}
        title={alertContent.title}
        content={alertContent.message}
        type={alertContent.type}
        actions={alertContent.actions}
      />
    </div>
  );
};

export default NewMap;
