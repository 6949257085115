import React from "react";
import HolosAlert from "../holosAlert";
import HolosButton from "../holosButton";

import "./styles.scss";

const DeactivateHolosAlert = ({ showAlert, setShowAlert, onClick }) => {
  return (
    <HolosAlert
      showAlert={showAlert}
      setShowAlert={setShowAlert}
      type={"warning"}
      title={"Desactivar"}
      content={
        <div>
          <span> Esta acción quedara pendiente de aprobación por PMO.</span>
        </div>
      }
      actions={[
        <HolosButton
          key={1}
          className="purple-background"
          text={{
            name: "Desactivar",
            className: "bold medium white-icon-text",
          }}
          onClick={onClick}
        />,
      ]}
    />
  );
};

export default DeactivateHolosAlert;
