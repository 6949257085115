import React from "react";
import "./styles.scss";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

/**
 * A component to display a link with some text before
 *
 * @param {object} props Props passed to the current component
 * @param {boolean} props.noIcon Boolean to not show the icon 
 * @param {function} props.onClick Method onClick with event when click
 */

const GoBack = ({ noIcon, onClick }) => {
  return (
    <div className="Go-back" onClick={onClick}>
      {!noIcon && <ArrowLeft className="Arrow-left" />}
      <span>Volver</span>
    </div>
  );
};

export default GoBack;
