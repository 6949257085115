import {
  DoneAll,
  EmojiEventsOutlined,
  FormatListNumbered,
  ShortText,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import CausesIcon from "../../../../../../assets/icons/causesIcon";
import SolutionsIcon from "../../../../../../assets/icons/solutionsIcon";
import FormSideBar from "../../../../../../shared/components/formSidebar";
import ChallengesActionPlan from "./parts/actionPlan";
import ChallengesCauses from "./parts/causes";
import ChallengesClosure from "./parts/closure";
import ChallengesDefinition from "./parts/definition";
import ChallengesRecognitions from "./parts/recognitions";
import ChallengesSolutions from "./parts/solutions";

import * as helpers from "../../../../../../utils/helpers";
import Request from "../../../../services/request";

import "./styles.scss";
import { useParams } from "react-router";
import { getErrorMessage } from "../../../../../../services/errors";
import HolosAlert from "../../../../../../shared/components/holosAlert";

const EditChallenge = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", message: "" });
  const [view, setView] = useState("ChallengeSolutions");
  const { id } = useParams();

  const tabs = [
    { name: "Definición", view: "ChallengeDefinition", icon: <ShortText /> },
    {
      name: "Causas",
      view: "ChallengeCauses",
      icon: <CausesIcon />,
    },
    {
      name: "Solución",
      view: "ChallengeSolutions",
      icon: <SolutionsIcon />,
    },
    {
      name: "Plan de acción",
      view: "ChallengeActionPlan",
      icon: <FormatListNumbered />,
    },
    { name: "Cierre", view: "ChallengeClosure", icon: <DoneAll /> },
    {
      name: "Reconocimiento",
      view: "ChallengeRecognitions",
      icon: <EmojiEventsOutlined />,
    },
  ];

  const [challenge, setChallenge] = useState({});
  const [causeCategories, setCauseCategories] = useState([]);
  const setChallengeData = (e) => {
    const { name, value } = e.currentTarget || e;
    setChallenge((challenge) => ({ ...challenge, [name]: value }));
  };

  const onButtonTapClick = (viewName) => {
    setView(viewName);
  };

  const showSideBar = () => {
    return (
      <div className="new-challenge-steps">
        <FormSideBar
          onClick={onButtonTapClick}
          currentView={view}
          tabs={tabs}
        />
      </div>
    );
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      const res = await Request.getChallenge(id);
      if (res?.status !== 200) {
        return helpers.redirect("/circles/challenges");
      }
      setChallenge(res?.body);
      console.log("Datos del desafio---->", res?.body);
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      const res = await Request.getCauseCategoriesList();
      if (res.status !== 200) {
        setAlertContent({
          type: "error",
          title: "Error",
          message: getErrorMessage("GNRL1001"),
        });
        setShowAlert(true);
        return;
      }
      let list = res?.body?.map((category) => {
        category.label = helpers.capitalizeFirstLetter(category?.name);
        category.value = category?.name;
        return category;
      });
      list.unshift({ label: "Todas las categorías", value: 0, _id: 0 });
      setCauseCategories(list);
    })();
  }, []);

  const components = {
    ChallengeDefinition: ChallengesDefinition({
      setChallengeData,
      challenge,
      setView,
    }),
    ChallengeCauses: ChallengesCauses({
      setChallengeData,
      challenge,
      causeCategories,
      setView,
    }),
    ChallengeSolutions: ChallengesSolutions({
      setChallengeData,
      challenge,
      setView,
    }),
    ChallengeActionPlan: ChallengesActionPlan({
      setChallengeData,
      challenge,
      setView,
    }),
    ChallengeClosure: ChallengesClosure({
      setChallengeData,
      challenge,
      setView,
    }),
    ChallengeRecognitions: ChallengesRecognitions({
      setChallengeData,
      challenge,
      setView,
    }),
  };
  return (
    <div className="New-challenge">
      {showSideBar()}
      <div className="new-challenge-container">
        <div className="new-challenge-content">{components[view]}</div>
      </div>
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        title={alertContent.title}
        content={alertContent.message}
        type={alertContent.type}
      />
    </div>
  );
};
export default EditChallenge;
