import React, { useContext } from "react";
import GoBack from "../goBack";
import HolosButton from "../holosButton";
import { Loading } from "../../../context";

import "./styles.scss";
const FormButtonStepper = ({
  disabled,
  onSubmit,
  showGoBack,
  showSkip,
  skipTo,
  goBackView,
  setView,
  nameButton,
  otherButton,
  className,
}) => {
  const [loading] = useContext(Loading);

  const getClass = () => {
    let name = "Button-stepper";
    if (className) name = `${name} ${className}`;
    return name;
  };

  return (
    <React.Fragment>
      <div className={getClass()}>
        {(showGoBack && (
          <GoBack onClick={() => setView(goBackView)} noIcon={true} />
        )) || <div></div>}
        {(showSkip && (
          <HolosButton
            text={{ name: "Omitir", className: "small" }}
            onClick={() => setView(skipTo)}
            className={"transparent-background beside-next"}
          />
        )) || <div></div>}
        <div className="form-stepper-cta">
          {otherButton && otherButton}
          <HolosButton
            className="purple-background continue-button"
            text={{
              name: nameButton || "Continuar",
              className: "continue-button bold white-icon-text",
            }}
            type="submit"
            width="100%"
            disabled={loading || disabled}
            onClick={onSubmit}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FormButtonStepper;
