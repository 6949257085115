import React from "react";
import { Link } from "react-router-dom";

function IndividualCard({ title, text, icon, path, flex }) {
  return (
    <Link to={path} className="dashboard-card-container" style={{flex: flex}}>
      <div className="dashboard-card">
        {icon}
        <div className="dashboard-card-content">
          <div className="dashboard-card-title medium bold">{title}</div>
          <div className="dashboard-card-slogan small">{text}</div>
          <div />
        </div>
      </div>  
    </Link>
  );
}

export default IndividualCard;
