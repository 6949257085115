import IconText from "../../../iconText";
import React, { useEffect, useState } from "react";

const DropdownItem = ({
  name,
  icon,
  path,
  onClick,
  showDropdown,
  className,
  setShowDropdown,
}) => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (showDropdown && clicked) {
      setShowDropdown(false);
      setClicked(false)
    }
  }, [clicked, showDropdown, setShowDropdown]);

  const handleClick = async () => {
    setClicked(true)
    if (onClick) return onClick() 
    return window.location.assign(path)
  }
  return (
    <div
      className={`purple-hover ${className ? className : ""}`}
      onClick={handleClick}
    >
      <IconText
        className={"system-item small lessBold purple-hover"}
        text={name}
        icon={icon}
        separatingMargin={"16px"}
      />
    </div>
  );
};

export default DropdownItem;
