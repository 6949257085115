import React from "react";
import {
  DonutLargeOutlined,
  EmojiEvents,
  LocationOnOutlined,
} from "@material-ui/icons";
import QRCode from "../../../../assets/qr";

export const headers = [
  {
    text: "Acciones",
    class: "actions",
  },
  {
    icon: <QRCode />,
    text: "Placa",
    class: "plaque",
  },
  {
    icon: <LocationOnOutlined />,
    text: "Mapa",
    class: "map",
  },
  {
    icon: <DonutLargeOutlined />,
    text: "Círculo",
    class: "circle",
  },
  {
    icon: <EmojiEvents />,
    text: "Reconocimiento",
    class: "reconocimiento",
  },
];

export const itemsPerPageRef = [
  { label: "Mostrar 20 filas", value: 20, id: 1 },
  { label: "Mostrar 10 filas", value: 10, id: 2 },
  { label: "Mostrar 5 filas", value: 5, id: 3 },
];
