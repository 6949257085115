import React, { useContext, useState } from "react";
import PasswordData from "./parts/createPassword";
import PersonalData from "./parts/personalData";
import ProfilePictureData from "./parts/profilePictureData";
import JobData from "./parts/jobData";
import "./styles.scss";
import Email from "./parts/email";
import CompanySelect from "./parts/companySelect";
import { useHistory } from "react-router";
import { Loading } from "../../../../context";
import HolosAlert from "../../../../shared/components/holosAlert";
import Request from "../../services/request";
import Support from "../../../../shared/components/support";
import HolosLink from "../../../../shared/components/holosLink/holosLink";
import FormSideBar from "../../../../shared/components/formSidebar";

const Register = () => {
  //Alert
  const [showEmailAlert, setShowEmailAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showToast, setshowToast] = useState(false);
  const timeToast = 2000;
  const [isOpenSupport, setIsOpenSupport] = useState(false);

  const [, setLoading] = useContext(Loading);
  const history = useHistory();

  const [view, setView] = useState("CompanySelect");
  const [person, setPerson] = useState({
    idOperation: "",
    origin: "",
    //personalData
    email: "",
    rut: "",
    firstName: "",
    secondName: "",
    firstLastName: "",
    secondLastName: "",
    birthDate: "",
    idGender: "",
    contactPhone: "",
    //Job
    masterCode: "",
    idGroup: "",
    idWorkSchedule: "",
    idArea: "",
    idLeader: "",
    // idContractor: "",
    contractorName: "",
    contractorRUT: "",
    managerCode: "",
    //profilePicture
    photo: "",
    //security
    rol: "",
    password: "",
    repeat: "",
  });

  const tabs = [
    { name: "Datos personales", view: "PersonalData" },
    { name: "Datos laborales", view: "JobData" },
    { name: "Foto de perfil", view: "ProfilePictureData" },
    { name: "Seguridad", view: "PasswordData" },
  ];

  const setPersonData = (e) => {
    const { name, value } = e.currentTarget || e;
    setPerson((person) => ({ ...person, [name]: value }));
  };

  const generateFormData = () => {
    const formdata = new FormData();
    for (const key in person) {
      if (Object.hasOwnProperty.call(person, key)) {
        const element = person[key];
        const value = key === "birthDate" ? element.toISOString() : element;
        formdata.set(key, value);
      }
    }

    return formdata;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = generateFormData(person);
    setLoading(true);
    const res = await Request.createAccount(formData);
    setLoading(false);

    if (res.status === 200) {
      setShowEmailAlert(true);
    }
    if (res.status !== 200) {
      setShowErrorAlert(true);
    }
  };

  const onResend = async () => {
    setLoading(true);
    const body = {
      email: person?.email,
      idOperation: "mockup",
    };
    const res = await Request.resendActivateAccount(body);
    if (res.status === 200) {
      setshowToast(true);
      setTimeout(() => {
        history.push("/landingpage");
      }, timeToast);
    }
    setLoading(false);
  };

  const onButtonTapClick = (viewName) => {
    setView(viewName);
  };

  const isInSteps = () => {
    const steps = [
      "PersonalData",
      "JobData",
      "ProfilePictureData",
      "PasswordData",
    ];
    return steps.includes(view);
  };

  const showSideBar = () => {
    if (!isInSteps()) return null;
    return (
      <div className="register-steps">
        <FormSideBar
          onClick={onButtonTapClick}
          currentView={view}
          tabs={tabs}
          isLogo
          footer={
            <HolosLink
              className="Holos-link-column"
              text="¿Necesitas ayuda?"
              link={
                <Support isOpen={isOpenSupport} setIsOpen={setIsOpenSupport} />
              }
              linkText={"Contacta a soporte"}
              onClick={() => setIsOpenSupport(true)}
            />
          }
        />
      </div>
    );
  };

  const components = {
    CompanySelect: CompanySelect({ setPersonData, person, setView }),
    Email: Email({ setPerson, setPersonData, person, setView }),
    PersonalData: PersonalData({ setPersonData, person, setView }),
    JobData: JobData({ setPersonData, person, setView }),
    ProfilePictureData: ProfilePictureData({ setPersonData, person, setView }),
    PasswordData: PasswordData({
      setPersonData,
      person,
      setView,
      onSubmit,
      onResend,
      showEmailAlert,
      showErrorAlert,
    }),
  };

  return (
    <div className={`Register ${isInSteps() ? "" : "grey-background"}`}>
      {showSideBar()}
      <div className="register-container">
        <div className="register-content">{components[view]}</div>
        <HolosAlert
          cssClass={`toast`}
          showAlert={showToast}
          setShowAlert={setshowToast}
          title={"Email de reactivación de cuenta enviado correctamente"}
          type={"success"}
          timeToast={timeToast}
        ></HolosAlert>
      </div>
    </div>
  );
};
export default Register;
