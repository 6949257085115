import React from "react";

import {
  AvTimer,
  CallMissedOutgoingOutlined,
  CompareArrowsOutlined,
} from "@material-ui/icons";


export const headers = [
  {
    text: "Acciones",
    class: "actions",
  },
  {
    icon: <CallMissedOutgoingOutlined />,
    text: "Desafío",
    class: "challenge",
  },
  {
    icon: <CompareArrowsOutlined />,
    text: "Apalancamiento",
    class: "leverage",
  },
  {
    icon: <AvTimer />,
    text: "Estado",
    class: "state",
  },
];
