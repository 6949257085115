import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

const InfoCard = ({ title, content, footer, link }) => {
  return (
    <div className="info-card">
      <Link to={link}>
        <div className="title">{title}</div>
        <div className="content">{content}</div>
      </Link>
      {footer && <div className="footer">{footer}</div>}
    </div>
  );
};

export default InfoCard;
