import React from "react";

import { AdjustOutlined, CategoryOutlined } from "@material-ui/icons";
import CausesIcon from "../../../../../../../../../../assets/icons/causesIcon";
import HolosButton from "../../../../../../../../../../shared/components/holosButton";

export const darkGrey = "#444444";

export const generateCausesHeaders = (onClick) => {
  const headers = [
    {
      text: "Acciones",
      class: "actions",
    },
    {
      icon: <CausesIcon />,
      text: "Causas",
      class: "causes",
      onClick: () => {
        onClick("cause");
      },
    },
    {
      icon: <CategoryOutlined />,
      text: "Categoría",
      class: "category",
      onClick: () => {
        onClick("category");
      },
    },
    {
      icon: <AdjustOutlined />,
      text: "Porque",
      class: "reason",
      onClick: () => {
        onClick("reason");
      },
    },
  ];
  return headers;
};

export const generateReasonsHeaders = (onClick) => {
  const headers = [
    {
      text: "",
      class: "ghost-column",
    },
    {
      icon: <AdjustOutlined />,
      text: "Porque",
      class: "reasons",
    },
    {
      class: "new-reason",
      text: (
        <HolosButton
          key={1}
          separatingMargin={"1em"}
          className="purple-background"
          text={{
            name: "Agregar",
            className: "white-icon-text",
          }}
          onClick={onClick}
        />
      ),
    },
  ];

  return headers;
};

export const itemsPerPageRef = [
  { label: "Mostrar 20 filas", value: 20, id: 1 },
  { label: "Mostrar 10 filas", value: 10, id: 2 },
  { label: "Mostrar 5 filas", value: 5, id: 3 },
];
