import React, { useState } from "react";
import FormButtonStepper from "../../../../../../shared/components/formButtonStepper";
import HolosButton from "../../../../../../shared/components/holosButton";
import CircleDataForm from "./parts/dataForm";
import "./styles.scss";

export default function CircleData(props) {
  const { setView, mode, discardEdit } = props;
  const [canContinue, setCanContinue] = useState(false);
  const [viewError, setViewError] = useState(false);

  const onSubmit = () => {
    setViewError(true);

    if (canContinue) {
      if (isContinueButton()) return setView("CircleTeam");
      return props?.createCircle();
    }
  };

  const isContinueButton = () => {
    if (mode === "edit") return false;
    return true;
  };

  return (
    <div className="Circle-data">
      <CircleDataForm
        setCanContinue={setCanContinue}
        viewError={viewError}
        {...props}
      />
      <FormButtonStepper
        onSubmit={onSubmit}
        nameButton={isContinueButton() ? "Continuar" : "Guardar"}
        className="buttons-flex-end"
        otherButton={
          mode === "edit" && (
            <HolosButton
              text={{ name: "Descartar", className: "small" }}
              //This is only to test error alert and then clean it
              onClick={discardEdit}
              className={"transparent-background beside-next"}
            />
          )
        }
      />
    </div>
  );
}
