import { IonPage } from "@ionic/react";
import React from "react";
import AppBar from "../../../shared/components/appBar";
import SideBar from "../../../shared/components/sideBar";
import moduleIcon from "../../../assets/icons/qrPurplex24.svg";
import { Routes } from "../routes";
import { isDesktop } from "../../../utils/helpers";
import { moduleTabs } from "../constants";
import { DonutLargeOutlined } from "@material-ui/icons";
import "./styles.scss";

const HomeCircles = (props) => {
  const canAccess = isDesktop();

  return (
    <IonPage id="home-circles">
      {(canAccess && (
        <div className="circlesHome">
          <SideBar
            moduleIcon={moduleIcon}
            moduleTabs={moduleTabs}
            moduleName={"Círculos de calidad"}
            logoIcon={<DonutLargeOutlined />}
            logoName={"CC"}
            isDesktop={canAccess}
            {...props}
          />
          <div className="mainContent">
            <AppBar />
            <Routes />
          </div>
        </div>
      )) || <p>No disponible en la version mobile</p>}
    </IonPage>
  );
};

export default HomeCircles;
