import React from "react";
import IconText from "../iconText";
import Idea from "../../../assets/icons/idea.svg";

/**
 * A component to display YoContribuyo Logo
 *
 * @returns {JSX.Element}
 */
const LogoYoContribuyo = () => (
  <IconText
    className="purple-icon-text bold large large-icon"
    text={"YO CONTRIBUYO"}
    icon={Idea}
    separatingMargin={"14px"}
  />
);

export default LogoYoContribuyo;
