import React from "react";
import "./styles.scss";
import { CheckCircleOutline } from "@material-ui/icons";
import ButtonTab from "../buttonTab";
import LogoYoContribuyo from "../logoYoContribuyo";

const getSidebarTabs = (onClick, currentView, tabs) => {
  const currentIndex = tabs?.findIndex((tab) => tab?.view === currentView);

  const sidebarTabs = tabs?.map((tab, index) => ({
    className: `${getClassName(currentIndex, index)}`,
    active: currentIndex === index,
    background: {
      colorSelect: "#E0E0E0",
    },
    text: {
      className: "small bold purple-select", 
      name: tab?.name,
      icon: tab?.icon || <CheckCircleOutline />,
    },
    urlRedirect: "#",
    onClick: () => {
      onClick(tab?.view);
    },
  }));

  return sidebarTabs;
};

const getClassName = (viewIndex, tabIndex) => {
  let className = "transparent-background purple-hover";
  if (tabIndex === viewIndex)
    className = className.concat(" purple-select select");
  if (tabIndex < viewIndex)
    className = className.concat(" purple-background-select select");
  if (tabIndex > viewIndex) className = className.concat(" disabled");
  return className;
};

const FormSideBar = ({ onClick, currentView, tabs, footer, isLogo }) => {
  return (
    <React.Fragment>
      <div className="Form-sidebar">
        <div className="sidebar-top">
          <div className="sidebar-logo">
            {isLogo && <LogoYoContribuyo />}
          </div>
          <ButtonTab
            className="sidebar-buttons"
            tabs={getSidebarTabs(onClick, currentView, tabs)}
            orientation={"vertical"}
            separatingMargin={"0.625em"}
          />
        </div>
        <div className="sidebar-bottom">{footer}</div>
      </div>
    </React.Fragment>
  );
};

export default FormSideBar;
