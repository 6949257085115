import React, { useState } from "react";
import HolosAlert from "../../../../../../../../shared/components/holosAlert";
import HolosButton from "../../../../../../../../shared/components/holosButton";
import HolosLink from "../../../../../../../../shared/components/holosLink/holosLink";
import IconText from "../../../../../../../../shared/components/iconText";
import MemberData from "./parts/memberData";
import AlertSecondStepExternal from "./parts/newExternal";

const AlertToAddExternal = ({
  circle,
  members,
  promiseOptions,
  onSelect,
  handleArrayElement,
  currentSelects,
  showAddExternalAlert,
  setShowAddExternalAlert,
  genders,
  addMember
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [member, setMember] = useState(null)

  const addNewMember=()=>{
    setMember({...member, show:false})
    addMember(member);
    setShowAddExternalAlert(false);
    setMember(null);
  }

  const onSubmit = e => {
    e.preventDefault()
    addNewMember()
  }
  
  return (
    <div> 
      <HolosAlert
        showAlert={showAddExternalAlert}
        setShowAlert={(state, action) => {
          setMember(null)
          setShowAddExternalAlert(state, action)
        }}
        title={
          <React.Fragment>
            <IconText
              icon={""}
              text={"Nuevo integrante externo"}
              className="small bold"
            />
          </React.Fragment>
        }
        content={
          <MemberData
            setMember={setMember}
            circle={circle}
            members={members}
            promiseOptions={promiseOptions}
            onSelect={onSelect}
            handleArrayElement={handleArrayElement}
            currentSelects={currentSelects}
            onSubmit={onSubmit}
            member={member}
          />
        }
        actions={[
          <div className="double-cta" key={1}>
            <HolosButton
              key={1}
              disabled={member ? false : true}
              separatingMargin={"1em"}
              className="purple-background"
              text={{ name: "Agregar", className: "white-icon-text" }}
              onClick={()=>addNewMember()}
            />
            <HolosLink
              text="¿No encuentras a tu compañera/o?"
              linkText={"Añádelo al sistema"}
              link={
                <AlertSecondStepExternal
                  genders={genders}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  addMember={addMember}
                  setShowAddExternalAlert={setShowAddExternalAlert}
                />
              }
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>,
        ]}
      ></HolosAlert>
    </div>
  );
};
export default AlertToAddExternal;
