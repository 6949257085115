import React, { useContext, useEffect, useState } from "react";
import "./previewViewFinder.scss";
import { LocationOnOutlined, Share } from "@material-ui/icons";
import qr from "../../../../assets/icons/qr.svg";
import IconText from "../../../../shared/components/iconText";
import RequestPlaque from "../../../plaques/services/request";
import PreviewData from "./parts/data";
import PreviewLocation from "./parts/location";
import { Loading } from "../../../../context";
import ShareQR from "../../../../shared/components/shareQR";

const PreviewViewFinder = (props) => {
  const [plaque, setPlaque] = useState({});
  const [openData, setOpenData] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [, setLoading] = useContext(Loading);

  const { id } = props.match.params;

  useEffect(() => {
    (async () => {
      setLoading(true);
      let res = await RequestPlaque.getPlaque(id);
      setLoading(false);
      if (res.status === 200) {
        return setPlaque(res.body);
      }
    })();
  }, [id, setLoading]);

  return (
    <div className="Preview-view-finder">
      <div className="preview-tab">
        <div
          onClick={() => {
            setOpenLocation(!openLocation);
            setOpenData(false);
          }}
        >
          <IconText
            icon={<LocationOnOutlined />}
            iconColor={"#444444"}
            text={plaque?.name}
            iconOpacity={0.54}
            fontSize={"14px"}
            fontWeight={500}
            fontColor={"var(--softBlack)"}
            separatingMargin={"1.375em"}
          />
        </div>
        <div className="tab">
          <div
            onClick={() => {
              setOpenData(!openData);
              setOpenLocation(false);
            }}
          >
            <IconText
              icon={qr}
              text={"Datos de la placa QR"}
              iconColor={"#444444"}
              iconOpacity={0.54}
              fontSize={"14px"}
              fontWeight={500}
              fontColor={"var(--softBlack)"}
              separatingMargin={"1.375em"}
            />
          </div>
          <Share className="Share" onClick={() => setShowModal(true)} />
        </div>
      </div>
      <div className="preview-pdf">
        <a
          className="aws-path"
          href={plaque && plaque.file && plaque.file.awsPath}
        >
          <img
            alt="img"
            src={plaque && plaque.file && plaque.file.awsCoverPath}
          />
        </a>
      </div>
      {openData && <PreviewData plaque={plaque} setOpenData={setOpenData} />}
      {openLocation && (
        <PreviewLocation plaque={plaque} setOpenLocation={setOpenLocation} />
      )}
      <ShareQR
        plaque={plaque}
        setShowModalShare={setShowModal}
        showModalShare={showModal}
      />
    </div>
  );
};

export default PreviewViewFinder;
