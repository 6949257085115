import React, {useState} from "react";
import HolosAlert from "../holosAlert";
import HolosButton from "../holosButton";

import "./styles.scss";

const DeleteRequest = ({ showAlert, setShowAlert, onClick }) => {
  const [showResult, setShowResult] = useState(false)
  return (
    <React.Fragment>
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        type={"warning"}
        title={"Eliminar definitivamente"}
        content={
          <div>
            <span className="bold">Esta acción es irreversible.</span>
            <span> ¿Segura/o que deseas continuar?</span>
          </div>
        }
        actions={[
          <HolosButton
            key={1}
            className="purple-background"
            text={{
              name: "Eliminar",
              className: "bold medium white-icon-text",
            }}
            onClick={()=>{
              setShowAlert(false)
              setShowResult(true)
              onClick()
            }}
          />,
        ]}
      />
      <HolosAlert
        showAlert={showResult}
        setShowAlert={setShowResult}
        type={"warning"}
        title={"Solicitud de eliminación exitosa"}
        content={
          <div>
            <span>
              Solicitud de eliminacion pendiente de aprobacion por PMO.
            </span>
          </div>
        }
      />
    </React.Fragment>
  );
};

export default DeleteRequest;
