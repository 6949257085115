import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

/**
 * A component to display a link with some text before
 *
 * @param {object} props Props pased to the component
 * @param {string} props.text Text showed before the link
 * @param {string|object} props.link path to redirect when user click in the link
 * @param {string} props.linkText Text of the link
 * @param {function} props.onClick Method onClick with event in case link is Component
 */
const HolosLink = ({ text, link, linkText, onClick, className}) => {
  const [isComponent, setisComponent] = useState(false);

  useEffect(() => {
    if (typeof link === "object") return setisComponent(true);
    return setisComponent(false);
  }, [link]);

  return (
    <p className={`Holos-link small ${className ? className: ""}`}>
      {text}
      {isComponent ? (
        <div className="link" onClick={(e) => onClick(e)}>
          {linkText}
        </div>
      ) : (
        <Link className="link" to={`${link}`}>
          {linkText}
        </Link>
      )}
      {isComponent && link}
    </p>
  );
};

export default HolosLink;