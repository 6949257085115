import React from "react";

import { IonCardHeader, IonContent, IonFooter, IonPopover } from "@ionic/react";

import "./modal.scss";

export const Modal = (props) => {
  var { showModal, title, cssClass, content, actions } = props;
  return (
    <IonPopover
      isOpen={showModal}
      cssClass={`Modal ${cssClass}`}
      backdropDismiss={false}
    >
      <IonCardHeader className={"modal-title"}>{title}</IonCardHeader>
      {content && (
        <IonContent
          id="modal-content"
          className="ion-text-center modal-content"
        >
          {content}
        </IonContent>
      )}
      {actions && <IonFooter className={"modal-footer"}>{actions}</IonFooter>}
    </IonPopover>
  );
};
