import React, { useState, useEffect, useCallback, useRef } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import {
  BorderColorOutlined,
  CallMissedOutgoingOutlined,
  ChatBubbleOutline,
  DonutLargeOutlined,
  EmojiEventsOutlined,
  MoreVertOutlined,
  NotInterestedOutlined,
  PeopleAltOutlined,
  DeleteForeverOutlined,
} from "@material-ui/icons";

// components
import HolosList from "../../../../shared/components/holosList";
import BaseBody from "../../../../shared/components/baseBody";
import HolosChip from "../../../../shared/components/holosChip";
import Tooltip from "../../../../shared/components/tooltip";
import HolosButton from "../../../../shared/components/holosButton";
import FloatingModal from "../../../../shared/components/floatingModal";
import HolosPagination from "../../../../shared/components/holosPagination";

// styles
import "./styles.scss";

// services
import Request from "../../services/request";
import { tabs, generateHeaders, itemsPerPageRef } from "../../constants";

//utils
import { handleSortHelper, parseSpaceByDash, usePrevious } from "../../../../utils/helpers";
import { getTranslate } from "../../../../i18n/translate";
import DeleteRequest from "../../../../shared/components/DeleteRequest";
import DeactivateHolosAlert from "../../../../shared/components/DeactivateHolosAlert";
import CircleInformation from "./parts/circleInformation";

const CirclesList = () => {
  const history = useHistory();
  const [circles, setCircles] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeactiveAlert, setShowDeactiveAlert] = useState(false);
  const [showUserCircles, setShowUserCircles] = useState(false);
  const [showFloating, setShowFloating] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [query, setQuery] = useState({
    page_size: 10,
    page_number: 1,
    page_count: 1,
  });
  const prevItems = usePrevious({ ...query });

  const filterRef = useRef();

  //TODO handle error
  const getCircles = useCallback(async (query) => {
    const res = (await Request.getCirclesList(query))?.body;
    return res;
  }, []);

  useEffect(() => {
    (async () => {
      if (JSON.stringify(prevItems) === JSON.stringify(query)) return;
      const res = await getCircles(query);
      if (!res?.data) setCircles([]);
      setCircles(res?.data);
      setQuery((query) => ({
        ...query,
        page_size: res?.page_size,
        page_number: res?.page_number,
        page_count: res?.page_count,
      }));
      filterRef.current.focus();
    })();
  }, [prevItems, getCircles, query]);

  const floatingTabs = [
    {
      className: "transparent-background purple-hover",
      text: {
        name: "Eliminar",
        className: "white-background small lessBold",
        icon: <DeleteForeverOutlined />,
      },
      onClick: () => setShowDeleteAlert(true),
      urlRedirect: history?.location?.pathname,
    },
    {
      className: "transparent-background purple-hover",
      text: {
        name: "Desactivar",
        className: "white-background small lessBold",
        icon: <NotInterestedOutlined />,
      },
      onClick: () => setShowDeactiveAlert(true),
      urlRedirect: history?.location?.pathname,
    },
  ];

  const showFloatingModal = (index) => {
    if (showFloating === null) return false;
    if (showFloating === index) return true;
    return false;
  };

  const getListRow = () => {
    let listRow = [];
    circles &&
      circles.forEach((circle, index) => {
        listRow.push(
          <React.Fragment>
            <div>
              <div className="item-actions-with-modal">
                <Link
                  to={`/circles/${circle?._id}/edit`}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <BorderColorOutlined />
                </Link>
                <span>
                  <MoreVertOutlined
                    onClick={() =>
                      showFloatingModal(index)
                        ? setShowFloating(null)
                        : setShowFloating(index)
                    }
                  />
                </span>
              </div>
              {showFloatingModal(index) && (
                <FloatingModal tabs={floatingTabs} />
              )}
            </div>
            <div className="item-container">
              <div className="item-content">{circle?.code}</div>
            </div>
            <div className="item-container">
              <div className="item-content">{circle?.name}</div>
            </div>
            <div className="item-container">
              <div className="item-content">{circle?.area}</div>
            </div>
            <div className="item-container">
              <div className="item-content">
                <HolosChip
                  name={getTranslate(circle?.state)}
                  status={parseSpaceByDash(circle?.state)}
                />
              </div>
            </div>
            <Tooltip text="Desafíos" className="location-bottom">
              <div className="item-container">
                <div className="item-box">
                  <CallMissedOutgoingOutlined
                    className="item-icon"
                    onClick={() => setOpenModal(!openModal)}
                  />
                  <div className="item-content">{circle?.challenges}</div>
                </div>
              </div>
            </Tooltip>
            <Tooltip text="Equipo de trabajo" className="location-bottom">
              <div className="item-container">
                <div className="item-box">
                  <PeopleAltOutlined
                    className="item-icon"
                    onClick={() => setOpenModal(!openModal)}
                  />
                  <div className="item-content">{circle?.team}</div>
                </div>
              </div>
            </Tooltip>
            <Tooltip text="Conversaciones" className="location-bottom">
              <div className="item-container">
                <div className="item-box">
                  <ChatBubbleOutline
                    className="item-icon"
                    onClick={() => setOpenModal(!openModal)}
                  />
                  <div className="item-content circle-conversation">{0}</div>
                </div>
              </div>
            </Tooltip>
            <Tooltip text="Reconocimientos" className="location-bottom">
              <div className="item-container">
                <div className="item-box">
                  <EmojiEventsOutlined
                    className="item-icon"
                    onClick={() => setOpenModal(!openModal)}
                  />
                  <div className="item-content">{0}</div>
                </div>
              </div>
            </Tooltip>
          </React.Fragment>
        );
      });

    return listRow;
  };
  const filter = (e) => {
    setQuery({
      ...query,
      filter: e,
      page_number: 1,
    });
  };

  const handleSort = (param) => {
    handleSortHelper(param, query, setQuery, "circleState")
  };

  return (
    <>
      <BaseBody
        tabsBody={tabs}
        bodyIcon={<DonutLargeOutlined />}
        bodyTitle={"Círculos de calidad"}
        children={
          <div className="circles-list">
            <HolosList
              filterRef={filterRef}
              icon={<DonutLargeOutlined />}
              headers={generateHeaders(handleSort)}
              showFilterBar={true}
              searchFilter={(e) => {
                filter(e.currentTarget.value);
              }}
              currentSearch={query.filter}
              gridTemplateColumns={"10% 5% 10% 25% 10% 5% 5% 5% 5% 20%"}
              gridGap={"1.5em"}
              rows={getListRow(circles)}
              showRowsColor={true}
              placeholder={"Buscar por nombre o codigo de círculo..."}
              moreFilters={
                <React.Fragment>
                  <div className="circles-filters-start">
                    <Select
                      options={itemsPerPageRef}
                      onChange={(e) =>
                        setQuery((query) => ({ ...query, page_size: e.value }))
                      }
                      value={itemsPerPageRef.find(
                        (item) => item.value === query.page_size
                      )}
                      placeholder={"Elementos por página"}
                    />
                  </div>
                  <div className="circles-filters-end">
                    <HolosButton
                      className={
                        !showUserCircles
                          ? "purple-rounded-corners"
                          : "transparent-rounded-corners"
                      }
                      text={{
                        name: "Todos los círculos",
                        className: !showUserCircles
                          ? "bold medium white-icon-text"
                          : "bold medium black-icon-text",
                      }}
                      onClick={() => {
                        setQuery({ ...query, owner: null, page_number: 1 });
                        setShowUserCircles(false);
                      }}
                    />
                    <HolosButton
                      className={
                        showUserCircles
                          ? "purple-rounded-corners"
                          : "transparent-rounded-corners"
                      }
                      text={{
                        name: "Mis círculos",
                        className: showUserCircles
                          ? "bold medium white-icon-text"
                          : "bold medium black-icon-text",
                      }}
                      onClick={() => {
                        setQuery({ ...query, owner: "user", page_number: 1 });
                        setShowUserCircles(true);
                      }}
                    />
                  </div>
                </React.Fragment>
              }
            />

            <HolosPagination
              items={circles}
              pageCount={query.page_count}
              setItems={setCircles}
              pageNumber={query.page_number}
              setPageNumber={(e) => {
                setQuery({ ...query, page_number: e });
              }}
              itemsPerPage={query.page_size}
              showGoToPage={true}
            />
          </div>
        }
      />
      <CircleInformation showModal={openModal} setShowModal={setOpenModal} />
      <DeleteRequest
        showAlert={showDeleteAlert}
        setShowAlert={setShowDeleteAlert}
        onClick={() => setShowDeleteAlert(false)}
      />

      <DeactivateHolosAlert
        showAlert={showDeactiveAlert}
        setShowAlert={setShowDeactiveAlert}
        onClick={() => setShowDeactiveAlert(false)}
      />
    </>
  );
};

export default CirclesList;
