import React from "react";
import "./styles.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
import IconText from "../iconText";
import { Error } from "@material-ui/icons";
registerLocale("es", es); // register it with the name you want

const HolosDate = ({
    id,
    name,
    autocomplete,
    className,
    placeholderText,
    maxDate,
    minDate,
    onChange,
    selected,
    dropdownMode,
    isError,
    msjError
}) => {

    return (
        <React.Fragment>
            <DatePicker
                id={id}
                name={name}
                autocomplete={"off" || autocomplete}
                dateFormat="dd/MM/yyyy"
                locale="es"
                className={`Holos-date ${className}`}
                placeholderText={placeholderText}
                minDate={minDate}
                maxDate={maxDate}
                type="date"
                onChange={onChange}
                selected={selected}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode={dropdownMode}
            />
            {isError && (
                <div className="input-error">
                    <IconText
                        icon={<Error />}
                        text={msjError}
                        className="red-icon-text"
                        separatingMargin="8px"
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default HolosDate;