import React, { useContext, useEffect, useState } from "react";
import { tabs } from "../../constants";
import BaseBody from "../../../../shared/components/baseBody";
import "./previewPlaque.scss";
import RequestPlaque from "../../services/request";
import {
  Autorenew,
  BorderColorOutlined,
  CallMissedOutgoingOutlined,
  DonutLargeOutlined,
  EmojiEventsOutlined,
  GetAppOutlined,
  LinkSharp,
  LocationOnOutlined,
  NotInterested,
  PictureAsPdf,
  SettingsEthernet,
  Share,
} from "@material-ui/icons";
import IconText from "../../../../shared/components/iconText";
import ShareQR from "../../../../shared/components/shareQR";
import qrx48 from "../../../../assets/icons/qrx48.svg";
import InfoCard from "../../../../shared/components/infoCard";
import { Link } from "react-router-dom";
import { Loading } from "../../../../context";
import DeleteAlert from "../../../../shared/components/deleteAlert";
import qr from "../../../../assets/icons/qr.svg";
import RenewAlert from "../../../../shared/components/renewAlert";

const PreviewPlaque = (props) => {
  const [plaque, setPlaque] = useState({});
  const [showModalShare, setShowModalShare] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showRenewAlert, setShowRenewAlert] = useState(false);
  const [downloadQR, setDownloadQR] = useState(false);
  const [, setLoading] = useContext(Loading);
  const { id } = props.match.params;

  const arrayFields = [
    {
      icon: <EmojiEventsOutlined />,
      label: "Nombre del reconocimiento",
      value: plaque?.recognitionName,
    },
    {
      icon: <CallMissedOutgoingOutlined />,
      label: "Desafío",
      value: plaque?.challengeName,
    },
    {
      icon: <DonutLargeOutlined />,
      label: "Círculo de calidad encargado",
      value: plaque?.circleName,
    },

    {
      icon: <LocationOnOutlined />,
      label: "Ubicación de la placa QR",
      value: plaque?.location?.name,
    },
    {
      icon: <SettingsEthernet />,
      label: "Tag",
      value: plaque?.machineTag,
    },
    {
      icon: <LinkSharp />,
      label: "URL Generada",
      value: plaque?.file?.awsPath,
      isRefer: true,
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      let res = await RequestPlaque.getPlaque(id);
      setLoading(false);
      if (res.status === 200) {
        return setPlaque(res.body);
      }
    })();
  }, [id, setLoading]);

  //methods alert
  const navigateTo = (typeShow) => {
    if (typeShow === "close") return;
    if (typeShow === "accept") deletePlaque(id);
    return;
  };

  const deletePlaque = async (id) => {
    const form = new FormData();
    form.append("user", "desarrollo@holos.cl");
    await RequestPlaque.deletePlaque(id, form);
    props.history.push(`/`);
    return;
  };
  const navigateToRenew = (typeShow) => {
    if (typeShow === "close") return;
    if (typeShow === "accept") renewPlaque(id);
    return;
  };

  const renewPlaque = async (id) => {
    const form = new FormData();
    form.append("user", "desarrollo@holos.cl");
    await RequestPlaque.setEnabledPlaque(id);
    props.history.push(`/list`);
    return;
  };

  return (
    <div className="Preview-plaque">
      <BaseBody
        tabsBody={tabs}
        bodyIcon={qr}
        bodyTitle={`${
          plaque.isActive
            ? "Placa QR activa"
            : "Placa QR Desactivada"
        }`}
      >
        <div className="columnContainer">
          <div className="viewer">
            <a
              href={plaque && plaque.file && plaque.file.awsPath}
              rel="noopener noreferrer"
              target="_blank"
              download
            >
              <img
                src={
                  plaque &&
                  plaque.file &&
                  plaque.file.awsCoverPath
                }
                alt="pdf"
              />
            </a>
          </div>
          <div className="data">
            <div className="headPreview">
              <div className="titlePreview">
                <div
                  className="iconQR"
                  style={{
                    WebkitMask: `url(${qrx48}) no-repeat center`,
                    height: "3em",
                    width: "3em",
                    margin: `${!plaque.isActive ? "0" : "0 1.7em 0 0"}`,
                  }}></div>
                {plaque && plaque.isActive===false && (
                  <div className="disabledPlaque">
                    Placa QR Desactivada
                  </div>
                )}
                {plaque.isActive && (
                  <div
                    style={{ cursor: "pointer", marginTop: "0.75em" }}
                    onClick={() => setDownloadQR(true)}
                  >
                    <IconText
                      icon={<GetAppOutlined />}
                      text={"Descargar QR"}
                      className={"small"}
                      separatingMargin={"0.625em"}
                    />
                  </div>
                )}
              </div>
              {plaque?.isActive && (
                <div className="editPreview">
                  <Link
                    to={`/edit/${plaque._id}`}
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    <BorderColorOutlined className="BorderColorOutlined" />
                  </Link>
                  <NotInterested
                    className="NotInterested"
                    onClick={() => setShowDeleteAlert(true)}
                  />
                  <DeleteAlert
                    item={plaque}
                    showAlert={showDeleteAlert}
                    setShowAlert={(bool, typeShow) => {
                      setShowDeleteAlert(bool);
                      navigateTo(typeShow);
                    }}
                  />
                </div>
              )}
              {plaque?.isActive === false &&(
                <div className="editPreview">
                  <Autorenew
                    className="NotInterested"
                    onClick={() => setShowRenewAlert(true)}
                  />
                  <RenewAlert
                    item={plaque}
                    showAlert={showRenewAlert}
                    setShowAlert={(bool, typeShow) => {
                      setShowRenewAlert(bool);
                      navigateToRenew(typeShow);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="bodyPreview">
              <div className="start">
                <IconText
                  icon={<PictureAsPdf />}
                  text={plaque?.file?.name}
                  iconColor={"var(--purpleBHP)"}
                  fontWeight={400}
                  fontColor={"var(--softBlack)"}
                  fontSize={"14px"}
                  separatingMargin={"1.375em"}
                />
              </div>

              <InfoCard
                arrayFields={arrayFields}
                fontSize={"14px"}
                fontWeight={500}
              />
              {plaque?.isActive && (
                <div className="row end">
                  <div
                    onClick={() => setShowModalShare(true)}
                    style={{ width: "100%", cursor: "pointer" }}
                  >
                    <IconText
                      icon={<Share />}
                      text={"Compartir placa QR"}
                      iconOpacity={0.54}
                      fontSize={"14px"}
                      fontWeight={500}
                      fontColor={"var(--softBlack)"}
                      separatingMargin={"1.375em"}
                    />
                  </div>

                  <a
                    className="imgQR"
                    href={
                      plaque && plaque.qr && plaque.qr[0].qrLink
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                    download
                  >
                    <img
                      className="img"
                      id="#img"
                      src={
                        plaque &&
                        plaque.qr &&
                        plaque.qr[0].qrLink
                      }
                      alt="qr"
                    />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </BaseBody>
      <ShareQR
        plaque={plaque}
        setShowModalShare={setShowModalShare}
        showModalShare={showModalShare}
        startDownload={downloadQR}
        setStartDownload={setDownloadQR}
      />
    </div>
  );
};

export default PreviewPlaque;
