import { Camera, CheckCircleOutline } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import HolosAlert from "../holosAlert";
import HolosButton from "../holosButton";
import HolosInput from "../holosInput";
import IconText from "../iconText";
import "./styles.scss";
const Picture = ({ url, onChange, isValidated, information, types }) => {
  const [imageSelected, setImageSelected] = useState(false);
  const [imageSaved, setImageSaved] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const defaultValue = () => {
    setImageSaved(false);
    setImageSelected(false);
    isValidated(false);
  };

  const formatValidation = (e) => {
    const file = e.target.files[0];
    if (types && !types.find((type) => type === file?.type)) {
      setShowAlert(true);
      defaultValue();
      return false;
    }
    return true;
  };

  //images preloaded or obtained by api rest
  useEffect(() => {
    if (url && typeof url === "string") {
      setImageSaved(true);
      setImageSelected(true);
      isValidated(true);
    }
  }, [url, isValidated, setImageSelected, setImageSaved]);

  useEffect(() => {
    if (imageSaved && imageSelected) isValidated(true);
  }, [imageSaved, imageSelected, isValidated]);

  return (
    <div className="Picture">
      <div className="picture-header">
        {!imageSelected && !imageSaved && (
          <div>
            <div className="title-container">
              <label htmlFor="email" className="form-group small">
                Elige una foto
              </label>
            </div>
            <div className="picture-container">
              <div className="picture-circle">
                <Camera fontSize="large" className="picture-icon" />
              </div>
            </div>
            <label className="upload-image upload-purple">
              Subir Foto
              <HolosInput
                id="photo"
                name="photo"
                className="hidden"
                value={""}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (formatValidation(e)) {
                    onChange(e);
                    setImageSelected(true);
                  }
                }}
              />
            </label>
          </div>
        )}
        {imageSelected && !imageSaved && (
          <div>
            <label>¿Estás contento/a con la foto?</label>
            <div className="picture-container">
              <div className="picture-circle">
                <img
                  className="picture-image"
                  src={`${url}`}
                  alt="Imagen de perfil"
                ></img>
              </div>
            </div>

            <HolosButton
              className="camera-button purple-background"
              text={{
                name: "Guardar Foto",
                className: "small white-icon-text",
              }}
              type="submit"
              onClick={() => {
                setImageSaved(true);
              }}
            />
            <label className="upload-image">
              Usar Otra
              <HolosInput
                id="photo"
                name="photo"
                className="hidden"
                value={""}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (formatValidation(e)) {
                    onChange(e);
                    setImageSelected(true);
                  }
                }}
              />
            </label>
          </div>
        )}
        {imageSaved && imageSelected && (
          <div>
            <label>¡Felicitaciones! Ya tienes una foto</label>
            <div className="picture-container">
              <div className="picture-circle">
                <img
                  className="picture-image"
                  src={`${url}`}
                  alt="Imagen de perfil"
                ></img>
              </div>
            </div>

            <HolosButton
              className="white-background camera-button"
              text={{
                name: "Usar Otra",
                className: "small",
              }}
              type="submit"
              width="100%"
              onClick={() => {
                defaultValue();
              }}
            />
          </div>
        )}
        {!imageSelected && !imageSaved && (
          <HolosButton
            className="white-background camera-button"
            text={{ name: "Usar camara", className: "small" }}
            type="submit"
            disabled={true}
          />
        )}
      </div>
      {information && (
        <div className="picture-validation">
          <IconText
            className="grey-icon-text"
            text={"Tamaño maximo de 30MB"}
            icon={CheckCircleOutline}
            separatingMargin={"14px"}
          />
          <IconText
            className="grey-icon-text"
            text={"Tipo de archivo .jpg / .jpge / .png"}
            icon={CheckCircleOutline}
            separatingMargin={"14px"}
          />
          <IconText
            className="grey-icon-text"
            text={"Foto clara y bien iluminada"}
            icon={CheckCircleOutline}
            separatingMargin={"14px"}
          />
        </div>
      )}
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={(isOpen) => setShowAlert(isOpen)}
        type={"error"}
        title={"Error"}
        content={"Formato incorrecto"}
      />
    </div>
  );
};
export default Picture;
