import React from "react";
import GoogleMap from "../../../../shared/components/googleMap";

import "./styles.scss";

const MapCard = ({
  header,
  footer,
  center,
  markers,
  markerIconURL,
  areaCoords,
  currentMarker,
  currentMarkerIconURL,
  setCurrentMarker,
}) => {
  return (
    <div className="map-card">
      <div className="header">{header}</div>
      <div className="map-card-google">
        <GoogleMap
          markers={markers}
          options={{ center, zoom: 3 }}
          markerIconURL={markerIconURL}
          currentMarkerIconURL={currentMarkerIconURL}
          currentMarker={currentMarker}
          setCurrentMarker={setCurrentMarker}
          area={areaCoords}
          mode="view"
        />
      </div>
      <div className="footer">{footer}</div>
    </div>
  );
};

export default MapCard;
