import React from "react";
import "./holosInput.scss";
import { SvgIcon } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import IconText from "../iconText";
const HolosInput = (props) => {
  const {
    //var
    placeholder,
    icon,
    width,
    height,
    value,
    name,
    disabled,
    isError,
    msjError,
    id,
    className,
    type,
    //method
    onClickIcon,
    onChange,
    onBlur,
    accept,
    filterRef,
  } = props;
  const isComponentSVG = (icon) => {
    if (typeof icon === "object" && icon) {
      return <SvgIcon component={icon.type} />;
    }
    return (
      <img
        src={icon}
        alt="icon"
        style={{ marginLeft: ".1em", marginRight: "0" }}
      ></img>
    );
  };
  const getClassName = (disabled, className, isError) => {
    let currentClass = "HolosInput";
    if (disabled) currentClass = currentClass.concat(" disabled");
    if (className) currentClass = currentClass.concat(` ${className}`);
    if (!msjError && isError) currentClass = currentClass.concat(" invalid");
    return currentClass;
  };
  return (
    <React.Fragment>
      <div
        className={getClassName(disabled, className, isError)}
        style={{
          width: width || "100%",
          height: height || "",
        }}
      >
        <div onClick={onClickIcon}>
          {icon && isComponentSVG(icon)}
        </div>
        <input
          ref={filterRef}
          id={id}
          name={name}
          //if it doesnt exist an icon, a margin is not necessary
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          type={type}
          accept={accept}
        />
      </div>
      {isError && msjError && (
        <div className="input-error">
          <IconText
            icon={<Error />}
            text={msjError}
            className="red-icon-text"
            separatingMargin="8px"
          />
        </div>
      )}
    </React.Fragment>
  );
};
export default HolosInput;
