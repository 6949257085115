import React, { useCallback, useContext, useEffect, useState } from "react";
import { CreateOutlined } from "@material-ui/icons";

import PictureInput from "../pictureInput";
import HolosInput from "../../../../../../../../shared/components/holosInput";
import IconSelect from "../../../../../../../../shared/components/iconSelect/iconSelect";
import Parameter from "../../../../../../../../services/globalApi/paremeters";
import { Loading } from "../../../../../../../../context";
import * as helpers from "../../../../../../../../utils/helpers";
import HolosAlert from "../../../../../../../../shared/components/holosAlert";
import { getErrorMessage } from "../../../../../../../../services/errors";
import HolosDate from "../../../../../../../../shared/components/holosDate";
import Service from "../../../../../../services/request";

const CircleDataForm = ({
  setCircleData,
  circle,
  setCanContinue,
  viewError,
  justWatch,
}) => {
  //states alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: "", message: "" });

  const [, setLoading] = useContext(Loading);

  const [circleTypes, setCircleTypes] = useState([]);
  const [workSchedules, setWorkSchedules] = useState([]);
  const [areas, setAreas] = useState([]);
  const [currentSelects, setCurrentSelects] = useState({
    workSchedule: null,
    area: null,
    circleType: null,
  });

  const labels = {
    photo: "Foto del círculo",
    circleType: "Tipo de Círculo",
    workSchedule: "Tiempo de trabajo",
    area: "Área",
    code: "Código del circulo",
    yammerLink: "Yammer",
    date: "Fecha de creación",
    firstWorkshop: "Primer taller",
  };

  const fieldValidation = {
    name: async (name) => await valdiateName(name),
    idCircleType: (validator) => helpers.isEmptyString(validator),
    area: (area) => helpers.isEmptyString(area._id),
    idWorkSchedule: (supervisor) => helpers.isEmptyString(supervisor),
    yammerLink: (leader) => helpers.isEmptyString(leader),
    date: (date) => helpers.isEmptyDate(date),
    firstWorkShop: (firstWorkShop) => validateDate(firstWorkShop),
  };

  const errors = helpers.useValidation(circle, fieldValidation);

  const existError = useCallback(helpers.existError, []);

  const validateDate = useCallback(
    (date) => {
      if (helpers.isEmptyDate(date))
        return { status: true, message: "Debe ingresar una fecha" };
      if (
        !helpers.compareDate(
          circle?.firstWorkShop,
          circle?.date,
          "issameorbefore"
        )
      ) {
        return {
          status: true,
          message:
            "Primer taller debe ser anterior o igual a la fecha de creación",
        };
      }
    },
    [circle]
  );

  const valdiateName = useCallback(
    async (name) => {
      if (helpers.isEmptyString(name))
        return { status: true, message: "Debe ingresar este campo" };

      //TODO:delete when validations are done in edit
      if (justWatch) return {};

      const res = await Service.getValidateCircle({
        name: name,
        circleId: circle._id,
      });
      if (res?.status === 400) {
        return { status: true, message: "Círculo existente" };
      }
    },
    [justWatch, circle]
  );

  const handleError = (res) => {
    setAlertContent({
      type: "error",
      title: "Error",
      message: getErrorMessage(res.internalCode),
    });
    setShowAlert(true);
    return;
  };

  const onSelect = (selectedItem, nameId) => {
    //set the id of the select in the parent component
    setCircleData({ name: nameId, value: selectedItem._id });

    //save selector data locally
    setCurrentSelects({
      ...currentSelects,
      [selectedItem.nameTarget]: selectedItem,
    });
  };
  const onSelectArea = (selectedItem, nameId) => {
    //set the id of the select in the parent component
    setCircleData({ name: nameId, value: selectedItem });

    //save selector data locally
    setCurrentSelects({
      ...currentSelects,
      [selectedItem.nameTarget]: selectedItem,
    });
  };

  const handleArrayElement = (array, id) => {
    if (!array || !id) return null;
    return array?.find((e) => e._id === id);
  };

  const getParameters = async () => {
    let resultCircleTypes = Parameter.getCircleTypes();
    let resultWorkS = Parameter.getWorkSchedules();
    let resultAreas = Parameter.getAreas();
    resultCircleTypes = await resultCircleTypes;
    resultWorkS = await resultWorkS;
    resultAreas = await resultAreas;
    return { resultCircleTypes, resultWorkS, resultAreas };
  };

  const getSelectorsOptions = useCallback(async () => {
    setLoading(true);
    const { resultCircleTypes, resultWorkS, resultAreas } =
      await getParameters();
    setLoading(false);

    if (
      resultAreas?.status !== 200 ||
      resultCircleTypes?.status !== 200 ||
      resultWorkS?.status !== 200
    )
      return handleError(resultAreas || resultCircleTypes || resultWorkS);

    setCircleTypes(helpers.parseForSelect(resultCircleTypes.body));
    setWorkSchedules(helpers.parseForSelect(resultWorkS.body));
    setAreas(helpers.parseForSelect(resultAreas.body));
  }, [setLoading]);

  useEffect(() => {
    if (!Object.values(errors).length) return;
    if (!existError(errors)) return setCanContinue(true);
    setCanContinue(false);
  }, [errors, setCanContinue, existError]);

  useEffect(() => {
    getSelectorsOptions();
  }, [getSelectorsOptions]);

  return (
    <div className="form">
      <div className="form-title">
        <div>
          <HolosInput
            className="bold title-input"
            placeholder="Círculo de calidad"
            value={circle?.name}
            id="name"
            name="name"
            onChange={(e) => {
              e.currentTarget.value = e?.currentTarget?.value?.toUpperCase();
              setCircleData(e);
            }}
            isError={viewError && errors?.name?.status}
            msjError={errors?.name?.message}
          />
        </div>
        <CreateOutlined
          className="Create-outlined"
          htmlFor="name"
          onClick={() => document.getElementById("name").focus()}
        />
      </div>
      <div className="form-group">
        <div className="form-control">
          <span htmlFor="circle-photo" className="small bold">
            {labels.photo}
          </span>
          <PictureInput
            setCircleData={setCircleData}
            setCanContinue={setCanContinue}
            types={["image/png", "image/jpg", "image/jpeg"]}
            //in case edit circle, get photo already uploaded to s3
            urlPhoto={circle?.photo}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="circleType" className="small bold required">
            {labels.circleType}
          </label>
          <IconSelect
            placeholder={"Seleccione un tipo de círculo"}
            id="circleType"
            name="circleType"
            onChange={(e) => onSelect(e, "idCircleType")}
            options={circleTypes}
            value={
              handleArrayElement(circleTypes, circle?.idCircleType) ||
              currentSelects?.circleType
            }
            isError={viewError && errors?.idCircleType}
            msjError={"Debe ingresar este campo"}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="workSchedule" className="small bold required">
            {labels.workSchedule}
          </label>
          <IconSelect
            placeholder={"Seleccione el tiempo de trabajo"}
            id="workSchedule"
            name="workSchedule"
            onChange={(e) => onSelect(e, "idWorkSchedule")}
            options={workSchedules}
            value={
              handleArrayElement(workSchedules, circle?.idWorkSchedule) ||
              currentSelects?.workSchedule
            }
            isError={viewError && errors?.idWorkSchedule}
            msjError={"Debe ingresar este campo"}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="area" className="small bold required">
            {labels.area}
          </label>
          <IconSelect
            placeholder={"Seleccione un área"}
            id="area"
            name="area"
            onChange={(e) => onSelectArea(e, "area")}
            options={areas}
            value={
              handleArrayElement(areas, circle?.area?._id) ||
              currentSelects?.area
            }
            isError={viewError && errors?.area}
            msjError={"Debe ingresar este campo"}
          />
        </div>
      </div>
      {circle?.code && (
        <div className="form-group">
          <div className="form-control form-info">
            <label htmlFor="yammerLink" className="small bold">
              {labels.code}
            </label>
            <span>{circle?.code}</span>
          </div>
        </div>
      )}
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="yammerLink" className="small bold required">
            {labels.yammerLink}
          </label>
          <HolosInput
            placeholder="http://yammer.com/uk.tesco.com"
            value={circle?.yammerLink}
            id="yammerLink"
            name="yammerLink"
            onChange={setCircleData}
            isError={viewError && errors?.yammerLink}
            msjError={"Debe ingresar este campo"}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="date" className="small bold required">
            {labels.date}
          </label>
          <HolosDate
            id="date"
            name="date"
            autocomplete="off"
            dateFormat="dd/MM/yyyy"
            locale="es"
            className="date-picker small"
            placeholderText="07/11/1989"
            type="date"
            onChange={(date) =>
              setCircleData({ value: new Date(date), name: "date" })
            }
            selected={circle?.date}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isError={viewError && errors?.date}
            msjError={"Debe ingresar este campo"}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-control">
          <label htmlFor="firstWorkShop" className="small bold required">
            {labels.firstWorkshop}
          </label>
          <HolosDate
            id="firstWorkShop"
            name="firstWorkShop"
            autocomplete="off"
            dateFormat="dd/MM/yyyy"
            locale="es"
            className="date-picker small"
            placeholderText="07/11/1989"
            type="date"
            onChange={(date) =>
              setCircleData({ value: new Date(date), name: "firstWorkShop" })
            }
            selected={circle?.firstWorkShop}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isError={viewError && errors?.firstWorkShop?.status}
            msjError={errors?.firstWorkShop?.message}
          />
        </div>
      </div>
      <HolosAlert
        showAlert={showAlert}
        setShowAlert={(isOpen) => setShowAlert(isOpen)}
        title={alertContent.title}
        content={alertContent.message}
        type={alertContent.type}
      />
    </div>
  );
};

export default CircleDataForm;
