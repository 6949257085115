import React, { useCallback, useEffect, useState } from "react";
import { CheckCircleOutline } from "@material-ui/icons";
import IconText from "../iconText";
import Tooltip from "../tooltip";

import "./styles.scss";

const ValidationPassword = ({ text, onVerifyPassword }) => {
  const [isMayus, setIsMayus] = useState(false);
  const [isSpecial, setSpecial] = useState(false);
  const [minimunCharacters, setMinimunCharacter] = useState(false);

  const validMayus = useCallback(() => {
    let valid = true;
    if (!/[a-z]/.test(text)) valid = false;
    if (!/[A-Z]/.test(text)) valid = false;
    return setIsMayus(valid);
  }, [text, setIsMayus]);

  const validSpecial = useCallback(() => {
    if (/[@#$_%&*!-.]/.test(text)) return setSpecial(true);
    return setSpecial(false);
  }, [text, setSpecial]);

  const validMinimunCharacters = useCallback(() => {
    if (text.length >= 8) return setMinimunCharacter(true);
    return setMinimunCharacter(false);
  }, [text, setMinimunCharacter]);

  const validAllinOne = useCallback(() => {
    validMayus();
    validSpecial();
    validMinimunCharacters();
  }, [validMayus, validSpecial, validMinimunCharacters]);

  useEffect(() => {
    validAllinOne();
  }, [text, validAllinOne]);

  useEffect(() => {
    if (isMayus && isSpecial && minimunCharacters) return onVerifyPassword(true);
    return onVerifyPassword(false);
  }, [isMayus, isSpecial, minimunCharacters, onVerifyPassword]);

  return (
    <div className="Validation-password">
      <IconText
        className={`${isMayus ? "purple-icon-text bold" : ""} small`}
        text={"Utiliza letras mayúsculas y minúsculas"}
        icon={CheckCircleOutline}
        separatingMargin={"14px"}
      />
      <Tooltip text="Caracteres permitidos: @#$%&*_-!.">
        <IconText
          className={`${isSpecial ? "purple-icon-text bold" : ""} small`}
          text={"Utiliza caracteres especiales"}
          icon={CheckCircleOutline}
          separatingMargin={"14px"}
        />
      </Tooltip>
      <IconText
        className={`${minimunCharacters ? "purple-icon-text bold" : ""} small`}
        text={"Mínimo 8 caracteres"}
        icon={CheckCircleOutline}
        separatingMargin={"14px"}
      />
    </div>
  );
};
export default ValidationPassword;
