import RequestManager from "../requestManager";
import config from "../config";
import { logout, persons } from "./urls.json";

class Request {
  constructor() {
    this.baseUrl = config.REACT_APP_API_URL;
  }
  
  logout() {
    let url = this.baseUrl + logout;
    return RequestManager.get(url);
  }

  createPerson(data) {
    let url = `${this.baseUrl}${persons}create/`
    return RequestManager.post(url, data)
  }

}

const instance = new Request();
Object.freeze(instance);
export default instance;
