import React from "react";
import HolosAlert from "../../../../../../../../shared/components/holosAlert";
import HolosButton from "../../../../../../../../shared/components/holosButton";
import PersonCard from "../../../../../../../../shared/components/personCard";

const AlertDeletePerson = ({
    showDeleteConfirm,
    setShowDeleteConfirm,
    currentSelects,
    showDeleteInfo,
    setShowDeleteInfo,
    removeMember
}) => {
  return (
    <div>
      <HolosAlert
        showAlert={showDeleteConfirm}
        setShowAlert={setShowDeleteConfirm}
        title={"Eliminar integrante"}
        content={<PersonCard person={currentSelects?.member} />}
        actions={[
          <HolosButton
            key={1}
            separatingMargin={"1em"}
            className="purple-background"
            text={{ name: "Eliminar", className: "white-icon-text" }}
            onClick={() => {
              removeMember(currentSelects?.member);
              setShowDeleteConfirm(false);
              setShowDeleteInfo(true);
            }}
          />,
        ]}
      ></HolosAlert>
      <HolosAlert
        showAlert={showDeleteInfo}
        setShowAlert={setShowDeleteInfo}
        title={"Integrante eliminado"}
        content={"Se ha eliminado permanentemente a este integrante."}
        actions={[
          <HolosButton
            key={1}
            separatingMargin={"1em"}
            className="purple-background"
            text={{ name: "Entendido", className: "white-icon-text" }}
            onClick={() => setShowDeleteInfo(false)}
          />,
        ]}
      ></HolosAlert>
    </div>
  );
};
export default AlertDeletePerson;
