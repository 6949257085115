import React from "react";
import { useHistory } from "react-router";
import SmallBaseBody from "../../../../../../shared/components/smallBaseBody";
import UsersButtons from "../../../../shared/usersButtons";
import "./styles.scss";

const SuccessForm = () => {
  const history = useHistory();

  return (
    <div className="Success-pass">
      <SmallBaseBody
        className={"success-container"}
        content={
          <React.Fragment>
            <label className="bold success-title great">
              ¡Has cambiada exitosamente tu contraseña!
            </label>
            <p>
              Para iniciar sesión con tu nueva contraseña haz click en el
              siguiente boton
            </p>
          </React.Fragment>
        }
        buttons={
          <UsersButtons
            onSubmit={() => history.push(`/users/login`)}
            buttonName="Iniciar sesión"
          />
        }
      />
    </div>
  );
};

export default SuccessForm;
