import { Autorenew, WarningOutlined } from "@material-ui/icons";
import React from "react";
import HolosAlert from "../holosAlert";
import HolosButton from "../holosButton";
import IconText from "../iconText";
import QRCode from "../../../assets/qr";

import "./styles.scss";

const RenewAlert = ({ showAlert, setShowAlert, item }) => {
  return (
    <div className="Renew-alert">
      <HolosAlert
        key={1}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        title={
          <React.Fragment>
            <IconText
              icon={<QRCode />}
              text={item.name}
              iconColor={"var(--purpleBHP)"}
            />
          </React.Fragment>
        }
        content={
          <div className="renew-plaque">
            <div className="alert-icon">
              <WarningOutlined className="Warning-outlined" />
            </div>
            <div className="alert-content">
              <p>¿Estás seguro que deseas recuperar esta placa QR?</p>
              <p>
                Si quieres acceder nuevamente, podrás hacerlo desde la pestaña
                "Placas QR"
              </p>
            </div>
          </div>
        }
        actions={[
          <HolosButton
            key={1}
            separatingMargin={"1em"}
            icon={{ icon: <Autorenew />, color: "white", opacity: "1" }}
            background={{ color: "var(--purpleBHP)" }}
            text={{ name: "Recuperar", color: "white", colorHover: "white" }}
            onClick={() => {
              setShowAlert(false, "accept");
            }}
          />,
        ]}
      />
    </div>
  );
};

export default RenewAlert;
