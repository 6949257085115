import {
  DonutLarge,
  FlagOutlined,
  Group,
  TrendingUp,
} from "@material-ui/icons";
import React, { useState, useContext, useEffect, useCallback } from "react";
import Request from "../../services/request";
import FormSideBar from "../../../../shared/components/formSidebar";
import * as helpers from "../../../../utils/helpers";
import { Loading } from "../../../../context";
import CircleChallenges from "./parts/challenges";
import CircleData from "./parts/data";
import KickOff from "./parts/kickOff";
import CircleTeam from "./parts/team";
import CreateCircleAlert from "./parts/alerts/createCircleAlerts";
import { getErrorMessage } from "../../../../services/errors";
import { useParams } from "react-router";

import "./styles.scss";

const NewCircle = () => {
  const [view, setView] = useState("CircleData");
  const [, setLoading] = useContext(Loading);
  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({});
  const [alertCode, setAlertCode] = useState("");
  const [isOpenSupport, setIsOpenSupport] = useState(false);

  const { id } = useParams();
  const tabs = [
    { name: "Datos", view: "CircleData", icon: <DonutLarge /> },
    { name: "Equipo de trabajo", view: "CircleTeam", icon: <Group /> },
    { name: "Desafios", view: "CircleChallenges", icon: <TrendingUp /> },
    { name: "Kick off", view: "KickOff", icon: <FlagOutlined /> },
  ];
  const [circle, setCircle] = useState({
    name: "",
    photo: null,
    idCircleType: "",
    area: {},
    idWorkSchedule: "",
    yammerLink: "",
    firstWorkShop: null,
    date: null,
    validator: null,
    bettermentLead: null,
    supervisor: null,
    leader: null,
    members: [],
    challenges: [],
  });
  //persisted data, serves to compare and detect if there is a change in the edition
  const [savedCircle, setSavedCircle] = useState(null);
  //possible mode: create,view and edit
  const [mode, setMode] = useState("create");
  //used to call getCircle again
  const [reload, setReload] = useState(false);

  const setCircleData = (e) => {
    const { name, value } = e.currentTarget || e;
    setCircle((circle) => ({ ...circle, [name]: value }));
  };

  const discardEdit = () => {
    setCircle(savedCircle);
  };

  const ParseCircleData = (data) => {
    let circle = {
      name: data?.name,
      idCircleType: data?.idCircleType,
      idArea: data?.area?._id,
      idWorkSchedule: data?.idWorkSchedule,
      yammerLink: data?.yammerLink,
      firstWorkShop: data?.firstWorkShop?.toISOString(),
      date: data?.date?.toISOString(),
      photo: data?.photo,
      validator: data?.validator?._id,
      bettermenLead: data?.bettermentLead?._id,
      supervisor: data?.supervisor?._id,
      leader: data?.leader?._id,
      challenges: JSON.stringify(
        data?.challenges?.map((challenge) => ({
          ...challenge,
          leverages: challenge?.leverages?.map((leverage) => leverage?._id),
        }))
      ),
      member: data?.members?.map((member) => member?._id),
    };

    let circleForm = helpers.generateFormData(circle);
    return circleForm;
  };

  const createCircle = async () => {
    setLoading(true);
    let res;
    if (mode === "create")
      res = await Request.postCircle(ParseCircleData(circle));
    else res = await Request.putCircle(ParseCircleData(circle), circle?._id);
    setLoading(false);
    getErrorAlert(res);
    if (mode === "create") {
      setAlertCode(res.body?.code);
      setAlert({
        type: "success",
      });
      return setShowAlert(true);
    }
    setReload(true);
  };

  const getErrorAlert = (res) => {
    setAlertCode("");
    if (res.code) {
      setAlert({
        type: "error",
        msg: getErrorMessage(res.internalCode),
      });
    }
  };

  const onButtonTapClick = (viewName) => {
    //can't change screen when editing
    if (mode === "edit") {
      setAlert({
        type: "warning",
        msg: "Por favor intente guardar los cambios",
      });
      return setShowAlert(true);
    }
    setView(viewName);
  };

  const components = {
    CircleData: CircleData({
      setCircleData,
      circle,
      setView,
      mode,
      discardEdit,
      createCircle,
    }),
    CircleTeam: CircleTeam({
      setCircleData,
      circle,
      setView,
      mode,
      discardEdit,
      createCircle,
    }),
    CircleChallenges: CircleChallenges({
      setCircleData,
      circle,
      setView,
      mode,
      discardEdit,
      createCircle,
    }),
    KickOff: KickOff({
      setCircleData,
      circle,
      setView,
      mode,
      createCircle,
    }),
  };

  //in case of edit circle
  const parseEditCircle = useCallback((circle) => {
    return {
      ...circle,
      date: new Date(circle?.date),
      firstWorkShop: new Date(circle?.firstWorkShop),
      validator: searchMember("validator", circle),
      supervisor: searchMember("supervisor", circle),
      leader: searchMember("leader", circle),
      bettermentLead: searchMember("bettermenLead", circle),
      members: searchMember("member", circle),
      challenges: circle?.challenges?.map((challenge) => ({
        ...challenge,
        date: new Date(challenge?.secondWorkShop),
      })),
    };
  }, []);

  const searchMember = (type, circle) => {
    const objParse = (person) => ({
      ...person,
      label: person?.name,
      value: person?._id,
      nameTarget: person?.memberType,
    });
    if (type === "member") {
      const members = circle?.team?.filter(
        (member) => member.memberType === type
      );
      return members?.map((person) => objParse(person));
    }
    let person = circle?.team?.find((member) => member.memberType === type);
    return objParse(person);
  };

  //handles the mode it finds the component
  useEffect(() => {
    if (!id || !savedCircle || !circle) return;
    if (JSON.stringify(savedCircle) !== JSON.stringify(circle)) {
      return setMode("edit");
    }
    setMode("view");
  }, [id, savedCircle, circle]);

  //getCircle in case edit or view mode
  useEffect(() => {
    //the endpoint will not be called when a circle is created and no changes have been saved from the edition
    if (!id || (savedCircle && !reload)) return;
    setReload(false);
    //conditional not to reload the page
    (async () => {
      setMode("view");
      setLoading(true);
      const res = await Request.getCircle(id);
      setLoading(false);
      if (res?.status !== 200) {
        return helpers.redirect("/circles/list");
      }
      const parse = parseEditCircle(res?.body);
      setCircle(parse);
      setSavedCircle(parse);
    })();
  }, [id, parseEditCircle, reload, savedCircle, setLoading]);

  return (
    <div className="New-circle ">
      <div className="new-circle-steps">
        <FormSideBar
          onClick={onButtonTapClick}
          currentView={view}
          tabs={tabs}
        />
      </div>
      <div className="new-circle-container">
        <div className="new-circle-content">{components[view]}</div>
      </div>
      <CreateCircleAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        code={alertCode}
        circle={circle}
        type={alert?.type}
        msg={alert?.msg}
        setIsOpenSupport={setIsOpenSupport}
        isOpenSupport={isOpenSupport}
      />
    </div>
  );
};
export default NewCircle;
