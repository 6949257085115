import React from "react";

import "./styles.scss";

const HolosCheck = ({ label, className, isChecked, onChange }) => {
  return (
    <div className={`Holos-check ${className ? className : ""}`}>
      <label htmlFor="checkbox">{label}</label>
      <input
        type="checkbox"
        name="checkbox"
        id="checkbox"
        checked={isChecked}
        onChange={(e) => {
          onChange(e.currentTarget.checked);
        }}
      />
    </div>
  );
};

export default HolosCheck;
