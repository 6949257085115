import React from "react";
import "./styles.scss";
const Tooltip = ({ text, children, className }) => {
  const getClass = () => {
    let name = "tooltip-text";
    if (className) name = `${name} ${className}`;
    return name;
  };
  return (
    <div className="Tooltip">
      <span className={getClass()}>{text}</span>
      {children}
    </div>
  );
};
export default Tooltip;
