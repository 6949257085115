import React, { useState } from "react";
import FormButtonStepper from "../../../../../../shared/components/formButtonStepper";
import ProfilePictureDataForm from "./parts/profilePictureForm";

import "./styles.scss";

const ProfilePictureData = ({ setPersonData, setView }) => {
  const [ canContinue, setCanContinue ] = useState(false)
  const nextStep = "PasswordData";
  const onSubmit = () => {
    setView(nextStep);
  };
  return (
    <div>
      <ProfilePictureDataForm setPersonData={setPersonData} setCanContinue={setCanContinue} />
      <FormButtonStepper
        disabled={!canContinue}
        showGoBack={true}
        goBackView={"JobData"}
        showSkip={true}
        skipTo={nextStep}
        setView={setView}
        onSubmit={onSubmit}
      />
    </div>
  );
};
export default ProfilePictureData;
