import { LocationOnOutlined, Maximize, PictureAsPdf } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import Draggable from "react-draggable";
import IconText from "../../../../../../shared/components/iconText";
import InfoCard from "../../../../../../shared/components/infoCard";
import qr from "../../../../../../shared/components/googleMap/icons/QR.png";
import qrPurple from "../../../../../../shared/components/googleMap/icons/currentQR.png";
import RequestMap from "../../../../../maps/services/request";
import "./location.scss";
import { getCenterPolygon } from "../../../../../../utils/helpers";
import GoogleMap from "../../../../../../shared/components/googleMap";
import { Loading } from "../../../../../../context";

const PreviewLocation = props => {
  const { plaque, setOpenLocation } = props;

  const [mapMarkers, setMapMarkers] = useState([]);
  const [centerMap, setCenterMap] = useState(null);
  const [, setLoading] = useContext(Loading);
  const [cancelDrag, setCancelDrag] = useState(false);
  const [positionY, setPositionY] = useState(0);
  const arrayFields = [
    {
      icon: <LocationOnOutlined />,
      label: "Ubicación",
      value: plaque?.location?.name,
    },
  ];

  useEffect(() => {
    const id = plaque.location._id;
    (async () => {
      setLoading(true);
      const map = (await RequestMap.getMap(id)).body;
      setLoading(false);
      if (map) {
        const markers = map.plaques.map(plaque => {
          return {
            id: plaque._id,
            type: "plaque",
            images: plaque.images,
            name: plaque.name,
            circleName: plaque.circleName,
            coords: {
              lat: parseFloat(plaque.latitude),
              lng: parseFloat(plaque.longitude),
            },
            current: false,
          };
        });
        setMapMarkers(markers);
        setCenterMap(getCenterPolygon(map.areaCoords));
      }
    })();
  }, [plaque.location._id, setLoading]);

  const handleDrag = position => {
    if (position.y < 0) return false;
    return true;
  };

  const resizeDrag = position => {
    if (position.y < 350) return setPositionY(0);
    setPositionY(800);
    setTimeout(() => {
      setOpenLocation(false);
    }, 1000);
  };

  return (
    <Draggable
      axis="y"
      onDrag={(e, position) => handleDrag(position)}
      onStop={(e, p) => resizeDrag(p)}
      disabled={cancelDrag}
      position={{ x: 0, y: positionY }}>
      <div className="Preview-location">
        <div
          className="preview-location-draggable"
          onTouchStart={() => setCancelDrag(false)}>
          <div className="preview-location-border">
            <Maximize className="Maximize" />
          </div>
          <div className="preview-location-title">
            <IconText
              icon={<PictureAsPdf />}
              text={plaque?.file?.name}
              iconColor={"var(--purpleBHP)"}
              fontColor={"var(--softBlack)"}
              fontSize={"14px"}
              fontWeight={"Normal"}
              separatingMargin={"1.375em"}
            />
          </div>
          <InfoCard
            arrayFields={arrayFields}
            fontSize={"14px"}
            fontWeight={500}
          />
        </div>
        <div
          className="google-map-card"
          style={{ height: "400px" }}
          onTouchStart={() => setCancelDrag(true)}>
          <GoogleMap
            markers={mapMarkers}
            options={{ center: centerMap, zoom: 5 }}
            markerIconURL={qr}
            currentMarkerIconURL={qrPurple}
            currentMarker={plaque._id}
            area={plaque.location.areaCoords}
            mode={"view"}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default PreviewLocation;
